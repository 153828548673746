import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import "../../css/DashMembers.css"
import ExcelModal from '../../components/Modal/OpenModal';
import instance from '../../../utils/axios';
import Member2 from "./Member2"
import MemberTable from "./MemberTable"

const Members = () => {

    const [showModal, setShowModal] = useState(false); 
    const [isActive, setIsActive] = useState(1);
    const [filteredDataLength, setFilteredDataLength] = useState(0);
    const [filteredDataLength1, setFilteredDataLength1] = useState(0);
    const [excelFile, setExcelFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setExcelFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setExcelFile(file);
  };


  const handleActive = (btn) => {
    setIsActive(btn);
  };

  const handleFilteredDataLength = (length) => {
    setFilteredDataLength(length);
  };

  const handleFilteredDataLength1 = (length) => {
    setFilteredDataLength1(length);
  };

  const toggleDropdown = () => {
    const dropdownContent = document.getElementById("dashMembersDropdownContent");
    if (dropdownContent) {
      dropdownContent.style.display = dropdownContent.style.display === "block" ? "none" : "block";
    }
  };


  const addMember = async () => {
    setLoading(true);
    if (!excelFile) {
      setLoading(false);
      return; 
    }
    try {
      const formData = new FormData();
      formData.append("file", excelFile);
      const response = await instance.post(
        "/api/user/member/addMemberFromExcel",
        formData
      );
      if (response.data.success) {
        setLoading(false)
        toast.success("Members added successfully.");
        closeModal();
      } else {
        setLoading(false)
        toast.error("Failed to add Members. Please try again.");
      }
    } catch (error) {
      setLoading(false)
      toast.error("An error occurred. Please try again later.");
    }
  };

  useEffect(() =>{
    if(excelFile){
      addMember();
    }
    // eslint-disable-next-line 
  },[]);

  return (
    <div>
        <div className="dashMembers">
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className='dashboard-title dashMembers-container'
            >
                Members Data 
                <div className="dashMembers-dropdown">
                    <MoreVertIcon className='dashMembersDropBtn' onClick={toggleDropdown}/>
                    <div id="dashMembersDropdownContent" className="dashMembers-drop-content">
                        <Link className='new-AContent' to={'/dashboard/members/new'} >
                            New Members
                        </Link>
                        <div onClick={openModal} className="new-AContent">Excel Data</div>
                    </div>
                </div>
            </motion.div>
            {showModal && (
                <ExcelModal 
                    showModal={showModal}
                    closeModal={closeModal}
                    h4="Add Excel Sheet Data For New Member"
                    handleFileChange={handleFileChange}
                    addMember={addMember}
                    loading={loading}
                />
            )}
            <div className="tabsContainer">
            <motion.div
              className="btnContainer"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <button
                className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
                onClick={() => handleActive(1)}
              >
                Offline ({filteredDataLength})
              </button>
              <button
                className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
                onClick={() => handleActive(2)}
              >
                Online ({filteredDataLength1})
              </button>
            </motion.div>
            {isActive === 1 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="tabContent">
                    <Member2 onFilteredDataLength={handleFilteredDataLength} />
                </div>
              </motion.div>
            )}
            {isActive === 2 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="tabContent">
                  <MemberTable onFilteredDataLength1={handleFilteredDataLength1} />
                </div>
              </motion.div>
            )}
          </div>
        </div>
    </div>
  )
}

export default Members