import React, { useEffect, useState } from 'react';
import useUserStore from '../../../store';
import { Link, useNavigate } from 'react-router-dom';
import instance from '../../../utils/axios';
import toast from 'react-hot-toast';
import '../../css/DashAdmin.css'
import Loader from '../../../components/MainLoader/Loader';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShowResponseModal from '../form-response/showResponseModal';


const DashUser = () => {

    const user = useUserStore((state) => state.user);
    const nav = useNavigate();

    const [forms, setForms] = useState([]);
    const [isTestChecked, setIsTestChecked] = useState(false);
    const [test, setTest] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ loading, setLoading ] = useState(false); 
    const [uploadedFiles, setUploadedFiles] = useState({}); 
    const [ showResponse, setShowResponse ] = useState(false);


    const checkFormResponse = async () => {
        try {
            const response = await instance.get(`/api/user/getFormForUserPage/${user._id}`);
            if (response.data.success) {
                setForms(response.data.data);
            }
        } catch (error) {
            console.log("check form response error: ", error);
            toast.error(error.message)
        } finally { 
            setIsLoading(false);
        }
    }

    const getAssignedTest = async () => {
        try {
            const response = await instance.get(`/api/user/getAssignedTest/${user._id}`);
            if (response.data.success) {
                setIsTestChecked(true);
                setTest(response.data.data);
            }
        } catch (error) {
            console.log("check form response error: ", error);
            toast.error(error.message)
        } finally { 
            setIsLoading(false);
        }
    }

    useEffect(() => {
        checkFormResponse();
        getAssignedTest();
        getReAssignedTest();
        // eslint-disable-next-line
    }, [])


    const handleFileUpload = (event, testName) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const base64String = event.target.result;
            setUploadedFiles((prevFiles) => ({
                ...prevFiles,
                [testName]: base64String,
            }));
        };

        reader.readAsDataURL(file);
    };

    const getReAssignedTest = async () => {
        try {
            const response = await instance.get(`/api/user/sendIncompleteTesting/${user._id}`);
            if (response.data.success) {
                setIsTestChecked(true);
                setTest(response.data.data);
            }
        } catch (error) {
            console.log("check form response error: ", error);
            toast.error(error.message)
        } finally {
            setIsLoading(false);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await instance.post(`/api/user/uploadTestResults`, {uploadedFiles, testId: test[0]._id});
            if (response.data.success) {
                toast.success("Test results uploaded successfully!");
                nav(0);
            } else  { 
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log("upload test results error: ", error);
            toast.error(error.message);
        } finally { 
            setLoading(false);
        }
    };    

    const handleClick = () => { 
        setShowResponse(!showResponse);
    }

    return (
        <div className="dashMembers">
            <div className="dashMembers-container">
                <h2 className="dashboard-title"> Exercise Forms </h2>
            </div>
            { isLoading && <Loader /> }
            { !isLoading &&  (
            <div className="dash-user">
                <h1 className='dash-user-container-h1'>Hello, {user.name}!</h1>
                <h4 className='dash-user-container-h4' >Team Planet Muscle Welcomes You</h4>

                {forms?.slice().reverse().map((form, value) => (
                    <div key={value} >
                        <span className='spare-span'>
                            { forms.length - value }. {form.planName[0]}  {form.planName[1]}
                        </span>
                        {!(form.responseData && Object.keys(form.responseData).length > 0) && (
                            <div className="dash-user-container">
                                <h4 className='dash-user-container-h4' >Your {form.planName} comes with a form  </h4>
                                <p className='dash-user-container-p'>Would you mind filling out a brief exercise science form to help us tailor a personalized exercise prescription just for you?</p>
                                <Link to='/dashboard/user/form' state={{ formId: form.formId, planId: form.planId }} >
                                    <button className="btn-fill-form dash-btn">Fill Response</button>
                                </Link>
                            </div>
                        )}
                        { form.responseData.isAssigned === false && (
                            <div className="dash-user-container">
                                <h2 className='dash-user-container-h2' >Thank you for filling in the form!</h2>
                                <p className='dash-user-container-p'>Your form is being checked by professionals. We'll get back to you shortly.</p>
                                <button className='dash-btn' onClick={handleClick}  > View Response  </button>
                                { showResponse && (
                                    <ShowResponseModal 
                                        closeModal={() => setShowResponse(false)}
                                        name={user.name}
                                        formId={form.responseData._id}
                                    />
                                )}
                                <h3 className='dash-user-container-h3'>Next Steps:</h3>
                                <ul className='dash-user-container-ul' >
                                    <li className='dash-user-container-li' >1. Keep checking this page for updates on your personalized exercise prescription.</li>
                                    <li className='dash-user-container-li'>2. The team will send you a list of tests you need to undergo and upload the results here.</li>
                                </ul>
                            </div>
                        )}
                        {form.responseData?.isAssigned && !form.responseData.testUploaded && isTestChecked && test?.map((testData, index) => (
                        <div className='dash-user-container'  key={index}>
                            <h3 className='dash-user-container-h2' >
                                { testData.status === 'partially accepted' ? "Re Assigned Test "  : "Assigned Test" }
                            </h3>
                            <p className='dash-user-container-p'>
                                { testData.status === 'partial accepted' ? "These tests have been reassigned to you due to some reasons, kindly refill them and submit!"  : "Please upload the results of the following tests for further assistance:" }
                            </p>
                            {testData?.testResponse?.map((test, testNameIndex) => (
                                <div className='dash-user-container-1' key={testNameIndex}>
                                    <h5 className='dash-user-container-q' > {testNameIndex + 1}. Test Name: {test.testName}</h5>
                                    <input
                                        type="file"
                                        accept='.pdf'
                                        id={`file-${index}-${testNameIndex}`}
                                        onChange={(e) => handleFileUpload(e, test.testName)}
                                    />
                                    {test.reason && test.isPass === false &&  (
                                        <span  className='dash-user-container-p' >
                                            Reasons : { test.reason}
                                        </span>
                                    )}
                                </div>
                            ))}
                            <button disabled={loading}  className={`dash-btn view-btn ${loading ? "upload-btn" : ""}`} onClick={handleSubmit}>
                                {loading ? (
                                    <AutorenewIcon className="loader-css" />
                                ) : "Submit Test Results"}
                            </button>
                        </div>
                    ))}
                     {form.responseData.testUploaded === true && form.testingData.prescriptionCreated === false && (
                        <div className="dash-user-container">
                            <h2 className='dash-user-container-h2' >Thank you for filling in your Results!</h2>
                            <p className='dash-user-container-p'>Your response is being checked by professionals. We'll get back to you shortly.  </p>
                            <h3 className='dash-user-container-h3'>Next Steps:</h3>
                            <ul className='dash-user-container-ul' >
                                <li className='dash-user-container-li' >1. Keep checking this page for updates on your personalized exercise prescription.</li>
                                <li className='dash-user-container-li'>2. In case your response does not meets our criteria, we will send the form back for reassurance.</li>
                                <li className='dash-user-container-li'>3. If it meets our criteria, your personalized exercise plan will be sent to you. </li>
                            </ul>
                        </div>
                    )}
                    {form.responseData.testUploaded === true && form.testingData.prescriptionCreated === true && (
                        <div className="dash-user-container">
                            <h2 className='dash-user-container-h2' >Thank you for filling in your Results!</h2>
                            <p className='dash-user-container-p'>Your Personalized Prescription have been created and an exercise page have been created for you!</p>
                            <p className='dash-user-container-p'>Click here to visit!</p>
                            <Link to='/dashboard/exercises' >
                                    <button className="btn-fill-form dash-btn">Your Prescription!</button>
                            </Link>
                        </div>
                    )}
                    </div>
                ))}
            </div>
            )}

        </div>
    );
};

export default DashUser;

