import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DeleteModal from '../../components/Modal/DeleteModal';

const ExEmployee = () => {

  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [userIdToRestore, setUserIdToRestore] = useState("");

  useEffect(() => {
    instance.get('/api/user/getExEmployees').then((response) => {
      const currentDate = new Date();
      if (response.data.success) {
        const rows = response.data.data && response.data.data.filter((user) => user.role !== "Admin").slice().reverse().map((user) => {
          const startDate = user.start ? new Date(user.start) : new Date(user.createdAt);
          const status = calculateStatus(startDate, currentDate);
          const startTime = moment(user.batch.batchStartTime, 'HH:mm:ss').format('h:mm A');
          const endTime = moment(user.batch.batchEndTime, 'HH:mm:ss').format('h:mm A');
          const shiftInfo = `${user.batch.batchShift}: ${startTime} - ${endTime}`;
            return {
              id: user._id,
              col1: user.file && user.file.secure_url,
              col2: user.uniqueId || "Deleted",
              col3: user.name,
              col4: user.email,
              col5: user.mobile,
              col6: user.gymLocation,
              col7: user.path || "N/A",
              col8: user.role,
              col9: shiftInfo || "N/A",
              col10: startDate.toLocaleDateString("en-GB"),
              col11: status,
          };
        });
        setUserData(rows);
    }
    }).catch((error) => {
    })
  }, []);

  const calculateStatus = (startDate, currentDate) => {
    const diffInMilliseconds = startDate - currentDate;
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  
    if (diffInDays > 0) {
      // Joining date is in the future, calculate number of days left
      return `${Math.ceil(diffInDays)} days left`;
    } else {
      // Start date is in the past, calculate number of months spent
      const diffInMonths = -diffInDays / 30; // Approximation of months
      return `${Math.floor(diffInMonths)} months`;
    }
  };


  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const handleRestore = async(id) =>{
    setUserIdToRestore(id);
    setShowModal1(true);
  }

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deletePermanent/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("User deleted successfully");
        setUserData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
        setShowModal(false);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const confirmRestore = async() =>{
    try {
      const response = await instance.post(`/api/user/restoreUsers/${userIdToRestore}`);
      if (response.data.success) {
        toast.success("User Restored successfully");
        setUserData((prevData) => prevData.filter((user) => user.id !== userIdToRestore));
        setShowModal1(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Unique Id', width: 100 },
    {
      field: 'col3', 
      headerName: 'Counsellor Name',
      width: 170,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/employees/employeePage/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },

    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Performance Center', width: 150 },
    { field: 'col7', headerName: 'Health Issues', width: 150 },
    { field: 'col8', headerName: 'Position', width: 150 },
    { field: 'col9', headerName: 'Shift Time', width: 250 },
    { field: 'col10', headerName: 'Joining Date', width: 150 },
    { field: 'col11', headerName: 'Status', width: 150 },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="activeButton" onClick={() => handleRestore(params.row.id)}>Restore</div>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)} >Delete</div>
          </div>
        );
      },
    },
  ]; 

  const data = {
    rows: userData,
    columns: columns.concat(actionColumn),
  };



  return (
    <div className='data2Table dashboard'>
      <div className='dataGrid'>
        <DataGrid 
            {...data}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}      
  
            pageSize={9}
            rowsPerPageOptions={[9]}
        />
      </div>
      {showModal && (
        <DeleteModal 
           h4="Are you sure you want to delete this Ex employee?"
           confirmDelete={confirmDelete}
           closeModal={()=>setShowModal(false)}
        />
      )}
      {showModal1 && (
        <DeleteModal 
           h4="Are you sure you want to restore this Ex employee?"
           confirmDelete={confirmRestore}
           closeModal={()=>setShowModal1(false)}
        />
      )}

    </div>
  )
}
export default ExEmployee
