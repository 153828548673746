import React from "react";
import useUserStore from "../../../store";
import "../../css/DashProfile.css";
import { Link } from "react-router-dom";
import { defaultUrl } from "../../../data/Images";

const DashProfileData = () => {
  const user = useUserStore((state) => state.user);

  const getAddressString = () => {
    if (user.address && user.address.pincode && user.address.line1) {
      return `${user.address.line1}, ${user.address.line2}, ${user.address.city}, ${user.address.state}, ${user.address.pincode}`;
    }
    return "";
  };

  return (
    <div>
      <div className="singleData">
        <h3 className="dashboard-title">Your Profile</h3>
        <Link to={`/dashboard/profile/${user._id}`}>
          <button className="dash-btn editButton">Edit</button>
        </Link>
        <div className="singleItem">
          <img
            src={user?.file?.secure_url || defaultUrl}
            alt="profilePhoto"
            className="singleItemImage"
          />
          <div className="singleItemDetails">
            <h1 className="singleItemName">{user.name}</h1>
            <div className="itemDetails">
              <span className="itemKey">Email:</span>
              <span className="itemValue">{user.email}</span>
            </div>
            <div className="itemDetails">
              <span className="itemKey">Mobile:</span>
              <span className="itemValue">{user.mobile}</span>
            </div>
            <div className="itemDetails">
              <span className="itemKey">Address:</span>
              <span className="itemValue">{getAddressString() || "N/A"}</span>
            </div>
            <div className="itemDetails">
              <span className="itemKey">Country:</span>
              <span className="itemValue">{user.country || "N/A"}</span>
            </div>
            <div className="itemDetails">
              <span className="itemKey">Role:</span>
              <span className="itemValue">{user.role}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashProfileData;
