import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import { motion } from "framer-motion"
import useUserStore from '../../../store';
import moment from 'moment';
import "../../css/DashEmployee.css"
import DeleteModal from '../../components/Modal/DeleteModal';

const DashEmployee = () => {

  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [select, setSelect] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const user = useUserStore((state) => state.user)

  const getData = async (currentPage, size) => {
    try {
      const response = await instance.get('/api/user/getEmployee', {
        params: {
          userId: user._id,
          page: currentPage,
          pageSize: size,
          staffGymLocation: user.gymLocation, 
          role: user.role
        }});
      const currentDate = new Date();
      if (response.data.success) {
        const rows = response.data.data && response.data.data.filter((user) => user.role !== "Admin").slice().reverse().map((user) => {
          const startDate = user.start ? new Date(user.start) : new Date(user.createdAt);
          const status = calculateStatus(startDate, currentDate);
          const startTime = moment(user.batch.batchStartTime, 'HH:mm:ss').format('h:mm A');
          const endTime = moment(user.batch.batchEndTime, 'HH:mm:ss').format('h:mm A');
          const shiftInfo = `${user.batch.batchShift}: ${startTime} - ${endTime}`;
          return {
            id: user._id,
            col1: user.file && user.file.secure_url,
            col2: user.uniqueId || "N/A",
            col3: user.name,
            col4: user.email,
            col5: user.mobile,
            col6: user.gymLocation,
            col7: user.path || "N/A",
            col8: user.role,
            col9: shiftInfo || "N/A",
            col10: startDate.toLocaleDateString("en-GB"),
            col11: user.salary,
            col12: status,
          };
        });
        setUserData(rows);
        setFilteredData(rows);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const calculateStatus = (startDate, currentDate) => {
    const diffInMilliseconds = startDate - currentDate;
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

    if (diffInDays > 0) {
      // Joining date is in the future, calculate number of days left
      return `${Math.ceil(diffInDays)} days left`;
    } else {
      // Start date is in the past, calculate number of months spent
      const diffInMonths = -diffInDays / 30; // Approximation of months
      return `${Math.floor(diffInMonths)} months`;
    }
  };

  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value} alt="user" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
      ),
    },
    { field: 'col2', headerName: 'Unique Id', width: 100 },
    {
      field: 'col3',
      headerName: 'Counsellor Name',
      width: 170,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/employees/employeepage/${params.row.id}`}
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },

    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Performance Center', width: 150 },
    { field: 'col7', headerName: 'Health Issues', width: 150 },
    { field: 'col8', headerName: 'Position', width: 150 },
    { field: 'col9', headerName: 'Shift Time', width: 250 },
    { field: 'col10', headerName: 'Joining Date', width: 150 },
    {
      field: 'col11', headerName: 'Base Salary', width: 150,
      renderCell: (params) => (
        <div>{`₹ ${new Intl.NumberFormat('en-IN').format(params.value)}`}</div>
      ),
    }, { field: 'col12', headerName: 'Status', width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {

        return (
          <div className="cellAction">
            <Link to={`/dashboard/employees/employeepage/${params.row.id}`} style={{ textDecoration: "none" }} >
              <div className="viewButton">View</div>
            </Link>
            {user.role === "Admin" ? <div className="deleteButton" onClick={() => handleDelete(params.row.id)} >Delete</div> : ""}
          </div>
        );
      },
    },

  ];

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteUser/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("Counsellor deleted successfully");
        setUserData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
        setShowModal(false);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
    getData(params.page, params.pageSize); // Fetch data with updated pageSize
  };


  const data = {
    rows: filteredData,
    columns: columns,
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [page, pageSize])



  const handleFilter = () => {
    if (!startDate || !endDate || !select) {
      toast.error("Please select all the field.");
      return;
    }
    const formattedStartDate = startDate ? new Date(startDate) : null;
    if (formattedStartDate) {
      formattedStartDate.setHours(0, 0, 0, 0);
    }
    const formattedEndDate = endDate ? new Date(endDate) : null;
    if (formattedEndDate) {
      formattedEndDate.setHours(0, 0, 0, 0);
    }
    const selectedType = select.toLowerCase();
    const filteredData = userData.filter((user) => {
      if (selectedType.includes("startdate")) {
        const newDate = parseDate(user.col10);
        const startDateCondition = ((newDate >= formattedStartDate) || (newDate === formattedStartDate)) &&
          ((newDate <= formattedEndDate) || (newDate === formattedEndDate));
        return startDateCondition;
      }
      return true;
    });
    if (filteredData === null || filteredData.length === 0) {
      toast.error("No Members Found");
    }
    setFilteredData(filteredData);
  };

  const parseDate = (dateStr) => {
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // January is month 0 in JavaScript Date object
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day, 0, 0, 0, 0);
    }
    return null;
  };



  return (
    <div className='dataTable'>
      <motion.div className="datatableTitle dashboard-title"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        Counsellors Data ({filteredData.length})
        <Link to='/dashboard/employees/new' style={{ textDecoration: "none" }} className='table-link' >
          Add New
        </Link>
      </motion.div>
      <motion.div className="filter-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}

      >
        <input
          type="date"
          value={startDate}
          className='filter-input'
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          className='filter-input'
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select className='filter-select' value={select} onChange={(e) => setSelect(e.target.value)}>
          <option value="">Select Filter Type</option>
          <option value="StartDate">Joining Date</option>
        </select>
        <button className='comment-btn dash-btn' onClick={handleFilter}>Filter</button>
      </motion.div>
      <motion.div className='dataGrid'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <DataGrid
          {...data}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          onPageSizeChange={handlePageChange}

        />
      </motion.div>
      {showModal && (
        <DeleteModal
          h4="Are you sure you want to delete this Counsellor? "
          confirmDelete={confirmDelete}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default DashEmployee;
