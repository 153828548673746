import React, { useState } from "react";
import "./Message.css";
import useUserStore from "./../../store";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import MessageRoutes from "./MessageRoutes";
import Conversation from "./Conversation";
import ChatSidebar from "./ChatSidebar";

const Message = () => {
  const loggedIn = useUserStore((state) => state.loggedIn);

  const [selectedChatRoomId, setSelectedChatRoomId] = useState(null);
  if (!loggedIn) {
    toast.error("Please Login To continue");
    return <Navigate to={"/login"} />;
  }

  const handleChatRoomClick = (chatRoomId) => {
    setSelectedChatRoomId(chatRoomId);
  };

  const resetSelectedChatRoomId = () => {
    setSelectedChatRoomId(null);
  };

  return (
    <>
      <section className="chat-section">
        <div className="chat-container">
          <MessageRoutes />
          <div className="chat-content">
            <ChatSidebar onChatRoomClick={handleChatRoomClick} />
            {!selectedChatRoomId ? (
              <div className="conversation conversation-default active">
                <TelegramIcon />
                <p>Select Chat & View Conversation </p>
              </div>
            ) : (
              <Conversation chatRoomId={selectedChatRoomId} resetSelectedChatRoomId={resetSelectedChatRoomId} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Message;
