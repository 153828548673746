import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import useUserStore from '../../../store';
import { defaultUrl } from '../../../data/Images';

const ExMembers = () => {

  const [userData, setUserData] = useState([]);
  const user = useUserStore((state) => state.user)

  const getData = async () => {
    try {
      const response = await instance.get('/api/user/getExOnlineUsers', {
        params: {
          userId: user._id,
        }
      });
      if (response.data.success) {
        const onlineMembers = response.data.data;
        const rows = await Promise.all(onlineMembers.slice().reverse().map(async (user) => {
          const response1 = await instance.get(`/api/user/activePlans/${user._id}`);
          const newData = response1.data.data;
          return {
            id: user._id,
            col1: user.file.secure_url,
            col2: user.uniqueId || "N/A",
            col3: user.name,
            col4: user.email,
            col5: user.mobile,
            col6: user.occupation || "N/A",
            col7: newData?.gymLocation || "N/A",
            col8: user?.path || "N/A",
            col9: newData?.planType || "N/A",
            col10: newData?.planTime || "N/A",
            col11: new Date(newData?.startDate).toLocaleDateString("en-GB") || "N/A",
            col12: new Date(newData?.endDate).toLocaleDateString("en-GB") || "N/A",
            col13: newData?.daysLeft ,
            col14: newData?.status || "N/A",
          };
        }));
  
        setUserData(rows);
      }
    } catch (error) {
      console.log(error);
      toast.error("An Error Occurred. Please Try Again!");
    }
  }


  useEffect(()=>{
    getData();
    // eslint-disable-next-line 
  },[user._id])


  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={ params.value || defaultUrl } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Unique ID', width: 100 },
    {
      field: 'col3', 
      headerName: 'Name',
      width: 170,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Occupation', width: 150 },
    { field: 'col7', headerName: 'Performance Center', width: 150 },
    { field: 'col8', headerName: 'Health Issues', width: 150 },
    { field: 'col9', headerName: 'Plan Type', width: 200 },
    { field: 'col10', headerName: 'Plan Time', width: 150 },
    { field: 'col11', headerName: 'Date of Commencement', width: 150 },
    { field: 'col12', headerName: 'Last Date', width: 150 },
    { field: 'col13', headerName: 'Days Left', width: 150 },
    { field: 'col14', headerName: 'Status', width: 150 },
  ];

  const [data, setData] = useState(userData);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const handleDelete = (id) => {
          setData(data.filter(item=>item.id !== id));
        }
      
        return (
          <div className="cellAction">
            <Link to={`/dashboard/users/userPage/${params.row.id}`}
            style={{ textDecoration: "none" }} >
              <div className="viewButton">View</div>
            </Link>
            <div className="deleteButton" onClick={()=>handleDelete(params.row.id)} >Delete</div>
          </div>
        );
      },
    },
  ]; 

  const newData = {
    rows: userData,
    columns: columns.concat(actionColumn),
  };

  return (
    <div className='data2Table'>
      <div className='dataGrid'>
        <DataGrid 
              {...newData}
              initialState={{
                ...newData.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}      
    
              pageSize={9}
              rowsPerPageOptions={[9]}
          />
      </div>
    </div>
  )
}

export default ExMembers;