import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../../css/DashProducts.css";
import axios from "axios";
import { Switch } from "@mui/material";

const DashProgramForm = ({ isUpdate }) => {
  const [file, setFile] = useState(null);
  const nav = useNavigate();
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);

  const [programData, setProgramData] = useState({
    file: '',
    time: '',
    desc: '',
    pincode: '',
    region: '',
    district: '',
    office: '',
    circle: '',
    category: '',
    quantity: '',
    place: '',
    price: '',
    showEvent: false,
  });

  useEffect(() => {
    if (isUpdate && programId) {
      instance
        .get(`/api/user/getParticularprogram/${programId}`)
        .then((response) => {
          if (response.data.success) {
            const eventData = response.data.data;
            setProgramData({
              ...eventData,
              office: eventData.town,
              circle: eventData.state,
              region: eventData.city,
              showEvent: eventData.showEvent,
            });
            if (eventData.file) {
              setFile(eventData.file.secure_url); 
            }
          } else {
            console.error(response.data.error);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [isUpdate, programId]);

  const handlepincode = async () => {
    const options = {
      method: 'POST',
      url: 'https://pincode.p.rapidapi.com/',
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': '67e5833798msha4de36d764fa485p18c24cjsne915cf0b56c4',
        'X-RapidAPI-Host': 'pincode.p.rapidapi.com'
      },
      data: {
        searchBy: 'pincode',
        value: programData.pincode,
      }
    };
    try {
      const response = await axios.request(options);
      const responseData = response.data;
      if (responseData.length > 0) {
        const town = responseData[0].office;
        const region = responseData[0].region;
        const circle = responseData[0].circle;
        const district = responseData[0].district;
        setProgramData((prevData) => ({
          ...prevData,
          office: town,
          circle: circle,
          region: region,
          district: district,
        }));
      } else {
        toast.error("Please enter a valid PinCode");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePincodeBlur = () => {
    if (programData.pincode === "") {
      setProgramData((prevData) => ({
        ...prevData,
        office: "",
        circle: "",
        region: "",
        district: "",
      }));
    } else {
      handlepincode();
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "showEvent") {
      setProgramData((prevData) => ({
        ...prevData,
        showEvent: checked,
      }));
      const notificationMessage = checked ? "The Event is currently accessible via the website." : 
      "The website is no longer displaying the event.";
      toast.success(notificationMessage);
    } else {
      setProgramData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const endpoint = isUpdate
        ? `/api/user/updateProgram/${programId}`
        : "/api/user/programData";
      const response = await instance[isUpdate ? "put" : "post"](endpoint, {
        ...programData,
        file: file,
        district: programData.district,
        town: programData.office,
        state: programData.circle,
        city: programData.region,
      });
      if (response.data.success) {
        setLoading(false);
        const successMessage = isUpdate ? "Event updated successfully!" : "Event added successfully!";
        toast.success(successMessage);
        nav("/dashboard/programs");
      } else {
        setLoading(false);
        toast.error(`Failed to ${isUpdate ? "update" : "Add"} an event.`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred");
    }
  };

  const handleRemoveImage = () => {
    setFile(null); // Set the file state to null to remove the image
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      toast.error('No file selected.');
      return;
    }

    if (file) {
      toast.error('Maximum one file allowed.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = () => {
      setFile(reader.result);
    };

    reader.onerror = (error) => {
      toast.error(error);
    };
  };

  return (
    <div>
      <div className="productForm">
        <h4 className="dashboard-title dashMembers-container">
          {isUpdate ? "Update Your Event" : "Add An Event"}
        </h4>
        <div className="product-form-container">
          <form onSubmit={handleSubmit} className="product-form">
            <div className="product-image-div">
              <div className="product-image-container">
              {file && (
            <div className="productImgContainer">
              <img src={file} alt="" className="product-img" />
              <div>
                <DeleteIcon className="delete-btn" onClick={handleRemoveImage}/>
              </div>
            </div>
          )}
          {!file && (
            <div>
              <label className="product-label" htmlFor="file">
                <DriveFolderUploadIcon className="icons" />
              </label>
              <input
                type="file"
                id="file"
                required
                accept=".png, .jpeg, .jpg"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          )}
              </div>
            </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="category">
                  Event Category{" "}
                </label>
                <input
                  type="text"
                  required
                  name="category"
                  value={programData.category}
                  onChange={handleChange}
                  placeholder="Enter category Name"
                />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="time">
                  Event Date & Time{" "}
                </label>
                <input
                  type="text"
                  required
                  name="time"
                  value={programData.time}
                  onChange={handleChange}
                  placeholder="Enter Event Date & time"
                />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="place">
                  Event Quantity{" "}
                </label>
                <input
                  type="number"
                  name="quantity"
                  min={1}
                  required
                  value={programData.quantity}
                  onChange={handleChange}
                  placeholder="Enter the event quantity"
                />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="place">
                  Event Place{" "}
                </label>
                <input
                  type="text"
                  name="place"
                  required
                  value={programData.place}
                  onChange={handleChange}
                  placeholder="Enter the event place"
                />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="pincode">
                  Event PinCode{" "}
                </label>
                <input type="text" id="pincode" name="pincode" required min={0} max={6} maxLength={6} value={programData.pincode} onChange={handleChange} onBlur={handlePincodeBlur} placeholder='Enter pincode'/>
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="office">
                  Event Town{" "}
                </label>
                <input type="text" id="office" name="office" value={programData.office} placeholder='Enter town name' onChange={handleChange} />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="region">
                  Event City{" "}
                </label>
                <input type="text" id="region" name="region" value={programData.region} readOnly placeholder='Enter city name' onChange={handleChange} />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="circle">
                  Event State{" "}
                </label>
                <input type="text" id="circle" name="circle" value={programData.circle} readOnly placeholder='Enter State name' onChange={handleChange} />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="district">
                  Event District{" "}
                </label>
                <input type="text" id="district" name="district" value={programData.district} readOnly placeholder='Enter District name' onChange={handleChange} />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="desc">
                  Event Description{" "}
                </label>
                <textarea name="desc" id="desc" value={programData.desc} required onChange={handleChange} cols="30" rows="10" placeholder='Enter the event Description' />
              </div>
              <div className="product-input-div">
                <label className="product-label" htmlFor="price">
                  Event Price{" "}
                </label>
                <input type="number" min={0} id="price" name="price" required value={programData.price} placeholder='Enter the event Price' onChange={handleChange} />
              </div>
              <span style={{ color: "var(--dash-primary)", fontSize: "12px" }} >Show on website</span> <Switch name="showEvent" color="primary" onChange={handleChange} checked={programData.showEvent} ></Switch>
              <button
                type="submit"
                className={`dash-btn ${loading ? "upload-btn" : ""}`}
              >
                {loading ? (
                  <AutorenewIcon className="loader-css" />
                ) : (
                  <>{isUpdate ? "Update Event" : "Add Event"}</>
                )}
              </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashProgramForm;

export const DashAddProgram = () => <DashProgramForm isUpdate={false} />;
export const UpdateProgram = () => <DashProgramForm isUpdate={true} />;
