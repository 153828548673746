import React, { useEffect, useState } from 'react'
import {Page, Text, View, Image, Document, StyleSheet} from "@react-pdf/renderer"
import logo from "../../../assets/logo1.PNG"
import { toast } from 'react-hot-toast'
import instance from '../../../utils/axios'

const Document2 = ({ planDetails, userData, location  }) => {

  const [allTnc, setAllTnc] = useState("");

  const styles = StyleSheet.create({
    header: {
      padding: 20,
    },
    pdfTop: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }, 
    pdfTopLeft: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    pdfTopLogo: {
      width: 75,
      height: 75,
    },
    pdfTop1: {
      fontSize: 20,
      fontWeight: "bold",
    },
    pdfTopRight: {
      display: "flex",
      flexDirection: "column",
    },
    pdfTopRight1: {
      fontSize: 10,
    },
    pdfBottom: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    pdfTncBottom: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "row",
      gap: 5,
    },
    pdfTopRight2: {
      fontWeight: "bold",
      fontSize: 13,
      marginBottom: 5,
    },
    pdfBottomRight: {
      display: "flex",
      flexDirection: "column",
      width: "47vw",
    },
    pdfTnc: {
      display : "flex",
      flexDirection: "column",
      width: "100vw",
    },
  })

  const fetchTnc = async () => {
    try {
      const response = await instance.get("/api/user/getTncData");
      if (response.data.success) {
        setAllTnc(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  
  useEffect(() =>{
    fetchTnc();
  },[]);


  return (
    <Document>
        <Page>
            <View style={styles.header}>
              <View style={styles.pdfTop}>
                <View style={styles.pdfTopLeft}>
                  <Image src={logo} style={styles.pdfTopLogo}/>
                  <Text style={styles.pdfTop1}> Receipt</Text>
                </View>
                <View style={styles.pdfTopRight}>
                  <Text style={styles.pdfTopRight1}>Planet Muscle</Text>
                  <Text style={styles.pdfTopRight1}>186/187, Aram Nagar Part 2 ,Versova,</Text>
                  <Text style={styles.pdfTopRight1}>Mumbai, Maharashtra</Text>
                  <Text style={styles.pdfTopRight1}>8454864948</Text>
                </View>
              </View>
              <View style={styles.pdfBottom}>
              {userData && (
                <View style={styles.pdfBottomRight}>
                      <Text style={styles.pdfTopRight2}>Bill To</Text>
                      <Text style={styles.pdfTopRight1}>Name: {userData.name}</Text>
                      <Text style={styles.pdfTopRight1}>Address: {userData.address.line1 && userData.address.pincode ? `${userData.address.line1}, ${userData.address.line2}, ${userData.address.city}, ${userData.address.state}, ${userData.address.pincode}` : "NA"}</Text>
                      <Text style={styles.pdfTopRight1}>Mobile No: {userData.mobile}</Text>
                      <Text style={styles.pdfTopRight1}>Email: {userData.email}</Text>
                      <Text style={styles.pdfTopRight1}>GST No: </Text>
                </View>
              )}
              {userData && planDetails && (
                <View style={styles.pdfTopRight}>
                  <Text style={styles.pdfTopRight2}>Details</Text>
                  <Text style={styles.pdfTopRight1}>Prospect ID: {userData.uniqueId}</Text>
                  <Text style={styles.pdfTopRight1}>Bill Date: {new Date(planDetails.purchaseDate).toLocaleDateString("en-GB")}</Text>
                  <Text style={styles.pdfTopRight1}>Bill No: {planDetails.receiptNumber}</Text>
                </View>
              )}
              </View>
              <View style={styles.pdfBottom}>
              {planDetails && location && (
                <View style={styles.pdfBottomRight}>
                  <Text style={styles.pdfTopRight2}>Description</Text>
                  <Text style={styles.pdfTopRight1}>Product Name: {planDetails.productName} </Text>
                  <Text style={styles.pdfTopRight1}>Brand Name: {planDetails.productBrand} </Text>
                  <Text style={styles.pdfTopRight1}>Quantity: {planDetails.products} </Text>
                  <Text style={styles.pdfTopRight1}>Product Size: {planDetails.productSize}</Text>
                  <Text style={styles.pdfTopRight1}>Base Cost: {planDetails.productPrice}</Text>
                  <Text style={styles.pdfTopRight1}>GST: {planDetails.gstAmount || 0}</Text>
                  <Text style={styles.pdfTopRight1}>Total: {planDetails.totalProductPrice} </Text>
                </View>
              )}
              {planDetails && !location && (
                <View style={styles.pdfBottomRight}>
                  <Text style={styles.pdfTopRight2}>Description</Text>
                  <Text style={styles.pdfTopRight1}>Event Name: {planDetails.eventName} </Text>
                  <Text style={styles.pdfTopRight1}>Event Location: {planDetails.eventPlace} </Text>
                  <Text style={styles.pdfTopRight1}>No Of Tickets: {planDetails.tickets} </Text>
                  <Text style={styles.pdfTopRight1}>Base Cost: {planDetails.eventPrice}</Text>
                  <Text style={styles.pdfTopRight1}>GST: {planDetails.gstAmount || 0}</Text>
                  <Text style={styles.pdfTopRight1}>Total: {planDetails.totalEventPrice} </Text>
                </View>
              )}
              {planDetails && (
                <View style={styles.pdfTopRight}>
                  <Text style={styles.pdfTopRight2}>Final Price</Text>
                  <Text style={styles.pdfTopRight1}>Total Amount: {location ? planDetails.totalProductPrice : planDetails.totalEventPrice}</Text>
                </View>
              )}
              </View>
              <View style={styles.pdfBottom}>
              {planDetails && (
                <View style={styles.pdfBottomRight}>
                  <Text style={styles.pdfTopRight1}>Receipt No: {planDetails.receiptNumber} </Text>
                  <Text style={styles.pdfTopRight1}>Payment Mode: {planDetails.paymentMode}</Text>
                  <Text style={styles.pdfTopRight1}>Charge Date: {new Date(planDetails.purchaseDate).toLocaleDateString("en-GB")}</Text>
                  {planDetails.paymentMode === "UPI" && (
                    <Text style={styles.pdfTopRight1}>UPI Id: {planDetails.upiId} </Text>                    
                  )}

                {planDetails.paymentMode === "Card" && (
                    <>
                      <Text style={styles.pdfTopRight1}>Card Type: {planDetails.cardDetails.cardType} </Text>                    
                      <Text style={styles.pdfTopRight1}>Bank Name: {planDetails.cardDetails.bankName} </Text>                    
                      <Text style={styles.pdfTopRight1}>Last Four Digits: {planDetails.cardDetails.lastFourDigits} </Text>                    
                    </>
                  )}

                  {planDetails.paymentMode === "Cheque" && (
                    <>
                      <Text style={styles.pdfTopRight1}>Cheque Number: {planDetails.chequeDetails.chequeNumber} </Text>                    
                      <Text style={styles.pdfTopRight1}>Bank Name: {planDetails.chequeDetails.bankName} </Text>                    
                      <Text style={styles.pdfTopRight1}>Branch Name: {planDetails.chequeDetails.branchName} </Text>                    
                      <Text style={styles.pdfTopRight1}>Cheque Date: {new Date(planDetails.chequeDetails.chequeDate).toLocaleDateString("en-GB")} </Text>                    
                    </>
                  )}
                </View>
              )}
              </View>
              <View style={styles.pdfBottom}>
                <View style={styles.pdfTnc}>
                  <Text style={styles.pdfTopRight2}>Terms & Conditions</Text>
                  {allTnc && allTnc.length > 0 && allTnc.map((terms, value) => (
                    <View style={styles.pdfTncBottom} key={value}>
                      <Text style={styles.pdfTopRight1}>{value + 1}.</Text>
                      <Text style={styles.pdfTopRight1}> {terms.tnc}</Text>
                    </View>
                  ))}                
                  </View>
              </View>
            </View>
        </Page>
    </Document>
  )
}

export default Document2;