import React, {useState, useEffect} from 'react'
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import "../../css/DashUser.css"
import ReceiptIcon from '@mui/icons-material/Receipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Document2 from './Document2';


const CurrentProducts = ({userId}) => {
    const [product, setProduct] = useState("");
    const [userData, setUserData] = useState(null);
    const location = "Merchandise";

    useEffect(() => {
        const fetchUserData = async () => {
        try {
            const response = await instance.get(`/api/user/getdata/${userId}`);
            if (response.data.success) {
            setUserData(response.data.data);
            }
        } catch (error) {
            toast.error(error.message);
        }
        };

    fetchUserData();
    }, [userId]);
    
    useEffect(() => {
        handleProducts();
        //  eslint-disable-next-line
    },[]);

    const handleProducts = async () => {
        try {
        const response = await instance.get("/api/user/getIndividualProducts", {
            params: {
            userId,
            }
        })
        if(response.data.success){
            setProduct(response.data.data.products)
        }
        } catch(error) {
        toast.error("An Error Occurred. Please Try Again Later!")
        }
    } 

    return (
        <div className='currentPlans'>
            {(!product || product.length === 0) &&  (
                <div className="currentPlans-container">
                    <h4 className="currentPlan-key">
                        Currently No Product to display! Buy now 
                    </h4>
                </div>
            )}

            {product && product.length > 0 && product.slice().reverse().map((text, index, array) => (          
                <div className="currentPlans-container" key={index}>
                    <h4 className="currentPlans-h4"> ({array.length - index}){text.productName} </h4>
                    <div className="currentPlan-details">
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Performance Center</h4>
                        <h5 className="currentPlan-value">{text.gymLocation}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Product Name</h4>
                        <h5 className="currentPlan-value">{text.productName}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Quantity</h4>
                        <h5 className="currentPlan-value">{text.products}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Product Size</h4>
                        <h5 className="currentPlan-value">{text.productSize}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Brand Name</h4>
                        <h5 className="currentPlan-value">{text.productBrand}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Purchase Date</h4>
                        <h5 className="currentPlan-value">{(new Date(text.purchaseDate).toLocaleDateString("en-GB"))}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Product Price</h4>
                        <h5 className="currentPlan-value">₹{text.totalProductPrice}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Applied GST</h4>
                        <h5 className="currentPlan-value">{text.gst ? text.gst : "No"}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">GST Price</h4>
                        <h5 className="currentPlan-value">{text.gst ?  (text.gst === "1.18" ? "18%" : "5%") : "No"}</h5>
                    </div>
                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Payment Mode</h4>
                        <h5 className="currentPlan-value">{text.paymentMode}</h5>
                    </div>
                    {text.paymentMode === "Card" && (
                        <>
                            <div className="currentDetails-container">
                                <h4 className="currentPlan-key">Card Type</h4>
                                <h5 className="currentPlan-value">{text.cardDetails?.cardType}</h5>
                            </div>
                            <div className="currentDetails-container">
                                <h4 className="currentPlan-key">Bank Name</h4>
                                <h5 className="currentPlan-value">{text.cardDetails?.bankName}</h5>
                            </div>
                            <div className="currentDetails-container">
                            <h4 className="currentPlan-key">Card Number</h4>
                            {text.cardDetails?.last4Digits.length > 0 && (
                            <h5 className="currentPlan-value">XXXX-XXXX-XXXX-{text.cardDetails?.last4Digits}</h5>
                            )}
                            </div>
                        </>
                    )}
                    {text.paymentMode === "UPI" && (
                        <>
                        <div className="currentDetails-container">
                            <h4 className="currentPlan-key">UPI Detail</h4>
                            <h5 className="currentPlan-value">{text.upiDetails?.upiId}</h5>
                        </div>
                        </>
                    )}
                    {text.paymentMode === "Cheque" && (
                        <>
                        <div className="currentDetails-container">
                            <h4 className="currentPlan-key">Cheque Number</h4>
                            <h5 className="currentPlan-value">{text.chequeDetails?.chequeNumber}</h5>
                        </div>
                        <div className="currentDetails-container">
                            <h4 className="currentPlan-key">Cheque Date</h4>
                            <h5 className="currentPlan-value">{new Date(text.chequeDetails?.chequeDate).toLocaleDateString("en-GB")}</h5>
                        </div>
                        <div className="currentDetails-container">
                            <h4 className="currentPlan-key">Bank Name</h4>
                            <h5 className="currentPlan-value">{text.chequeDetails?.bankName}</h5>
                        </div>
                        <div className="currentDetails-container">
                            <h4 className="currentPlan-key">Branch Name</h4>
                            <h5 className="currentPlan-value">{text.chequeDetails?.branchName}</h5>
                        </div>
                        </>
                    )}

                    <div className="currentDetails-container">
                        <h4 className="currentPlan-key">Billing Done By</h4>
                        <h5 className="currentPlan-value">{text.createdBy}</h5>
                    </div>
                    {text?.discountCode && (
                        <>
                            <div className="currentDetails-container">
                                <h4 className="currentPlan-key">Discount Applied </h4>
                                <h5 className="currentPlan-value">Yes</h5>
                                </div>
                            <div className="currentDetails-container">
                                <h4 className="currentPlan-key">Discount Code</h4>
                                <h5 className="currentPlan-value">{text.discountCode}</h5>
                            </div>
                        </>
                    )}

                </div>
                <div className="additional-buttons addComment-btn">
                {/* download plan button */}
                    <PDFDownloadLink className="dash-btn Link"  document={<Document2 planDetails={text} location={location} userData={userData}/>} fileName={`${text.productName} Bill`}>
                        <ReceiptIcon/> Document
                    </PDFDownloadLink>
                </div>
            </div>
            ))}
        </div>
    )
}

export default CurrentProducts