import React, { useState, useRef } from 'react'
import "./Message.css"
import { defaultUrl } from '../../data/Images';
import  AddIcon  from "@mui/icons-material/Add"
import  ArrowBack  from "@mui/icons-material/ArrowBack"
import  ExitToAppIcon  from "@mui/icons-material/ExitToApp"
import  DeleteIcon  from "@mui/icons-material/Delete"
import NewChatGroup from './NewChatGroup';
import useUserStore from '../../store';
import instance from '../../utils/axios';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom"
import DeleteModal from '../../dashboard/components/Modal/DeleteModal';

const GroupDetails = ({ modal, groupName, closeModal, participants, groupId, profile }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const fileInputRef = useRef(null);
    const user = useUserStore((state) => state.user);
    const nav = useNavigate();

    if ( showModal === true ) { 
        return <NewChatGroup setShowModal={setShowModal} location={'update'} groupId={groupId} /> 
    }
    const handleDelete = async (id) => {
        setUserIdToDelete(id);
        setShowModal1(true);
    };

    const handleExit = async (id) => {
        setUserIdToDelete(id);
        setShowModal2(true);
    };

    const confirmExit = async () => {
        try {
            const response = await instance.post(`/api/user/exitGroup/${userIdToDelete}`, { userId: user._id});
            if (response.data.success) {
            toast.success(response.data.message);
            setShowModal2(false);
            nav(0);
            } else {
            toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An Error Occurred. Please Try Again Later!");
        }
    }
    const confirmDelete = async () => {
        try {
            const response = await instance.post(`/api/user/deleteGroup/${userIdToDelete}`);
            if (response.data.success) {
            toast.success(response.data.message);
            setShowModal(false);
            nav(0);
            } else {
            toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An Error Occurred. Please Try Again Later!");
        }
    }   

    if ( showModal1 === true ) { 
        return (
            <DeleteModal 
                h4={"Are you sure you want to delete this group?"} 
                confirmDelete={confirmDelete}
                closeModal={()=> setShowModal1(false)} 
            />
        )
    }

    if ( showModal2 === true ) { 
        return (
            <DeleteModal 
                h4={"Are you sure you want to leave this group?"} 
                confirmDelete={confirmExit}
                closeModal={()=> setShowModal2(false)} 
            />
        )
    }
    const handleFileChange = () => {
        fileInputRef.current.click();
    }
    const handleInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setSelectedImage(reader.result);
                handleSubmit(reader.result);
            };
        } else {
            console.log("No file selected");
        }
    }
    const handleSubmit = async (image) =>  {
        if (selectedImage !== defaultUrl) {
            console.log(image);
            try {
                const response = await instance.patch(`/api/user/updateChatGroup/${groupId}`, {
                    profilePicture: image
                });
                if (response.data.success) {
                    toast.success(response.data.message);
                    nav(0);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error.message);
            }
        }
    }
    return (
        <div className={`group-details  ${modal ? 'open' : ''}`}>
            <div className="group-details-top">
                <img src={selectedImage || profile || defaultUrl} className='group-details-top-img'  alt="" onClick={handleFileChange} />
                <input type='file' id='file' accept='.png, .jpg, .jpeg, .webp' style={{ display: 'none' }} ref={fileInputRef} onChange={handleInputChange} />
                <h4 className='group-details-top-h4'>{ groupName }</h4>
                <div className="group-details-top-length">
                    {participants.length} Members
                </div>
                <ArrowBack className="arrow-back-icon" onClick={closeModal}  />
                {/* { edit ? (
                    <>                
                        <CheckCircleOutlineIcon className="group-details-edit-icon" onClick={handleSubmit}  />
                    </>
                ) :  <>            
                    <EditIcon className="group-details-edit-icon" onClick={onEditClick}  />
                </>}             */}
            </div>
            <div className="group-details-center">
                {['Admin', 'Regional Manager', 'Branch Manager'].includes(user.role) ? (
                    <button className='group-details-center-btn' onClick={() => setShowModal(true)} >
                        <AddIcon className="group-details-center-add" />
                        Add Members
                    </button>
                ) : ""}
                <div className='group-details-center-div' >
                    {participants.map((participant) => (
                        <div className='group-details-center-main' key={participant._id} >
                            <img src={participant?.avatar?.secure_url || defaultUrl} className='group-details-center-main-img' alt="" />
                            <h5 className='group-details-center-main-h5' > {participant?.name} </h5>
                        </div>
                    ))}
                </div>
            </div>
            <div className="group-details-center group-details-bottom ">
                <button onClick={() => handleExit(groupId)}  className="group-details-center-btn group-btn-1 ">
                    <ExitToAppIcon className='group-details-center-add group-icon1' />
                    Exit Group
                </button>
                {['Admin', 'Regional Manager', 'Branch Manager'].includes(user.role) ? (
                <button onClick={() => handleDelete(groupId)} className="group-details-center-btn  group-btn-2 ">
                    <DeleteIcon className='group-details-center-add group-icon2'/>
                    Delete Group
                </button>
                ) : ""}
            </div>
        </div>
    )
}

export default GroupDetails;