import React, { useState } from 'react'
import '../../css/Register.css'
import { motion } from 'framer-motion';
import instance from "../../utils/axios";
import {toast} from "react-hot-toast";

const ForgotPassword = () => {
    const [val, setVal] = useState({
      email: "",
    });

    const ForgotPassword = async (e) => {
            e.preventDefault();
            try {
                const response = await instance.post('/api/user/forgot-password', {
                    email: val.email
                });
                if(response.data.success){
                  toast.success(response.data.message);
                }else{
                  toast.error(response.data.message);
                }
                } catch (e) {
                  toast.error("An Error Occurred");
                }
            }

    const setdata = (e) => {
            const {name, value} = e.target;
            setVal((preval) =>{
              return{
                ...preval,
                [name]:value
              }
            })
          }
  return (
    <>
      <motion.div className="register"
                initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}

      >
        <div className="login-container ">
            <div className="login-photo">
                <img src="https://images.pexels.com/photos/9578527/pexels-photo-9578527.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" loading="lazy"/>
            </div>
            <div className="login-container-1">
                <h4 className="register-title ">
                    Forgot Password
                </h4>
                <form className='register-form' onSubmit={ForgotPassword}>
                <div className="text-field-container">
                    <div className="text-field">
                        <span className="register-span">Email</span>
                        <input type="email" className='register-input' placeholder="Enter Registered Email ID" label="Email" name="email" value={val.email} onChange={setdata}/>
                    </div>
                </div>
                <button className="submit-btn send-btn" type="submit">Send Link</button>
                </form>
            </div>
        </div>
      </motion.div>
    </>
  )
}

export default ForgotPassword