import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instance from "../../../utils/axios";
import Loader from "../../../components/MainLoader/Loader";
import { toast } from "react-hot-toast";
import useUserStore from "../../../store";
import Comment from "../../components/DashboardComments/Comment";

const OnlineProspect = () => {
  const { id } = useParams();
  const [prospectDetails, setProspectDetails] = useState(null);
  const [addComment, setAddComment] = useState([]);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);

  const user = useUserStore((state) => state.user);
  const currentDate = new Date();
  const formattedDateTime = `${currentDate.toLocaleDateString(
    "en-GB"
  )} ${currentDate.toLocaleTimeString("en-GB")}`;

  const setData = (e) => {
    const { name, value } = e.target;
    setName((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post("/api/user/commentData", {
        comment: name.comment,
        username: id,
        addedFrom: user.name,
        location: "Prospect",
        callSubject: name.callSelect,
        callResponse: name.callResponse,
        reason: name.reasons,
        priority: name.priority,
        nextCallDate: name.callDate,
        nextCallTime: name.callTime,
        callStatus: name.callStatus,
        callDateAndTime: formattedDateTime,
      });
      if (response.data.success) {
        toast.success("Your Comment is Added Successfully");
        e.target.reset();
        setName({
          comment: "",
          callSelect: "",
          callResponse: "",
          reasons: "",
          priority: "",
          callDate: "",
          callTime: "",
          callStatus: "",
          currentCallDate: formattedDateTime,
        });
        // Fetch the updated comments after adding a new comment
        fetchComments();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await instance.get(`/api/user/getCommentData/${id}`, {
        params: {
          location: "Prospect",
        },
      });
      if (response.data.success) {
        setAddComment(response.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchComments();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    instance
      .get(`/api/user/getProspect/${id}`)
      .then((response) => {
        if (response.data.success) {
          setProspectDetails(response.data.data);
        }
      })
      .catch((error) => {});
  }, [id]);

  if (!prospectDetails) {
    return (
      <>
        <div style={{ display: "grid", placeItems: "center" }}>
          <Loader />
        </div>
      </>
    );
  }

  const handleFilter = () => {
    const formattedStartDate = startDate
      ? new Date(startDate).toLocaleDateString("en-GB")
      : null;
    const formattedEndDate = endDate
      ? new Date(endDate).toLocaleDateString("en-GB")
      : null;

    const filteredComments = addComment.filter((comment) => {
      const commentDate = comment.callDateAndTime.split(" ")[0];
      const dateCondition =
        !formattedStartDate ||
        !formattedEndDate ||
        ((commentDate >= formattedStartDate ||
          commentDate === formattedStartDate) &&
          (commentDate <= formattedEndDate ||
            commentDate === formattedEndDate));
      return dateCondition;
    });

    if (filteredComments === null || filteredComments.length === 0) {
      return toast.error("No Comments Found");
    }
    setFilteredComments(filteredComments);
  };

  const commentsToRender =
    filteredComments.length > 0
      ? filteredComments
      : addComment && addComment.slice().reverse();

  const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  return (
    <div className="userPage-container">
      <h2 className="dashboard-title">
        {prospectDetails.name}'s Prospect Details{" "}
      </h2>
      <div className="user-main">
        <div className="user-wrap">
          <div className="user-index">
            <h3 className="user-title">Name: </h3>
            <h3 className="user-value">{prospectDetails.name}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Email: </h3>
            <h3 className="user-value">{prospectDetails.email}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Date Of Birth: </h3>
            <h3 className="user-value">
              {new Date(prospectDetails.dob).toLocaleDateString("en-GB")}
            </h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Created On: </h3>
            <h3 className="user-value">
              {new Date(prospectDetails.createdAt).toLocaleDateString("en-GB")}{" "}
              {new Date(prospectDetails.createdAt).toLocaleTimeString()}
            </h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Pincode: </h3>
            <h3 className="user-value">{prospectDetails.pincode}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Mobile number: </h3>
            <h3 className="user-value">{prospectDetails.mobile}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Training Mode: </h3>
            <h3 className="user-value">{prospectDetails.train}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Gym Location: </h3>
            <h3 className="user-value">
              {prospectDetails.gymLocation ? prospectDetails.gymLocation : "Online"}
            </h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Referral: </h3>
            <h3 className="user-value">{prospectDetails.referral}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Health issues: </h3>
            <h3 className="user-value">{prospectDetails.path}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Interested In: </h3>
            <h3 className="user-value">{prospectDetails.interest}</h3>
          </div>
          <div className="user-index">
            <h3 className="user-title">Created By: </h3>
            <h3 className="user-value">{prospectDetails.createdBy || "N/A"}</h3>
          </div>
        </div>
      </div>
      <div className="user-documents">
        <h3 className="user-title">Documents: </h3>
        {prospectDetails.document && prospectDetails.document.secure_url ? (
          <a
            className="ahref"
            href={prospectDetails.document.secure_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {getFileName(prospectDetails.document.secure_url)}
          </a>
        ) : (
          <h3 className="user-value">No document found</h3>
        )}
      </div>
      <div>
        <Comment
          length={addComment.length}
          name={name}
          setData={setData}
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          commentsToRender={commentsToRender}
          formattedDateTime={formattedDateTime}
        />
      </div>
    </div>
  );
};

export default OnlineProspect;
