import React, { useState } from 'react'
import "../../css/Hero.css"
import { Link } from 'react-router-dom'
import heroImage from "../../assets/hero-image.webp"
import video from "../../assets/watch.mp4"
import CloseIcon from '@mui/icons-material/Close';

const Hero = () => {

  const [modal1Open, setModal1Open] = useState(false);

  const closeModal = () => {
    setModal1Open(false);
  };

  return (
    <div>
      <div className="hero">
        <div className="left-hero">
          <div className="hero-h3">
            <span className="hero-slider-text">
                  Performance | Peak | Performance
            </span>
            <div className="hero-slider"></div>
          </div>

          <div className="hero-main-text">
            <div className="hero-text">
              Create your Side Hustle
            </div>
            <div className="hero-text">
              Invest in Planet Muscle
            </div>
            <span className="hero-span">
              Your Health and Wellness Coach
            </span>
          </div>

          <div className="hero-button">
            <Link className='Link' to={`/prospect/${"General Fitness Plan"}`} >
              <button className="btn">Get Started</button>
            </Link>
            <div className="watch">
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                  />
                </svg>
                <span className="watchMe"  onClick={() => setModal1Open(true)} >Watch Me!</span>
                {modal1Open && (
                <div className="hero-modal1">
                    <div className="hero-video1">
                      <video src={video} loop infinite="true" autoPlay className='hero-video' />
                      <CloseIcon className="ad-close-icon" onClick={closeModal} />
                    </div>
                </div>
              )}      

            </div>
          </div>
        </div>
        <div className="right-hero">
          <div className="polygon"></div>
            <img src={heroImage} alt="heroImage" rel='preload' />
        </div>
      </div>
    </div>
  )
}

export default Hero