import React from "react";
import { Link } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const Widget = ({ title, counter, linkTo, icon }) => {
    
  const getIconComponent = () => {
    switch (icon) {
      case "person":
        return (
          <PersonOutlineIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(128, 0, 128, 0.47)",
            }}
          />
        );
      case "shoppingCart":
        return (
          <ShoppingCartIcon
            className="icon"
            style={{
              color: "goldenrod",
              backgroundColor: "rgba(218,165, 32, 0.2)",
            }}
          />
        );
      case "monetization":
        return (
          <MonetizationOnIcon
            className="icon"
            style={{
              color: "green",
              backgroundColor: "rgba(0,128, 0, 0.2)",
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="widget">
      <div className="left">
        <span className="dashboard-title widget-title">{title}</span>
        <span className="counter">{counter}</span>
        <span className="link">
          <Link className="Link" to={linkTo}>
            See All {title}
          </Link>
        </span>
      </div>
      <div className="right">
        <div className="percentage">{/* Add your percentage here */}</div>
        {getIconComponent()}
      </div>
    </div>
  );
};

export default Widget;
