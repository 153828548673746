import React from "react";
import "../../css/DashUser.css"

const DocumentDisplay = ({ title, document }) => {

    const getFileName = (url) => {
        const parts = url.split("/");
        const fileName = parts[parts.length - 1];
        return fileName.length > 22 ? fileName.slice(0, 22) + "..." : fileName;
      };        
    
    return (
        <div className='user-doc-container'>
            <h3 className="user-title">{title}: </h3>
            {document && document.secure_url ? (
            <a
                className="ahref"
                href={document.secure_url}
                rel="noopener noreferrer"
                target='_blank'
            >
                {getFileName(document.secure_url)}
            </a>
            ) : (
            <h3 className="user-value">No document found</h3>
            )}
        </div>
    );
  };

export default DocumentDisplay;  
  