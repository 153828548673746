import React, { useState } from "react";
import { toast } from "react-hot-toast";
import "../../css/DashUser.css";
import CloseIcon from "@mui/icons-material/Close";
import instance from "../../../utils/axios";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../../store";

const CreatePrescription = ({ formId, closeModal, name, userId, planId }) => {

  console.log("planID: ", planId)

  const [weeks, setWeeks] = useState("");
  const user = useUserStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await instance.post(`/api/user/createPrescription`, {
        numberOfWeeks: weeks,
        userId,
        clientName: name,
        planId,
        formId,
        uniqueId: user.uniqueId,
      });
      if (response.data.success) {
        toast.success(response.data.message);
        setLoading(false);
        navigate(
          `/dashboard/prescription/weeks/${response.data.prescriptionId}`
        );
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("create prescription error: ", error.message);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="tnc-modal1">
      <div className="tnc-modal-content1 show-response-modal">
        <h4 className="show-response-modal-h4">{name}&apos;s Response </h4>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <form onSubmit={handleSubmit} className="create-prescription-form">
          <div className="create-form-div">
            <label className="create-form-label" htmlFor="">
              Prescription Weeks
            </label>
            <input
              className="create-form-input"
              type="number"
              min={0}
              name="weeks"
              value={weeks}
              placeholder="Enter Number Of Weeks"
              onChange={(e) => setWeeks(e.target.value)}
            />
          </div>
          <button
            disabled={loading}
            className={`dash-btn view-btn ${loading ? "upload-btn" : ""}`}
            type="submit"
          >
            {loading ? (
              <AutorenewIcon className="loader-css" />
            ) : (
              "Create Prescription"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreatePrescription;
