import { Button, FormControlLabel,IconButton, MenuItem, Select, Switch, Typography} from "@mui/material";
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useEffect, useState } from "react";
import SubjectIcon from '@mui/icons-material/Subject';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Radio from '@mui/icons-material/Radio';
import ShortTextIcon from '@mui/icons-material/ShortText';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import instance from "../../utils/axios"
import { toast } from "react-hot-toast"
import useUserStore from "../../store"
import "./GoogleForm.css";
import { useNavigate, useParams } from "react-router-dom";

const QuestionForm = ({ isUpdate }) => {
  const { id } = useParams();
  const [questions, setQuestions] = useState([{
    questionText: "",
    questionType: "",
    options: [
      { optionText: "" },
    ],
    open: true,
    required: false,
  }]);
  const [formDetails, setFormDetails] = useState({
    formName: "",
    formDescription: "",
    planName: [],
    staffUniqueId: [],
  });
  const [planData, setPlanData] = useState([]);
  const [uniqueId, setUniqueId] = useState([]);
  const user = useUserStore((state) => state.user);
  const nav = useNavigate();

  const planNameData = async () => {
    try {
      await instance
        .get("/api/user/getPlanName")
        .then((response) => {
          setPlanData(response.data.data);
        })
    } catch (error) {
      console.log(error.message);
    }
  }

  const getUsername = async () => {
    try {
      const response = await instance.get("/api/user/usernames", {
        params: {
          userRole: user.role,
        },
      });
      if (response.data.success) {
        setUniqueId(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const getFormDetails = async () => {
    try {
      const response = await instance.get(`/api/user/getFormsById/${id}`)
      if (response.data.success) {
        const formData = response.data.data;
        setQuestions(formData.questions);
        setFormDetails({
          formName: formData.formName,
          formDescription: formData.formDescription,
          planName: formData.planName,
          staffUniqueId: formData.staffUniqueId,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    planNameData();
    getUsername();
    if (id) {
      getFormDetails();
    }
    //eslint-disable-next-line
  }, [id])

  const setData = (e) => {
    const { name, value } = e.target;
    setFormDetails((preval) => {
      return {
        ...preval,
        [name]: value,
      }
    });
  };

  const handleFormSubmit = async () => {
    if(!formDetails.formName || !formDetails.formDescription || !formDetails.planName) {
      toast.error("Please Fill all the parameters before submitting the form!")
      return;
    }
    try {
      const apiEndpoint = isUpdate ? `/api/user/forms/${id}` : "/api/user/forms";
      const method = isUpdate ? "put" : "post";

      const requestBody = {
        formName: formDetails.formName,
        formDescription: formDetails.formDescription,
        questions: questions,
        uniqueId: user.uniqueId,
        planName: formDetails.planName,
        staffUniqueId: formDetails.staffUniqueId,
      };
      const response = await instance[method](apiEndpoint, requestBody);
      if (response.data.success === true) {   
        toast.success(response.data.message);
        nav('/dashboard/admin')
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  function changeQuestion(text, i) {
    var newQ = [...questions];
    newQ[i].questionText = text;
    setQuestions(newQ);
  }

  function addQuestionType(i, type) {
    let newQ = [...questions];
    newQ[i].questionType = type;
    setQuestions(newQ);
  }

  function changeOptionValue(text, i, j) {
    let newQ = [...questions];
    newQ[i].options[j].optionText = text;
    setQuestions(newQ);
  }

  function removeOption(i, j) {
    var newQ = [...questions];
    if (newQ[i].options.length > 1) {
      newQ[i].options.splice(j, 1);
    }
    setQuestions(newQ);
  }

  function addOptions(i) {
    var newQ = [...questions];
    if (newQ[i].options.length < 100) {
      newQ[i].options.push({ optionText: ""});
    } else {
      toast.error("Max 100 options!!")
    }
    setQuestions(newQ);
  }

  function copyQuestion(i) {
    expandCloseAll();
    var newQ = [...questions];
    var newQuestion = { ...newQ[i],  options: [...newQ[i].options.map(option => ({ ...option }))] };
    setQuestions([...questions, newQuestion]);
  }

  function deleteQuestion(i) {
    var newQ = [...questions];
    if (questions.length > 1) {
      newQ.splice(i, 1)
    }
    setQuestions(newQ);
  }

  function requireQuestion(i) {
    var newQ = [...questions];
    newQ[i].required = !newQ[i].required;
    setQuestions(newQ);
  }

  function addMoreQuestion(i) {
    expandCloseAll();
    setQuestions([
      ...questions,
      { questionText: "", questionType: "", options: [{ optionText: "" }], open: true, required: false }
    ]);
  }

  function onDragend(result) {
    if (!result.destination) {
      return;
    }
    var itemDf = [...questions];
    const itemF = reorder(
      itemDf,
      result.source.index,
      result.destination.index
    )
    setQuestions(itemF);
  }

  function expandCloseAll() {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  }

  function handleExpand(i) {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      if (i === j) {
        qs[i].open = true;
      } else {
        qs[j].open = false;
      }
    };
    setQuestions(qs);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  const handleCheckboxChange = (e, uniqueId) => {
    const { checked } = e.target;

    setFormDetails((prevData) => {
      if (checked) {
        return {
          ...prevData,
          staffUniqueId: [...prevData.staffUniqueId, uniqueId],
        };
      } else {
        return {
          ...prevData,
          staffUniqueId: prevData.staffUniqueId.filter((user) => user !== uniqueId),
        };
      }
    });
  };
  const handleCheckboxChange1 = (e, name) => {
    const { checked } = e.target;

    setFormDetails((prevData) => {
      if (checked) {
        return {
          ...prevData,
          planName: [...prevData.planName, name.planname],
        };
      } else {
        return {
          ...prevData,
          planName: prevData.planName.filter((user) => user !== name.planname),
        };
      }
    });
  };

  function QuestionsUI() {
    return questions.map((a, b) => (
      <Draggable key={b} draggableId={b + "id"} index={b} >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="ui">
              <div className="ui1">
                <div className="ui2" >
                  <DragIndicatorIcon className="dragIndicatorIcon" fontSize="small" />
                </div>
                <div className="ui3">
                  <Accordion expanded={questions[b].open} onChange={() => { handleExpand(b) }} className={questions[b].open ? "add-border" : ""}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      elevation={1}
                      style={{ width: "100%"}}
                    >
                      {!questions[b].open ? (
                        <div className="saved-questions">
                          <Typography className="ui4">
                            {b + 1}. {questions[b].questionText}
                          </Typography>
                          {a.options.map((c, d) => (
                            <div key={d}>
                              <div className="ui5">
                                {a.questionType === "image" || a.questionType === "file" ? (
                                  <FormControlLabel
                                    className="ui6"
                                    control={
                                      <input
                                        type="file"
                                        color="primary"
                                        className="ui13"
                                        required={a.required}
                                      />
                                    }
                                  />
                                ) : (
                                  <FormControlLabel
                                    className="ui6"
                                    control={
                                      <input
                                        type={a.questionType}
                                        color="primary"
                                        required={a.required}
                                        className="ui13"
                                      />
                                    }
                                    label={
                                      <Typography className="ui4">{a.options[d].optionText}</Typography>
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>) : ""}
                    </AccordionSummary>
                    {questions[b].open ? (
                      <div className="question-boxes">
                        <AccordionDetails className="add-questions" >
                          <div className="add-question-top">
                            <input type="text" className="question-input" placeholder="questions" value={a.questionText} onChange={(e) => { changeQuestion(e.target.value, b) }} />
                            <ImageOutlinedIcon className="ui7" />
                            <Select className="google-select-questions" value={a.questionType || ""} onChange={(e) => { addQuestionType(b, e.target.value) }} >
                              <MenuItem id="text" value="text"> <SubjectIcon className="ui9" checked /> Single Line </MenuItem>
                              <MenuItem id="checkbox" value="checkbox" > <CheckBoxIcon className="ui9" checked /> CheckBox </MenuItem>
                              <MenuItem id="radio" value="radio" > <Radio className="ui9" checked /> Multiple Choice </MenuItem>
                              <MenuItem id="file" value="file"> <InsertDriveFileIcon className="ui9" checked /> File </MenuItem>
                              <MenuItem id="image" value="image"> <ImageOutlinedIcon className="ui9" checked /> Image </MenuItem>
                              <MenuItem id="date" value="date" > <ImageOutlinedIcon className="ui9" checked /> Date </MenuItem>
                              <MenuItem id="time" value="time" > <ImageOutlinedIcon className="ui9" checked /> Time </MenuItem>
                            </Select>
                          </div>
                          {a.options.map((c, d) => (
                            <div className="add-question-body" key={d} >
                              {(a.questionType !== "text") ?
                                <input type={a.questionType === "image" ? "file" : a.questionType } className="ui9" /> :
                                <ShortTextIcon className="ui9" />
                              }
                              <div className="ui8">
                                <input type="text" className="google-text-input" placeholder="option" value={a.options[d].optionText} onChange={(e) => {changeOptionValue(e.target.value, b, d) }} />
                              </div>
                              <IconButton aria-label="delete" onClick={() => { removeOption(b, d) }}>
                                <CloseIcon />
                              </IconButton>
                            </div>
                          ))}
                          <div className="add-footer">
                            <div className="add-footer-bottom">
                              <IconButton aria-label="copy" onClick={() => { copyQuestion(b) }} > 
                                <ContentCopyIcon /> 
                              </IconButton>
                              <IconButton onClick={addMoreQuestion}> 
                                <AddCircleOutlineIcon /> 
                              </IconButton>
                              <IconButton aria-label="delete" onClick={() => { deleteQuestion(b) }}> 
                                <DeleteIcon /> 
                                </IconButton>
                              <span style={{ color: "#5f6368", fontSize: "12px" }} >Required</span> <Switch name={`switch-${b}`} color="primary" checked={questions[b].required} onClick={() => { requireQuestion(b) }} ></Switch>
                            </div>
                          </div>
                          {a.options.length < 100 ? (
                            <div className="add-question-body">
                              <FormControlLabel disabled control={
                                (a.questionType !== "Text") ?
                                  // <input type={a.questionType} color="primary" inputprops={{ 'aria-label': "secondary checkbox" }} className="ui9" disabled />
                                  <input type={a.questionType === "image" ? "file" : a.questionType } color="primary" inputprops={{ 'aria-label': "secondary checkbox" }} className="ui9" disabled />
                                  : <ShortTextIcon className="ui9"/>
                              } label={
                                <div>
                                  <input type="text" className="text-input ui12" />
                                  <Button size="small" onClick={() => { addOptions(b)}} >
                                  Options
                                  </Button>
                                </div>
                              } />
                            </div>
                          ) : ""}
                        </AccordionDetails>
                      </div>
                    ) : ""}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    ))
  }

  return (
    <div className="google-form">
      <div className="google-form-main">
        <div className="google-question-form">
          <div className="google-question-section">
              <div className="google-question-form-top">
                <input
                  type="text"
                  name="formName"
                  className="question-for-top-name"
                  placeholder="Untitled Document"
                  value={formDetails.formName}
                  onChange={setData}
                  required
                />
                <input
                  type="text"
                  name="formDescription"
                  className="question-for-top-name question-for-top-desc"
                  placeholder="Form Description"
                  value={formDetails.formDescription}
                  onChange={setData}
                  required
                />
                {/* <select name="planName" required className="question-for-top-name google-form-select" value={formDetails.planName || ""} onChange={setData}>
                  <option value="">Select MemberShip Type</option>
                  {planData.map((name, id) => (
                    
                    <option key={id} value={name.planname}>{name.planname}</option>
                  ))}
                </select> */}
                <div className="product-input-div">
                  <label className="product-label" htmlFor="planName">Add Plan Name</label>
                  <div className="google-form-uniqueId">                    
                    {planData?.map((name, index) => (
                      <div className="unique-id" key={name}>
                        <h5 className="uniqueId-h5"> {index + 1}. {name.planname}</h5>
                        <input
                          type="checkbox"
                          id={name.planname}
                          name="planName"
                          value={formDetails.planName}
                          checked={formDetails.planName.includes(name.planname)}
                          onChange={(e) => handleCheckboxChange1(e, name)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="product-input-div">
                  <label className="product-label" htmlFor="uniqueId">Add Unique ID</label>
                  <div className="google-form-uniqueId">                    
                    {uniqueId?.map((username, index) => (
                      <div className="unique-id" key={username}>
                        <h5> {index + 1}. {username}</h5>
                        <input
                          type="checkbox"
                          id={username}
                          name="staffUniqueId"
                          value={formDetails.staffUniqueId}
                          checked={formDetails.staffUniqueId.includes(username)}
                          onChange={(e) => handleCheckboxChange(e, username)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            <DragDropContext onDragEnd={onDragend}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {QuestionsUI()}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button variant="contained" color="primary" className="google-btn" onClick={handleFormSubmit}>
              {isUpdate ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionForm;

export const AddQuestionForm = () => <QuestionForm isUpdate={false} />;
export const UpdateQuestionForm = () => <QuestionForm isUpdate={true} />;
