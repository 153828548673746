import React, { useState } from 'react'
import { motion } from 'framer-motion'
import ExUsers from './ExUsers'
import ExMembers from './ExMembers'
import ExEmployee from './ExEmployee'

const DashExUser = () => {

    const [isActive, setIsActive] = useState(1)
    const handleActive = (btn) => setIsActive(btn)

  return (
    <div className="dashMembers">
    <motion.h3 className="dashboard-title dashMembers-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{marginBottom: "1rem"}}
    >
    All Past Users
    </motion.h3>
    <div className='tabsContainer'>
                <motion.div className="btnContainer"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.6}}
                >
                    <button className={`tabs ${isActive === 1 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(1)}> Ex Offline Members </button>
                    <button className={`tabs ${isActive === 2 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(2)}> Ex Online Members </button>
                    <button className={`tabs ${isActive === 3 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(3)}>Ex Counsellors</button>

                </motion.div>
                {isActive === 1 && <div className="tabData">
                    <motion.div className="tabContent"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <ExUsers/>
                    </motion.div>
                </div>}
                {isActive === 2 && <div className="tabData">
                    <motion.div className="tabContent"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}                                
                    >
                        <ExMembers/>
                    </motion.div>
                </div>}
                {isActive === 3 && <div className="tabData">
                    <motion.div className="tabContent"
                                 initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <ExEmployee/>
                    </motion.div>
                </div>}

    </div>
</div>

  )
}

export default DashExUser;