import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../../css/DashProducts.css";
import { planNameData } from "../../../data/Gym";
import { Switch } from "@mui/material";

const DashPlanForm = ({ isUpdate }) => {
  const [file, setFile] = useState("");
  const nav = useNavigate();
  const { planId } = useParams();
  const [loading, setLoading] = useState(false);
  const [tagText, setTagText] = useState("");
  const [tags, setTags] = useState([]);
  const [reRender, setReRender] = useState(false)

  const [planData, setPlanData] = useState({
    planmode: "",
    plantype: "",
    tags: [],
    brochure: "",
    planname: "",
    showPlan: false,
    size: [{ value: "", price: "" }],
  });

  useEffect(() => {
    if (isUpdate && planId) {
      instance
        .get(`/api/user/getParticularPlanData/${planId}`)
        .then((response) => {
          if (response.data.success) {
            const planData = response.data.data;
            setPlanData({
              ...planData,
              size: planData.planduration || [], 
            });
            setTags(planData.tags || []); 
      } else {
            console.error(response.data.error);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [isUpdate, planId]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "showPlan") {
      setPlanData((prevData) => ({
        ...prevData,
        showPlan: checked,
      }));
      const notificationMessage = checked ? "The Plan is currently accessible via the website."
        : "The website is no longer displaying the Plan.";
      toast.success(notificationMessage);
    } else {
      setPlanData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function convertBase64(e) {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.onerror = error => {
    }
  }

  const forceRender = () => {
    setReRender(!reRender);
  };

  const handleAddTag = (e) => {
    forceRender();
    if (e.key === "Shift") {
      setTagText("");
      if (tagText !== "") {
        setTags([...tags, tagText]);
      } else {
      }
    }
  };

  const handleDeleteTag = (index) => {
    forceRender();
    tags.splice(index, 1);
  };

  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...planData.size];
    updatedSizes[index][field] = value;
    setPlanData({ ...planData, size: updatedSizes });
  };

  const handleAddSize = () => {
    setPlanData({
      ...planData,
      size: [...planData.size, { value: "", price: "" }],
    });
  };

  const handleRemoveSize = (index) => {
    const updatedSizes = [...planData.size];
    updatedSizes.splice(index, 1);
    setPlanData({ ...planData, size: updatedSizes });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const endpoint = isUpdate ? `/api/user/updatePlanData/${planId}` : "/api/user/plans";
      
      const planduration = planData.size.map((item) => ({
        price: item.price,
        value: item.value,
      }));

      const response = await instance[isUpdate ? "put" : "post"](endpoint, {
        ...planData,
        file: file,
        tags: tags, 
        planduration: planduration,       
        brochure: file ? file : ""
      });
      if (response.data.success) {
        setLoading(false);
        const successMessage = isUpdate ? "Plan updated successfully!" : "Plan added successfully!";
        toast.success(successMessage);
        nav("/dashboard/plans");
      } else {
        setLoading(false);
        toast.error(`Failed to ${isUpdate ? "update" : "Add"} plan.`);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <div className="productForm">
        <h4 className="dashboard-title dashMembers-container">
          {isUpdate ? "Update Your Plan" : "Add A Plan"}
        </h4>
        <div className="product-form-container">
          <form onSubmit={handleSubmit} className="product-form">
            <div className="product-input-div">
              <label className="product-label" htmlFor="plantype">Plan Type</label>
              <select name="plantype" id="plantype" value={planData.plantype} onChange={handleChange}>
                    <option>Select Plan Type...</option>
                    <option value="Service">Service</option>
                    <option value="Partner">Partner With Us</option>
              </select>
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="planmode">Plan Mode</label>
              <select name="planmode" id="planmode" value={planData.planmode} onChange={handleChange}>
                <option>Select Plan Mode...</option>
                <option value="Offline">Offline</option>
                <option value="Online">Online</option>
              </select>
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="planname">
              Plan Name{" "}
              </label>
              <select name="planname" id="planname" onChange={handleChange} value={planData.planname} >
                  <option>Select Plan Name...</option>
                  {planNameData.map((a)=> (
                    <option key={a} value={a.name}>{a.name}</option>
                  ))}
              </select>
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="tags">
              Plan Description{" "}
              </label>
              <div className="AddTagContainer">
                          <div className="addTagBox">
                            <h5 className="tags-h2">Add Tags</h5>
                            <div className="addTagInput">
                              {tags.map((tag, index) => {
                                return (
                                  <div
                                    className="tags"
                                    name="tags"
                                    key={index}
                                  >
                                    <span>{tag}</span>
                                    <div
                                      className="crossIcon"
                                      onClick={() => handleDeleteTag(index)}
                                    >
                                      <CancelIcon className="icon" />
                                    </div>
                                  </div>
                                );
                              })}
                              <input
                                className="inputTag"
                                type="text"
                                autoFocus
                                value={tagText}
                                onKeyUpCapture={(e) => {
                                  handleAddTag(e);
                                }}
                                onChange={(e) => setTagText(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="details">
                Plan Brochure{" "}
              </label>
              <input type="file" id="file" accept=".pdf" onChange={convertBase64} />
            </div>
            <div className="product-input-div">
              <label className="product-label">Product Sizes</label>
              {planData?.size?.map((size, index) => (
                  <div className="product-sizes" key={index}>
                    <input
                      type="text"
                      className="product-size-map"
                      name="value"
                      value={size.value}
                      onChange={(event) =>
                        handleSizeChange(index, "value", event.target.value)
                      }
                      placeholder="Size"
                    />
                    <input
                      type="number"
                      name="price"
                      min={0}
                      className="product-size-map"
                      value={size.price}
                      onChange={(event) =>
                        handleSizeChange(index, "price", event.target.value)
                      }
                      placeholder="Price"
                    />
                    <CancelIcon
                      className="cancel-icon icons"
                      onClick={() => handleRemoveSize(index)}
                    />
                  </div>
                ))}
              <button
                type="button"
                className="dash-btn add-btn"
                onClick={handleAddSize}
              >
                Add
              </button>
            </div>
            <span style={{ color: "var(--dash-primary)", fontSize: "12px" }}>
              Show on website
            </span>
            <Switch
              name="showPlan"
              color="primary"
              onChange={handleChange}
              checked={planData.showPlan}
            ></Switch>

            <button
              type="submit"
              className={`dash-btn ${loading ? "upload-btn" : ""}`}
            >
              {loading ? (
                <AutorenewIcon className="loader-css" />
              ) : (
                <>{isUpdate ? "Update Plan" : "Add Plan"}</>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashPlanForm;

export const DashAddPlan = () => <DashPlanForm isUpdate={false} />;
export const UpdatePlan = () => <DashPlanForm isUpdate={true} />;
