import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import "./About.css"


const Map = () => {
    return (
        <div>
            <div className="map">
                <div className="map-details">
                    <span className="map-details-span">GET IN TOUCH</span>
                    <h3 className="map-details-h3">
                        Visit/Contact Us Today
                    </h3>
                    <h3 className='map-details-h3'>
                        Head Office
                    </h3>
                    <div className="map-address">
                        <li className="map-icons">
                            <HomeIcon />
                            <span className='map-span'>
                            Near Station, Lohona Colony , Andheri West, Mumbai - 400053
                            </span>
                        </li>
                        <li className="map-icons">
                            <EmailIcon />
                            <span className='map-span'>
                                planetMusclepmfc@gmail.com
                            </span>
                        </li>
                        <li className="map-icons">
                            <PhoneIcon />
                            <span className='map-span'>
                                +91 9137829886
                            </span>
                        </li>
                        <li className="map-icons">
                            <AccessTimeIcon />
                            <span className='map-span'>
                            07:00 - 22:00 Everyday
                            </span>
                        </li>
                    </div>
                </div>
                <div className="map-link">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d30156.29841880822!2d72.8224779154995!3d19.127943983281625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1splanet%20muscle!5e0!3m2!1sen!2sin!4v1679489705641!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} title="PM" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
        </div>
    )
}

export default Map