import React from 'react'
import DashCalendar from './DashCalendar'
import { Link } from "react-router-dom"
import '../../css/Calendar.css'
import useUserStore from "../../../store"

const Calendar = () => {
  const user = useUserStore((state) => state.user)

  return (
    <div className="main-calendar">
      <div className="main-calendar1">
        <DashCalendar />
        {["Admin", "Regional Manager", "Branch Manager", "Silver Trainer", "Gold Trainer", "Platinum Trainer", "Diamond Trainer", "Master Trainer", "Sales", "Accounts"].includes(user.role) ?
          <Link to="/dashboard/calendar/PTcalendar"> <button className="dash-btn addComment-btn"> Personal Training </button> </Link> : ""}
      </div>
    </div>
  )
}

export default Calendar