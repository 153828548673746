import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../../store";
import "../../css/Header.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { toast, Toaster } from "react-hot-toast";
import instance from "../../utils/axios"
import DeleteModal from "../../dashboard/components/Modal/DeleteModal"
import DropDown from "../MainDropDown/DropDown";

const Header = () => {
  const [click, setClick] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const handleClick = () => setClick(!click);
  const user = useUserStore((state) => state.user);
  const loggedIn = useUserStore((state) => state.loggedIn);
  const nav = useNavigate();
  const [prospect, setProspect] = useState(false);

  const navUser1 = ["Peak Performance Plan", "Online Personal Training", "Nutritional Plan", "Appointment & Doctor"]
  const navUser2 = ["General Fitness Plan", "Personal Training Plan", "Physiotherapy Plan", "Exercise Science Plan", "Diet & Nutrition"]
  const navUser3 = ["Franchise", "Society Management", "Appointment & Doctor" ];
  const navUser4 = ["Gym Franchise", "Society Management", "List a Gym", "Gym Management Services", "List A Garden", "Become a Trainer"]

  useEffect(() =>{
    async function getProspect(){
      const response = await instance.get("/api/user/getProspectNumber", {
        params:{
          userId: user._id
        },
      })
      if(response.data.success && response.data.data !== null){
          setProspect(true);
      } 
    }
    getProspect();
    // eslint-disable-next-line
  }, [user._id]);

  const handleOfflineDropdownClick = async (plan) => {
    if (!loggedIn) {
      toast.error("Please Login to continue");
      return nav("/login");
    }
    try{
      const response = await instance.get("/api/user/getProspectNumber", {
        params: {
          userId: user._id, 
        },
      })
      if(response.data.success){
        if(response.data.data === "" || response.data.data === null){
          setClick(false);
          nav(`/prospect/${plan}`);
        }
        setSelectedPlan(plan);
        setShowModal(true); 
      }else{
        toast.error(response.data.message)
      }
    }catch(error) {
      toast.error("An Error Occurred");
    }
  };

  const handleOnlineDropdownClick = async (plan) => {
    if (!loggedIn) {
      return nav("/login");
    }
    try{
      const response = await instance.get("/api/user/getProspectNumber", {
        params: {
          userId: user._id, 
        },
      })
      if(response.data.success){
        if(response.data.data === "" || response.data.data === null){
          setClick(false);
          nav(`/prospect/${plan}`);
        }
        setSelectedPlan(plan);
        setShowModal(true); 
      }else{
        toast.error(response.data.message)
      }
    }catch(error) {
      toast.error("An Error Occurred");
    }
  };

  const handleModalYes = () => {
    setShowModal(false);
    nav(`/prospect/${selectedPlan}`);
  };

  const handleModalNo = () => {
    setShowModal(false);
    nav("/plans");
  };

  return (
    <div className="Navbar">
      <Toaster className="toaster" reverseOrder={false} />
      <div className="logo">
        <Link to={'/'} className="navlink">
          <h2 className="pm-logo">PlanetMuscle</h2>
        </Link>
      </div>
      <div className={click ? "menu active" : "menu"}>
        <ul className="nav-ul">
          <li className="dropdown">
            <Link className="navlink">Services <ArrowDropDownIcon className="down-icon" /></Link>
            <div className="dropdown-content">
              <Link className="something">Online <ArrowRightIcon /> </Link>
              <ul className="nested-dropdown-content">
              {navUser1.map((h1, h2) => (
                    <li className="some" onClick={() => handleOnlineDropdownClick(h1)} key={h2} value={h1} >{h1}</li>
                ))}
              </ul>
              <Link className="something">Offline <ArrowRightIcon /></Link>
              <ul className="nested-dropdown-content">
                {navUser2.map((h1, h2) => (
                    <li className="some" onClick={() => handleOfflineDropdownClick(h1)} key={h2} >{h1}</li>
                ))}
              </ul>
              {loggedIn && (prospect || user.role !== "User") ? <Link to={'/plans'} className="something" >Plans</Link> : null}
            </div>
          </li>
          <li className="dropdown">
            <Link className="navlink">Partner With Us <ArrowDropDownIcon className="down-icon" /></Link>
            <div className="dropdown-content">
              <Link className="something">Online <ArrowRightIcon /></Link>
              <ul className="nested-dropdown-content">
                {navUser3.map((h1, h2) => (
                      <li className="some" onClick={() => handleOnlineDropdownClick(h1)} key={h2} >{h1}</li>
                  ))}
              </ul>
              <Link className="something">Offline <ArrowRightIcon /></Link>
              <ul className="nested-dropdown-content">
                {navUser4.map((h1, h2) => (
                      <li className="some" onClick={() => handleOfflineDropdownClick(h1)} key={h2} >{h1}</li>
                  ))}
              </ul>
            </div>
          </li>

          <Link to={'/articles'} className="navlink">Articles</Link>
          <Link to={'/products'} className="navlink">Merchandise</Link>
          <Link to={'/programs'} className="navlink">Events</Link>
          <Link to={'/aboutUs'} className="navlink">About Us</Link>
        </ul>
      </div>
      <div className="button">
        {user && user.username ? (
          <DropDown />
        ) : (
          <Link to={'/login'} style={{ textDecoration: "none" }} >
            <button className="btn btn-login">Sign In</button>
          </Link>
        )}
      </div>
      <div className="mobile" id="mobile" onClick={handleClick}>
        {click ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 burger"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 burger"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      {showModal && 
              <DeleteModal 
                h4="Do you want to Submit Another Prospect?"
                closeModal={handleModalNo}
                confirmDelete={handleModalYes}
              />}    
    </div>
  );
};

export default Header;

