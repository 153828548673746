export const Images = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/260352/pexels-photo-260352.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Dumbbells",
      desc: "Versatile free weights for various exercises"
    },
    {
      id: 2,
      img: "https://images.pexels.com/photos/4944427/pexels-photo-4944427.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Stationary Bikes",
      desc: "High-performance bikes with adjustable settings"
    },
    {
      id: 3,
      img: "https://images.pexels.com/photos/13896072/pexels-photo-13896072.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Treadmill",
      desc: "Top-rated treadmill with advanced features"
    },
    {
      id: 4,
      img: "https://images.pexels.com/photos/13885345/pexels-photo-13885345.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "BarBells",
      desc: "Bar-shaped weights for strength training"
    },
    {
      id: 5,
      img: "https://images.pexels.com/photos/4804336/pexels-photo-4804336.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Kettle Bells",
      desc: "Dynamic weights for full-body workouts"
    },
    {
      id: 6,
      img: "https://images.pexels.com/photos/6339637/pexels-photo-6339637.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Resistance Bands",
      desc: "Flexible bands for muscle toning and stretching"
    },
    {
      id: 7,
      img: "https://images.pexels.com/photos/4944429/pexels-photo-4944429.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Medicine Balls",
      desc: "Weighted balls for strength and conditioning"
    },
    {
      id: 8,
      img: "https://images.pexels.com/photos/3823086/pexels-photo-3823086.jpeg?auto=compress&cs=tinysrgb&w=600",
      name: "Yoga Mats",
      desc: "Non-slip mats for yoga and floor exercises"
    }
  ];


export const defaultUrl = "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000"


