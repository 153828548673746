import React, {useState, useEffect} from 'react'
import '../../css/Products.css';
import { Link } from "react-router-dom";
import instance from "../../utils/axios"
import { motion } from "framer-motion";
import toast from 'react-hot-toast';
import Layout from '../MainLayout/Layout';
import Loader from '../../components/MainLoader/Loader';

const Programs = () => {

const [programData, setProgramData] = useState([]);
const [loading, setLoading] = useState(false);

const fetchData = async () => {
  try {
    setLoading(true);
    const response = await instance.get("/api/user/getProgramsData")
    if (response.data.success) {
      setLoading(false);
      setProgramData(response.data.data);
    } else {
      setLoading(false);
      console.error(response.data.error);
      toast.error("Something Went Wrong! Please reload the page");
    }
  } catch (error) {
    setLoading(false);
    toast.error("Failed to fetch event data");
  }
}

useEffect(() => {
  fetchData();
}, [])


  return (
      <Layout >
        <motion.div className='products'         
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="website-header">Planet Muscle Events</div>
            <div className="products-container">
                {loading ? (<Loader />) : programData.length === 0 ? (
                <div className="no-product-div">
                  <h3 className="empty-product-h3">Oops... No Articles Yet</h3>
                  <h6 className="empty-product-h6">Go ahead and explore our other categories</h6>
                </div>) : (<>
                {programData && programData.length > 0 && programData.slice().reverse().map((program, index) =>  (
                    <div className="product" key={index}>
                        <Link key={program._id} to={`${program._id}`} className="eventImg">
                            <img src={program.file.secure_url}  className="productImage" alt="programImage"  loading="lazy"/>
                        </Link>
                        <div className="product-description">
                            <h3 className="product-name">{program.category}</h3>
                            <h4 className="product-price">Date: {program.time}</h4>
                            <h4 className="product-price">Place: {program.place}</h4>
                            <h4 className="product-price">Address: {program.town}, {program.pincode}</h4>
                            <h4 className="product-price"> Price: ₹{program.price}</h4>
                            <Link className='Link' key={program._id} to={`${program._id}`}> <button className="send-btn event-btn"> Show More </button></Link>
                       </div>
                    </div>
                ))}
                </>
              )}
            </div>
        </motion.div>
      </Layout>
  )
}

export default Programs;