import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import instance from '../../../utils/axios';
import {toast } from 'react-hot-toast';
import { motion } from "framer-motion"

const ViewComments = () => {

  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [length, setLength] = useState(0);
  const [addComment, setAddComment] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [select, setSelect] = useState('');
  const [filteredComments, setFilteredComments] = useState([]);

  const fetchComments = async () => {
    try {
      const response = await instance.get(`/api/user/getAllCommentData/${id}`)
      if (response.data.success) {
        setLength(response.data.data.length);
        setAddComment(response.data.data);
      } else {
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchComments();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = () => {
    instance
      .get(`/api/user/getEventsData/${id}`)
      .then((response) => {
        if (response.data.success) {
          setUsername(response.data.name);
        }
      })
      .catch((error) => {
      });
  };

  const handleFilter = () => {
    const formattedStartDate = startDate ? new Date(startDate).toLocaleDateString('en-GB') : null;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('en-GB') : null;
    const selectedLocation = select.toLowerCase();
    const filteredComments = addComment.filter((comment) => {
      const commentDate = comment.callDateAndTime.split(" ")[0];
      const commentLocation = comment.location.toLowerCase();
      const dateCondition = !formattedStartDate || !formattedEndDate || ((commentDate >= formattedStartDate || 
        commentDate === formattedStartDate) && (commentDate <= formattedEndDate || commentDate === formattedEndDate));
      const locationCondition = !selectedLocation || commentLocation.includes(selectedLocation);
      return dateCondition || locationCondition;
    });
  
    if (filteredComments === null || filteredComments.length === 0) {
      return toast.error("No Comments Found");
    }
    setFilteredComments(filteredComments);
  };
  
  const commentsToRender = filteredComments.length > 0 ? filteredComments : addComment && addComment.slice().reverse();

  return (
    <div className='dataTable '>
    <motion.div className="datatableTitle dashboard-title"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {username}'s All Comments ({ length })
    </motion.div>
    <motion.div className="comment-container"  initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.3 }}
>
      <div className="filter-container">
        <input
          type="date"
          value={startDate}
          className='filter-input'
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          className='filter-input'
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select className='filter-select'  value={select} onChange={(e) => setSelect(e.target.value)} >
          <option value="">Select Location</option>
          <option value="Online User">Online User</option>
          <option value="Employee">Employee</option>
          <option value="Calendar">Calendar</option>
          <option value="Prospect">Prospect</option>
        </select>
        <button onClick={handleFilter} className='comment-btn dash-btn'>Filter</button>
      </div>
      <div className="show-comment">
        {commentsToRender.map((product, value) => (
          <div className="show-comment-container-1" key={value}>
              <div className="show-comment-header">
                <div className="call-form-header">
                  <h5 className="call-addedBy prospect-name">Prospect Name: {product.prospectName.name || product.prospectName.userName}</h5>
                </div>
                <div className="call-form-header">
                  <h5 className="call-addedBy">Added by: {product.addedFrom}</h5>
                </div>
                <div className="call-form">
                  <div className="call-fields">
                    <label htmlFor="">Call Subject</label>
                    <h5 className="show-comments-value">{product.callSubject}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Call Response</label>
                    <h5 className="show-comments-value">{product.callResponse}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Reasons</label>
                    <h5 className="show-comments-value">{product.reason}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Priority</label>
                    <h5 className="show-comments-value">{product.priority}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Next Call Date/ Time</label>
                    <h5 className="show-comments-value">{new Date(product.nextCallDate).toLocaleDateString("en-GB")} {product.nextCallTime}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Call Date</label>
                    <h5 className="show-comments-value">{product.callDateAndTime}</h5>
                  </div>
                  <div className="call-fields">
                    <label htmlFor="">Call Status</label>
                    <h5 className="show-comments-value">{product.callStatus}</h5>
                  </div>
                </div>
                <div className="call-form-footer">
                  <h5 className="show-comments-value show-comment-value">Comment: {product.comment}</h5>
                </div>
              </div>
            </div>
        ))}
      </div>
    </motion.div>
  </div>
)
}

export default ViewComments;
