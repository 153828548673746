import React, {  useState } from "react";
import { Link } from "react-router-dom";
import "./Message.css";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatIcon from "@mui/icons-material/Chat";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import NewChatGroup from "./NewChatGroup";

const MessageRoutes = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="chat-sidebar chat-sidebar1">
      <Link to={"/message"} className="chat-sidebar-logo">
        <ChatBubbleOutlineIcon />
      </Link>
      <ul className="chat-sidebar-menu">
        <li className="active">
          <Link to={"/message/chat"} data-title="Chats">
            <ChatIcon />
          </Link>
        </li>
        <li>
          <Link onClick={() => setShowModal(!showModal)} data-title="New Group">
            <AddIcon />
          </Link>
        </li>
        <li className={`chat-sidebar-profile`}>
          <button type="button" className="chat-sidebar-profile-toggle">
              <Link to={"/dashboard/profile"} data-title="Logout">
                <LogoutIcon className="logout-message-icons" />
              </Link>
          </button>
        </li>
      </ul>
      {showModal && <NewChatGroup setShowModal={setShowModal} location='new' />}
    </div>
  );
};

export default MessageRoutes;
