import { useRef, useState } from "react";
import "../../css/DropDown.css";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../../store";
import instance from "../../utils/axios";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Dropdown = () => {
    const buttonRef = useRef(null);
    const chevronRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [menuTop, setMenuTop] = useState();
    const [menuRight, setMenuRight] = useState();

    const user = useUserStore((state) => state.user); 
    const setLoggedIn = useUserStore((state) => state.setLoggedIn);
    const setUser = useUserStore((state) => state.setUser);
    const clearCart = useUserStore((state) => state.clearCart);
    const nav = useNavigate();


    const handleLogout = async () => {
        const response = await instance.post('/api/user/logout',{
            withCredentials:true
        });
        if(response.data.success){
            setLoggedIn(false);
            setUser({});
            // setCartItems("");
            clearCart();
            nav('/');
        }
      };
    
    const handleClick = () => {
        const buttonRect = buttonRef?.current?.getBoundingClientRect();
        const chevronRect = chevronRef?.current?.getBoundingClientRect();

        if (buttonRect && chevronRect && isOpen) {
            const menuRight = buttonRect.right - chevronRect.right;
            const menuTop = chevronRect.top - buttonRect.top;
            setMenuRight(`${menuRight}px`);
            setMenuTop(`${menuTop}px`);
        } else {
            setMenuRight("0");
            setMenuTop("78px");
        }

        setIsOpen(!isOpen);
    };

    return (
        <div className={`dropdown ${isOpen ? "open" : ""}`}>
            <button className="d-btn" ref={buttonRef} onClick={handleClick}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" style={{ width: "1.5rem" }} strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </div>
                <span >{user.username} </span>
                <div
                    divref={chevronRef}
                    className="chevron material-symbols-outlined"
                >
                    {isOpen}
                </div>
            </button>
            <div
                className={`menu1 ${isOpen ? "openMenu" : ""}`}
                style={{ right: menuRight, top: menuTop }}
            >
                <Link className="b-btn Link" to={'/dashboard/profile'}>
                    <div> <AccountBoxIcon /></div>
                    <span> My Profile</span>
                </Link>
                <Link to={'/cart'} className="b-btn Link">
                    <div><ShoppingCartIcon/></div>
                    <span>My Cart</span>                 
                </Link>
                <Link className="b-btn Link" onClick={handleLogout}>
                    <div><LogoutIcon/></div>
                    <span>Logout</span>
                </Link>
            </div>
        </div>
    );
};

export default Dropdown;