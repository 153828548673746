import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import OrderReceipt from "../../components/orderReciept/order-reciept";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useUserStore from "../../../store";

const UserOrder = () => {
  const [userData, setUserData] = useState([]);
  const user = useUserStore((state) => state.user);

  const getData = async () => {
    try {
      const response = await instance.get("/api/user/getParticularUsersOrder", {
        params: { userId: user._id },
      });
      if (response.data.success) {
        const rows = response.data.data && response.data.data.slice().reverse().map((order, index) => {
              const reversedIndex = response.data.data.length - index;
              const createdAt = new Date(order.createdAt);
              const createdAtDate = createdAt.toLocaleDateString("en-GB");
              const createdAtTime = createdAt.toLocaleTimeString();
              const createdAtFormatted = `${createdAtDate} ${createdAtTime}`;

              const productNames = order.cartItems?.length === 1 ? order.cartItems[0].name : order.cartItems[0].name + ` + ${order.cartItems.length - 1} others...`;

              const shippingAddress = `${order.shippingAddress.line1}, ${order.shippingAddress.line2}, ${order.shippingAddress.city}, ${order.shippingAddress.state} - ${order.shippingAddress.pincode}, ${order.shippingAddress.country}`;

              const formatter = new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              });
              const formattedAmount = formatter.format(order.totalAmount);

              return {
                id: index + 1,
                col1: reversedIndex,
                col2: order.orderId,
                col3: productNames,
                col4: createdAtFormatted,
                col5: formattedAmount,
                col6: order.paymentMode,
                col7: order.discountCode ? "Yes" : "No",
                col8: order.discountCode || "N/A",
                col9: shippingAddress,
                col10: order.status,
                orderDetails: order,
              };
            });
        setUserData(rows);
      }
    } catch (error) {
      console.log("get all orders error: ", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const columns = [
    { field: "col1", headerName: "Index", width: 70 },
    { field: "col2", headerName: "Order Id", width: 170 },
    { field: "col3", headerName: "Product Name", width: 270 },
    { field: "col4", headerName: "Date", width: 175 },
    { field: "col5", headerName: "Amount", width: 120 },
    { field: "col6", headerName: "Payment Method", width: 120 },
    { field: "col7", headerName: "Discount Applied", width: 100 },
    { field: "col8", headerName: "Discount Code", width: 100 },
    { field: "col9", headerName: "Shipping Address", width: 300 },
    { field: "col10", headerName: "Status", width: 120 },
    {
      field: "col11",
      headerName: "Bill",
      width: 120,
      renderCell: (params) => (
        <PDFDownloadLink
          document={
            <OrderReceipt
              planDetails={params.row.orderDetails}
              userData={user}
            />
          }
          fileName={`Order_Bill_${user.name}`}
        >
          <button className="viewButton">Download Bill</button>
        </PDFDownloadLink>
      ),
    },
  ];

  const data = {
    rows: userData,
    columns: columns,
  };

  return (
    <DataGrid
      {...data}
      initialState={{
        ...data.initialState,
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
    />
  );
};

export default UserOrder;
