import './App.css';
import { Routes, Route } from "react-router-dom"
import instance from './utils/axios';
import { useEffect } from 'react';
import useUserStore from './store';

import IndexPage from './pages/MainIndexPage/IndexPage.jsx';
import AboutUs from './pages/MainAboutUs/AboutUs.jsx';
import Login from './components/Authentication/Login.jsx';
import Register from './components/Authentication/Register.jsx';
import ForgotPassword from './components/Authentication/ForgotPassword.jsx';
import OtpVerification from './components/Authentication/OtpVerification.jsx';
import ResetPassword from './components/Authentication/ResetPassword.jsx';
import DashLayout from './dashboard/components/DashboardLayout/DashLayout.jsx';
import DashProfile from './dashboard/pages/DashboardProfile/DashProfile.jsx';
import Analysis from './dashboard/pages/DashboardAnalysis/Analysis.jsx';
import Members from './dashboard/pages/DashboardMembers/Members.jsx';
import Visitors from './dashboard/pages/DashboardVisitors/Visitors.jsx';
import UserPage from './dashboard/pages/DashboardUserPage/UserPage.jsx';
import DashEmployee from './dashboard/pages/DashboardEmployees/DashEmployee.jsx';
import EmployeePage from './dashboard/pages/DashboardEmployees/EmployeePage.jsx';
import Admin from './dashboard/pages/DashboardAdmin/Admin.jsx';
import DashProducts from './dashboard/pages/DashboardProduct/DashProducts.jsx';
import { DashAddProducts, UpdateProduct } from './dashboard/pages/DashboardProduct/DashProductForm.jsx';
import DashPrograms from './dashboard/pages/DashboardPrograms/DashPrograms.jsx';
import { DashAddProgram, UpdateProgram } from './dashboard/pages/DashboardPrograms/DashProgramForm.jsx';
import DashTask from './dashboard/pages/DashboardTask/DashTask.jsx';
import UserProspect from './dashboard/pages/DashboardUserProspect/UserProspect.jsx';
import DashPlans from './dashboard/pages/DashboardPlans/DashPlans.jsx';
import { DashAddPlan, UpdatePlan } from './dashboard/pages/DashboardPlans/DashPlanForm.jsx';
import DashArticles from './dashboard/pages/DashboardArticles/DashArticles.jsx';
import { DashAddArticle, UpdateArticle } from './dashboard/pages/DashboardArticles/DashArticlesForm.jsx';
import Sales from './dashboard/pages/DashboardSales/Sales.jsx';
import ViewComments from './dashboard/pages/DashboardSales/ViewComments.jsx';
import ListSales from './dashboard/pages/DashboardSales/ListSales.jsx';
import Products from './pages/MainProducts/Products.jsx';
import Programs from './pages/MainPrograms/Programs.jsx';
import Articles from './pages/MainArticles/Articles.jsx';
import SingleProduct from './pages/MainProducts/SingleProduct.jsx';
import SingleArticles from './pages/MainArticles/SingleArticles.jsx';
import SingleProgram from './pages/MainPrograms/SIngleProgram.jsx';
import Cart from './components/MainCart/Cart.jsx';
import Accounts from './dashboard/pages/DashboardAccounts/Accounts.jsx';
import DashRequest from './dashboard/pages/DashboardRequests/DashRequest.jsx';
import ProfileData from './dashboard/pages/DashboardProfile/ProfileData.jsx';
import Calendar from './dashboard/pages/DashboardCalendar/Calendar.jsx';
import PTcalendar from './dashboard/pages/DashboardCalendar/PTcalendar.jsx';
import DetailCalendar from './dashboard/pages/DashboardCalendar/DetailCalendar.jsx';
import ListCalendar from './dashboard/pages/DashboardCalendar/ListCalendar.jsx';
import Success from "./components/MainSuccess/Success.jsx"
import PlansPage from './pages/MainPlans/PlansPage.jsx';
import NewMember from './dashboard/pages/DashboardMembers/NewMember.jsx';
import NewEmployee from './dashboard/pages/DashboardEmployees/NewEmployee.jsx';
import OnlineProspect from './dashboard/pages/DashboardUserProspect/OnlineProspect.jsx';
import Prospect from './components/MainPlans/Prospect.jsx';
import DashExUser from './dashboard/pages/DashbaordExUsers/DashExUser';
import DashUser from './dashboard/pages/DashboardUserForm/DashUser.jsx';
import { UpdateQuestionForm } from './pages/GoogleForm/QuestionForm.jsx';
import EventAnalysis from './dashboard/pages/DashboardAnalysis/EventAnalysis.jsx';
import ProductAnalysis from './dashboard/pages/DashboardAnalysis/ProductAnalysis.jsx';
import Message from './pages/MessageApp/Message.jsx';
import DashAds from './dashboard/pages/DashboardAdmin/DashAds.jsx';
import GoogleTab from './pages/GoogleForm/GoogleTab.jsx';
import Userform from './pages/GoogleForm/Userform.jsx';
import IndividualForm from './dashboard/pages/form-response/individual-form.jsx';
import UpdatePrescription from './dashboard/pages/form-response/update-prescription.jsx';
import DaysPrescription from './dashboard/pages/form-response/days-prescription.jsx';
import UserExercises from './dashboard/pages/exercises/user-exercises.jsx';
import WeekExercises from './dashboard/pages/exercises/week-exercises.jsx';
import ExercisesList from './dashboard/pages/exercises/exercises-list.jsx';
import SingleExercise from './dashboard/pages/exercises/single-exercise.jsx';
import Exercise from './dashboard/pages/exercises/exercise.jsx';

function App() {

  const setLoggedIn = useUserStore((state) => state.setLoggedIn);
  const setUser = useUserStore((state) => state.setUser);
  const setCartItems = useUserStore((state) => state.setCartItems);
  
  const checkTokenExpiration = async () => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await instance.get('/api/user/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.loggedIn) {
        setLoggedIn(true);
        setUser(response.data.user);
      } else {
        setLoggedIn(false);
        setUser("");
        setCartItems("");
      }
    } catch (error) {
      // Handle error if the token expiration check fails
    }
  };

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 600000);
    checkTokenExpiration();
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser, setLoggedIn]);

  return (
    <div className="App">
      <div className="app">
        <Routes>
          <Route exact path="/" element={<IndexPage />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgotPassword' element={<ForgotPassword />} />
          <Route path='/otp' element={<OtpVerification />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/plans' element={<PlansPage />} />
          <Route path='/updateForm/:id' element={<UpdateQuestionForm />} />
          <Route path='/createForm' element={<GoogleTab />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:productId' element={<SingleProduct />} />
          <Route path='/programs' element={<Programs />} />
          <Route path='/programs/:programId' element={<SingleProgram />} />
          <Route path='/articles' element={<Articles />} />
          <Route path='/articles/:articleId' element={<SingleArticles />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/prospect/:value' element={<Prospect />} />
          <Route path='/success' element={<Success />} />
          <Route path='/message/*' element={<Message />} />
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
        </Routes>
      </div>
    </div>
  );
}

function DashboardRoutes() {
  return (
    <DashLayout>
      <Routes>
        <Route index element={<DashLayout />} />
        <Route path="users">
          <Route index element={<Visitors />} />
          <Route path='userPage/:id' element={<UserPage />} />
        </Route>
        <Route path="members">
          <Route index element={<Members />} />
          <Route path='userPage/:id' element={<UserPage />} />
          <Route path='new' element={<NewMember />} />
        </Route>
        <Route path="employees">
          <Route index element={<DashEmployee />} />
          <Route path='employeePage/:id' element={<EmployeePage />} />
          <Route path='new' element={<NewEmployee />} />
        </Route>
        <Route path="products">
          <Route index element={<DashProducts />} />
          <Route path='addProduct' element={<DashAddProducts />} />
          <Route path='analysis/:productId' element={<ProductAnalysis />} />
          <Route path="updateProduct/:productId" element={<UpdateProduct />} />
        </Route>
        <Route path="programs">
          <Route index element={<DashPrograms />} />
          <Route path='addProgram' element={<DashAddProgram />} />
          <Route path='analysis/:programId' element={<EventAnalysis />} />
          <Route path="updateProgram/:programId" element={<UpdateProgram />} />
        </Route>
        <Route path="plans">
          <Route index element={<DashPlans />} />
          <Route path='addPlan' element={<DashAddPlan />} />
          <Route path="updatePlan/:planId" element={<UpdatePlan />} />
        </Route>
        <Route path='calendar'>
          <Route index element={<Calendar />} />
          <Route path="PTcalendar" element={<PTcalendar />} />
          <Route path="PTcalendar/listCalendar/:id" element={<ListCalendar />} />
          <Route path="PTcalendar/listCalendar/detailCalendar/:id" element={<DetailCalendar />} />
        </Route>
        <Route path="editor">
          <Route index element={<DashArticles />} />
          <Route path='addArticle' element={<DashAddArticle />} />
          <Route path="updateArticle/:articleId" element={<UpdateArticle />} />
        </Route>
        <Route path='sales'>
          <Route index element={<Sales />} />
          <Route path="viewComments/:id" element={<ViewComments />} />
          <Route path="listSales/:id" element={<ListSales />} />
        </Route>
        <Route path='user'>
          <Route index element={<DashUser />} />
          <Route path="form" element={<Userform />} />
        </Route>
        <Route path='userProspect'>
          <Route index element={<UserProspect />} />
          <Route path=":id" element={<OnlineProspect />} />
        </Route>
        <Route path="past" element={<DashExUser />} />
        <Route path="admin" element={<Admin />} />
        <Route path='admin/form/:id' element={<IndividualForm />} />
        <Route path="task" element={<DashTask />} />
        <Route path="ads" element={<DashAds />} />
        <Route path="requests" element={<DashRequest />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="profile" element={<DashProfile />} />
        <Route path="profile/:userId" element={<ProfileData />} />
        <Route path='prescription'>
          <Route path="weeks/:prescriptionId" element={<UpdatePrescription/>} />
          <Route path="day/:dayId" element={<DaysPrescription/>} />
        </Route>
        <Route path='exercises'>
          <Route index element={<Exercise />} />
          <Route path=":prescriptionId" element={<UserExercises />} />
          <Route path="days/:weekId" element={<WeekExercises />} />
          <Route path="day/:dayId" element={<ExercisesList/>} />
          <Route path="exercise/:exerciseId" element={<SingleExercise />} />
        </Route>

        <Route path="analysis" element={<Analysis />} />
      </Routes>
    </DashLayout>
  );
}

export default App;
