import React, { useEffect, useState } from 'react';
import instance from '../../utils/axios';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const Advertisement = () => {
  const [advertisement, setAdvertisement] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    instance
      .get('/api/user/getAdvertisement')
      .then((response) => {
        if (response.data.success) {
          setAdvertisement(response.data.data);
          setTimeout(() => {
            setModalOpen(true);
          }, 10000); 
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setModalOpen(true);
    }, 3600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (location.pathname !== '/' || advertisement.length === 0) {
    return null;
  }
  return (
    <>
      {modalOpen && (
        <div className="hero-modal">
          { advertisement && advertisement.map((ad, key) => (
            <div className="hero-ad" key={key}>
              <img src={ad.file.secure_url} alt="" className="ad-img" />
              <CloseIcon className="ad-close-icon" onClick={closeModal} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Advertisement;
