import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../css/Register.css'
import {motion} from "framer-motion"
import {toast} from "react-hot-toast";
import instance from "../../utils/axios";

const ResetPassword = () => {
  const [val, setVal] = useState({
    password:"",
    password1:"",
});

  const nav = useNavigate();

  const setdata = (e) => {
    const {name, value} = e.target;
    setVal((preval) =>{
      return{
        ...preval,
        [name]:value
      }
    })
  }
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const id = searchParams.get('id');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(val.password !== val.password1) {
      return toast.error("The Password and Confirm Password Do not match");
    }
    if(val.password.trim().length < 8 || val.password.trim().length >20){
      return toast.error("The password must be 8 to 20 characters long");
    }
    try{
      const response = await instance.post(`/api/user/reset-password?token=${token}
      &id=${id}`,{password: val.password});
      if(response.data.success){
        toast.success("Password Reset Successfully.");
        nav('/login');
      }else{
        toast.error(response.data.message);
      }
    }catch(error){
      toast.error("An Error Occurred")
    }
  };

  const verifyToken = async () =>{
    try {  
    await instance.get(`/api/user/verify-token?token=${token}
    &id=${id}`);
    }catch(error) {
      return toast.error("An Error Occurred");
  };
}

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, [])
  
  return (
    <>
      <motion.div className="register"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
      >
        <div className="otp-container">
          <h3 className="register-title">Reset Password</h3>
          <form className='register-form' onSubmit={handleSubmit}>
            <div className="text-field-container">
              <div className="text-field">
                <span className='register-span'>New Password</span>
                <input required="required" placeholder="Enter New Password" className="register-input" name="password" value={val.password} onChange={setdata} type={showPassword ? 'text' : 'password'}/>
                <div                
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    aria-label="toggle password visibility"
                    className="show-icon"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
                
              </div>
              <div className="text-field">
                <span className='register-span'>Confirm Password</span>
                <input required="required" placeholder="Enter the password again"  className="register-input" name="password1" value={val.password1} onChange={setdata} type={showPassword1 ? 'text' : 'password'}/>
                <div                
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword}
                    aria-label="toggle password visibility"
                    className="show-icon"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
            </div>
            <button className="submit-btn send-btn" type="submit">Submit</button>
          </form>
        </div>
      </motion.div>
    </>
  )
}

export default ResetPassword;