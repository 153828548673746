import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import { motion } from "framer-motion"
import useUserStore from '../../../store';
import "../../css/DashEmployee.css"
import { PDFDownloadLink } from '@react-pdf/renderer';
import Document2 from '../DashboardUserPage/Document2';
import { useParams } from 'react-router-dom';

const ProductAnalysis = () => {

  const {productId} =  useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const location = "Merchandise"

  const user = useUserStore((state) => state.user)

  const getData = async (currentPage, size) => {
    try {
      const response = await instance.get(`/api/user/getAllUserProducts/${productId}`, {
        params: {
          userId: user._id,
          page: currentPage,
          pageSize: size,
        }
      }); 
      if (response.data.success) {
        let totalCount = 0;
        setName(response.data.data[0].products[0].productName);
        const rows = response.data.data.map((element) => {
          const user = element.userId;
          const productsData = element.products.map((event, eventIndex) => {
            totalCount += 1;
            let gstApplied;
            if (event.gst === "No") {
              gstApplied = "No";
            } else if (event.gst === "1.18") {
              gstApplied = "18%";
            } else {
              gstApplied = "5%";
            }
            return {
              id: `${user._id}_${eventIndex}`,
              col1: totalCount,
              col2: user.name,
              col3: user.uniqueId,
              col4: user.mobile,
              col5: event.products,
              col6: event.productSize,
              col7: event.productPrice,
              col8: event.totalProductPrice,
              col9: gstApplied,
              col10: event.gstAmount,
              col11: event.gymLocation,
              col12: event.paymentMode,
              col13: event.discountCode || "N/A",
              col14: event.createdBy,
              col15: event.receiptNumber,
              eventDetails: event,
              userData: user
            };
          });
          return productsData;
        }).flat(2);
        setFilteredData(rows);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  }
  
  const columns = [
    {field: 'col1',headerName: 'Index',width: 50},
    { field: 'col2', headerName: 'Customer Name', width: 200 },
    { field: 'col3', headerName: 'Unique Id', width: 100 },
    { field: 'col4', headerName: 'Mobile', width: 150 },
    { field: 'col5', headerName: 'Quantity', width: 150 },
    { field: 'col6', headerName: 'Size', width: 150 },
    { field: 'col7', headerName: 'Product Price', width: 150 },
    { field: 'col8', headerName: 'Total Price', width: 150 },
    { field: 'col9', headerName: 'Gst Applied', width: 150 },
    { field: 'col10', headerName: 'Gst Amount', width: 150 },
    { field: 'col11', headerName: 'Gym Location', width: 150 },
    { field: 'col12', headerName: 'Payment Mode', width: 150 },
    { field: 'col13', headerName: 'Discount Code', width: 150 },
    { field: 'col14', headerName: 'Created By', width: 150 },
    { field: 'col15', headerName: 'Receipt Number', width: 150 },
    {
      field: 'col16', 
      headerName: 'Download',
      width: 120,
      renderCell: (params) => (
        <PDFDownloadLink
          document={<Document2 planDetails={params.row.eventDetails} userData={params.row.userData} location={location} />}
          fileName={`${params.row.eventDetails.productName}_${params.row.col2}_Bill`}        
        >
          <button className='viewButton' >Document</button>
        </PDFDownloadLink>
      ),
    },
  ];

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
    getData(params.page, params.pageSize);
  };


  const data = {
    rows: filteredData,
    columns: columns,
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [page, pageSize])



  return (
    <div className='dataTable'>
      <motion.div className="datatableTitle dashboard-title"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        {name}        
      </motion.div>
      <motion.div className='dataGrid'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <DataGrid
          {...data}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          onPageSizeChange={handlePageChange}

        />
      </motion.div>
    </div>
  )
}

export default ProductAnalysis;
