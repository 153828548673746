import React, { useState, useEffect } from 'react';
import '../../css/BmiPage.css';
import toast from 'react-hot-toast';

const BmiPage = () => {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [bmi, setBMI] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (weight && height) {
      const calculatedBMI = (weight / (height * height)) * 10000;
      setBMI(calculatedBMI.toFixed(2));

      if (calculatedBMI < 18.5) {
        setCategory('Underweight');
      } else if (calculatedBMI >= 18.5 && calculatedBMI <= 24.9) {
        setCategory('Normal');
      } else if (calculatedBMI >= 25 && calculatedBMI <= 29.9) {
        setCategory('Overweight');
      } else {
        setCategory('Obese');
      }
    }
 }, [weight, height]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (weight && height) {
      toast.success(`Your BMI value is ${bmi}, You are ${category}`);
    } else {
      toast.error('Fill up all the parameters');
    }
  };

  return (
    <div className="bmi-page">
      <div className="glass">
        <h5 className="bmi-h2">Advanced Calculator</h5>
        <h2 className="bmi-h5">Calculate Your BMI</h2>
        <form className="bmi-form" onSubmit={handleFormSubmit}>
          <div>
            <div className="height-data">
              <label htmlFor="">Height (in cm) : </label>
              <input
                type="number"
                min={0}
                value={height}
                required
                placeholder='Enter your Height'
                onChange={(event) => setHeight(event.target.value)}
              />
            </div>
            <div className="weight-data">
              <label htmlFor="">Weight (in kg) : </label>
              <input
                type="number"
                min={0}
                value={weight}
                required
                placeholder='Enter your Weight'
                onChange={(event) => setWeight(event.target.value)}
              />
            </div>
            <div className="age-data">
              <label htmlFor="">Your Age : </label>
              <input type="number" min={0} required placeholder="Age" />
            </div>
            <div className="form-btn">
              <select className="gender" required>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
              <button type="submit" className="btn bmi-btn">
                Calculate Now!
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="bmi-right">
        <img
          src="https://img.freepik.com/free-photo/young-healthy-man-athlete-doing-exercise-with-ropes-gym-single-male-model-practicing-hard-training-his-upper-body-concept-healthy-lifestyle-sport-fitness-bodybuilding-wellbeing_155003-27879.jpg?w=740&t=st=1680018669~exp=1680019269~hmac=cce7e418919a991bcf884493516c42d3f283b3e5c3580aa36229c719f35fcede"
          alt=""
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default BmiPage;
