import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import instance from "../../../utils/axios";
import data from "../../../data/api.json";
import "../../css/Dashprescription.css"

const SingleExercise = () => {
  const { exerciseId } = useParams();
  const [name, setName] = useState([]);
  const [exerciseData, setExerciseData] = useState(null);


  const getData = async () => {
    try {
      const response = await instance.get(`/api/user/getExerciseDetails/${exerciseId}`);
      if (response.data.success) {
        setName(response.data.data);
        const exerciseName = response.data.data.exerciseName;
        const matchedExercise = data.find(exercise => exercise.EXERCISE === exerciseName);

        if (matchedExercise) {
          setExerciseData(matchedExercise);
        } else {
          toast.error("Exercise details not found in the JSON data.");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get exercise by day error: ", error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashMembers">
      <div className="dashMembers-container">
          <div className='dashboard-title' >Week {name.exerciseName}</div>
      </div>
      <div className="dash-user">
        <div className="single-exercise-iframe" >
            <iframe width={'80%'} height={315} src="https://www.youtube.com/embed/gr-afp796jY?si=PDfKgG6jNOF0oAJK" title="Planet Muscle Fitness" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" className='iframe' referrerPolicy="strict-origin-when-cross-origin" allowFullScreen autoPlay >
            </iframe>      
        </div>
        <div className="single-exercise-container" >
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Sets: </label>
            <h4>{name.numberOfSets}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Repetitions: </label>
            <h4>{name.reps}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Ideal Time: </label>
            <h4>{name.time}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Gap between Sets: </label>
            <h4>{name.gap}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Bone Targeted: </label>
            <h4>{exerciseData?.BONE || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Joint: </label>
            <h4>{exerciseData?.JOINT || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Muscle: </label>
            <h4>{exerciseData?.MUSCLE || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Muscle Origin: </label>
            <h4>{exerciseData?.MUSCLE_ORIGIN || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Muscle Insertion: </label>
            <h4>{exerciseData?.MUSCLE_INSERTION || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Muscle Nerve Supply: </label>
            <h4>{exerciseData?.MUSCLE_NERVE_SUPPLY || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Planes: </label>
            <h4>{exerciseData?.PLANES || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Action: </label>
            <h4>{exerciseData?.ACTION || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Forment Technique: </label>
            <h4>{exerciseData?.FORMENT_TECHNIQUE || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Where & Support: </label>
            <h4>{exerciseData?.WHERE_SUPPORT || 'N/A'}</h4>
          </div>
          <div className="single-exercise-div">
            <label htmlFor="" className="single-exercise-label"> Muscle Testing: </label>
            <h4>{exerciseData?.MUSCLE_TESTING || 'N/A'}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleExercise;
