import React, {  useState } from 'react';
import { motion } from 'framer-motion';

import VisitorsData from './VisitorsData';
import NewsLetters from './NewsLetters';

const Visitors = () => {
  const [isActive, setIsActive] = useState(1);

  const handleActive = (btn) => {
    setIsActive(btn);
  };

  return (
    <div className="dashMembers">
    <motion.div
      className="dashMembers-container dashboard-title"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      Visitors Data     
    </motion.div>
    <div className="tabsContainer">
      <motion.div
        className="btnContainer"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <button
          className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
          onClick={() => handleActive(1)}
        >
          Visitors 
        </button>
        <button
          className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
          onClick={() => handleActive(2)}
        >
          NewsLetters         
        </button>
      </motion.div>
      {isActive === 1 && (
        <motion.div
          className="tabData"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="tabContent">
            <VisitorsData />
          </div>
        </motion.div>
      )}
      {isActive === 2 && (
        <motion.div
          className="tabData"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="tabContent">
            <NewsLetters />
          </div>
        </motion.div>
      )}
    </div>
  </div>

  );
};

export default Visitors;
