import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../../assets/logo1.PNG";
import { toast } from "react-hot-toast";
import instance from "../../../utils/axios";

const OrderReceipt = ({ planDetails, userData }) => {
  const [allTnc, setAllTnc] = useState("");

  const styles = StyleSheet.create({
    header: {
      padding: 20,
    },
    pdfTop: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    pdfTopLeft: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    pdfTopLogo: {
      width: 75,
      height: 75,
    },
    pdfTop1: {
      fontSize: 20,
      fontWeight: "bold",
    },
    pdfTopRight: {
      display: "flex",
      flexDirection: "column",
    },
    pdfTopRight1: {
      fontSize: 10,
    },
    pdfBottom: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    pdfTncBottom: {
      paddingTop: 10,
      display: "flex",
      flexDirection: "row",
      gap: 5,
    },
    pdfTopRight2: {
      fontWeight: "bold",
      fontSize: 13,
      marginBottom: 5,
    },
    pdfBottomRight: {
      display: "flex",
      flexDirection: "column",
      width: "47vw",
    },
    pdfTnc: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
    },
    pdfTable: {
        width: "100vw",
        display: "flex",
    },
    pdfTableTitle: {
        fontSize: 13,
        fontWeight: "bold",
        flexDirection: "column"
    },
    pdfTableHeader: {
        display: "flex",
        flexDirection: "row",
        borderBottomColor: "#bff0fd",
        backgroundColor: "#f3f3f3",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        fontSize: 12,
        textAlign: "center",  
    },
    pdfTableBody: {
      flexDirection: "row",
      alignItems: "center",
      height: 24,
      textAlign: "center",
    },
    pdfTableBodyCell: {
      width: "20%",
      textAlign: "center",
      borderBottomColor: "#bff0fd",
      backgroundColor: "#f3f3f3",
      borderBottomWidth: 1,
      fontSize: 10,
    },
    pdfTableCell: {
      width: "25%",
      textAlign: "center",
    },
  });

  const fetchTnc = async () => {
    try {
      const response = await instance.get("/api/user/getTncData");
      if (response.data.success) {
        setAllTnc(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchTnc();
  }, []);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const totalAmountDisplay = formatAmount(planDetails.totalAmount)

  return (
    <Document>
      <Page>
        <View style={styles.header}>
          <View style={styles.pdfTop}>
            <View style={styles.pdfTopLeft}>
              <Image src={logo} style={styles.pdfTopLogo} />
              <Text style={styles.pdfTop1}> Order Receipt</Text>
            </View>
            <View style={styles.pdfTopRight}>
              <Text style={styles.pdfTopRight1}>Planet Muscle</Text>
              <Text style={styles.pdfTopRight1}>
                186/187, Aram Nagar Part 2 ,Versova,
              </Text>
              <Text style={styles.pdfTopRight1}>Mumbai, Maharashtra</Text>
              <Text style={styles.pdfTopRight1}>8454864948</Text>
            </View>
          </View>
          <View style={styles.pdfBottom}>
            {planDetails && userData && (
              <View style={styles.pdfBottomRight}>
                <Text style={styles.pdfTopRight2}>Bill To</Text>
                <Text style={styles.pdfTopRight1}>Name: {userData.name}</Text>
                <Text style={styles.pdfTopRight1}>
                  Address:{" "}
                  {planDetails.shippingAddress?.line1 &&
                  planDetails.shippingAddress?.pincode
                    ? `${planDetails.shippingAddress.line1}, ${planDetails.shippingAddress.line2}, ${planDetails.shippingAddress.city}, ${planDetails.shippingAddress.state}, ${planDetails.shippingAddress.pincode}`
                    : "NA"}
                </Text>
                <Text style={styles.pdfTopRight1}>
                  Mobile No: {userData.mobile}
                </Text>
                <Text style={styles.pdfTopRight1}>Email: {userData.email}</Text>
              </View>
            )}
            {userData && planDetails && (
              <View style={styles.pdfTopRight}>
                <Text style={styles.pdfTopRight2}>Details</Text>
                <Text style={styles.pdfTopRight1}>
                  Order Date:{" "}
                  {new Date(planDetails.createdAt).toLocaleDateString("en-GB")}
                </Text>
                <Text style={styles.pdfTopRight1}>
                  Order Id: {planDetails.orderId}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.pdfBottom}>
            <View style={styles.pdfTable}>
              <Text style={styles.pdfTableTitle}>Cart Items</Text>
              <View style={styles.pdfTableHeader}>
                <Text style={styles.pdfTableCell}>Index</Text>
                <Text style={styles.pdfTableCell}>Item Name</Text>
                <Text style={styles.pdfTableCell}>Quantity</Text>
                <Text style={styles.pdfTableCell}>Size</Text>
                <Text style={styles.pdfTableCell}>Price</Text>
              </View>
              {planDetails &&
                planDetails.cartItems &&
                planDetails.cartItems.map((item, index) => (
                  <View key={index} style={styles.pdfTableBody}>
                    <Text style={styles.pdfTableBodyCell}>{index + 1}</Text>
                    <Text style={styles.pdfTableBodyCell}>{item.name}</Text>
                    <Text style={styles.pdfTableBodyCell}>{item.quantity}</Text>
                    <Text style={styles.pdfTableBodyCell}>{item.itemType === "program" ? "N/A" : item.size}</Text>
                    <Text style={styles.pdfTableBodyCell}>{item.price}</Text>
                  </View>
                ))}
            </View>
          </View>
          <View style={styles.pdfBottom}>
            {planDetails && (
              <View style={styles.pdfBottomRight}>
                <Text style={styles.pdfTopRight2}>Order Summary</Text>
                <Text style={styles.pdfTopRight1}>
                  Total Amount: {totalAmountDisplay}
                </Text>
                <Text style={styles.pdfTopRight1}>
                  Order Id: {planDetails.orderId}
                </Text>
                <Text style={styles.pdfTopRight1}>
                  Payment Mode: {planDetails.paymentMode}
                </Text>
                <Text style={styles.pdfTopRight1}>
                  Ordered On: {new Date(planDetails.createdAt).toLocaleDateString("en-GB")}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.pdfBottom}>
            <View style={styles.pdfTnc}>
              <Text style={styles.pdfTopRight2}>Terms & Conditions</Text>
              {allTnc &&
                allTnc.length > 0 &&
                allTnc.map((terms, value) => (
                  <View style={styles.pdfTncBottom} key={value}>
                    <Text style={styles.pdfTopRight1}>{value + 1}.</Text>
                    <Text style={styles.pdfTopRight1}> {terms.tnc}</Text>
                  </View>
                ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OrderReceipt;
