import React, { useState, useEffect } from 'react';
import { toast } from "react-hot-toast";
import '../../css/DashUser.css';
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import useUserStore from "../../../store"
import instance from '../../../utils/axios';

const TestingResult = ({ formId, closeModal, name }) => {
    const [formResponseData, setFormResponseData] = useState();
    const [rejectedResponseId, setRejectedResponseId] = useState(null);
    const [rejectReason, setRejectReason] = useState('');
    const user = useUserStore((state) => state.user)

    const getFormData = async () => {
        try {
            const response = await instance.get(`/api/user/getIndividualTestingResults/${formId}`);
            if (response.data.success) {
                console.log(response.data.data);
                setFormResponseData(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("An Error Occurred.");
        }
    }

    useEffect(() => {
        getFormData();
        // eslint-disable-next-line
    }, []);

    const handleAccept = async (id) => {
        try {
            const response = await instance.put(`/api/user/acceptTestingResult/${formId}`, { id, uniqueId: user.uniqueId });
            if (response.data.success) {
                toast.success(response.data.message);
                getFormData(); // Refresh data after accepting
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("An Error Occurred.");
        }
        setRejectedResponseId(null); // Reset rejectedResponseId
    }

    const handleReject = (id, reason) => {
        setRejectedResponseId(id); // Set rejectedResponseId to show the input field
        setRejectReason(reason); // Set rejectReason to show the current reason
    };

    const submitRejectReason = async (id) => {
        try {
            const response = await instance.put(`/api/user/rejectTestingResult/${formId}`, { reason: rejectReason, id, uniqueId: user.uniqueId });
            if (response.data.success) {
                toast.success(response.data.message);
                getFormData(); 
                setRejectedResponseId(null);
                setRejectReason(''); // Clear rejectReason state
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error("An Error Occurred.");
        }
    };

    return (
        <div className='tnc-modal1'>
            <div className='tnc-modal-content1 show-response-modal'>
                <h4 className="show-response-modal-h4">{name}&apos;s Result Response </h4>
                <CloseIcon className="close-icon" onClick={closeModal} />
                {formResponseData?.testResponse?.map((data, value) => (
                    <div key={value} className='show-response-modal-container'>
                        <span className='show-response-modal-span'> {value + 1}. {data.testName} </span>
                        <a href={data.file.secure_url} target='_blank' rel="noreferrer" className='show-response-modal-li'>
                            View Response
                        </a>
                        {data.isPass === false && (
                            <div className="show-response-modal-btns">
                                <button className='show-response-modal-btn' onClick={() => handleReject(data._id, data.reason)}>
                                    <CloseIcon className='show-response-modal-btn-icons' />
                                    Reject
                                </button>
                                <button className='show-response-modal-btn' onClick={() => handleAccept(data._id)}>
                                    <CheckIcon className='show-response-modal-btn-icons' />
                                    Accept
                                </button>
                            </div>
                        )}
                        {data.isPass === false && data.reason !== '' && (
                            <div>
                                <div>
                                    {data.reason}
                                </div>
                            </div>
                        )}
                        {rejectedResponseId === data._id && (
                            <div className='show-response-modal-btns'>
                                <input 
                                    type="text" 
                                    className='show-response-modal-input' 
                                    placeholder='Specify Your Reason Here...' 
                                    value={rejectReason}
                                    onChange={(e) => setRejectReason(e.target.value)}
                                />
                                <button 
                                    className='dash-btn' 
                                    onClick={() => submitRejectReason(data._id)}>
                                    Submit
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TestingResult;
