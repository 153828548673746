import React, { useState, useEffect } from 'react'
import CelebrationIcon from '@mui/icons-material/Celebration';

import '../../css/Notifications.css'
import useUserStore from '../../../store';
import instance from '../../../utils/axios';
import { defaultUrl } from '../../../data/Images';

const BirthDays = ({ onLengthChange, birthdayCount }) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [birthday, setBirthday] = useState([]);
    const user = useUserStore((state) => state.user);

    useEffect(() => {
        const handleNotification = async () => {
            try {
                const response = await instance.get("/api/user/getBirthdayNotification", {
                    params: {
                        userId: user._id,
                    },
                });
                if (response.data.success) {
                    setBirthday(response.data.data);
                    const length1 = response.data.data.length;
                    onLengthChange(length1);
                } else {
                }
            } catch (error) {
            }
        };
        handleNotification();
        // eslint-disable-next-line
    }, [user._id]);

    const currentDate = new Date(); // Get the current date
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}/${month}/${year}`;

    return (
        <div className="notifications">
            <div className='notifyIcon' onClick={() => setShowDropDown(!showDropDown)}>
                <CelebrationIcon className='icon' />
            </div>
            <div className="notificationBox1" style={{
                opacity: !showDropDown ? "0" : "1",
                transition: "0.3s ease",
                visibility: !showDropDown ? "hidden" : "visible",
                transform: !showDropDown ? "scale(0.9)" : "scale(1)",
                transformOrigin: "top right"
            }}>
                <div className="notificationsHeader">
                    <h4 className='notification-h4'>BirthDay's & Celebrations ({birthday.length})</h4>
                </div>
                {user.role === "User" ? (
                    <div className="notification-user"  >
                        <div className="notification-photoUrl">
                            <img src={user.file.secure_url === null || user.file.secure_url.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : user.file.secure_url} alt='' />
                        </div>
                        <div className="notification-details">
                            <div className="notifyMessage">
                                <span className="textBold">
                                    Team Planet Muscle wishes {user.name} a very happy birthday
                                </span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {user.role !== "User" && birthday && birthday.length > 0 ? (
                            birthday.map((product, value) => (
                                <div className="notification-user" key={value}>
                                    <div className="notification-photoUrl">
                                        <img
                                            src={
                                                product && product.file && product.file.secure_url
                                                    ? product.file.secure_url : defaultUrl
                                            }
                                            alt=''
                                        />
                                    </div>
                                    <div className="notification-details">
                                        <div className="notifyMessage">
                                            Today is
                                            <span className="textBold"> {product.name}'s </span> birthday.
                                            They have turned {product.age} years old.
                                            Wish them a happy birthday!
                                        </div>
                                        <div className="notifyMessage">
                                            Call them at <span className="textBold">{product.mobile}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="noNotifications">No birthday notifications on {formattedDate}</div>
                        )}
                    </>
                )}

            </div>
        </div>
    )
}

export default BirthDays;

