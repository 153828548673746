import React, { useState, useEffect } from 'react';
import '../../css/DashNavbar.css';
import MessageIcon from '@mui/icons-material/Message';
import useUserStore from "../../../store";
import Notifications from './Notifications';
import BirthDays from './BirthDays';
import { defaultUrl } from '../../../data/Images';
import {Link} from "react-router-dom"

const Navbar = () => {
  const user = useUserStore((state) => state.user);
  const [number, setNumber] = useState(0);
  const [number1, setNumber1] = useState(0);
  const currentDate = new Date().toLocaleDateString('en-GB');
  const dob = new Date(user.dob).toLocaleDateString('en-GB');

  useEffect(() => {
    if (user.role !== "User") {
      setNumber1(number1);
    }
  }, [number1, user.role]);

  useEffect(() => {
    setNumber(number);
  }, [number]);

  const handleLengthChange = (length) => {
    setNumber(length);
  };

  const handleLengthChange1 = (birthdayLength) => {
    setNumber1(birthdayLength);
  };

  const renderBirthdayItem = () => {
    if (user.role === "User" && dob === currentDate) {
      return <BirthDays className="icon" />;
    }
    return null;
  };

  const renderNotificationItem = () => (
    <div className="item">
      <Notifications className='icon' onLengthChange={handleLengthChange} />
      {number > 0 && <div className="counter">{number}</div>}
    </div>
  );

  return (
    <div className='navbar dashboard'>
      <div className="wrapper">
        <div className="items">
          {renderBirthdayItem()}
          {user.role !== "User" && (
            <div className="item">
              <BirthDays className="icon" onLengthChange={handleLengthChange1} /> 
              {number1 > 0 && <div className="counter">{number1}</div>}
            </div>
          )}
          {renderNotificationItem()}
          <Link className="item Link" to={"/message"} >
            <MessageIcon className="icon" />
          </Link>
          <div className="item">
            <img
              src={user.file && (user.file.secure_url === "" || user.file.secure_url.length === 0) ? defaultUrl : user.file.secure_url}
              alt="#"
              className='avatar'
            />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
