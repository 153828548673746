import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import instance from "../../utils/axios";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import "../../css/Register.css";

const ReadTnc = ({ closeModal }) => {
  const [allTnc, setAllTnc] = useState([]);

  const fetchTnc = async () => {
    try {
      const response = await instance.get("/api/user/getTncData");
      if (response.data.success) {
        setAllTnc(response.data.data);
      } else {
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchTnc();
  }, []);

  const renderGroupedTnc = () => {
    const groupedTnc = {};
    allTnc.forEach((product) => {
      const { title, tnc } = product;
      if (!groupedTnc[title]) {
        groupedTnc[title] = [tnc];
      } else {
        groupedTnc[title].push(tnc);
      }
    });

    return Object.entries(groupedTnc).map(([title, tncs], index) => (
      <div key={index}>
        <h3 className="faqMap">{title}</h3>
        {tncs?.map((tnc, innerIndex) => (
          <div className="faqMap-container faq-tnc" key={innerIndex}>
            <h4 className="faqmap-h3 register-tnc-h4">{innerIndex + 1}</h4>
            <h4 className="faqmap-answer">{tnc}</h4>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div>
      <div className="tnc-modal1">
        <motion.div
          className="tnc-modal-content1"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="register-tnc-container">
            <CloseIcon className="tnc-close-icon" onClick={closeModal} />
            <h4 className="tnc-title">Terms And Conditions</h4>
            {renderGroupedTnc()}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ReadTnc;
