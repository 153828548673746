import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import useUserStore from "../../../store";
import DeleteModal from "../../components/Modal/DeleteModal";
import toast from "react-hot-toast";

const DashArticles = () => {

    const [articleData, setArticleData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState("");
    const user = useUserStore((state) => state.user);

    useEffect(() => {
    instance.get("/api/user/getArticlesData")
      .then((response) => {
        if (response.data.success) {
          setArticleData(response.data.data);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/articleData2/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("Article deleted successfully");
        setArticleData((prevData) => prevData.filter((product) => product._id !== userIdToDelete));
        setShowModal(false);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred');
      }
    }
  };

  return (
    <>
      <div className="dataTable">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0 }}
          className="datatableTitle dashboard-title"
        >
          Article Page
          <Link
            to="/dashboard/editor/addArticle"
            className="table-link"
          >
            Add New
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="update-product-container"
        >
            {articleData.length === 0 || articleData.length === null ? (
                <div className="no-product">
                    <h1 className="no-product-h1">Ooopss!!!</h1>
                    <h4 className="no-product-h4">Currently There is no Article</h4>
                    <span className="no-product-span">Click on Add New and start writing your thoughts</span>
                </div>
            ) : <>
            <div className="showProduct">
                {articleData.slice().reverse().map((product, value) => (
                    <div className="show-product" key={value}>
                        <div className="show-left">
                            <img src={product.file.secure_url} alt="#" />
                        </div>
                        <div className="show-right">
                            <div className="show-name">
                                <h3 className='show-title'>{product.title}</h3>
                                <div className="show-price">Author: {product.author}</div>
                                <div className="show-price">Created At: {new Date(product.createdAt).toLocaleDateString("en-GB")}</div>
                            </div>
                            <div className="show-button">
                            {["Admin", "Regional Manager"].includes(user.role) ? <>
                                <Link to={`/dashboard/editor/updateArticle/${product._id}`} className='update-btn Link'>Update</Link>
                                <button className='delete-product-btn Link' onClick={() => handleDelete(product._id)}>Delete</button>
                                </> : "" }
                            </div>
                        </div>
                        {showModal && (
                            <DeleteModal 
                                h4={"Are you sure you want to delete this article?"} 
                                confirmDelete={confirmDelete}
                                closeModal={()=> setShowModal(false)} 
                            />
                        )}
                    </div>
                ))}
            </div>
          </>}
        </motion.div>

      </div>
    </>
  );
};

export default DashArticles;
