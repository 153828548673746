import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import instance from "../../../utils/axios";
import { motion } from "framer-motion";
import useUserStore from "../../../store";
import OpenModal from "../../components/Modal/OpenModal"
import { toast } from "react-hot-toast";
import ShowPlanModal from "../DashboardUserPage/ShowPlanModal";

const UserProspect = () => {
  const nav = useNavigate();
  const [isActive, setIsActive] = useState(1);
  const handleActive = (btn) => setIsActive(btn);
  const [onlineProspects, setOnlineProspects] = useState([]);
  const [incompleteOfflineProspects, setIncompleteOfflineProspects] = useState([]);
  const [offlineProspects, setOfflineProspects] = useState([]);
  const [incompleteOnlineProspects, setIncompleteOnlineProspects] = useState([]);
  const user = useUserStore((state) => state.user);
  const [uniqueId1, setUniqueId1] = useState("");  
  const [uniqueId2, setUniqueId2] = useState("");
  const [showModal, setShowModal] = useState(false); 
  const [excelFile, setExcelFile] = useState(null); 
  const [employee, setEmployee] = useState([]);
  const [employee1, setEmployee1] = useState("");
  const [assignedUserMap, setAssignedUserMap] = useState({});
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [mobileNumberModal, setMobileNumberModal] = useState("");
  const [planSubmitted, setPlanSubmitted] = useState(false);

  const toggleShowPlanModal = () => {
    setShowPlanModal(!showPlanModal);
  };

  const getName = async() => {
      const response = await instance.get("/api/user/getEmployeeName");
        if (response.data.success) {
          setEmployee(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      }

  const getProspect = async () => {
    const response = await instance.get("/api/user/getProspect", {
        params: {
          userId: user._id,
        }})
      if (response.data.success) {
          const onlineRows = [];
          const offlineRows = [];
          const incompleteOfflineRows = [];
          const incompleteOnlineRows = [];
          response.data.data.forEach((user) => {
            const formatDOB = (dob) => {
              if (dob && (dob.includes("T") || dob.includes("-"))) {
                return new Date(user.dob).toLocaleDateString("en-GB");
              } else {
                return dob; 
              }
            };
            const prospect = {
              id: user._id,
              col1: user.uniqueId,
              col2: user.name,
              col3: user.mobile,
              col4: user.email,
              col5: user.referral,
              col6: user.interest,
              col7: formatDOB(user.dob),
              col8: user.path,
              col9: user.status,
              col11: user.assignedTo,
            };

            if (user.train === "Offline" && user.status === "TBD") {
              offlineRows.push(prospect);
            } else if (user.train === "Online" && user.status === "TBD") {
              onlineRows.push(prospect);
            } else if (user.train === "Offline") {
              incompleteOfflineRows.push(prospect);
            } else if (user.train === "Online") {
              incompleteOnlineRows.push(prospect);
            }
          });

          setOnlineProspects(onlineRows.reverse());
          setOfflineProspects(offlineRows.reverse());
          setIncompleteOfflineProspects(incompleteOfflineRows.reverse());
          setIncompleteOnlineProspects(incompleteOnlineRows.reverse());
          const assignedUserMap = {};
            response.data.data.forEach((user) => {
              assignedUserMap[user._id] = user.assignedTo;
            });
            setAssignedUserMap(assignedUserMap);
        }
  };

  useEffect(() =>{
    getProspect();
    getName();
    // eslint-disable-next-line
  },[]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setExcelFile(null); // Reset the excel file state when closing the modal
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setExcelFile(file);
  };

  const addProspect = async () => {
    if (!excelFile) {
      return; 
    }
    try {
      const formData = new FormData();
      formData.append("file", excelFile);
      const response = await instance.post(
        "/api/user/prospect/addFromExcel",
        formData
      );
      if (response.data.success) {
        toast.success("Prospects added successfully.");
        getProspect();
        closeModal();
      } else {
        toast.error("Failed to add prospects. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleTransfer = async () =>{
    try {
      const response = await instance.put("/api/user/transferProspect",{
        uniqueId1: uniqueId1,
        uniqueId2: uniqueId2,
        employee: employee1,
        userId: user._id,
      })
      if(response.data.success){
        toast.success(response.data.message);
        for (let prospectId = uniqueId1; prospectId <= uniqueId2; prospectId++) {
          setAssignedUserMap((prevAssignedUserMap) => ({
            ...prevAssignedUserMap,
            [prospectId]: employee1,
          }));
        }
        setUniqueId1("");
        setUniqueId2("");
        setEmployee1("");
      }else {
        toast.error(response.data.message)
      }
      getProspect();
    } catch (error) {
      toast.error("Failed to transfer prospects. Please try again.");
    }
  }

const handleAssign = async (prospectId, assignedTo) => {
  try {
    const response = await instance.put(
      `/api/user/updateProspectAssignment/${prospectId}`,
      { assignedTo: assignedTo }
    );
    if (response.data.success) {
      toast.success("Prospect Assigned Successfully.");
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("An error occurred while assigning the prospect.");
  }
};

const handleConvert = async (prospectId, mobileNumber) => {
  try {
    const user = await instance.get("/api/user/userExists", {
      params: {
        mobile: mobileNumber,
      },
    })
    if(user.data.success) {
      setMobileNumberModal(mobileNumber);
      toggleShowPlanModal(); 
    }else {
      toast.error("Please Register The Customer First.")
      nav('/dashboard/members/new')
      return;
    }
    if(planSubmitted) {
      const response = await instance.put(
        `/api/user/updateProspect/${prospectId}`,
        { status: `Converted By ${user.username}` }
      );
      if (response.data.success === true) {
        // Handle prospect removal from the respective lists
        if (response.data.data.train === "Offline") {
          setOfflineProspects((prevProspects) =>
            prevProspects.filter((prospect) => prospect.id !== prospectId)
          );
        } else if (response.data.data.train === "Online") {
          setOnlineProspects((prevProspects) =>
            prevProspects.filter((prospect) => prospect.id !== prospectId)
          );
        }
        getProspect(); // Refresh the prospect data
      } else {
        toast.error(response.data.message);
      }
    }
  } catch (error) {
    toast.error(error.message);
  }
};

  const handleDecline = async (prospectId) => {
    try {
      const response = await instance.put(
        `/api/user/updateProspect/${prospectId}`,
        { status: `Declined by ${user.username}` }
      );
      if (response.data.success) {
        toast.success("Prospect Updated SuccessFully.");
        if (response.data.data.train === "Offline") {
          setOfflineProspects((prevProspects) =>
            prevProspects.filter((prospect) => prospect.id !== prospectId)
          );
        } else if (response.data.data.train === "Online") {
          setOnlineProspects((prevProspects) =>
            prevProspects.filter((prospect) => prospect.id !== prospectId)
          );
        }
        getProspect();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const columns = [
    { field: "col1", headerName: "ID", width: 70 },
    {
      field: 'col2', 
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/userProspect/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: "col3", headerName: "Mobile", width: 120 },
    { field: "col4", headerName: "Email", width: 220 },
    { field: "col5", headerName: "Referral", width: 100 },
    { field: "col6", headerName: "Interest", width: 250 },
    { field: "col7", headerName: "Date Of Birth", width: 100 },
    { field: "col8", headerName: "Health issues", width: 120 },
    { field: "col9", headerName: "Status", width: 200 },
    {
      field: "col10",
      headerName: "Conversion",
      width: 170,
      renderCell: (params) => {
        const prospectId = params.row.id;
        const mobileNumber = params.row.col3;
        return (
          <div className="cellAction">
            <button
              className="activeButton"
              onClick={() => {
                  // toggleShowPlanModal();
                  handleConvert(prospectId, mobileNumber);
                }}>
              Converted
            </button>
            <button
              className="deleteButton"
              onClick={() => handleDecline(prospectId)}
            >
              Declined
            </button>
          </div>
        );
      },
    },
    ...(["Admin", "Regional Manager", "Branch Manager"].includes(user.role) ? [
      {
        field: "col11",
        headerName: "Assign To",
        width: 180,
        renderCell: (params) => {
          const prospectId = params.row.id;
          const assignedTo = assignedUserMap[prospectId] || "";
          
          // New function to handle assignment when the dropdown value changes
          const handleAssignChange = (selectedValue) => {
            setAssignedUserMap({
              ...assignedUserMap,
              [prospectId]: selectedValue,
            });
            
            // Call the handleAssign function with the prospect ID and the selected username
            handleAssign(prospectId, selectedValue);
          };
          return assignedTo ? (
            <div>{assignedTo}</div>
            ) : (
            <select
              value={assignedTo}
              onChange={(e) => handleAssignChange(e.target.value)}
            >
              <option value="">Select Employee name</option>
              {employee &&
                employee.map((run, index) => (
                  <option key={index} value={run.username}>
                    {run.username}
                  </option>
                ))}
            </select>
          );
        },
      },
    ] : []),];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/dashboard/userProspect/${params.row.id}`}
              className="Link"
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  
  const handlePlanSubmission = async () => {
    setPlanSubmitted(true);
  };

  return (
    <>
        <div className="dataTable">
          {showPlanModal && <ShowPlanModal 
            mobileNumber={mobileNumberModal} 
            closeModal={toggleShowPlanModal} 
            onPlanSubmit={handlePlanSubmission} 
          />}
          <motion.div
            className="datatableTitle dashboard-title"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0 }}
          >
            User Prospect
            <div className="table-link" onClick={openModal}>
              Add New
            </div>
          </motion.div>
          {showModal && (
            <OpenModal 
                h4="Add New Prospect"
                handleFileChange={handleFileChange}
                closeModal={closeModal}
                addMember={addProspect}
                showModal={showModal}
            />
         )}
          <div className="tabsContainer">
            <motion.div
              className="btnContainer"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <button
                className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
                onClick={() => handleActive(1)}
              >
                New Online
              </button>
              <button
                className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
                onClick={() => handleActive(2)}
              >
                New Offline
              </button>
              <button
                className={`tabs ${isActive === 3 ? "activeTab" : ""}`}
                onClick={() => handleActive(3)}
              >
                Incomplete Online
              </button>
              <button
                className={`tabs ${isActive === 4 ? "activeTab" : ""}`}
                onClick={() => handleActive(4)}
              >
                Incomplete Offline
              </button>
            </motion.div>
            {isActive === 1 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <div className="tabContent">
                  <div className="dataGrid">
                    <DataGrid
                      rows={onlineProspects}
                      columns={columns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </div>
                  {["Admin", "Regional Manager", "Branch Manager", "Manager"].includes(user.role) ? <>                  
                  <div className="filter-container">
                    <input
                      type="text"
                      value={uniqueId1}
                      className="filter-input"
                      placeholder="Enter initial unique Id"
                      onChange={(e) => setUniqueId1(e.target.value.toUpperCase())}
                      required
                    />
                    <input
                      type="text"
                      value={uniqueId2}
                      className="filter-input"
                      placeholder="Enter terminal unique Id"
                      onChange={(e) => setUniqueId2(e.target.value.toUpperCase())}
                      required
                    />
                    <select
                      value={employee1}
                      className="filter-input"
                      onChange={(e) => setEmployee1(e.target.value)}
                      required
                    >
                      <option value="">Select Employee name</option>
                      {employee &&
                        employee.map((run, index) => (
                          <option key={index} value={run.username}>
                            {run.username}
                          </option>
                        ))}
                    </select>
                    <button className="comment-btn dash-btn" onClick={handleTransfer}>Assign</button>
                  </div>
                  </> : ""}
                </div>
              </motion.div>
            )}
            {isActive === 2 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="tabContent">
                  <div className="dataGrid">
                    <DataGrid
                      rows={offlineProspects}
                      columns={columns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </div>
                  {["Admin", "Regional Manager", "Branch Manager", "Manager"].includes(user.role) ? <>                  
                  <div className="filter-container">
                    <input
                      type="text"
                      value={uniqueId1}
                      className="filter-input"
                      placeholder="Enter initial unique Id"
                      onChange={(e) => setUniqueId1(e.target.value.toUpperCase())}
                      required
                    />
                    <input
                      type="text"
                      value={uniqueId2}
                      className="filter-input"
                      placeholder="Enter terminal unique Id"
                      onChange={(e) => setUniqueId2(e.target.value.toUpperCase())}
                      required
                    />
                    <select
                      value={employee1}
                      className="filter-input"
                      onChange={(e) => setEmployee1(e.target.value)}
                      required
                    >
                      <option value="">Select Employee name</option>
                      {employee &&
                        employee.map((run, index) => (
                          <option key={index} value={run.username}>
                            {run.username}
                          </option>
                        ))}
                    </select>
                    <button className="comment-btn dash-btn" onClick={handleTransfer}>Assign</button>
                  </div>
                  </> : ""}
                </div>
              </motion.div>
            )}
            {isActive === 3 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="tabContent">
                  <div className="dataGrid">
                    <DataGrid
                      rows={incompleteOnlineProspects}
                      columns={columns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </div>
                </div>
              </motion.div>
            )}
            {isActive === 4 && (
              <motion.div
                className="tabData"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="tabContent">
                  <div className="dataGrid">
                    <DataGrid
                      rows={incompleteOfflineProspects}
                      columns={columns.concat(actionColumn)}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        </div>

    </>
  );
};

export default UserProspect;
