import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import instance from '../../utils/axios';
import {Link, useNavigate } from 'react-router-dom';
import useUserStore from '../../store';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "../../css/Register.css"
import { motion } from 'framer-motion';

const Login = () => {

    const loggedIn = useUserStore((state) => state.loggedIn);
    const setLoggedIn = useUserStore((state) => state.setLoggedIn);
    const setUser = useUserStore((state) => state.setUser);
    const [loading, setLoading] = useState(false);
  
    const [val, setVal] = useState({
      email:"",
      password:"",
  });
    const nav = useNavigate();
  
    
    const setdata = (e) => {
      const {name, value} = e.target;
      setVal((preval) =>{
        return{
          ...preval,
          [name]:value
        }
      })
    }
  
    const [showPassword, setShowPassword] = useState(false);
    const newRegister = val.email.toLowerCase();
  
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    async function LoginUser(ev) {
      ev.preventDefault();
      setLoading(true);
      try{
          const userAgent = navigator.userAgent;
          const response = await instance.post('/api/user/signIn', {email: newRegister, password: val.password,userAgent} )
            if(response.data.success){
               setLoading(false);
              if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
                sessionStorage.setItem('token',  response.data.user.token);
              }
                const token = sessionStorage.getItem('token');
                const response1 = await instance.get('/api/user/me', {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },})
                if (response1.data.success) {
                  setLoggedIn(true);
                  setUser(response1.data.user);
                  toast.success(`Welcome To Planet Muscle`)
                  nav("/");
                }else{
                  setLoading(false);
                  toast.error(response1.data.message);
                }
            }else {
              setLoading(false);
              toast.error(response.data.message);
            }
          } catch (error) {
            setLoading(false);
            toast.error(error.message);
          }  
    }
  
    useEffect(() => {
      if(loggedIn) {
        nav('/');
      };
    }, [loggedIn, nav])
  

  return (
    <div>
        <motion.div 
          className="login"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
            <div className="login-container">
                <div className="login-photo">
                    <img src="https://images.pexels.com/photos/4761779/pexels-photo-4761779.jpeg?auto=compress&cs=tinysrgb&w=600" alt=""  loading="lazy"/>
                </div>
                <div className="login-container-1">
                <h3 className="register-title">Welcome To Planet Muscle</h3>
                <h4 className="register-title">Login</h4>
                <form onSubmit={LoginUser} className="register-form">
                    <div className="text-field-container">
                        <div className="text-field">
                        <span className='register-span'>Email</span>
                        <input type="email" placeholder="Enter Registered Email Id" required="required"  className="register-input" name="email" value={val.email} onChange={setdata} />
                        </div>
                        <div className="text-field">
                        <span className='register-span'>Password</span>
                        <input required="required" placeholder="Enter Password" className="register-input" name="password" value={val.password} onChange={setdata} type={showPassword ? 'text' : 'password'}/>
                        <div                
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            aria-label="toggle password visibility"
                            className="show-icon"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </div>
                        </div>
                    </div>
                    <button className={`submit-btn send-btn ${loading ? 'upload-btn' : ''}`}>
                        {loading ? <AutorenewIcon className="loader-css" /> : "Login"}
                    </button>
                </form>
                <div className="main_pass">
                    <Link className="pass" to={'/forgotpassword'}>Forgot Password ?</Link>
                </div>
                <div className="sign_up">Not a member ? 
                        <Link to={"/register"} className="link" >Register</Link>
                </div>
                </div>
            </div>
      </motion.div>

    </div>
  )
}

export default Login