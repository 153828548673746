import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Success.css'
import useUserStore from "../../store";
import Layout from "../../pages/MainLayout/Layout"

const Thanks = () => {
    const clearCart = useUserStore((state) => state.clearCart);
    useEffect(() => {
        clearCart();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Layout>
                <div className="thanks-container">
                    <div className="thanks-wrapper">
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /> <path classma="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </div>
                    <h4 className="thanks-h4">Thank You For Using Planet Muscle</h4>
                    <h5 className="thanks-h5">To visit your Order, <Link to={'/dashboard/profile'} className='Link' ><span>Click here</span></Link> </h5>
                </div>
            </Layout>
        </>
    )
}

export default Thanks