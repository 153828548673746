import React, { useState, useEffect, useRef } from "react";
import "../../css/Register.css";
import { toast } from "react-hot-toast";
import instance from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const OtpVerification = ({ id }) => {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const inputRefs = useRef([]);
  const nav = useNavigate();

  const handleChange = (index, event) => {
    const { value } = event.target;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const otpText = otpValues.join("");
    try {
      const response = await instance.post("/api/user/verifyEmail", {
        userId: id,
        otp: otpText,
      });
      if (response.data.success) {
        toast.success("Email Verified Successfully");
        nav("/login");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Get OTP verification error: ", error.message);
      toast.error(error.message);
    }
  };

  const handleResendOTP = async () => {
    try{
      const response = await instance.post("/api/user/resendOTP", {
        userId: id,
      })
      if(response.data.success){
        toast.success(response.data.message);
        setResendEnabled(false);
      }else {
        toast.error(response.data.message)
      }
    }catch(error) {
      console.log(error);
      toast.error("An Error Occurred. Please Again Later")
    }
  };

  useEffect(() => {
    if (timeRemaining > 0) {
      const countdown = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      
      return () => clearInterval(countdown);
    } else if (timeRemaining <= 0) {
      setResendEnabled(true);
      setTimeRemaining(0);
    }
  }, [timeRemaining]);

  useEffect(() => {
    const cleanupFunction = async () => {
      try {
        // Check if OTP verification was successful
        const response = await instance.get(`/api/user/checkVerification/${id}`);
        if (!response.data.success) {
          // OTP verification was not successful, delete the user
          const deleteResponse = await instance.delete(`/api/user/deleteUser/${id}`);
          if (deleteResponse.data.success) {
            console.log("User deleted successfully");
          } else {
            console.log("Failed to delete user:", deleteResponse.data.message);
          }
        }
      } catch (error) {
        console.error("Error checking OTP verification:", error.message);
      }
    };
  
    window.addEventListener("beforeunload", cleanupFunction);
  
    return () => {
      window.removeEventListener("beforeunload", cleanupFunction);
      cleanupFunction();
    };
    //eslint-disable-next-line
  }, []);
  
  return (
    <motion.div
      className="register"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="otp-container" id="otp-container">
        <h1 className="register-title">OTP Verification</h1>
        <form id="otp" onSubmit={handleSubmit}>
          <div>
            {otpValues.map((value, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="otp-input"
                value={value}
                onChange={(e) => handleChange(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <button type="submit" className="submit-btn send-btn">
            Verify Otp
          </button>
          <div className="sign_up">
            {resendEnabled ? (
              <div className="link" onClick={handleResendOTP}>
                Resend Otp
              </div>
            ) : (
              <div className="disabled-link">
                Resend Otp in {Math.floor(timeRemaining / 60)}:
                {timeRemaining % 60 < 10
                  ? `0${timeRemaining % 60}`
                  : timeRemaining % 60}{" "}
              </div>
            )}
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default OtpVerification;
