import React from 'react'
import Hero from '../../components/MainHero/Hero'
import Details from '../../components/MainDetails/Details'
import ImageSlider from '../../components/MainImageSlider/ImageSlider'
import BmiPage from '../../components/MainBmiPage/BmiPage'
import Plans from '../../components/MainPlans/Plans'
import CustomerReview from '../../components/MainCustomerReview/CustomerReview'
import Layout from '../MainLayout/Layout'

const IndexPage = () => {
  return (
    <div>
        <Layout>
          <Hero />
          <Details />
          <BmiPage/>
          <ImageSlider/>
          <Plans/>
          <CustomerReview />
        </Layout>
    </div>
  )
}

export default IndexPage

// import React, { lazy, Suspense } from 'react';
// import Layout from '../MainLayout/Layout';
// import Loader from '../../components/MainLoader/Loader';

// const Hero = lazy(() => import('../../components/MainHero/Hero'));
// const Details = lazy(() => import('../../components/MainDetails/Details'));
// const BmiPage = lazy(() => import('../../components/MainBmiPage/BmiPage'));
// const ImageSlider = lazy(() => import('../../components/MainImageSlider/ImageSlider'));
// const Plans = lazy(() => import('../../components/MainPlans/Plans'));
// const CustomerReview = lazy(() => import('../../components/MainCustomerReview/CustomerReview'));

// const IndexPage = () => {
//   return (
//     <div>
//       <Layout>
//         <Suspense fallback={<Loader/>}>
//           <Hero />
//           <Details />
//           <BmiPage />
//           <ImageSlider />
//           <Plans />
//           <CustomerReview />
//         </Suspense>
//       </Layout>
//     </div>
//   );
// };

// export default IndexPage;
