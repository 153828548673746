export const content = [
    {
      title: "Sahil s",
      desc: "Really satisfied with the service provided by the gym and it's trainer especially haseen sir who has been working really hard along with me to achieve my goals. The equipments are upto date and the ambience is amazing too",
      img: "https://lh5.googleusercontent.com/p/AF1QipOql87xack-jJKA2Ft-4bUJzyUkedKqcecHqPZ0=w600-h988-p-k-no"
    },
    {
      title: "Srijan Lath",
      desc: "Nice ambiance, trainers are helpful n friendly, Machines n other gym equipments are well maintained. Overall awesome workout experience...",
      img: "https://lh5.googleusercontent.com/p/AF1QipNT3hZVBkl1LrltAsJke99HMcATxeHdxIaKbHO-=w600-h988-p-k-no"
    },
    {
      title: "Prashant Kargeti",
      desc: "Best gym in versova, spacious and helpful trainers. Haseem is very patient and guides very well.",
      img: "https://lh5.googleusercontent.com/p/AF1QipODCledDI7eqylR7Y6nnmT829koCj-91NJubWli=w600-h988-p-k-no"
    }
  ];
