import React from "react";
import "../../css/Plans.css";
import { plansData } from "../../data/Plans";
import whiteTicks from "../../assets/whiteTick.webp";

import { Link } from "react-router-dom";

const Plans = () => {
  return (
    <div>
      <div className="plans">
        <div className="plans-header">
          <h5 className="plans-h5">PRICING TABLE</h5>
          <h2 className="plans-h2">Top Membership Plans</h2>
        </div>

        <div className="plans-container">
          {plansData.map((plan) => (
            <div className="plans-table">
              {plan.icon}
              <span className="plans-span-name">{plan.name}</span>
              <span className="plans-span-price">{plan.price}</span>

              <div className="plans-features">
                {plan.features.map((feature, i) => (
                  <div className="plans-feature" key={i}>
                    <img src={whiteTicks} alt="ticks" />
                    <span className="plans-feature-span">{feature}</span>
                  </div>
                ))}
              </div>

              <Link className="Link" to={"/"}>
                <button className="btn plans-btn">Know More</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Plans;
