import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import instance from "../../utils/axios";
import { motion } from "framer-motion";
import Layout from "../MainLayout/Layout";
import "../../css/Articles.css"
import Loader from "../../components/MainLoader/Loader";

const SingleArticles = () => {

    const [article, setArticle] = useState(null);
    const { articleId } = useParams();
  
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await instance.get(`/api/user/getArticlesData/${articleId}`);
        if (response.data.success) {
          setArticle(response.data.data);
        } else {
        }
      } catch (error) {
      }
    };
    fetchArticle();
  }, [articleId]);

  if (!article) {
    return <Loader />
  }

  return (
    <>
      <Layout>
        <motion.div className="singleArticle-container"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x:0 }}
          transition={{ duration: 0.5 }}
        >
            <div className="singleArticle-title">{article.title}</div>
            <div className="singleArticle-photo">
                <div className="singlephoto-left">
                    <img src={article.file.secure_url} alt="#" className="single-article-image" id="articleImage" loading="lazy" />
                </div>
                <div className="singlephoto-right">
                    <img src={article.file1.secure_url} alt="#" className="single-article-image-1" id="articleImage" loading="lazy"/>
                    <h3 className="article-date">Author Name: {article.author}</h3>
                    <h3 className="article-date">Date Published: {new Date(article.createdAt).toLocaleDateString("en-GB")} {new Date(article.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h3>
                </div>
            </div>
            <div className="singleArticle-article">
                {article.article}
            </div>
        </motion.div>
      </Layout >
    </>
  );
};

export default SingleArticles;