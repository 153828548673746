import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import "../../css/DashAdmin.css"
import instance from "../../../utils/axios";
import useUserStore from '../../../store';
import DeleteIcon from "@mui/icons-material/Delete"
import DeleteModal from "../../components/Modal/DeleteModal";

function CouponCode() {

  const [showModal, setShowModal] = useState(false);
  const [length, setLength] = useState(0);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const user = useUserStore((state) => state.user)
  const [coupon, setCoupon] = useState({
    name: "",
    percentage: "",
    length: "",
    capLength: ""
  });
  const [allCoupon, setAllCoupon] = useState([]);

  const setData = (e) => {
    const {name, value} = e.target;
    setCoupon((preval) => {
        return {
            ...preval, 
            [name]: value
        }
    })
}

const handleDelete = async (id) => {
  setUserIdToDelete(id);
  setShowModal(true);
};

const confirmDelete = async () => {
  try {
    const response = await instance.post(`/api/user/deleteCoupon/${userIdToDelete}`);
    if (response.data.success) {
      toast.success("Coupon Deleted SuccessFully");
      setAllCoupon((prevData) => prevData.filter((Plan) => Plan._id !== userIdToDelete));
      setShowModal(false);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

const fetchCoupon = async () =>{
    try{
      const response = await instance.get("/api/user/getAllCoupon")
      if(response.data.success){
        setAllCoupon(response.data.data)
        setLength(response.data.data.length);
      }else{
      }
    }catch(error) {
      toast.error(error.message);
    }
}

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!coupon.name || !coupon.percentage || !coupon.length || !coupon.capLength) {
        toast.error("Please fill down all the parameters");
        return;
  }

  try{
   const response = await instance.post('/api/user/createCoupon',{
          name: coupon.name,
          percentage: coupon.percentage,
          length: coupon.length,
          capLength: coupon.capLength,
      });
      if(response.data.success){
        toast.success("Coupon Created Successfully");
        e.target.reset();
        setCoupon({ name: '', percentage: '', length: '', capLength: "" });
        fetchCoupon();
      }else {
        toast.error(response.data.message);
      }
  } catch (error) {
      toast.error(error.message);
  } 
}

useEffect(() =>{
  fetchCoupon();
},[]);

  return (
    <div className="couponCode">
      <div className="generator">
        <form className="code-form" onSubmit={handleSubmit}>
          <h1 className="dashboard-title" >Code Generator</h1>
          <input
            type="text"
            className="code-input"
            min="5"
            maxLength={8}
            name= "name"
            placeholder="Enter Coupon Name"
            value={coupon.name}
            onChange={setData}
          />
          <input
            type="number"
            className="code-input"
            min="0"
            placeholder="Enter Discount Percentage Rate"
            name="percentage"
            value={coupon.percentage}
            onChange={setData}
          />
          <input
            type="number"
            min="1"
            name="length"
            className="code-input"
            placeholder="How many codes you want?"
            value={coupon.length}
            onChange={setData}
          />
          <input
            type="number"
            min="1"
            name="capLength"
            className="code-input"
            placeholder="Max Discount Amount To avail"
            value={coupon.capLength}
            onChange={setData}
          />
          <button className="dash-btn" style={{marginTop: "1rem"}} type="submit">Generate</button>
        </form>
      </div>      
      <div className="show-product-container second-coupon">
          <h3 className="dashboard-title">All Generated Coupons ({length}) </h3>
          {allCoupon && allCoupon.slice().reverse().map((product, value) => (
            <div className="coupon-product-container" key={value}>
                  <div className="coupon-main">
                      <div className="coupon-main-1">
                          <h4 className='show-coupon-title'>Coupon Name: {product.name}</h4>
                          <h4 className='show-coupon-title'>Discount Limit:  {product.discountLimit}</h4>
                          <h4 className='show-coupon-title'>Number Of Coupons: {product.numberOfCoupon}</h4>
                          <h4 className='show-coupon-title'>Percentage of Discount: {product.percentage}</h4>
                      </div>
                      <div className="show-coupon-button">
                      {["Admin", "Regional Manager"].includes(user.role) ? <button className='delete-coupon-btn' onClick={() => handleDelete(product._id)}><DeleteIcon /></button> : ""}                        
                    </div>
                  </div>
                  {showModal && 
                    <DeleteModal 
                        h4={"Are you sure you want to delete this plan?"}
                        confirmDelete={confirmDelete}
                        closeModal={() => setShowModal(false)}
                    />}
              </div>
            ))}
      </div>
    </div>
  );
}

export default CouponCode;
