import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import {motion} from "framer-motion"
import useUserStore from '../../../store';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const Accounts1 = ( ) => {

  const [userData, setUserData] = useState([]);
  const user = useUserStore((state) => state.user);
  useEffect(() => {
    instance.get('/api/user/getTotalCallData',{
      params: {
        userId: user._id,
      }
    }).then((response) => {
      if (response.data.success) {
        let filteredData = response.data.data;      
        console.log(filteredData);
        const rows = filteredData && filteredData.slice().reverse().map((user) => {
            const startTime = moment(user.batch.batchStartTime, 'HH:mm:ss').format('h:mm A');
            const endTime = moment(user.batch.batchEndTime, 'HH:mm:ss').format('h:mm A');
            const shiftInfo = `${user.batch.batchShift}: ${startTime} - ${endTime}`;

          return {
            id: user._id,
            col1: user.file && user.file.secure_url,
            col2: user.name,
            col3: user.role,
            col4: user.gymLocation || 'N/A',
            col5: user.batch?.batchShift  ? shiftInfo : "N/A",
            col6: user.convertedCount,
            col7: user.convertedCount,
            col8: user.convertedCount,
            col9: user.convertedCount,
            col10: user.totalAssigned,
            col11: user.totalCalls,
            col12: user.convertedCount,
            col13: user.convertedCount,
            col14: user.convertedCount,
            col15: user.convertedCount,
          }
        });        
        setUserData(rows);
      }
    }).catch((error) => {
        toast.error(error.message);
    });
  }, [user._id, user.role]);
  
  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 70,
      renderCell: (params) => (
        <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Counsellor Name', width: 150 },
    { field: 'col3', headerName: 'Position', width: 150 },
    { field: 'col4', headerName: 'Branch', width: 100 },
    { field: 'col5', headerName: 'Batch Timing', width: 250 },
    { field: 'col6', headerName: 'Total PT Assigned', width: 150 },
    { field: 'col7', headerName: 'PT completed', width: 150 },
    { field: 'col8', headerName: 'PT assigned (last 30 days)', width: 150 },
    { field: 'col9', headerName: 'PT completed (last 30 days)', width: 150 },
    { field: 'col10', headerName: 'Calls Assigned', width: 150 },
    { field: 'col11', headerName: 'Calls Initiated', width: 150 },
    { field: 'col12', headerName: 'Calls Converted', width: 150 },
    {
      field: 'col13',
      headerName: 'Conversion',
      width: 100,
      renderCell: (params) => {
          const totalCalls = params.row.col11; // Total Calls
          const callsConverted = params.row.col12; // Calls Converted
          const conversionRate = totalCalls !== 0 ? (callsConverted / totalCalls) * 100 : 0;
          const formattedConversionRate = conversionRate.toFixed(2); // Format conversion rate to two decimal places
          return (
              <div>{formattedConversionRate}%</div>
          );
      },
  },
    { field: 'col14', headerName: 'Total Calls (last 30 days)', width: 150 },
    { field: 'col15', headerName: 'Calls Converted (last 30 days)', width: 150 },
    { field: 'col16', headerName: 'Call Conversion (last 30 days)', width: 150 },
  ];



  return (
    <div className='data2Table '>
    <motion.div className='dataGrid'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
    >     
      <DataGrid 
          rows={userData}   
          columns={columns}         
          pageSize={9}
          rowsPerPageOptions={[9]}
      />
    </motion.div>
  </div>
)
}

export default Accounts1;
