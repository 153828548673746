import React, { useEffect, useState } from 'react'
import instance from "../../../utils/axios"
import useUserStore from '../../../store';
import {toast} from "react-hot-toast"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import "../../css/Notifications.css"


const Notifications = ({onLengthChange}) => {

    const [showDropDown, setShowDropDown] = useState(false)
    const user = useUserStore((state) => state.user);
    // const [notification, setNotification] = useState([]);
    const notifications = useUserStore(state => state.notications);
    const setNotifications = useUserStore(state => state.setNotifications);

    useEffect(() => {
        const handleNotification = async () => {
            try {
                const response = await instance.get("/api/user/getNotification", {
                    params: {
                        userId: user._id,
                    },
                })
                if (response.data.success) {
                    // setNotification(response.data.notifications);
                    setNotifications(response.data.notifications);
                    const length = response.data.notifications.length;
                    onLengthChange(length);
                } else {
                }
            } catch (error) {
            }
        };
        handleNotification();
        // eslint-disable-next-line
    }, [user._id]);


    const handleSeen = async (notificationId) => {
        try {
            const response = await instance.post("/api/user/removeNotification", {
                data: {
                    userId: user._id,
                    notificationId: notificationId,
                },
            });
            if (response.data.success) {
                const updatedNotifications = notifications.filter(
                    (item) => item._id !== notificationId
                );
                toast.success("Notification Deleted!");
                setNotifications(updatedNotifications);
                const length = updatedNotifications.length;
                onLengthChange(length);
            } else {
            }
        } catch (error) {
            toast.error("An error occurred! Please try again later");
            if (user.role === "Developer") {
                console.error(error);
            }        
        }
    };

  return (
    <div>
        <div className="notifications">
            <div className="notifyIcon" onClick={() => setShowDropDown(!showDropDown)}>
                <NotificationsNoneOutlinedIcon className="icon" />
            </div>
            <div className="notificationBox" style={{
                opacity: !showDropDown ? "0" : "1",
                transition: "0.3s ease",
                visibility: !showDropDown ? "hidden" : "visible",
                transform: !showDropDown ? "scale(0.9)" : "scale(1)",
                transformOrigin: "top right"}}
            >
                <div className="notificationsHeader">
                    <h4 className="notification-h4">
                        Notifications ({notifications? notifications.length : "0"})
                    </h4>
                </div>
                {notifications && notifications?.slice().reverse().map((product) => (
                    <div className="notification-user" key={product._id} >
                        <div className="notification-photoUrl">
                            <img src={product.url || "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000"} alt="" />
                        </div>
                        <div className="notifications-details">
                            {product.location === "Task" && (
                                <div className="notifyMessage">
                                    <span className='textBold'>{product.name} </span>
                                    assigned you a task <span className='textBold'>" {product.message} " </span>
                                </div>
                            )}
                            {product.location === "Calendar" && (
                                <div className="notifyMessage">
                                    <span className='textBold'>{product.name}</span> mentioned you in the calendar
                                </div>
                            )}
                            <div className='notifyTime'>{new Date(product.timestamp).toLocaleDateString('en-GB')} {new Date(product.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            <div className="buttons">
                                <button className='cta-btn' onClick={() => handleSeen(product._id)}>Seen</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Notifications