import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import {toast} from "react-hot-toast";
import OrderReceipt from "../../components/orderReciept/order-reciept";
import {PDFDownloadLink} from "@react-pdf/renderer"

const AllOrder = () => {
  const [userData, setUserData] = useState([]);

  const getData = async () => {
    try {
      const response = await instance.get("/api/user/getAllOrders"); 
      if (response.data.success) {
              const rows = response.data.data && response.data.data.slice().reverse().map((order, index) => {
                  const reversedIndex = response.data.data.length - index;
                  const createdAt = new Date(order.createdAt);
                  const createdAtDate = createdAt.toLocaleDateString("en-GB");
                  const createdAtTime = createdAt.toLocaleTimeString();
                  const createdAtFormatted = `${createdAtDate} ${createdAtTime}`;

                  const productNames = order.cartItems?.length === 1
                  ? order.cartItems[0].name
                  : order.cartItems[0].name + ` + ${order.cartItems.length - 1} others...`;

                  const shippingAddress = `${order.shippingAddress.line1}, ${order.shippingAddress.line2}, ${order.shippingAddress.city}, ${order.shippingAddress.state} - ${order.shippingAddress.pincode}, ${order.shippingAddress.country}`;

                  const formatter = new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                  });
                  const formattedAmount = formatter.format(order.totalAmount);
      
                  return {
                    id: index + 1,
                    col1: reversedIndex,
                    col2: order.orderId,
                    col3: productNames,
                    col4: order.user[0].name,
                    col5: order.user[0].email,
                    col6: order.user[0].mobile,
                    col7: createdAtFormatted,
                    col8: formattedAmount,
                    col9: order.paymentMode,
                    col10: order.discountCode ? "Yes" : "No",
                    col11: order.discountCode || "N/A",
                    col12: shippingAddress,
                    col13: order.status,
                    orderDetails: order, 
                    userData: order.user[0]
                  };
                });
            setUserData(rows);
      }
    } catch (error) {
      console.log("get all orders error: ", error);
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { field: "col1", headerName: "Index", width: 70 },
    { field: "col2", headerName: "Order Id", width: 170 },
    { field: "col3", headerName: "Product Name", width: 270 },
    { field: "col4", headerName: "Customer Name", width: 170 },
    { field: "col5", headerName: "Email", width: 200 },
    { field: "col6", headerName: "Mobile", width: 100 },
    { field: "col7", headerName: "Date", width: 175 },
    { field: "col8", headerName: "Amount", width: 120 },
    { field: "col9", headerName: "Payment Method", width: 120 },
    { field: "col10", headerName: "Discount Applied", width: 100 },
    { field: "col11", headerName: "Discount Code", width: 100 },
    { field: "col12", headerName: "Shipping Address", width: 300 },
    { field: "col13", headerName: "Status", width: 120 },
    {
      field: 'col14',
      headerName: 'Bill',
      width: 120,
      renderCell: (params) => (
        <PDFDownloadLink
          document={<OrderReceipt planDetails={params.row.orderDetails} userData={params.row.userData} />} 
          fileName={`Order_Bill_${params.row.userData.name}`}
        >
          <button className='viewButton'>Download Bill</button>
        </PDFDownloadLink>
      ),
    },
  ];

  const data = {
    rows: userData,
    columns: columns,
  };


  return (
    <DataGrid
      {...data}
      initialState={{
        ...data.initialState,
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50, 100]}    
    />
  );
};

export default AllOrder;
