import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../css/DashAnalysis.css";
import instance from "../../../utils/axios";
import useUserStore from "../../../store";
import { defaultUrl } from "../../../data/Images";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion'
import toast from "react-hot-toast";

const Membership = ({ isOnline }) => {

  const user = useUserStore((state) => state.user);  
  const [onlineMembers, setOnlineMembers] = useState([]);
  const [offlineMembers, setOfflineMembers] = useState([]);
  const [collectionData, setCollectionData] = useState([]);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  const columns1 = [
    { field: 'col1', headerName: 'Index', width: '70' },
    {
      field: 'col2',
      headerName: 'Image',
      width: 90,
      renderCell: (params) => (
        <img
          src={params.value || defaultUrl}
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          alt=""
        />
      ),
    },
    { field: 'col3', headerName: 'Unique ID', width: 100 },
    {
      field: 'col4', 
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.userId}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col5', headerName: 'Email ID', width: 220 },
    { field: 'col6', headerName: 'Mobile', width: 150 },
    { field: 'col7', headerName: 'Performance Center', width: 150 },
    { field: 'col8', headerName: 'Plan Type', width: 200 },
    { field: 'col9', headerName: 'Plan Time', width: 150 },
    { field: 'col10', headerName: 'Price', width: 150 },
    { field: 'col11', headerName: 'Purchase Date', width: 150 },
    { field: 'col12', headerName: 'Date of Commencement', width: 150 },
    { field: 'col13', headerName: 'Last Date', width: 150 },
    { field: 'col14', headerName: 'Days Left', width: 150 },
    { field: 'col15', headerName: 'Status', width: 150 },
];

const getCollection = async () => {
  try {
    const response = await instance.get('/api/user/getMembershipData', {
      params: {
        userRole: user.role,
        gymLocation: user.gymLocation,
      },
    });
    if (response.data.success) {
      const data = response.data.data;
      setOnlineMembers(data.onlineMembers);
      setOfflineMembers(data.offlineMembers);
    }
  } catch (error) {
    if (["Admin", "Developer"].includes(user.role)) {
      console.log(error.message);
    }
    toast.error("An Error Occurred. Please Try Again!");
  }
};

useEffect(() => {
  getCollection();
  // eslint-disable-next-line
}, [])

useEffect(() => {
  const filteredMembers = isOnline === "Online" ? onlineMembers : offlineMembers;

  const rows = filteredMembers.map((plan, index) => {
    const reversedIndex = filteredMembers.length - index;

    return {
      id: index,
      userId: plan._id,
      col1: reversedIndex,
      col2: plan.file.secure_url || defaultUrl,
      col3: plan.uniqueId,
      col4: plan.name,
      col5: plan.email,
      col6: plan.mobile,
      col7: plan.gymLocation,
      col8: plan.planType,
      col9: plan.planTime,
      col10: formatAmount(plan.price),
      col11: new Date(plan.purchaseDate).toLocaleDateString("en-GB"),
      col12: new Date(plan.startDate).toLocaleDateString("en-GB"),
      col13: new Date(plan.endDate).toLocaleDateString("en-GB"),
      col14: plan.daysLeft,
      col15: plan.status,
    };
  });
  setCollectionData(rows);
}, [onlineMembers, offlineMembers, isOnline]);

  


  return (
      <div className='data2Table '>
        <motion.div className='dataGrid'
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
        >     
      <DataGrid
            rows={collectionData}
            columns={columns1}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
          />
        </motion.div>
    </div>
  );
};

export default Membership;
