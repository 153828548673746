import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

import instance from "../../utils/axios";
import useUserStore from "../../store";
import "../../css/Register.css"
import ReadTnc from "./ReadTnc";
import OtpVerification from "./OtpVerification";

const Register = () => {
  const loggedIn = useUserStore((state) => state.loggedIn);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [id, setId] = useState(null);
  const currencies = [
    {value: "User",label: "User",},
    {value: "Staff",label: "Administration",}];

  const [val, setVal] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    role: "User",
    mobile: "",
    dob: "",
  });

  const nav = useNavigate();

  const setData = (e) => {
    const { name, value } = e.target;
    setVal((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const newRegister = val.email.toLowerCase();

  const handleUsernameFocusBlur = (event) => {
    const { value } = event.target;
    if (value.includes(" ")) {
      toast.error("Username must not contain spaces");
    }
  };

  useEffect(() => {
    if (loggedIn) {
      nav("/");
    }
  }, [loggedIn, nav]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const RegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (val.password.length < 8) {
      toast.error("Password should have at least 8 characters");
      return;
    }
    if (!/[A-Z]/.test(val.password)) {
      toast.error("Password should have at least one uppercase letter");
      return;
    }
    if (!/\d/.test(val.password)) {
      toast.error("Password should have at least one number");
      return;
    }
    if (val.password.includes(" ")) {
      toast.error("Password should not contain spaces");
      return;
    }
    if (!/^\d*$/.test(val.mobile)) {
      toast.error("Mobile number should only contain digits");
      return;
    }
    if (val.dob) {
      const dobParts = val.dob.split("-");
      const dobYear = parseInt(dobParts[0], 10);
      const dobMonth = parseInt(dobParts[1], 10);
      const dobDay = parseInt(dobParts[2], 10);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are 0-based
      const currentDay = currentDate.getDate();

      const minYear = currentYear - 13;
      if (dobYear > minYear) {
        toast.error("Minimum age to join is 13 years.");
        return;
      }

      if (dobYear === minYear && dobMonth > currentMonth) {
        toast.error("Minimum age to join is 13 years.");
        return;
      }

      if (
        dobYear === minYear &&
        dobMonth === currentMonth &&
        dobDay > currentDay
      ) {
        toast.error("Minimum age to join is 13 years.");
        return;
      }
    }
    try {
      const response = await instance.post("/api/user/create", {
        name: val.name,
        email: newRegister,
        password: val.password,
        role: val.role,
        username: val.username,
        mobile: val.mobile,
        dob: val.dob,
      });
      if (response.data.success) {
        setLoading(false);
        toast.success(response.data.message);
        if ( response.data.data.role !== "User") { 
          nav("/login");
        }
        setId(response.data.data);
        setShowModal1(true);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An Error Occurred. Please Try Again!!");
    }
  };

  return (
    <div>
      {showModal1 && <OtpVerification id={id} />}
      <motion.div
        className="register"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="register-container">
          <h5 className="register-title">Register To Planet Muscle</h5>

          <form onSubmit={RegisterUser} className="register-form">
            <div className="text-field-container">
              <div className="text-field">
                <span className="register-span">Name</span>
                <input
                  type="text"
                  required
                  name="name"
                  className="register-input"
                  placeholder="Enter Your Name"
                  value={val.name}
                  onChange={setData}
                />
              </div>
              <div className="text-field">
                <span className="register-span">Username</span>
                <input
                  type="text"
                  required
                  name="username"
                  className="register-input"
                  placeholder="Enter Your Username"
                  value={val.username}
                  onChange={setData}
                  maxLength={9}
                  onFocus={handleUsernameFocusBlur}
                  onBlur={handleUsernameFocusBlur}
                />
              </div>
              <div className="text-field">
                <span className="register-span">Date Of Birth</span>
                <input
                  type="date"
                  required
                  name="dob"
                  className="register-input"
                  value={val.dob}
                  onChange={setData}
                />
              </div>
              <div className="text-field">
                <span className="register-span">Email</span>
                <input
                  type="email"
                  required
                  name="email"
                  className="register-input"
                  placeholder="Enter Your Email"
                  value={val.email}
                  onChange={setData}
                />
              </div>
              <div className="text-field">
                <span className="register-span">Mobile</span>
                <input
                  type="text"
                  required
                  name="mobile"
                  maxLength={10}
                  className="register-input"
                  placeholder="Enter mobile number"
                  value={val.mobile}
                  onChange={setData}
                />
              </div>
              <div className="text-field">
                <span className="register-span">Password</span>
                <input
                  type={showPassword ? "text" : "password"}
                  required
                  name="password"
                  className="register-input"
                  placeholder="Enter Your Password"
                  value={val.password}
                  onChange={setData}
                />
                <div
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                  className="show-icon"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </div>
              </div>
              <div className="text-field">
                <span>Select Role </span>
                <select
                  required="required"
                  label="Role"
                  className="register-input"
                  name="role"
                  value={val.role}
                  onChange={setData}
                >
                  {currencies.map((option) => (
                    <option
                      key={option.value}
                      className="input-option"
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="registered-input-tnc">
              <input type="checkbox" required className="required-checkbox" />
              <div className="register-tnc">
                By Clicking Register, you have read and agree to our
                <span className="link" onClick={() => setShowModal(true)}>
                  {" "}
                  Terms & Conditions.{" "}
                </span>{" "}
                of Planet Muscle. You may receive SMS notifications from us and
                can opt out any time. (Click on Terms and Conditions to read
                them).
              </div>
            </div>
            <button
              className={`submit-btn send-btn ${loading ? "upload-btn" : ""}`}
            >
              {loading ? <AutorenewIcon className="loader-css" /> : "Register"}
            </button>
          </form>
          <div className="sign_up">Already a member ?
            <Link to={"/login"} className="link" >Sign In</Link>
          </div>

        </div>
        {showModal && (<ReadTnc closeModal={() => setShowModal(false)} />)}
      </motion.div>
    </div>
  );
};

export default Register;
