import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import instance from "../../../utils/axios";
import useUserStore from "../../../store";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import { gymName } from "../../../data/Gym";

const ShowPlanModal = ({planId,userId, closeModal,location,mobileNumber,onPlanSubmit}) => {
  const [discounted, setDiscounted] = useState(0);
  const [planDetails, setPlanDetails] = useState({
    planType: "",
    planTime: "",
    gymLocation: "",
    batchTiming: "",
    startDate: "",
    endDate: "",
    price: "",
    totalPlanPrice: "",
    gst: "",
    gstAmount: 0,
    trainerAssigned: "",
    paymentMode: "",
    planmode: "",
    promo: "",
    discountCode: "",
    purchaseDate: "",
    eventName: "",
    eventTime: "",
    totalEventPrice: "",
    eventPlace: "",
    tickets: 1,
    productName: "",
    productBrand: "",
    products: 1,
    totalProductPrice: "",
    productSize: "",
  });
  const [service, setService] = useState("");
  const [editPlans, setEditPlans] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useUserStore((state) => state.user);
  const [trainer, setTrainer] = useState("");
  const [planData, setPlanData] = useState([]);
  const nav = useNavigate();
  const [cardDetails, setCardDetails] = useState({
    cardType: "",
    last4Digits: "",
    bankName: "",
  });
  const [upiDetails, setUpiDetails] = useState({
    upiId: "",
  });
  const [chequeDetails, setChequeDetails] = useState({
    chequeNumber: "",
    chequeDate: "",
    bankName: "",
    branchName: "",
  });

  const getTrainerIds = async () => {
    try {
      const response = await instance.get("/api/user/getTrainerUniqueId");
      if (response.data.success) {
        setTrainer(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  };

  const getPlanDetails = async () => {
    try {
      const response = await instance.get(`/api/user/getIndividualPlansDetails/${planId}`, {
          params: {
            userId,
            service: service,
          }});
      if (response.data.success) {
        console.log(response.data.data);
        setEditPlans(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again Later!");
    }
  };

  const getPlanPrice = async () => {
    try {
      const response = await instance.get("/api/user/getPlanPrice", {
        params: {
          service: service,
        },});
      if (response.data.success) {
        if(response.data.data.length > 0){
          setPlanData(response.data.data);
        }else {
          setPlanData([]);
        }
      } else {
        setPlanData([]);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again Later!");
    }
  };

  useEffect(() => {
    if (service !== "") {
      getTrainerIds();
      getPlanPrice();
    }
    if (planId) {
      getPlanDetails();
    }
    // eslint-disable-next-line
  }, [planId, service]);

  useEffect(() => {
    if (planDetails.planTime !== "") {
      const updatedPlanDetails = { ...planDetails };
      const updateEditPlanDetails = { ...editPlans };
      const { planTime } = updatedPlanDetails;
      const { planType, startDate } = updateEditPlanDetails;
      if (planType && startDate) {
        let endDate = new Date(startDate);
        const parsedPlanTime = parseInt(planTime); // Parse planTime to an integer
        if (!isNaN(parsedPlanTime)) {
          if (
            planType === "Peak Performance Plan" ||
            planType.includes("Personal Training")
          ) {
            endDate.setDate(endDate.getDate() + parsedPlanTime * 30 + 10);
          } else if (planType === "One Day Trial") {
            endDate.setDate(endDate.getDate() + parsedPlanTime * 1);
          } else {
            endDate.setDate(endDate.getDate() + parsedPlanTime * 30);
          }
          updatedPlanDetails.endDate = endDate.toISOString().split("T")[0]; // Store the Date object
        }
      }
      setPlanDetails(updatedPlanDetails);
    }
  }, [planDetails.planTime, editPlans, planDetails]);

  const setData = (e) => {
    const { name, value } = e.target;
    setPlanDetails((preVal) => {
      if (name === "amountPaid" || name === "amountPending") {
        return {
          ...preVal,
          partialPayment: {
            ...preVal.partialPayment,
            [name]: value,
          },
        };
      } else if (name === "planTime" || name === "startDate") {
        const updatedPlanDetails = {
          ...preVal,
          [name]: value,
        };
        if (name === "planTime" || name === "startDate") {
          const { planTime, startDate, planType } = updatedPlanDetails;
          if (planTime && startDate) {
            let endDate = new Date(startDate);
            if (
              planType === "Peak Performance Plan" ||
              planType.includes("Personal Training")
            ) {
              endDate.setDate(endDate.getDate() + parseInt(planTime) * 30 + 10);
            } else if (planType === "One Day Trial") {
              endDate.setDate(endDate.getDate() + parseInt(planTime) * 1);
            } else {
              endDate.setDate(endDate.getDate() + parseInt(planTime) * 30);
            }
            updatedPlanDetails.endDate = endDate.toISOString().split("T")[0]; // Store the Date object
          }
        }
        return updatedPlanDetails;
      } else if (name === "gst") {
          if (value === "1.18" || value === "1.05") {
            let gstValue;
            let updatedTotalEventPrice;
            let updatedTotalProductPrice;
            let updatedPrice;
            if(service === "Events") {
              let previousTotalEventPrice = preVal.eventPrice * preVal.tickets; 
              updatedTotalEventPrice = previousTotalEventPrice * value;
              gstValue = updatedTotalEventPrice - previousTotalEventPrice;
            }else if(service === "Merchandise"){
              let previousTotalProductPrice = preVal.products * preVal.productPrice;
              updatedTotalProductPrice = previousTotalProductPrice * value;
              gstValue = updatedTotalProductPrice - previousTotalProductPrice;
            }else {
              updatedPrice = preVal.price * value;
              gstValue = updatedPrice - preVal.price;
            }
            return {
              ...preVal,
              [name]: value,
              totalPlanPrice: updatedPrice,
              totalEventPrice: updatedTotalEventPrice,
              totalProductPrice: updatedTotalProductPrice,
              gstAmount: gstValue,
            };
          } else {
            const newPrice = preVal.totalPlanPrice - preVal.gstAmount;
            const newEventPrice = preVal.totalEventPrice - preVal.gstAmount;
            const newProductPrice = preVal.totalProductPrice - preVal.gstAmount;
            return {
              ...preVal,
              [name]: value,
              totalPlanPrice: newPrice,
              totalProductPrice: newProductPrice,
              totalEventPrice: newEventPrice,
              gstAmount: 0,
            };
          }      
        } else {
        return {
          ...preVal,
          [name]: value,
        };
      }
    });
  };

  const handlePlanTypeChange = (selectedPlanType) => {
    const selectedPlan = planData.find(
      (plan) => plan.planname === selectedPlanType
    );
    if (selectedPlan) {
      setPlanDetails((prevState) => ({
        ...prevState,
        planType: selectedPlanType,
        planTime: selectedPlan.planduration.value, // Set default plan time
        price: selectedPlan.planduration.price, // Set default price
        planmode: selectedPlan.planmode,
      }));
    }
  };

  const handlePlanTimeChange = (selectedPlanTime) => {
    const selectedPlan = planData.find(
      (plan) => plan.planname === planDetails.planType
    );
    if (selectedPlan) {
      const selectedPlanDuration = selectedPlan.planduration.find(
        (duration) => duration.value === selectedPlanTime
      );
      if (selectedPlanDuration) {
        setPlanDetails((prevState) => ({
          ...prevState,
          planTime: selectedPlanTime,
          price: selectedPlanDuration.price,
        }));
      }
    }
  };
  const handleEventTypeChange = (selectedEventName) => {
    const selectedPlan = planData.find(
      (plan) => plan.category === selectedEventName
    );
    if (selectedPlan) {
      setPlanDetails((prevState) => ({
        ...prevState,
        eventTime: selectedPlan.time,
        eventPlace: selectedPlan.place,
        eventName: selectedPlan.category,
        eventPrice: selectedPlan.price, // Set default price
        totalEventPrice: selectedPlan.price,
      }));
    }
  }
  const updateTotalPrice = (tickets) => {
    // Assuming planDetails.price is the price per ticket
    const totalPrice = planDetails.eventPrice * tickets;
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      totalEventPrice: totalPrice,
    }));
  };

  const handleTicketsChange = (selectedTickets) => {
    // Call the function to update total price
    updateTotalPrice(selectedTickets);
    // Update the tickets field in the state
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      tickets: selectedTickets,
    }));
  };

  const handleProductTypeChange = (selectedProductName) => {
    const selectedPlan = planData.find(
      (plan) => plan.name === selectedProductName
    );
    if (selectedPlan) {
      setPlanDetails((prevState) => ({
        ...prevState,
        productName: selectedProductName,
        productBrand: selectedPlan.brand,
        productSize: selectedPlan.size.value, 
        productPrice: selectedPlan.size.price, 
        totalPrice: selectedPlan.productPrice,
      }));
    }
  }

  const handleProductSizeChange = (selectedSize) => {
    const selectedPlan = planData.find(
      (plan) => plan.name === planDetails.productName
    );
    if (selectedPlan) {
      const selectedPlanDuration = selectedPlan.size.find(
        (duration) => duration.value === selectedSize
      );
      if (selectedPlanDuration) {
        setPlanDetails((prevState) => ({
          ...prevState,
          productSize: selectedSize,
          productPrice: selectedPlanDuration.price,
          totalProductPrice: selectedPlanDuration.price,
        }));
      }
    }
  };

  const updateTotalProductPrice = (products) => {
    // Assuming planDetails.price is the price per ticket
    const totalPrice = planDetails.productPrice * products;
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      totalProductPrice: totalPrice,
    }));
  };

  const handleQuantityChange = (selectedProduct) => {
    // Call the function to update total price
    updateTotalProductPrice(selectedProduct);
    // Update the tickets field in the state
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      products: selectedProduct,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let requestData = {
        userId: userId,
        mobile: mobileNumber,
      };
  
      if (service === "Events") {
        requestData = {
          planDetails: {
            ...requestData,
            eventName: planDetails.eventName || editPlans.eventName,
            tickets: planDetails.tickets,
            totalEventPrice: planDetails.totalEventPrice || editPlans.totalEventPrice,
            eventPrice: planDetails.eventPrice || editPlans.eventPrice,
            gymLocation: planDetails.gymLocation || editPlans.gymLocation,
            discountCode: planDetails.discountCode || editPlans.discountCode,
            paymentMode: planDetails.paymentMode || editPlans.paymentMode,
            gst: planDetails.gst || editPlans.gst,
            gstAmount: planDetails.gstAmount,
            purchaseDate: planDetails.purchaseDate,
            eventTime: planDetails.eventTime,
            eventPlace: planDetails.eventPlace,
          }
        }
          if (planDetails.paymentMode === "Card") {
            requestData.planDetails.cardDetails = cardDetails;
          } else if (planDetails.paymentMode === "UPI") {
            requestData.planDetails.upiDetails = upiDetails;
          } else if (planDetails.paymentMode === "Cheque") {
            requestData.planDetails.chequeDetails = chequeDetails;
          }
        } else if(service === "Merchandise") {
          requestData = {
            planDetails: {
              ...requestData,
              productName: planDetails.productName || editPlans.productName,
              productBrand: planDetails.productBrand || editPlans.productBrand,
              productSize: planDetails.productSize || editPlans.productSize,
              products: planDetails.products || editPlans.products,
              totalProductPrice: planDetails.totalProductPrice || editPlans.totalProductPrice,
              productPrice: planDetails.productPrice || editPlans.productPrice,
              gymLocation: planDetails.gymLocation || editPlans.gymLocation,
              discountCode: planDetails.discountCode || editPlans.discountCode,
              paymentMode: planDetails.paymentMode || editPlans.paymentMode,
              gst: planDetails.gst || editPlans.gst,
              gstAmount: planDetails.gstAmount,
              purchaseDate: planDetails.purchaseDate,
            }
          }
            if (planDetails.paymentMode === "Card") {
              requestData.planDetails.cardDetails = cardDetails;
            } else if (planDetails.paymentMode === "UPI") {
              requestData.planDetails.upiDetails = upiDetails;
            } else if (planDetails.paymentMode === "Cheque") {
              requestData.planDetails.chequeDetails = chequeDetails;
            }
        }else {
        // Include other fields for services other than "Events"
        requestData.planDetails = {
          planType: planDetails.planType || editPlans.planType,
          promo: planDetails.promo || editPlans.promo,
          planTime: planDetails.planTime || editPlans.planTime,
          gymLocation: planDetails.gymLocation || editPlans.gymLocation,
          batchTiming: planDetails.batchTiming || editPlans.batchTiming,
          startDate: planDetails.startDate || editPlans.startDate,
          endDate: planDetails.endDate || editPlans.endDate,
          purchaseDate: planDetails.purchaseDate,
          trainerAssigned: planDetails.trainerAssigned || editPlans.trainerAssigned,
          price: planDetails.price || editPlans.price,
          paymentMode: planDetails.paymentMode || editPlans.paymentMode,
          planmode: planDetails.planmode || editPlans.planmode,
          promoCode: planDetails.discountCode || editPlans.discountCode,
          gst: planDetails.gst || editPlans.gst,
          gstAmount: planDetails.gstAmount || editPlans.gstAmount,
        };
        if(planDetails.gst === "" || planDetails.gst === "No") {
          requestData.planDetails.price = planDetails.price;
        }else {
          requestData.planDetails.price = planDetails.totalPlanPrice;
        };
        if (planDetails.paymentMode === "Card") {
          requestData.planDetails.cardDetails = cardDetails;
        } else if (planDetails.paymentMode === "UPI") {
          requestData.planDetails.upiDetails = upiDetails;
        } else if (planDetails.paymentMode === "Cheque") {
          requestData.planDetails.chequeDetails = chequeDetails;
        }
      }
  
      let endPoint = "/api/user/createPlan";
  
      if (service === "Events") {
        endPoint = "/api/user/createUserEvent";
        requestData.createdBy = user.uniqueId;
      } else if (service === "Merchandise") {
        endPoint = "/api/user/createUserProduct"
        requestData.createdBy = user.uniqueId;
      }else if (Object.keys(editPlans).length > 0) {
        requestData.updatedBy = user.uniqueId;
        endPoint = `/api/user/updatePlan/${planId}`;
      } else {
        requestData.createdBy = user.uniqueId;
      }
        
      const response = await instance.post(endPoint, requestData, {
        params: {
          userId,
        },
      });
  
      if (response.data.success) {
        setLoading(false);
        toast.success(response.data.message);
        if (location) {
          nav("/dashboard/members");
        }
        if (mobileNumber) {
          onPlanSubmit();
        }
  
        closeModal();
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDiscountCode = async () => {
    try {
      const response = await instance.post("/api/user/getDiscountCode", {
        discountCode: planDetails.discountCode,
      });
      if (response.data.success === true) {
        const discountLimit = response.data.data.discountLimit;
  
        // Check if the service is 'Events'
        if (service === 'Events') {
          const selectedEvent = planData.find(
            (event) => event.category === planDetails.eventName
          );
  
          if (selectedEvent) {
            const updatedPrice = planDetails.totalEventPrice - discountLimit;
            const newDiscounted = updatedPrice > 0 ? updatedPrice : 0;
            setPlanDetails((prevPlanDetails) => ({
              ...prevPlanDetails,
              totalEventPrice: newDiscounted > 0 ? newDiscounted : "0",
            }));
            // setDiscounted(newDiscounted);
            toast.success("Discount applied successfully!");
          }
        } else if(service === "Merchandise") {
          const selectedEvent = planData.find(
            (event) => event.name === planDetails.productName
          );
          if (selectedEvent) {
            const updatedPrice = planDetails.totalProductPrice - discountLimit;
            const newDiscounted = updatedPrice > 0 ? updatedPrice : 0;
            setPlanDetails((prevPlanDetails) => ({
              ...prevPlanDetails,
              totalProductPrice: newDiscounted > 0 ? newDiscounted : "0",
            }));
            toast.success("Discount applied successfully!");
          }
        }else {
          // Handle other services with planduration
          const selectedPlan = planData.find(
            (plan) => plan.planname === planDetails.planType
          );
          if (selectedPlan) {
            let newDiscounted;
            if(planDetails.gst === "" || planDetails.gst === "No") {
              const updatedPrice =
                selectedPlan.planduration.find(
                  (duration) => duration.value === planDetails.planTime
                )?.price - discountLimit;
              newDiscounted = updatedPrice > 0 ? updatedPrice : "0";
              setPlanDetails((prevPlanDetails) => ({
                ...prevPlanDetails,
                price: newDiscounted,
              }));
            }else {
              const updatedPrice = planDetails.totalPlanPrice - discountLimit;
              newDiscounted = updatedPrice > 0 ? updatedPrice : "0";
              setPlanDetails((prevPlanDetails) => ({
              ...prevPlanDetails,
              totalPlanPrice: newDiscounted,
            }));
            }
            setDiscounted(newDiscounted);
            toast.success("Discount applied successfully!");
          }
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred");
    }
  };

  useEffect(() => {
    // This effect will run whenever planDetails.price changes
    if(planDetails.gst === "" || planDetails.gst === "No") {
      setPlanDetails((prevPlanDetails) => {
        return {
          ...prevPlanDetails,
          price: discounted,
        };
      });
    }else {
      setPlanDetails((prevPlanDetails) => {
        return {
          ...prevPlanDetails,
          totalPlanPrice: discounted,
        };
      });
    }
    //eslint-disable-next-line
  }, [discounted]);

  return (
    <div className="tnc-modal1">
      <div className="tnc-modal-content1">
        <h4 className="tnc-h4">Add New MemberShip</h4>
        <CloseIcon className="close-icon" onClick={closeModal} />
        <form className="showPlan-form" onSubmit={handleSubmit}>
          <div className="showPlan-form-input">
            <label htmlFor="service">Service</label>
            <select
              className="showPlan-input-field"
              required
              name="service"
              value={service}
              onChange={(e) => setService(e.target.value)}
            >
              <option value="">Select Service</option>
              <option value="General Fitness">Gym Membership</option>
              <option value="Personal Training">Personal Training</option>
              <option value="Nutrition">Nutrition</option>
              <option value="Physiotherapy">Physiotherapy</option>
              <option value="One Day Trial">One Day Trial</option>
              <option value="Events">Events</option>
              <option value="Merchandise">Merchandise</option>
            </select>
          </div>
          {["General Fitness", "Personal Training", "One Day Trial", "Nutrition", "Physiotherapy"].includes(service) ? (
            <>
              <div className="showPlan-form-input">
                <label htmlFor="planType">Plan Name</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="planType"
                  value={planDetails.planType || editPlans.planType}
                  onChange={(e) => handlePlanTypeChange(e.target.value)}
                >
                  <option value="">Select Plan Name...</option>
                  {planData.map((plan, index) => (
                    <option key={index} value={plan?.planname}>
                      {plan.planname}
                    </option>
                  ))}
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="">Plan Duration</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="planTime"
                  value={planDetails.planTime || editPlans.planTime}
                  onChange={(e) => handlePlanTimeChange(e.target.value)}
                >
                  <option value="">Select Plan Time</option>
                  {planData.find((plan) => plan.planname === planDetails.planType)
                    ?.planduration.map((duration, index) => (
                      <option key={index} value={duration.value}>
                        {duration.value}
                      </option>
                    ))}
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="">Trainer Assigned</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="trainerAssigned"
                  value={
                    planDetails.trainerAssigned || editPlans.trainerAssigned
                  }
                  onChange={setData}
                >
                  <option value="">Select Trainer</option>
                  {trainer &&
                    trainer.length > 0 &&
                    trainer.map((train, index) => (
                      <option value={train.value} key={index}>
                        {train.uniqueId}
                      </option>
                    ))}
                  <option value="none">None</option>
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="">Batch Timing</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="batchTiming"
                  value={planDetails.batchTiming || editPlans.batchTiming}
                  onChange={setData}
                >
                  <option value="">Select Batch Timing</option>
                  <option value="Morning">Morning</option>
                  <option value="Evening">Evening</option>
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  className="showPlan-input-field"
                  name="startDate"
                  required
                  value={planDetails.startDate || editPlans.startDate}
                  onChange={setData}
                />
                {/* min={editPlans.startDate ? undefined : new Date().toISOString().split('T')[0]} */}
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  className="showPlan-input-field"
                  name="endDate"
                  required
                  value={planDetails.endDate || editPlans.endDate}
                  onChange={setData}
                  disabled
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="price">Price</label>
                <input
                  type="text"
                  className="showPlan-input-field"
                  name="price"
                  value={(planDetails.gst === "No" || planDetails.gst === "")? planDetails.price : planDetails.totalPlanPrice}
                  onChange={setData}
                  readOnly
                  placeholder="Add Price"
                />
              </div>
            </>
          ) : ""}
          {service === "Events" && (
            <>
              <div className="showPlan-form-input">
                <label htmlFor="eventName">Event Name</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="eventName"
                  value={planDetails.eventName || editPlans.eventName}
                  onChange={(e) => handleEventTypeChange(e.target.value)}
                >
                  <option value="">Select Event Name...</option>
                  {planData.map((plan, index) => (
                    <option key={index} value={plan?.category}>
                      {plan.category}
                    </option>
                  ))}
                </select>
              </div>
              <div className= "showPlan-form-input">
                <label htmlFor="tickets">Tickets</label>
                  <input 
                    type="number"
                    className="showPlan-input-field"
                    required
                    name="tickets"
                    value={planDetails.tickets}
                    onChange={(e) => handleTicketsChange(e.target.value)} 
                    min={1}
                  />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="eventPrice">Event Price</label>
                <input
                  type="number"
                  className="showPlan-input-field"
                  name="eventPrice"
                  required
                  value={planDetails.eventPrice || editPlans.eventPrice}
                  readOnly
                  placeholder="Add Price"
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="totalEventPrice">Total Price</label>
                <input
                  type="text"
                  className="showPlan-input-field"
                  name="totalEventPrice"
                  value={planDetails.totalEventPrice || editPlans.totalEventPrice}
                  readOnly
                  placeholder="Total Price"
                />
              </div>
              <div className= "showPlan-form-input">
                <label htmlFor="eventTime">Event Time</label>
                  <input 
                    type="text"
                    className="showPlan-input-field"
                    name="eventTime"
                    value={planDetails.eventTime}
                    readOnly
                  />
              </div>
              <div className= "showPlan-form-input">
                <label htmlFor="eventPlace">Event Location</label>
                <input
                    type="text"
                    className="showPlan-input-field"
                    readOnly
                    name="eventPlace"
                    value={planDetails.eventPlace}
                  />
              </div>

            </>
          )}
          {service === "Merchandise" && (
            <>
              <div className="showPlan-form-input">
                <label htmlFor="productName">Merchandise Name</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="productName"
                  value={planDetails.productName || editPlans.productName}
                  onChange={(e) => handleProductTypeChange(e.target.value)}
                >
                  <option value="">Select Merchandise Name...</option>
                  {planData.map((plan, index) => (
                    <option key={index} value={plan?.name}>
                      {plan.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className= "showPlan-form-input">
                <label htmlFor="productBrand">Merchandise Brand </label>
                  <input 
                    type="text"
                    className="showPlan-input-field"
                    required
                    name="productBrand"
                    value={planDetails.productBrand}
                    readOnly
                  />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="productSize">Merchandise Size</label>
                <select
                  className="showPlan-input-field"
                  required
                  name="productSize"
                  value={planDetails.productSize || editPlans.productSize}
                  onChange={(e) => handleProductSizeChange(e.target.value)}
                >
                  <option value="">Select Size</option>
                  {planData.find((plan) => plan.name === planDetails.productName)
                    ?.size.map((duration, index) => (
                      <option key={index} value={duration.value}>
                        {duration.value}
                      </option>
                    ))}
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="eventPrice">Merchandise Price</label>
                <input
                  type="number"
                  className="showPlan-input-field"
                  name="productPrice"
                  required
                  value={planDetails.productPrice || editPlans.productPrice}
                  readOnly
                  placeholder="Add Price"
                />
              </div>
              <div className= "showPlan-form-input">
                <label htmlFor="products">Quantity</label>
                  <input 
                    type="number"
                    className="showPlan-input-field"
                    required
                    name="products"
                    value={planDetails.products}
                    onChange={(e) => handleQuantityChange(e.target.value)} 
                    min={1}
                  />
              </div>

              <div className="showPlan-form-input">
                <label htmlFor="totalProductPrice">Total Price</label>
                <input
                  type="text"
                  className="showPlan-input-field"
                  name="totalProductPrice"
                  value={planDetails.totalProductPrice || editPlans.totalProductPrice}
                  onChange={(e) => updateTotalProductPrice(e.target.value)}
                  readOnly
                  placeholder="Total Price"
                />
              </div>
            </>
          )}

          <div className="showPlan-form-input">
            <label htmlFor="purchaseDate">Purchase Date</label>
            <input
              type="date"
              className="showPlan-input-field"
              name="purchaseDate"
              required
              value={planDetails.purchaseDate}
              onChange={setData}
              min={
                planDetails.purchaseDate
                  ? undefined
                  : new Date().toISOString().split("T")[0]
              }
            />
          </div>
          <div className="showPlan-form-input">
            <label htmlFor="">Performance Center</label>
            <select
              className="showPlan-input-field"
              required
              name="gymLocation"
              value={planDetails.gymLocation || editPlans.gymLocation}
              onChange={setData}
            >
              <option value="">Select Performance Center</option>
              {gymName.map((a) => (
                  <option value={a.name} key={a}> {a.name} </option>
              ))}
            </select>
          </div>
          <div className="showPlan-form-input">
            <label htmlFor="promo">Apply Promo Code? </label>
            <select
              name="promo"
              className="showPlan-input-field"
              required
              value={planDetails.promo || editPlans.promo}
              onChange={setData}
            >
              <option value="">Select Yes / No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          {planDetails.promo === "Yes" ? (
            <div>
              <div className="showPlan-form-input">
                <label htmlFor="discountCode">Enter Promo Code</label>
                <input
                  type="text"
                  name="discountCode"
                  className="showPlan-input-field"
                  value={planDetails.discountCode || editPlans.discountCode}
                  onChange={setData}
                />
              </div>
              <button
                type="button"
                className="showPlan-form-input dash-btn"
                onClick={() => getDiscountCode()}
                style={{ margin: "0.54rem" }}
              >
                Apply Promo Code
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="showPlan-form-input">
            <label htmlFor="paymentMode">Payment Mode</label>
            <select
              name="paymentMode"
              className="showPlan-input-field"
              required
              value={planDetails.paymentMode || editPlans.paymentMode}
              onChange={setData}
            >
              <option value="">Select Payment Method</option>
              <option value="Cash">Cash</option>
              <option value="Card">Card</option>
              <option value="UPI">UPI</option>
              <option value="Cheque">Cheque </option>
              <option value="RTGS">RTGS</option>
              <option value="NEFT">NEFT</option>
              <option value="Net Banking">Net Banking</option>
            </select>
          </div>
          {planDetails.paymentMode === "Card" && (
            <div>
              <div className="showPlan-form-input">
                <label htmlFor="cardType">Card Type</label>
                <select
                  name="cardType"
                  className="showPlan-input-field"
                  value={cardDetails.cardType}
                  onChange={(e) =>
                    setCardDetails({ ...cardDetails, cardType: e.target.value })
                  }
                >
                  <option value="">Select Card Type</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                </select>
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="last4Digits">Last 4 Digits</label>
                <input
                  type="text"
                  name="last4Digits"
                  maxLength={4}
                  className="showPlan-input-field"
                  value={cardDetails.last4Digits}
                  onChange={(e) =>
                    setCardDetails({
                      ...cardDetails,
                      last4Digits: e.target.value,
                    })
                  }
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  className="showPlan-input-field"
                  value={cardDetails.bankName}
                  onChange={(e) =>
                    setCardDetails({ ...cardDetails, bankName: e.target.value })
                  }
                />
              </div>
            </div>
          )}
          {planDetails.paymentMode === "UPI" && (
            <div>
              {/* Render UPI related input fields */}
              <div className="showPlan-form-input">
                <label htmlFor="upiId">UPI ID</label>
                <input
                  type="text"
                  name="upiId"
                  className="showPlan-input-field"
                  value={upiDetails.upiId}
                  onChange={(e) =>
                    setUpiDetails({ ...upiDetails, upiId: e.target.value })
                  }
                />
              </div>
            </div>
          )}
          {planDetails.paymentMode === "Cheque" && (
            <div>
              {/* Render cheque related input fields */}
              <div className="showPlan-form-input">
                <label htmlFor="chequeNumber">Cheque Number</label>
                <input
                  type="text"
                  name="chequeNumber"
                  className="showPlan-input-field"
                  value={chequeDetails.chequeNumber}
                  onChange={(e) =>
                    setChequeDetails({
                      ...chequeDetails,
                      chequeNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="chequeDate">Cheque Date</label>
                <input
                  type="date"
                  name="chequeDate"
                  className="showPlan-input-field"
                  value={chequeDetails.chequeDate}
                  onChange={(e) =>
                    setChequeDetails({
                      ...chequeDetails,
                      chequeDate: e.target.value,
                    })
                  }
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  type="text"
                  name="bankName"
                  className="showPlan-input-field"
                  value={chequeDetails.bankName}
                  onChange={(e) =>
                    setChequeDetails({
                      ...chequeDetails,
                      bankName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="branchName">Branch Name</label>
                <input
                  type="text"
                  name="branchName"
                  className="showPlan-input-field"
                  value={chequeDetails.branchName}
                  onChange={(e) =>
                    setChequeDetails({
                      ...chequeDetails,
                      branchName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="showPlan-form-input">
            <label htmlFor="gst">Apply GST ? </label>
            <select
              name="gst"
              className="showPlan-input-field"
              required
              value={planDetails.gst || editPlans.gst}
              onChange={setData}
            >
              <option value="">Select Yes / No</option>
              <option value="1.18">18%</option>
              <option value="1.05">5%</option>
              <option value="No">No</option>
            </select>
          </div>
          {planDetails.gst !== "No" && planDetails.gst !== "" && (
            <div className="showPlan-form-input">
            <label htmlFor="gstAmount">Gst Amount</label>
            <input
              type="number"
              name="gstAmount"
              readOnly
              className="showPlan-input-field"
              value={planDetails.gstAmount}
              onChange={setData}
            />
          </div>
          )}
          <br />
          <button
            className={`dash-btn showPlan-btn ${loading ? "upload-btn" : ""}`}
            disabled={loading}
          >
            {loading ? <AutorenewIcon className="loader-css" /> : "Add Plan"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShowPlanModal;