import React, { useState, useEffect } from 'react';
import '../../css/DashSidebar.css';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { motion } from "framer-motion";
import Logo from '../../../assets/logo.webp'
import SegmentIcon from '@mui/icons-material/Segment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddTaskIcon from '@mui/icons-material/AddTask';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SellIcon from '@mui/icons-material/Sell';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import useUserStore from "../../../store";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import instance from '../../../utils/axios';
import toast from "react-hot-toast"

const Sidebar = () => {

  const [expanded, setExpanded] = useState(window.innerWidth > 768);
  const [ forms , setForms ] = useState([]);
  const [plan, setPlan] = useState(false);

  const user = useUserStore((state) => state.user)
  const userRole = user.role;
  const sidebarVariants = {
    true: {
      left : '0'
    },
    false:{
      left : '-62%'
    }
  }
  const getUserPlanDetails = async()=>{
    try{ 
      const response = await instance.get("/api/user/getPlanDates", {
        params: {
          userId: user._id,
        },
      })
      if(response.data.success === true) {
        setPlan(true);
      }
    }catch(error) {
      toast.error(error.message);;
    }
  }

  const checkFormResponse = async () => {
    try {
        const response = await instance.get(`/api/user/getFormForUserPage/${user._id}`);
        if (response.data.success) {
          setForms(response.data.data);
        }
    } catch (error) {
        console.log("check form response error: ", error);
        toast.error(error.message)
    } 
  }

  useEffect(() =>{
    if(userRole === "User"){
      getUserPlanDetails();
    }
    checkFormResponse();
    // eslint-disable-next-line
  }, [userRole]);

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setExpanded(false);
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      setExpanded(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    setExpanded(window.innerWidth > 768);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="dash-sidebar">
          <div className={`side-icon ${expanded ? 'expanded' : ''}`} onClick={() => setExpanded(!expanded)}>
            <SegmentIcon className="icon segment-icon"/>
          </div>
          <motion.div className="sidebar dashboard"
              variants={sidebarVariants}
              animate={expanded ? 'true' : 'false'}
          >
              <div className="top">
                <Link  className='dashnav-links'  to='/dashboard/profile' style={{textDecoration: "none"}}>
                  <img src={Logo} alt="" className="img"/>
                </Link>
                <Link  className='dashnav-links'  to='/dashboard/profile' style={{textDecoration: "none"}}>
                  <span className="logo">PlanetMuscle</span>
                </Link>
                </div>
              <hr />
              <div className="center">
                  <ul>
                      {["Admin", "Regional Manager", "Sales Manager", "Branch Manager", "Sales"].includes(user.role) ? (<>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/users'> <PersonIcon  className='icon' /> <li>Visitors</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/members'> <PeopleAltIcon  className='icon' /> <li>Members</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/employees'><AccountBoxIcon  className='icon' /><li>Counsellors</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/past'><NoAccountsIcon  className='icon' /><li>Past Users</li></Link>
                        </> 
                      ): ""}

                      {["Admin", "Regional Manager", "Branch Manager","Sales Manager", "Accounts"].includes(user.role) ? (<Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/admin'><AdminPanelSettingsIcon  className='icon' /><li>{["Admin", "Regional Manager"].includes(user.role) ? "Admin" : "Orders"}</li></Link>) : ""}
                      
                      {["Admin", "Regional Manager", "Branch Manager", "Silver Trainer", "Gold Trainer", "Platinum Trainer", "Diamond Trainer", "Master Trainer", "Sales", "Accounts", "Sales Manager"].includes(user.role) || plan !== false ? 
                      <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/calendar'><CalendarMonthIcon  className='icon' /><li>Calendar</li></Link> : ""}

                      {["Admin", "Regional Manager", "Branch Manager", "Sales", "Sales Manager"].includes(user.role) ? <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/userProspect'><QueryStatsIcon  className='icon' /><li>User Prospect</li></Link>: ""}

                      {["Admin", "Regional Manager", "Branch Manager", "Sales"].includes(user.role) ? <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/requests'><RequestPageIcon  className='icon' /><li>Requests</li></Link> : "" }

                      {["Admin", "Regional Manager", "Accounts", "Sales Manager"].includes(user.role) ? <> <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/accounts'><AccountBalanceIcon  className='icon' /><li>Accounts</li></Link></>: ""}
                    
                      {["Admin", "Regional Manager", "Branch Manager", "Silver Trainer", "Gold Trainer", "Platinum Trainer", "Diamond Trainer", "Master Trainer", "Sales", "Accounts", "Sales Manager"].includes(user.role) ? 
                      <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/task'><AddTaskIcon  className='icon' /><li>Task</li></Link> : ""}

                      {["Admin", "Regional Manager", "Branch Manager", "Silver Trainer", "Gold Trainer", "Platinum Trainer", "Diamond Trainer", "Master Trainer", "Sales", "Accounts", 
                      "Sales Manager"].includes(user.role) ? 
                      <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/sales'><SellIcon  className='icon' /><li>Sales</li></Link> : ""}

                      {["Admin","Regional Manager"].includes(user.role) ? 
                      ( <>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/editor'><EditIcon  className='icon' /><li>Editor</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/programs'><EmojiEventsIcon  className='icon' /><li>Events</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/plans'><PlaylistAddIcon  className='icon' /><li>Plans</li></Link>
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/products'><ShoppingCartIcon  className='icon' /><li>Products</li></Link>
                      </>): ""}
                      {forms.some(form => form.prescriptionData.isSubmitted) && (
                      <Link className='dashnav-links' onClick={handleLinkClick}  to={`/dashboard/exercises`}><FitnessCenterIcon  className='icon' /><li>  Exercises </li></Link> )}

                      { forms.length > 0 && (
                        <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/user'><InsertDriveFileIcon  className='icon' /><li> Exercise Forms </li></Link>
                      )}

                      {["Admin", "Regional Manager", "Branch Manager", "Accounts"].includes(user.role) ? <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/analysis'><EqualizerIcon  className='icon' /><li>Analysis</li></Link>: ""}        

                      <Link className='dashnav-links' onClick={handleLinkClick}  to='/dashboard/profile'><AccountCircleIcon  className='icon' /><li>My Profile</li></Link>
                      <Link className='dashnav-links' to='/'><LogoutIcon  className='icon' /><li>Home</li></Link>                 
                  </ul>
              </div>
          </motion.div>
      </div>
    </>
  )
}

export default Sidebar;

