import React, { useEffect, useRef } from 'react'
import Layout from './../MainLayout/Layout';
import "../../css/PlansPage.css"
import { useState } from 'react';
import useUserStore from '../../store';
import toast from 'react-hot-toast';
import instance from '../../utils/axios';
import Service from './Service';

const PlansPage = () => {

  const sliderRef = useRef(null);
  const [planData, setPlanData] = useState('');
  const user = useUserStore((state) => state.user);
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedOptions, setSelectedOptions] = useState('');

  const handleSlide = (direction) => {
    const slider = sliderRef.current;
    const slideAmount = 300; // Adjust as needed
    if (slider) {
      if (direction === 'left') {
        slider.scrollTo({
          left: slider.scrollLeft - slideAmount,
          behavior: 'smooth', 
        });
      } else {
        slider.scrollTo({
          left: slider.scrollLeft + slideAmount,
          behavior: 'smooth',
        });
      }
    }
  };

  const getData = async () => {
    try {
      const response = await instance.get("/api/user/getPlanData");
      if(response.data.success) {
        setPlanData(response.data.data);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later!")
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const handleSizeChange = (e, cardId) => {
    const selectedOption = e.target.value;
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [cardId]: selectedOption,
    }));
    const selectedOptionExists = Object.keys(selectedOptions).some((key) => key === cardId);
    if (selectedOptionExists) {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [cardId]: selectedOption,
      }));
    }
    else {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [cardId]: selectedOption,
      }));
    }
    const selectedPlanSize = planData
      .flatMap((card) => card.planduration)
      .find((item) => item.value === selectedOption);
    setSelectedSize((prevSelectedSizes) => ({
      ...prevSelectedSizes,
      [cardId]: selectedPlanSize.price,
    }));
  };
  
  useEffect(() => {
    if (planData) {
      const initialSelectedOptions = planData.reduce((acc, curr) => {
        acc[curr._id] = curr.planduration[0].value;
        return acc;
      }, {});
      setSelectedOptions(initialSelectedOptions);
  
      const initialSelectedSizes = planData.reduce((acc, curr) => {
        acc[curr._id] = curr.planduration[0].price;
        return acc;
      }, {});
      setSelectedSize(initialSelectedSizes);
    }
  }, [planData]);
  
  useEffect(() => {
    if (planData && Object.keys(selectedOptions).length === 0) {
      const defaultSelectedOptions = planData.reduce((acc, curr) => {
        acc[curr._id] = curr.planduration[0].value;
        return acc;
      }, {});
      setSelectedOptions(defaultSelectedOptions);
    }

  }, [planData, selectedOptions]);
        
  const handleAddToCart = async (card, selectedOptions) => {
    const item = {
      ...card,
      size: selectedOptions, 
      id: card._id,
      name: card.planname,
      mode: card.planmode,
      price: selectedSize[card._id],
      quantity: 1,
      itemType: "plan"
    };
  
    try {
      const response = await instance.post("/api/user/addToCart", { item, userId: user._id });
      if (response.data.success) {       
        toast.success("Plan Added To Cart Successfully!");
      } else {
        toast.error(response.data.message);
        console.log(response.data.error);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  
  return (
    <Layout>
      <div className="plansPage">
        <h2 className="website-header">Planet Muscle Plans</h2>
        {planData && (
          <Service 
            title="All Plans"
            sliderRef={sliderRef}
            planName={planData}
            selectedOptions={selectedOptions}
            sizeChange={(e, cardId) => handleSizeChange(e, cardId)} 
            addToCart={(card, selectedOptions) => handleAddToCart(card, selectedOptions)} 
            buttonSize={selectedSize} 
            handleLeft={() => handleSlide('left')}
            handleRight={() => handleSlide('right')}
          />
        )}
      </div>
    </Layout>
  )
}

export default PlansPage