import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "../../css/PlansPage.css"

const Service = ({title, sliderRef, planName, selectedOptions, sizeChange, addToCart, buttonSize, handleLeft,handleRight}) => {
    
  return (
    <div className="plansPage-container">
    <h3 className="plansPage-title">{title}</h3>
    <div className="plansPage-slider" ref={sliderRef}>

      {planName && planName?.map((a,b) => (
        <div className="plansCard" key={b}>
          <h4 className="plansCard-h4">{a.planmode} {a.plantype}</h4>
          <h3 className="plansCard-h3">{a.planname}</h3>
          <select name="planduration" value={selectedOptions[a._id]} onChange={(e) => sizeChange(e, a._id)} className='plansCard-select'>
            {a.planduration && a.planduration.map((c) => (
              <option className="plansCard-option" key={c.value} value={c.value}>{c.value}</option>
            ))}
          </select>
          <ul className='plansCard-ul'>
            {a.tags && a.tags.map((d) => (
              <li className="plansCard-li" key={d}>{d}</li>
            ))}
          </ul>
          <button onClick={() => addToCart(a, selectedOptions[a._id])} className="send-btn">₹{buttonSize[a._id]}</button>

        </div>
      ))}
    </div>            
    <KeyboardArrowRightIcon className='decrease-plan plans-slide-btn' onClick={handleLeft}/>
    <KeyboardArrowRightIcon className='increase-plan plans-slide-btn'  onClick={handleRight}/>
  </div>

  )
}

export default Service