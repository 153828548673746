import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import instance from '../../../utils/axios';
import "../../css/Dashprescription.css"

const ExercisesList = () => {

  const { dayId } = useParams();
  const [ name, setName ] = useState([]);

  const getData = async () => { 
    try { 
      const response = await instance.get(`/api/user/getIndividualDayExercise/${dayId}`);
      if ( response.data.success ){ 
        setName(response.data.data)
      } else { 
        toast.error(response.data.message)
      }
    } catch ( error ) { 
      console.log("get exercise by day error: ", error.message);
      toast.error(error.message)
    }
  }

  useEffect(() => {  
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className='dashMembers' >
      <div className='dashMembers-container'>
        <span className="dashboard-title">
           Week { name.dayNumber } 
        </span>
      </div>
      <div className='dash-user'>
        <div className='single-exercise-title'>
            Title: { name.dayHeading }
        </div>
        <div className='single-exercise-lists'>
            { name.exercises?.map((exercise, value) => (
                <Link className='single-exercise-list' key={value} to={`/dashboard/exercises/exercise/${exercise._id}`} >
                    <h3 className='single-exercise-list-h3'> {value + 1}. { exercise.exerciseName}</h3>
                    <div className='single-exercise-main-div'>
                      <div className='single-exercise-list1' >
                          <img src="https://images.healthshots.com/healthshots/en/uploads/2023/05/10200007/exercise.jpg" alt="2" className='single-exercise-list1-img'/>
                      </div>
                      <div className='single-exercise-list2'>
                          <span className='single-exercise-list2-span'> Sets: { exercise.numberOfSets}</span>
                          <span className='single-exercise-list2-span'> Repetitions: { exercise.reps}</span>
                          <span className='single-exercise-list2-span'> Ideal Time:  { exercise.time}</span>
                          <span className='single-exercise-list2-span'> Gap between Sets: { exercise.gap}</span>
                      </div>
                    </div>
                </Link>
            )) }

        </div>
      </div>
    </div>
  )
}

export default ExercisesList;