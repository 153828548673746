import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import "../../css/Products.css";
import instance from "../../utils/axios";
import useUserStore from "../../store";
import {toast} from 'react-hot-toast';
import { motion } from "framer-motion";
import Layout from "../MainLayout/Layout";
import Loader from "../../components/MainLoader/Loader";
import {useNavigate} from "react-router-dom";

const SingleProduct = () => {
  // const addToCart = useUserStore((state) => state.addToCart);
  const user = useUserStore((state) => state.user);
  const loggedIn = useUserStore((state) => state.loggedIn);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const { productId } = useParams();
  const nav = useNavigate();
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await instance.get(`/api/user/getProductData/${productId}`);
        if (response.data.success) {
          setProduct(response.data.data);
        } else {
          toast.error(response.data.message)
        }
      } catch (error) {
        toast.error("An Error Occurred. Please Try Again!");
      }
    };
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    if (product && product.size.length > 0) {
      setSelectedSize(product.size[0]);
    }
  }, [product]);

  if (!product) {
    return (
      <>
        <Loader />
      </>
    );

  }
  const handleImageClick = (src) => {
    setProduct(prevProduct => {
      const updatedFile = [src, ...prevProduct.file.secure_url.filter(image => image !== src)];
      return {
        ...prevProduct,
        file: {
          ...prevProduct.file,
          secure_url: updatedFile
        }
      };
    });
  };

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const handleSizeChange = (e) => {
    const selectedOption = e.target.value;
    const selectedProductSize = product.size.find(item => item.value === selectedOption);

    setSelectedSize(selectedProductSize);
  };

  const handleAddToCart = async () => {
    if(!loggedIn){
      toast.error("Please Login To Continue");
      nav("/login");
      return;
    }
    const item = {
      ...product,
      size: selectedSize.value, 
      id: productId,
      name: product.name,
      price: selectedSize?.price,
      quantity: quantity,
      itemType: "product"
    };
    // addToCart(item);
    // toast.success("Product added to cart");
    try{
      const response = await instance.post("/api/user/addToCart", {item, userId: user._id})
      if(response.data.success) {       
        toast.success("Product added to cart");
      }else if(response.data.error) {
        toast.error(response.data.error);
      }else{
        toast.error("An Error Occurred");
      }
    }catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Layout >
      <motion.div className="single-products"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x:0 }}
          transition={{ duration: 0.3 }}
 
      >
        <div className="single-product-images">
          <img src={product.file.secure_url[0]} alt="#" className="single-main-image" id="mainImage" />
          <div className="single-image-grp">
          { product.file.secure_url && product.file.secure_url.slice(1, 5).map((image, index) => (
              <div className="single-image-col" key={index}>
                <img src={image} alt="#" className="small-img" onClick={() => handleImageClick(image)} />
              </div>
            ))}
          </div>
        </div>
        <div className="single-products-details">
          <h6 className="product-brand">{product.brand}</h6>
          <h4 className="product-title">{product.name}</h4>
          <h2 className="product-price">₹{selectedSize?.price}</h2>
          <select name="size" id="" onChange={handleSizeChange}>
            {product.size && product.size.map((tag, index) => (
              <option key={index} value={tag.value} className="general-li">{tag.value}</option>
            ))}
          </select>
          <div className="add-div">
            <input type="number" min={1} max={6} value={quantity} onChange={handleQuantityChange} />
            <button className="send-btn addToCart-btn" onClick={() => handleAddToCart(product)}>Add to Cart</button>
          </div>
          <h4 className="product-d">Product Details</h4>
          <p className="product-detail">{product.details}</p>
        </div>
      </motion.div>
      </Layout >
    </>
  );
};

export default SingleProduct;