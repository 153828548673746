import React from "react";
import "../../css/ImageSlider.css";
import { Images } from "../../data/Images";

const ImageSlider = () => {
  return (
    <div className="imageSlider">
      <h3 className="image-title">Facilities in Planet Muscle</h3>
      <h4 className="image-desc">You Can Enjoy The Best Facilities</h4>
      <div className="imageSlider-container">
        {Images.map((product, item) => (
          <div className="imageSlider-card" key={item}>
            <div className="imageSlider-image">
              <img src={product.img} alt="" loading="lazy" />
            </div>
            <div className="imageSlider-details">
              <div className="imageSlider-title">{product.name}</div>
              <div className="imageSlider-desc">{product.desc}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
