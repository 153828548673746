import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MoreVertIcon from "@mui/icons-material/MoreVert"
import useUserStore from "../../../store";
import DeleteModal from "../../components/Modal/DeleteModal";
import toast from "react-hot-toast";

const DashPrograms = () => {

    const [productData, setProductData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState("");
    const user = useUserStore((state) => state.user);

    useEffect(() => {
      instance.get("/api/user/getProgramsData", {
        params: {
          location: "DashBoard",
        },
      })
        .then((response) => {
          if (response.data.success) {
            setProductData(response.data.data);
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => console.log(error));
  }, []);

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/getProgramData2/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("Product deleted successfully");
        setProductData((prevData) => prevData.filter((product) => product._id !== userIdToDelete));
        setShowModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
        toast.error("An Error Occurred. Please Try Again Later!");
      }
    }
  
    const toggleDropdown = (productId) => {
      const dropdownContent = document.getElementById(`dashMembersDropdownContent-${productId}`);
      if (dropdownContent) {
        dropdownContent.style.display = dropdownContent.style.display === "block" ? "none" : "block";
      }
    };

  return (
    <>
      <div className="dataTable">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0 }}
          className="datatableTitle dashboard-title"
        >
          Events Page
          <Link
            to="/dashboard/programs/addProgram"
            className="table-link"
          >
            Add New
          </Link>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="update-product-container"
        >
            {productData.length === 0 || productData.length === null ? (
                <div className="no-product">
                    <h1 className="no-product-h1">Opppss!!!</h1>
                    <h4 className="no-product-h4">Currently There is no Product</h4>
                    <span className="no-product-span">Add Products to start your online journey</span>
                </div>
            ) : <>
            <div className="showProduct">
                {productData.slice().reverse().map((product, value) => (
                    <div className="show-product" key={value}>
                        <div className="show-left">
                            <img src={product.file.secure_url} alt="#" />
                        </div>
                        <div className="show-right">
                          <div className="show-right-name">
                            <h3 className='show-title'>{product.category}</h3>
                            <div className="dashMembers-dropdown">
                              <MoreVertIcon className='dashMembersDropBtn' onClick={() => toggleDropdown(product._id)} />
                              <div id={`dashMembersDropdownContent-${product._id}`} className="dashMembers-drop-content">
                                {["Admin", "Regional Manager"].includes(user.role) ? <>
                                <Link to={`/dashboard/programs/updateProgram/${product._id}`} className="new-AContent">Update</Link>
                                <Link to={`/dashboard/programs/analysis/${product._id}`} className="new-AContent">Analysis</Link>
                                <div className="new-AContent" onClick={() => handleDelete(product._id)}>Delete</div>
                                </> : "" }
                              </div>
                            </div>
                          </div>
                          <div className="show-price"> Place: {product.place} , {product.state} </div>
                          <div className="show-right-name1">
                            <div className="show-price">Price: ₹{product.price}</div>
                            <div className="show-price"> Quantity: {product.quantity} </div>
                            <div className="show-price"> Quantity Left: {product.quantityLeft} </div>
                            <div className="show-price"> Tickets Sold: {product.ticketsSold} </div>
                            <div className="show-price"> Free Tickets: {product.freeTickets} </div>
                            <div className="show-price"> Manual: {product.manualTickets} </div>
                            <div className="show-price"> Websites: {product.websiteTickets} </div>
                            <div className="show-price"> Earning: ₹{product.totalEarning} </div>
                            <div className="show-price">Manual Earning: ₹{product.manualEarning}{" "}</div>
                          <div className="show-price">Website Earning: ₹{product.websiteEarning}{" "}</div>
                            <div className="show-price"> Profit: ₹{product.profit} </div>
                            <div className="show-price"> Gst: ₹{product.gstAmount} </div>
                          </div>
                        </div>
                        {showModal && (
                            <DeleteModal 
                                h4={"Are you sure you want to delete this product?"} 
                                confirmDelete={confirmDelete}
                                closeModal={()=> setShowModal(false)} 
                            />
                        )}
                    </div>
                ))}
            </div>
          </>}
        </motion.div>

      </div>
    </>
  );
};

export default DashPrograms;
