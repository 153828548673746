import React, { useEffect, useState } from 'react'

import "../../css/Details.css"
import logo from "../../assets/logo.webp"

const Counter = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let animationFrameId = null;
    const duration = 5000; // Duration in milliseconds
    const framesPerSecond = 60;
    const increment = Math.ceil((value / duration) * framesPerSecond);
    let frameCount = 0;

    const startAnimation = () => {
      animationFrameId = requestAnimationFrame(startAnimation);

      if (frameCount < duration) {
        frameCount += 1000 / framesPerSecond;
        setCount((prevCount) => {
          if (prevCount < value) {
            return Math.min(prevCount + increment, value);
          } else {
            cancelAnimationFrame(animationFrameId);
            return prevCount;
          }
        });
      } else {
        cancelAnimationFrame(animationFrameId);
      }
    };

    startAnimation();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [value]);

  return <span className="counter">{count}</span>;
};

const Details = () => {
  return (
    <div>
        <div className="details">
            <div className="figures">
                <div className="figures-container">
                    <span className="details-span">
                       <Counter value={2000} />  + 
                    </span>
                    <h5 className="details-title">Members</h5>
                </div>
                <div className="figures-container">
                    <span className="details-span">
                        <Counter value={3} /> + 
                    </span>
                    <h5 className="details-title">Centers</h5>
                </div>
                <div className="figures-container details-logo">
                    <img src={logo} alt="logo" className='details-logo-img' />
                </div>
                <div className="figures-container">
                    <span className="details-span">
                        <Counter value={30} /> + 
                    </span>
                    <h5 className="details-title">Best Coaches</h5>
                </div>
                <div className="figures-container">
                    <span className="details-span">
                        <Counter value={100} /> + 
                    </span>
                    <h5 className="details-title">Appreciations</h5>
                </div>

            </div>
            <div className="marquee-container">
                <div className="marquee">
                    PERFORMANCE AND FITNESS CENTER . EXERCISE TESTING . EXERCISE CONSULTATION . SPORTS AND EXERCISE SCIENCE . . CUSTOMIZE WORKOUT PLANS . STRENGTH AND CONDITIONING .
                </div>
            </div>
        </div>
    </div>
  )
}

export default Details