import React, {useState, useEffect} from 'react'
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import ShowPlanModal from "./ShowPlanModal";
import "../../css/DashUser.css"
import AcUnitIcon from '@mui/icons-material/AcUnit';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import {motion} from "framer-motion"
import useUserStore from '../../../store';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Document1 from './Document1';
import DeleteIcon  from '@mui/icons-material/Delete';
import DeleteModal from '../../components/Modal/DeleteModal';

const CurrentPlans = ({userId, userName}) => {
  const [plan, setPlan] = useState("")
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [modalStates, setModalStates] = useState([]);
  const user = useUserStore((state)=> state.user);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [transfer, setTransfer]= useState({
    transferCharges: "2500",
    transferTo: "",
  });
  const [freezed, setFreezed] = useState({
    freezeCharges: "1250",
    startDate: "",
    endDate: "",
    freezeTime: ""
  });
  const [terminate, setTerminate] = useState({
      terminateReason: "",
  });

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await instance.get(`/api/user/getdata/${userId}`);
        if (response.data.success) {
          setUserData(response.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchUserData();
  }, [userId]);
    
  useEffect(() => {
    handlePlans();
    //  eslint-disable-next-line
  },[]);

  const toggleShowPlanModal = (index) => {
    const newModalStates = [...modalStates];
    newModalStates[index] = !newModalStates[index];
    setModalStates(newModalStates);
  };

  const closeModal = () => {
    setDeleteModal(false);
  }

  const handlePlans = async () => {
    try {
      const response = await instance.get("/api/user/getIndividualPlans", {
        params: {
          userId,
        }
      })
      if(response.data.success){
        setUserDetails(response.data.data1.mobile)
        setPlan(response.data.data.plans)
        setModalStates(new Array(response.data.data.plans.length).fill(false));
      }
    } catch(error) {
      toast.error("An Error Occurred. Please Try Again Later!")
      console.log("Current Plans: ", error.message);
    }
  } 

  const transferPlan = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      const response = await instance.post("/api/user/transferPlan", {
        userId,
        transferredTo: transfer.transferTo,
        transferredFrom: userDetails,
        transferCharges: transfer.transferCharges,
        planId: selectedPlanId,
        transferredBy: user._id,
      });
      if(response.data.success){
        setLoading(false);
        setShowModal(false)
        toast.success(response.data.message);
      }else {        
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An Error Occurred. Please Try Again Later!");
    }
  }

const setData = (e) => {
  const { name, value } = e.target;
  if(name === "terminateReason") {
    setTerminate((preVal) =>{
      return {
        ...preVal,
        [name]: value
      }
    })
  };
  let updatedFreezed = {
    ...freezed,
    [name]: value,
  };
  if ((name === "freezeTime" || name === "startDate") && updatedFreezed.startDate) { // Check for both conditions
    const startDate = new Date(updatedFreezed.startDate);
    let endDate;

    if (updatedFreezed.freezeTime === "30 Days") {
      endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    } else if (updatedFreezed.freezeTime === "60 Days") {
      endDate = new Date(startDate.getTime() + 60 * 24 * 60 * 60 * 1000);
    }

    updatedFreezed = {
      ...updatedFreezed,
      endDate: endDate?.toISOString().split("T")[0],
    };
  }
  
  setFreezed(updatedFreezed);
};

const setData1 = (e) =>{
  const {name, value} = e.target;
  setTransfer((preVal) =>{
    return {
      ...preVal,
      [name]: value,
    }
  }) 
};
  const toggleShowModal1 = (planId) => {
  setSelectedPlanId(planId);
  setShowModal1(true);
};

const toggleShowModal = (planId) => {
  setSelectedPlanId(planId);
  setShowModal(true);
};

  const handleFreeze = async(e) =>{
    e.preventDefault();
    setLoading(true);    
    try{
      const response = await instance.post("/api/user/freeze", {
          freezeTime: freezed.freezeTime,
          freezeStartDate: freezed.startDate,
          freezeEndDate: freezed.endDate,
          freezeCharges: freezed.freezeCharges,
          freezedBy: user._id,
          planId: selectedPlanId, 
          userId,
      })
      if(response.data.success){
        setLoading(false);
        setShowModal1(false);
        toast.success(response.data.message);
      }else {
        setLoading(false);
        toast.error(response.data.message);
      }
    }catch(error){
      setLoading(false);
      toast.error("Something went Wrong, Please Try Again.");
    }
  }

  const toggleShowModal2 = (planId) => {
    setSelectedPlanId(planId);
    setShowModal2(true);
  };
  
  const handleTerminate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await instance.post("/api/user/terminate", {
        terminateReason: terminate.terminateReason, 
        planId: selectedPlanId, 
        userId,
  })
    if(response.data.success){
      setLoading(false);
      setShowModal2(false);
      toast.success(response.data.message);
    }else {
      setLoading(false);
      toast.error(response.data.message);
    }
    } catch (error) {
      toast.error("An Error Ocurred")
    }
  }


  const handleDelete = async (planId) => {
    setUserIdToDelete(planId);
    setDeleteModal(true);
  };


  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deletePlan/${userIdToDelete}`, {
        userId: userId,
      })
      if (response.data.success) {
        toast.success(response.data.message);
        handlePlans();
        setDeleteModal(false)
      } else {  
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("delete plans error: ", error.message);
      toast.error(error.message);
    }
  }


  const getStatusClass = (status) => {
    switch (status) {
        case "Active":
            return "status-active";
        case "Expired":
            return "status-expired";
        case "Transferred":
            return "status-transferred";
        case "Freezed":
            return "status-freezed";
        case "Upcoming":
            return "status-upcoming";
        case "Terminated":
            return "status-terminated";
        case "Upgraded":
            return "status-upgrade";
        default:
            return "";
    }
};

  return (
    <div className='currentPlans'>
        {(!plan || plan.length === 0) &&  (
          <div className="currentPlans-container">
              <h4 className="currentPlan-key">
                Currently No Plan to display! Buy now 
              </h4>
          </div>
        )}
        {plan && plan.length > 0 && plan.slice().reverse().map((text, index, array) => (          
          <div className="currentPlans-container" key={index}>
            <h4 className="currentPlans-h4"> ({array.length - index})   {text.status} Plan <div className={`color-changer ${getStatusClass(text.status)}`} > </div> </h4>
            {/* <EditIcon className="edit-mui-icon editIcon" onClick={() => toggleShowPlanModal(index)} /> */}
            {['Admin', 'Regional Manager', 'Branch Manager'].includes(user.role) && (
              <DeleteIcon className="edit-mui-icon editIcon" onClick={() => handleDelete(text.individualPlanId)} />
            )}
            {modalStates[index] && (
              <ShowPlanModal
                planId={text.individualPlanId}
                userId={userId}
                closeModal={() => toggleShowPlanModal(index)}
              />
            )}
            <div className="currentPlan-details">
            {text.isFreezed === true ? <>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Freezed From</h4>
                  <h5 className="currentPlan-value">{new Date(text.freezeStartDate).toLocaleDateString("en-GB")}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Freezed To</h4>
                  <h5 className="currentPlan-value">{new Date(text.freezeEndDate).toLocaleDateString("en-GB")}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Freezed Charges</h4>
                  <h5 className="currentPlan-value">{text.freezeCharges}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Freezed By</h4>
                  <h5 className="currentPlan-value">{text.freezedBy}</h5>
              </div>
            </> : ""}
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Performance Center</h4>
                  <h5 className="currentPlan-value">{text.gymLocation}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Plan Name</h4>
                  <h5 className="currentPlan-value">{text.planType}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Batch Timing</h4>
                  <h5 className="currentPlan-value">{text.batchTiming}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Plan Duration</h4>
                  <h5 className="currentPlan-value">{text.planTime}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Start Date</h4>
                  <h5 className="currentPlan-value">{new Date(text.startDate).toLocaleDateString("en-GB")}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">End Date</h4>
                  <h5 className="currentPlan-value">{new Date(text.endDate).toLocaleDateString("en-GB")}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Purchase Date</h4>
                  <h5 className="currentPlan-value">{(new Date(text.purchaseDate).toLocaleDateString("en-GB"))}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Days Remaining</h4>
                  <h5 className="currentPlan-value">{text.daysLeft < 0 ? "0" : text.daysLeft}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Trainer Assigned</h4>
                  <h5 className="currentPlan-value">{text.trainerAssigned || "N/A"}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Plan Price</h4>
                  <h5 className="currentPlan-value">₹{text.price}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Applied GST</h4>
                  <h5 className="currentPlan-value">{text.gst === "No"  ? "No"  : text.gst === "1.18"  ? "18%"  : text.gst === "1.05"  ? "5%"  : "No"}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">GST Price</h4>
                  <h5 className="currentPlan-value">₹{text.gstAmount}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Payment Mode</h4>
                  <h5 className="currentPlan-value">{text.paymentMode}</h5>
              </div>
              {text.paymentMode === "Card" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Card Type</h4>
                    <h5 className="currentPlan-value">{text.cardDetails?.cardType}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Bank Name</h4>
                    <h5 className="currentPlan-value">{text.cardDetails?.bankName}</h5>
                  </div>
                  <div className="currentDetails-container">
                   <h4 className="currentPlan-key">Card Number</h4>
                   {text.cardDetails?.last4Digits.length > 0 && (
                   <h5 className="currentPlan-value">XXXX-XXXX-XXXX-{text.cardDetails?.last4Digits}</h5>
                   )}
                 </div>
                </>
              )}
              {text.paymentMode === "UPI" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">UPI Detail</h4>
                    <h5 className="currentPlan-value">{text.upiDetails?.upiId}</h5>
                  </div>
                </>
              )}
              {text.paymentMode === "Cheque" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Cheque Number</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.chequeNumber}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Cheque Date</h4>
                    <h5 className="currentPlan-value">{new Date(text.chequeDetails?.chequeDate).toLocaleDateString("en-GB")}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Bank Name</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.bankName}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Branch Name</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.branchName}</h5>
                  </div>
                </>
              )}

              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Billing Done By</h4>
                  <h5 className="currentPlan-value">{text.createdBy}</h5>
              </div>
              {text.status === "Terminated" ? <>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Terminated By</h4>
                  <h5 className="currentPlan-value">{text.createdBy}</h5>
              </div></> : ""}

              {text.promoCode.length !== 0 && (
                <>
                  <div className="currentDetails-container">
                      <h4 className="currentPlan-key">Discount Applied </h4>
                      <h5 className="currentPlan-value">Yes</h5>
                    </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Discount Code</h4>
                    <h5 className="currentPlan-value">{text.promoCode}</h5>
                  </div>
                </>
              )}

            </div>
            <div className="additional-buttons addComment-btn">
              { user.role !== "User" && (
                <>                
                  {text.planType.includes("General Fitness Plan") && text.planTime.includes("12") ?  
                    <span className="dash-btn" onClick={() => toggleShowModal(text.individualPlanId)} > <TransferWithinAStationIcon /> Transfer </span> : ""}
      
                    {text.isFreezed === true ? "" : <>
                  {text.planType.includes("General Fitness Plan") && text.planTime.includes("12") ?  
                    <span className="dash-btn" onClick={() => toggleShowModal1(text.individualPlanId)} > <AcUnitIcon /> Freeze </span> : ""}</>}

                  {/* terminate plan button */}
                  {text.isTerminated === true ? "" : <>
                  <span className="dash-btn" onClick={() => toggleShowModal2(text.individualPlanId)}> <PersonRemoveIcon/> Terminate </span></>}
                </>                
              )}

              {/* download plan button */}
              <PDFDownloadLink className="dash-btn Link"  document={<Document1 planDetails={text} userData={userData}/>} fileName={`${userName} Bill`}>
                  <ReceiptIcon/> Document
              </PDFDownloadLink>
            </div>
          </div>
        ))}
        {showModal && (
        <div className="modal1">
          <motion.div
            className="modal-content1"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h4 className="delete-para">
              Transfer Plan
            </h4>
            <CloseIcon className="review-close-icon" onClick={() => setShowModal(false)} />
            <form className="showPlan-form" onSubmit={transferPlan}>
              <div className="showPlan-form-input">
                <label htmlFor="">Transfer To</label>
                <input type="text" name="transferTo" maxLength={10} pattern="[0-9]*" className="showPlan-input-field" placeholder='Enter Mobile Number' value={transfer.transferTo} onChange={setData1} />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="">Transfer From</label>
                <input type="text" name="transferFrom" className="showPlan-input-field" value={userDetails} disabled />
              </div>
              <div className="showPlan-form-input">
                <label htmlFor="">Transfer Charges</label>
                <input type="text" name="transferCharges" className="showPlan-input-field" value={transfer.transferCharges} onChange={setData1} />
              </div>  
              <button className={`dash-btn showPlan-btn ${loading ? 'upload-btn' : ''}`}>
                  {loading ? <AutorenewIcon className="loader-css" /> : "Transfer"}
             </button>  
            </form>
          </motion.div>
        </div>
      )}
       {showModal1 && (
        <div className="modal1">
          <motion.div
            className="modal-content1"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
        <h4 className="tnc-h4">Freeze Plan</h4>
        <CloseIcon className="review-close-icon" onClick={() => setShowModal1(false)} />

        <form className='showPlan-form' onSubmit={handleFreeze}>
            <div className="showPlan-form-input">
              <label htmlFor="">Freeze Time</label>
              <select name="freezeTime" className='showPlan-input-field' onChange={setData} required value={freezed.freezeTime}>
                <option value="">Select Freeze Time</option>
                <option value="30 Days">30 Days</option>
                <option value="60 Days">60 Days</option>
              </select>
            </div>
            <div className="showPlan-form-input" >
              <label htmlFor="startDate">Start Date</label>
              <input type="date" className='showPlan-input-field' name='startDate' min={freezed.startDate ? undefined : new Date().toISOString().split("T")[0]} onChange={setData} required value={freezed.startDate}  />
          </div>
          <div className="showPlan-form-input" >
              <label htmlFor="endDate">End Date</label>
              <input type="date" className='showPlan-input-field' name='endDate' onChange={setData} required value={freezed.endDate}  disabled />
          </div>
          <div className="showPlan-form-input" >
              <label htmlFor="freezeCharges">Freeze Charges</label>
              <input type="text" className='showPlan-input-field' name='freezeCharges' onChange={setData} value={freezed.freezeCharges} disabled/>
          </div>
          <button className={`dash-btn showPlan-btn ${loading ? 'upload-btn' : ''}`}>
              {loading ? <AutorenewIcon className="loader-css" /> : "Freeze"}
          </button>
        </form>
          </motion.div>
        </div>
      )}
      {showModal2 && (
        <motion.div className="modal1"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <div className="modal-content1">
          <h4 className="tnc-h4">Terminate Plan</h4>
        <CloseIcon className="review-close-icon" onClick={() => setShowModal2(false)} />

        <form className='showPlan-form' onSubmit={handleTerminate}>
          <div className="showPlan-form-input" >
              <label htmlFor="terminateReason">Terminate Reason</label>
              <input type="text" className='showPlan-input-field' required name='terminateReason' onChange={setData} value={terminate.terminateReason}/>
          </div>
          <button className={`dash-btn showPlan-btn ${loading ? 'upload-btn' : ''}`}>
              {loading ? <AutorenewIcon className="loader-css" /> : "Terminate"}
          </button>
        </form>
          </div>
        </motion.div>
      )}
       {deleteModal && (
        <DeleteModal 
            h4={"Are you sure you want to delete this plan?"}
            confirmDelete={confirmDelete}
            closeModal={closeModal}
        />
      )}

    </div>
  )
}

export default CurrentPlans