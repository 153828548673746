import React, { useState } from 'react';
import { motion } from "framer-motion"
import DashWidgets from './DashWidgets';
import "../../css/DashAnalysis.css"
import Collection from './Collection';

const Analysis = () => {
  const [gst, setGst] = useState(0);
  const [total, setTotal] = useState(0);

  return (
    <>
      <div className='analysis'>
        <motion.div className="widgets"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}

        >
          <DashWidgets 
            gst={gst} total={total}     
          />
        </motion.div>
        <motion.div className="charts"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <Collection 
            setGst={setGst} setTotal={setTotal}
          />
        </motion.div>
      </div>

    </>
  )
}

export default Analysis;