import React, { useEffect, useState } from 'react';
import "../../css/DashChatbot.css"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import instance from '../../../utils/axios';
import useUserStore from '../../../store';
import toast from 'react-hot-toast';

const Chatbot = ({ toggleChatbot, isChatbotOpen }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [allFaq, setAllFaq] = useState([]);
  const user = useUserStore((state) => state.user)

  const [ message, setMessage ] = useState("");

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchFaq = async () => {
    try {
      const response = await instance.get("/api/user/getFaqData")
      if (response.data.success) {
        setAllFaq(response.data.data);
      } else {
        toast.error("An Error Occurred while fetching data!");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchFaq();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!message) { 
      return toast.error("Please type the message you want to ask!")
    }
    try {
      const response = await instance.post("/api/user/createUserGroup", {
        userId: user._id, 
        message: message,
        username: user.username,
        gymLocation: user.gymLocation
      });
      if (response.data.success) {
        toast.success(response.data.message);
        setMessage("");
      } else { 
        toast.error(response.data.message)
      }
    } catch (error) {
      console.log("creating chatroom from faq error: ", error);
      toast.error(error.message);
    }
  }

  return (
    <div className={`faq-card ${isChatbotOpen ? 'open' : 'closed'}`} >
      <div className="faq-header">
        <h4 className="faq-h4">Frequently Asked Questions</h4>
        <CloseIcon onClick={toggleChatbot} className="faq-close-icon" />
      </div>
      {allFaq || allFaq.length > 0 ? (
        <>
        <div className="faq-section">
              {allFaq && allFaq.map((product, index) => (
                <div key={index} className={`faqMain ${activeIndex === index ? 'active' : ''}`}>
                  <div className="question-box" onClick={() => handleAccordionClick(index)}>
                    <div className="question-main">
                      <h3 className="question-h3">{index + 1}.</h3>
                      <h3 className="question">{product.question}</h3>
                    </div>
                    <AddIcon className="i" />
                  </div>
                  <div className="answer-box">
                    <p className="answer">{product.answer}</p>
                  </div>
                </div>
              ))}
        </div>
        </>
      ) : (
        <div className="faq-no-section">
          <h3 className="no-faqs">No Faqs Right Now!</h3>
          <p className="talk-faq">Contact our Team Now by dropping a message</p>
        </div>
      )}
      <form onSubmit={handleSubmit} id="input-section">
        <input
          id="input"
          type="text"
          placeholder="Talk to Us!!!"
          autoComplete="off"
          autoFocus="autofocus"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type='submit' className="send">
          <SendIcon className="send-icon"/>
        </button>
      </form>
    </div>
  );
}

export default Chatbot;
