import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import instance from '../../../utils/axios';
import "../../css/Dashprescription.css"

const UserExercises = () => {

  const { prescriptionId } = useParams();
  const [ name, setName ] = useState([]);

  const getData = async () => { 
    try { 
      const response = await instance.get(`/api/user/getUserExercisesByWeek/${prescriptionId}`);
      if ( response.data.success ){ 
        setName(response.data.data)
      } else { 
        toast.error(response.data.message)
      }
    } catch ( error ) { 
      console.log("get exercise by weeks error: ", error.message);
      toast.error(error.message)
    }
  }

  useEffect(() => {  
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className='dashMembers'>
      <div className='dashMembers-container' >
        <span className="dashboard-title">
          { name.clientName}'s  Exercise Prescription 
        </span>
      </div>
      <div className='dash-user'>
        <div className="single-exercise-week-lists">
          { name.weeks?.map((week) => (
            <Link to={`/dashboard/exercises/days/${week._id}`} className="single-exercise-week-list " key={week._id} > 
              <span> Week { week.weekNumber } </span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserExercises