import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import useUserStore from '../../../store';
import instance from '../../../utils/axios';
import DeleteModal from '../../components/Modal/DeleteModal';
import {defaultUrl} from "../../../data/Images"

const VisitorsData = () => {
  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [select, setSelect] = useState('');
  const user = useUserStore((state) => state.user)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const closeModal = () => {
    setShowModal(false);
  }

  const getData = async(currentPage, size) =>{
    try{
      const response = await instance.get('/api/user/getData1', {
        params: {
          userId: user._id,
          page: currentPage,
          pageSize: size,
        }
      })
      if (response.data.success) {
        const rows = response.data.data && response.data.data.slice().reverse().map((user) => {
          return {
            id: user._id,
            col1: user.file?.secure_url || "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000",
            col2: user.uniqueId || "N/A",
            col3: user.name,
            col4: new Date(user.dob).toLocaleDateString("en-GB") || "N/A",
            col5: user.mobile,
            col6: user.email,
            col7: user.username,
            col8: new Date(user.createdAt).toLocaleDateString("en-IN"),
          };
        });
        setUserData(rows);
        setFilteredData(rows);
      }else {
        toast.error("No Data Found");
      }
    }catch(error){
      toast.error("An Error Occurred. Please Try Again!");
      console.log(error.message);
    }
  }
  useEffect(() =>{
    getData();
  // eslint-disable-next-line
  },[])

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteUser/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("User deleted successfully");
        setUserData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
        setShowModal(false);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  };

  const columns = [
    {
      field: 'col1',headerName: 'Image',width: 70,
      renderCell: (params) => (
        <img
          src={params.value === "" || params.value.length === 0 ? defaultUrl : params.value}
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          alt=""
        />
      ),
    },
    { field: 'col2', headerName: 'Unique ID', width: 90 },
    {
      field: 'col3', 
      headerName: `Visitor's Name`,
      width: 170,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Birth Date', width: 120 },
    { field: 'col5', headerName: 'Mobile', width: 120 },
    { field: 'col6', headerName: 'Email', width: 250 },
    { field: 'col7', headerName: 'Username', width: 150 },
    { field: 'col8', headerName: 'Registered On', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <div className="cellAction">
          <Link to={`/dashboard/users/userPage/${params.row.id}`} style={{ textDecoration: "none" }}>
            <div className="viewButton">View</div>
          </Link>
          {["Admin"].includes(user.role) ? <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div> : ""}
        </div>
      ),
    },
  ];

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
    getData(params.page, params.pageSize); // Fetch data with updated pageSize
  };

  const data = {
    rows: filteredData,
    columns: columns,
  };

  useEffect(()=>{
    getData();
    // eslint-disable-next-line 
  },[page, pageSize])


  const handleFilter = () => {
    if (!startDate || !endDate || !select) {
      toast.error("Please select all the field.");
      return;
    }
    const formattedStartDate = startDate ? new Date(startDate) : null;
    if (formattedStartDate) {
      // Set hours, minutes, seconds, and milliseconds to zero
      formattedStartDate.setHours(0, 0, 0, 0);
    }
    const formattedEndDate = endDate ? new Date(endDate) : null;
    if (formattedEndDate) {
      // Set hours, minutes, seconds, and milliseconds to zero
      formattedEndDate.setHours(0, 0, 0, 0);
    }
    const selectedType = select.toLowerCase();
    const filteredData = userData.filter((user) => {
      if(selectedType.includes("startdate")) {
        const newDate = parseDate(user.col7);
        const startDateCondition = ((newDate >= formattedStartDate)||(newDate === formattedStartDate)) && 
        ((newDate <= formattedEndDate)||(newDate === formattedEndDate));
        return startDateCondition;
      }
      return true;
    });
    if (filteredData === null || filteredData.length === 0) {
      toast.error("No Members Found");
    }
    setFilteredData(filteredData);
  };

  const parseDate = (dateStr) => {
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // January is month 0 in JavaScript Date object
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day,0,0,0,0);
    }
    return null;
  };


  return (
    <div className='data2Table'>
      <div className="filter-container">
        <input
          type="date"
          value={startDate || ""}
          className='filter-input'
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate || ""}
          className='filter-input'
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select className='filter-select' value={select} onChange={(e) => setSelect(e.target.value)}>
          <option value="">Select Filter Type</option>
          <option value="StartDate">Login Date</option>
        </select>
        <button className='comment-btn dash-btn' onClick={handleFilter}>Filter</button>
      </div>

      <motion.div className='dataGrid'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <DataGrid
          {...data}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          onPageSizeChange={handlePageChange}        
        />
      </motion.div>
      {showModal && (
        <DeleteModal 
            h4="Are you sure you want to delete this visitor?"
            confirmDelete={confirmDelete}
            closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default VisitorsData;
