import React, { useEffect, useState, useMemo } from "react";
import instance from "../../../utils/axios";
import useUserStore from "../../../store";
import Widget from "./Widget";

const DashWidgets = ({ gst, total }) => {
  const user = useUserStore((state) => state.user);
  const [membersData, setMembersData] = useState(null);
  const [orders, setOrders] = useState(null);
  const [employeesData, setEmployeesData] = useState(null);
  const [events, setEvents] = useState(null);
  const [products, setProducts] = useState(null);

  const formatIndianNumber = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-IN").format(number);
    return formattedNumber;
  };

  const totalMembers = useMemo(() => {
    return (
      (membersData?.data.onlineMembers.length || 0) +
      (membersData?.data.offlineMembers.length || 0)
    );
  }, [membersData]);

  const totalOrders = useMemo(() => {
    return (
      (orders?.data || 0) 
    );
  }, [orders]);


  const totalEmployees = useMemo(() => {
    return employeesData?.data.length || 0;
  }, [employeesData]);

  const totalEvents = useMemo(() => {
    return events?.data.length || 0;
  }, [events]);

  const totalProducts = useMemo(() => {
    return products?.data.length || 0;
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [membersRes, employeesRes,  orderRes, eventsRes, productsRes] =
          await Promise.all([
            instance.get("/api/user/getdata", {
              params: {
                userId: user._id,
              },
            }),
            instance.get("/api/user/getEmployee", {
              params: {
                userId: user._id,
              },
            }),
            instance.get("/api/user/getAllOrdersForAnalysis"),
            instance.get("/api/user/getProgramsData", {
              params: {
                location: "DashBoard",
                userId: user._id,
              },
            }),
            instance.get("/api/user/getProductData", {
              params: {
                location: "DashBoard",
                userId: user._id,
              },
            }),
          ]);

        if (membersRes.data.success) {
          setMembersData(membersRes.data);
        }

        if (employeesRes.data.success) {
          setEmployeesData(employeesRes.data);
        }

        if (orderRes.data.success) {
          setOrders(orderRes.data);
        }

        if (eventsRes.data.success) {
          setEvents(eventsRes.data);
        }

        if (productsRes.data.success) {
          setProducts(productsRes.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user._id]);

  return (
    <>
      <div className="widget-container">
        <Widget
          title="Counsellors"
          counter={totalEmployees}
          linkTo="/dashboard/employees"
          icon="person"
        />
        <Widget
          title="Members"
          counter={totalMembers}
          linkTo="/dashboard/members"
          icon="person"
        />
        <Widget
          title="Orders"
          counter={totalOrders}
          linkTo="/dashboard/admin"
          icon="shoppingCart"
        />
        <Widget
          title="Earnings"
          counter={`₹${formatIndianNumber(total)}`}
          linkTo="/dashboard/admin"
          icon="monetization"
        />
        <Widget
          title="Gst"
          counter={`₹${formatIndianNumber(gst)}`}
          linkTo="/dashboard/admin"
          icon="monetization"
        />
        <Widget
          title="Events"
          counter={totalEvents}
          linkTo="/dashboard/programs"
          icon="monetization"
        />
        <Widget
          title="Merchandise"
          counter={totalProducts}
          linkTo="/dashboard/products"
          icon="monetization"
        />
      </div>
    </>
  );
};

export default DashWidgets;
