import React, { useRef, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import '../../css/DashEvents.css'
import useUserStore from "../../../store";
import { toast } from 'react-hot-toast';
import instance from "../../../utils/axios";
import CloseIcon from '@mui/icons-material/Close';
import { motion } from "framer-motion";
import Loader from "../../../components/MainLoader/Loader";

const DashCalendar = () => {
  const user = useUserStore((state) => state.user);
  const calendarRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [eventCounter, setEventCounter] = useState(0);
  const userRole = user.role;
  const [eventCreated, setEventCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState("");
  let startDate;
  let endDate;
  const options = [
    { id: 1, value: "MWF", days: [1, 3, 5] }, 
    { id: 2, value: "TTS", days: [2, 4,6] },  
    { id: 3, value: 'Single Day' }, 
  ];
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getDates = async () =>{
    try{
      const response = await instance.get("/api/user/getPlanDates",{
        params: {
          userId: user._id,
        },
      })
      if(response.data.success){
        setDates(response.data.data);
      }else {
        toast.error(response.data.message)
      }
    }catch (error) {
      toast.error("An Error Occurred");
    }
  }

  const fetchTaskData = async () => {
    try {
      const response = await instance.get("/api/user/getTrainerNames", {
        params: {
          userId: user._id,
        },
      });
      if (response.data.success) {
        setUsernames(response.data.data);
      }else{
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateClick = (event) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const selectedDate = new Date(event.dateStr);

    // if (selectedDate < currentDate) {
    //   toast.error("Cannot add an event with a past date.");
    //   return;
    // }
    
    // Check if the user is allowed to create more events
    if (userRole === "User" && eventCounter >= 12) {
      toast.error("You have reached the maximum number of allowed events.");
      return;
    }
    startDate.setHours(0,0,0,0);
    endDate.setHours(0,0,0,0);
    if ((selectedDate < startDate || selectedDate > endDate) && userRole !== "Admin") {
      toast.error("Cannot add an event before the start date or after the end date.");
      return;
    }
    // Check if the selected date follows the alternate pattern
    const diffDays = Math.ceil(
      Math.abs(selectedDate.getTime() - startDate.getTime()) /(1000 * 60 * 60 * 24)
    );
    if (selectedDate.getDay() === 0 && userRole === "User") {
      toast.error("Cannot add an event on Sundays.");
      return;
    };
    
    if (eventCreated && userRole === "User") {
      if (diffDays % 2 === 0) {
        // Skip Sunday and continue the pattern
        const nextDay = new Date(selectedDate.getTime() + (24 * 60 * 60 * 1000));
        if (nextDay.getDay() === 0) {
          const blockedDate = new Date(nextDay.getTime() + (24 * 60 * 60 * 1000));
          if (blockedDate.getDay() === 1) {
            toast.error("Cannot add an event on Monday.");
            return;
          }
        }
      } else {
        toast.error("Cannot add an event on this date.");
        return;
      }
    }

    setIsModalOpen(true);
    setCurrentEvent({
      ...currentEvent,
      startdate: selectedDate.toISOString().slice(0, 10),
      starttime: '',
      enddate: selectedDate.toISOString().slice(0, 10), // Set the end date to the same as the start date
      endtime: '',
      color: '',
      task: '',
      username: '',
      trainer:'',
      isEdit: false,
    });
};

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    try{
      const response = await instance.get("/api/user/getAllCalendarData", {
        params: {
          userId: user._id,
        },
      })
        if (response.data.success) {
          setEventCounter(((response.data.data2 && response.data.data2.length) || (response.data.data && response.data.data.length)));
          const fetchedEvents = response.data.data && response.data.data.map((event) => ({
            id: event._id,
            title: event.task,
            start: event.startdate.split("T")[0]+ 'T' + event.starttime, // Separate startdate property
            end: event.enddate.split("T")[0]+ 'T' + event.endtime,
            backgroundColor: event.color,
            extendedProps: {
              starttime: event.starttime,
              endtime: event.endtime,
              task: event.task,
              username: event.username,
              trainer: event.trainer,
            },
          }));
          setEvents(fetchedEvents);
        } else {
          toast.error(response.data.message);
        }
      }  catch(error) {
        toast.error("An Error Occurred. Please Try Again!");
      }
    }

    useEffect(() =>{
      fetchData();
      if(userRole === 'User') {
        getDates();
      };
      fetchTaskData();
      // eslint-disable-next-line
    },[user._id])
    
    if(userRole === "User" && dates !== ""){
      startDate = new Date(dates[0].startDate);
      endDate = new Date(dates[0].endDate);
    }else {
      startDate = new Date(user.start);
      endDate = new Date(user.endDate);
    }

  const handleEventSubmit = async (event) => {
    event.preventDefault();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
  
    const selectedStartDate = new Date(currentEvent.startdate);
    selectedStartDate.setHours(0, 0, 0, 0);
  
    const selectedEndDate = new Date(currentEvent.enddate);
    selectedEndDate.setHours(0, 0, 0, 0);
  
    if (selectedStartDate < currentDate || selectedEndDate < currentDate) {
      toast.error("Cannot add an event with a past date.");
      return;
    };
    if(currentEvent.starttime >= currentEvent.endtime){
      toast.error("The endTiming cannot be less then startTimming");
      return;
    };
    if(currentEvent.startDate < startDate || currentEvent.endDate > endDate){
      toast.error("Cannot add a event outside the plans start or end date");
      return;
    };
    const startTime = currentEvent.starttime; // Assuming startTime is in the format "HH:mm"
    const endTime = currentEvent.endtime; // Assuming endTime is in the format "HH:mm"

    // Convert start time to minutes
    const startParts = startTime.split(':');
    const startHours = parseInt(startParts[0], 10);
    const startMinutes = parseInt(startParts[1], 10);
    const startTotalMinutes = startHours * 60 + startMinutes;
    // Convert end time to minutes
    const endParts = endTime.split(':');
    const endHours = parseInt(endParts[0], 10);
    const endMinutes = parseInt(endParts[1], 10);
    const endTotalMinutes = endHours * 60 + endMinutes;
    // Perform the subtraction
    const diffMinutes = endTotalMinutes - startTotalMinutes;
    if (diffMinutes !== 60 && userRole === "User") {
      toast.error("The difference between start time and end time should be exactly 1 hour.");
      return;
    }

     // Calculate the difference between startDate and endDate in days
      const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

      // Determine the maximum number of events based on the difference in days
      let maxEvents = 0;
      if (diffInDays <= 40) {
        maxEvents = 12;
      } else if (diffInDays <= 100) {
        maxEvents = 36;
      } else if (diffInDays <= 190) {
        maxEvents = 72;
      } else if (diffInDays <= 375) {
        maxEvents = 144;
      }

      // Check the number of events created within the time frame
      const eventsWithinTimeframe = events.filter((event) => {
        const eventStartDate = new Date(event.start);
        return (
          eventStartDate >= startDate &&
          eventStartDate <= endDate &&
          (event.user === user._id || userRole === 'Admin')
        );
      });

      // Check the number of events created within the past 30 days
      const eventsWithinPast30Days = events.filter((event) => {
        const eventStartDate = new Date(event.start);
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        return (
          eventStartDate >= thirtyDaysAgo &&
          eventStartDate <= today &&
          (event.user === user._id || userRole === 'admin')
        );
      });

      // Check if the maximum number of events has been reached
      if (eventsWithinTimeframe.length >= maxEvents && userRole === "User") {
        toast.error('Maximum number of events reached for the selected timeframe.');
        return;
      }

      // Check if the maximum number of events within the past 30 days has been reached
      if (eventsWithinPast30Days.length >= 12 && userRole === "User") {
        toast.error('Maximum number of events reached within the past 30 days.');
        return;
      }
    setLoading(true);
    // Event Creation
    const apiUrl = currentEvent.isEdit ? `/api/user/calendarData/${currentEvent.id}` : '/api/user/calendarData';
    if (selectedOption === "MWF" || selectedOption === "TTS") {
      try {
        const startDate = new Date(currentEvent.startdate);
        const endDate = new Date(currentEvent.enddate);

        // Calculate the number of days between start date and end date
        const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

        // Determine the selected option object
        const selectedOptionObj = options.find((option) => option.value === selectedOption);
        const selectedDays = selectedOptionObj.days;

        const createdEvents = [];

        // for (let i = 0; i <= totalDays && createdEvents.length < 12; i++) {
        for (let i = 0; i <= totalDays && createdEvents.length < maxEvents; i++) {
          const currentDate = new Date(startDate);
          currentDate.setDate(currentDate.getDate() + i);

          // Check if the current day is in the selected days array
          if (selectedDays.includes(currentDate.getDay())) {
            const newEvent = {
              startdate: currentDate,
              starttime: currentEvent.starttime,
              enddate: currentDate,
              endtime: currentEvent.endtime,
              task: currentEvent.task,
              trainer: currentEvent.trainer,
              username: currentEvent.isEdit ? currentEvent.username : user.username,
              color: currentEvent.color,
            };

            // Make API call to create the event
            const response = await instance[currentEvent.isEdit ? 'put' : 'post'](apiUrl, newEvent);

            if (response.data.success) {
              const eventId = currentEvent.isEdit ? currentEvent.id : response.data.data._id; // Retrieve the event ID
              const response1 = await instance.post("/api/user/notification", {
                recipient: response.data.data.trainer,
                url: user.file.secure_url || null,
                message: `${response.data.data.task}`,
                name: `${response.data.data.username}`,
                location: "Calendar",
              });
              if(response1.data.success){
                toast.success("Notification Send SuccessFully");
              }else{
                toast.error(response1.data.message);
              }
              if(!currentEvent.isEdit){
                setEventCreated(true);
                setEventCounter((prevCounter) => prevCounter + 1);
              }
              const updatedEvent = {
                id: eventId,
                title: currentEvent.task,
                start: currentEvent.startdate + 'T' + currentEvent.starttime,
                end: currentEvent.enddate + 'T' + currentEvent.endtime,
                backgroundColor: currentEvent.color,
                extendedProps: {
                  starttime: currentEvent.starttime,
                  endtime: currentEvent.endtime,
                  task: currentEvent.task,
                  username: currentEvent.username,
                  trainer: currentEvent.trainer,
                },
              };

              createdEvents.push(updatedEvent);
              // if (createdEvents.length === 12) {
                //   break; // Exit the loop once 12 events are created
                // }
              if (createdEvents.length === maxEvents) {
                toast.success(currentEvent.isEdit ? "Event updated successfully" : "Event added successfully");
                break; // Exit the loop once the maximum number of events is reached
              }
            } else {
              toast.error(response.data.message);
            }
          }
        }
        setLoading(false);
        // Update the events state with the created events
        setEvents((prevEvents) => {
          const updatedEvents = currentEvent.isEdit
            ? prevEvents.map((event) => (event.id === currentEvent.id ? createdEvents[0] : event))
            : [...prevEvents, ...createdEvents];

            return updatedEvents;
        });

        setIsModalOpen(false);
        setCurrentEvent(null);
        fetchData();
      } catch (error) {
        toast.error(error.message);
      }
    }else {
      try {
        const response = await instance[currentEvent.isEdit ? 'put' : 'post'](apiUrl, {
          startdate: currentEvent.startdate,
          starttime: currentEvent.starttime,
          enddate: currentEvent.enddate,
          endtime: currentEvent.endtime,
          task: currentEvent.task,
          trainer: currentEvent.trainer,
          username: currentEvent.isEdit ? currentEvent.username : user.username,
          color: currentEvent.color,
        });
    
        if (response.data.success) {
          toast.success(currentEvent.isEdit ? "Event updated successfully" : "Event added successfully");
          const eventId = currentEvent.isEdit ? currentEvent.id : response.data.data._id; // Retrieve the event ID
          const response1 = await instance.post("/api/user/notification", {
            recipient: response.data.data.trainer,
            url: user.file.secure_url || null,
            message: `${response.data.data.task}`,
            name: `${user.username}`,
            location: "Calendar",
          });
          if(response1.data.success){
            toast.success("Notification Send SuccessFully");
          }else{
            toast.error(response1.data.message);
          }
          if(!currentEvent.isEdit){
            setEventCreated(true);
            setEventCounter((prevCounter) => prevCounter + 1);
          }
          const updatedEvent = {
            id: eventId,
            title: currentEvent.task,
            start: currentEvent.startdate + 'T' + currentEvent.starttime,
            end: currentEvent.enddate + 'T' + currentEvent.endtime,
            backgroundColor: currentEvent.color,
            extendedProps: {
              starttime: currentEvent.starttime,
              endtime: currentEvent.endtime,
              task: currentEvent.task,
              username: currentEvent.username,
              trainer: currentEvent.trainer,
            },
          };
    
          setEvents((prevEvents) => {
            if (currentEvent.isEdit) {
              return prevEvents.map((event) => (event.id === currentEvent.id ? updatedEvent : event));
            } else {
              return [...prevEvents, updatedEvent];
            }
          });
          setIsModalOpen(false);
          setCurrentEvent(null);
        }else{
          toast.error(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  if (loading) {
    return <Loader />
  }
  

  const handleEventClick = (clickInfo) => {
    setIsModalOpen(true);
    const clickedEvent = clickInfo.event;
  
    // Extract the required event details
    const { id, start, end, backgroundColor } = clickedEvent;
    const { starttime, endtime, task, username, trainer } = clickedEvent.extendedProps;

    // Adjust the start and end dates according to the browser's time zone offset
    const startdate = new Date(start.getTime() - start.getTimezoneOffset() * 60000);
    const enddate = end ? new Date(end.getTime() - end.getTimezoneOffset() * 60000) : startdate;
  
    setCurrentEvent({
      id: id,
      startdate: startdate.toISOString().slice(0, 10),
      starttime,
      enddate: enddate.toISOString().slice(0, 10),
      endtime,
      task,
      color: backgroundColor,
      username,
      trainer,
      isEdit: true,
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "startDate") {
      const date = new Date(value);
      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      updatedValue = utcDate.toISOString().split("T")[0];
    }
    setCurrentEvent((prevEvent) => ({
      ...prevEvent,
      [name]: updatedValue,
      color: name === "color" ? value : prevEvent.color,
    }));
    }

    const handleDeleteClick = async () => {
      try {
        const response = await instance.post(`/api/user/calendarData/${currentEvent.id}`);
        if (response.data.success) {
          setEventCreated(false);
          setEventCounter((prevCounter) => prevCounter - 1);
          toast.success("Task deleted successfully");
          const calendarApi = calendarRef.current.getApi();
          calendarApi.getEventById(currentEvent.id).remove();
          setIsModalOpen(false);
          setCurrentEvent(null);
          setEvents((prevEvents) =>
            prevEvents.filter((event) => event.id !== currentEvent.id)
          );
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

  return (
    <>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          className="full-calendar"
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          headerToolbar={{
            center: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={events}
        />
      {isModalOpen && (
        <motion.div className="modal"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
        >
          <div className="modal-content">
            <CloseIcon className="editor-close icons" onClick={closeModal}/>
            <form onSubmit={handleEventSubmit}>
            <div className="form-group">
                <label htmlFor="startdate">Start Date</label>
                <input
                  type="date"
                  id="startdate"
                  name="startdate"
                  required
                  value={currentEvent.startdate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="starttime">Start Time</label>
                <input
                  type="time"
                  id="starttime"
                  name="starttime"
                  required
                  value={currentEvent.starttime}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="enddate">End Date</label>
                <input
                  type="date"
                  id="enddate"
                  name="enddate"
                  required
                  value={currentEvent.enddate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="endtime">End Time</label>
                <input
                  type="time"
                  id="endtime"
                  name="endtime"
                  required
                  value={currentEvent.endtime}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                 <input
                  type="text"
                  id="username"
                  name="username"
                  readOnly="readOnly"
                  value={currentEvent && (currentEvent.username === "" || currentEvent.username === null) ? user.username : currentEvent.username }
                />
              </div>
              <div className="form-group">
                <label htmlFor="username">Select Training Days</label>
                {options.map((option) => (
                    <div key={option.id}>
                      <input
                        type="radio"
                        id={option.id}
                        name="options"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor={option.id}>{option.value}</label>
                    </div>
                  ))}
              </div>
              <div className="form-group">
                <label htmlFor="trainer">Add Trainer</label>
               <select
                          name="trainer"
                          id="trainer"
                          required 
                          value={currentEvent.trainer}
                          onChange={handleChange}
                        >
                          <option>Select a Trainer</option>
                          {usernames.map((username) => (
                            <option key={username} value={username}>
                              {username}
                            </option>
                          ))}
                        </select>
              </div>
              <div className="form-group">
                <label htmlFor="task">Client Name</label>
                <input
                  type="text"
                  id="task"
                  name="task"
                  required
                  value={currentEvent.task}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="color">Color</label>
                <input
                  type="color"
                  id="color"
                  name="color"
                  required
                  value={currentEvent.color}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">               
                  {currentEvent.isEdit ?  (userRole !== "User" ?  <button type="submit" className="dash-btn dash-new-btn">
                      Update
                    </button> : null ) 
                    : 
                   <button type="submit" className="dash-btn dash-new-btn">
                    Add
                  </button>}
              </div> 
              {currentEvent.isEdit && userRole !== "User" && (
              <div className="form-group">
                <button
                  type="button"
                  className="dash-btn dash-new-btn"
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              </div>
            )}
            </form>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default DashCalendar;

