import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useUserStore from "../../store";
import "../../css/Cart.css";
import toast from "react-hot-toast";
import { useNavigate, Navigate } from "react-router-dom";
import instance from "../../utils/axios";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import pmLogo from "../../assets/logo1.PNG";
import Layout from "../../pages/MainLayout/Layout";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Loader from "../MainLoader/Loader";
import { gymName } from "../../data/Gym";
// import Razorpay from 'razorpay';

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve("success");
    };
    script.onerror = () => {
      reject("Something went wrong");
    };
    document.body.appendChild(script);
  });
};

const Cart = () => {
  const cartItems = useUserStore((state) => state.cartItems);
  const loggedIn = useUserStore((state) => state.loggedIn);
  const removeFromCart = useUserStore((state) => state.removeFromCart);
  const setCartItems = useUserStore((state) => state.setCartItems);
  const user = useUserStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState({
    batchTiming: "",
    gymLocation: "",
    startDate: "",
  });
  const nav = useNavigate();
  const [cartId, setCartId] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const image =
    "https://asset.cloudinary.com/dmgifp52d/24e8c7de293b759d2262afe228b7d3e0";

  const handleModalOpen = () => {
    const isOfflinePlanInCart = cartItems.some(
      (item) => item.itemType === "plan"
    );
    if (isOfflinePlanInCart) {
      setShowPlanModal(true);
    } else {
      handleCheckout();
    }
  };

  const handleCheckout = async () => {
    if (
      user.address === "" ||
      user.address.line1 === "" ||
      user.address.pincode === null
    ) {
      toast.error("Please Complete your Profile Before Checkout");
      nav("/dashboard/profile");
      return;
    }
    try {
      await loadScript("https://checkout.razorpay.com/v1/checkout.js");
      const response = await instance.post(
        "/api/user/checkout/create-session",
        {
          items: cartItems,
          id: cartId,
          total: totalPaymentToProcess,
          email: user.email,
          address: user.address,
          name: user.name,
          userId: user._id,
          discount: discountCode,
          discountAmount: discountAmount,
          pmLogo: image,
          gymLocation: planData.gymLocation,
          batchTiming: planData.batchTiming,
          startDate: planData.startDate,
        }
      );
      if (response.data.orderId) {
        const { orderId, currency, amount } = response.data;
        const options = {
          // key: "rzp_test_1jLYSU8IEZdjRe",
          key: 'rzp_live_QpkQxTqRuF2m2M',
          amount: amount, // Razorpay expects the amount in paisa
          currency: currency, // Change currency as needed
          order_id: orderId,
          handler: async function (response) {
            try{
              const response1 = await instance.post("/api/user/verify-Signature",{
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                order_id: orderId,
              });
              if(response1.data.success){
                nav("/success")
              }else {
                toast.error(response1.data.message)
              }
            }catch(error) {
              console.log(error);
              toast.error("An Error Occurred");
            }
          },
          prefill: {
            name: user.name,
            email: user.email,
            contact: user.mobile,
          },
          theme: {
            color: "#528FF0", // Change color as needed
          },
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleRemove = async (itemId) => {
    try {
      const response = await instance.post("/api/user/removeFromCart", {
        userId: user._id,
        itemId: itemId,
      });
      if (response.data.success) {
        toast.success("Item removed from cart successfully");
        removeFromCart(itemId);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setData = (e) => {
    const { name, value } = e.target;
    setPlanData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (
        planData.gymLocation !== "" &&
        planData.batchTiming !== "" &&
        planData.startDate !== ""
      ) {
        setLoading(false);
        handleCheckout();
      } else {
        toast.error("Please select all the fields.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("An error occurred! Please try again");
    }
  };

  const handleQuantityChange = async (itemId, newQuantity) => {
    try {
      const response = await instance.put("/api/user/updateCart", {
        userId: user._id,
        itemId: itemId,
        quantity: newQuantity,
      });
      if (response.data.success) {
        toast.success("Cart Updated SuccessFully");
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await instance.get("/api/user/getCartItems", {
        params: {
          userId: user._id,
        },
      });
      if (response.data.success) {
        setCartId(response.data.data._id);
        setCartItems(response.data.data.items);
        setDiscountCode(response.data.data.discountCode);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleDiscount = async () => {
    try {
      const response = await instance.post("/api/user/getDiscountCode", {
        discountCode: discountCode,
      });
      if (response.data.success) {
        setDiscount(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (discount !== "") {
      calculateDiscount();
    }
    // eslint-disable-next-line
  }, [discount]);

  useEffect(() => {
    setDiscountAmount(
      cartItems.reduce((acc, item) => {
        if (item.discountedPrice > 0) {
          if(item.discountedPrice === item.price){
            return acc + item.discountedPrice;
          }
          return acc + (item.price - item.discountedPrice);
        }
        return acc;
      }, 0)
    );
  }, [cartItems]);

  const calculateDiscount = async () => {
    try {
      const response = await instance.post("/api/user/calculateDiscount", {
        discount: discount,
        cartId: cartId,
      });
      if (response.data.success === true) {
        setCartId(response.data.data._id);
        setDiscountCode(response.data.data.discountCode); 
        setCartItems(response.data.data.items);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("An Error Occurred");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const removeDiscount = async() => {
    try{
      const response = await instance.post("/api/user/removeDiscount", {
        cartId: cartId,
      });
      if(response.data.success === true) {
        toast.success(response.data.message);
        fetchData();
      }else {
        toast.error(response.data.message);
      }
    }catch(error) {
      console.log(error.message);
      toast.error("Something Went Wrong. Please Try again Later.")
    }
  }

  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.price, // Summing up the price values directly
    0
  );
  const delivery = 0; // Example delivery cost
  const total = subtotal + delivery - discountAmount;
  const totalPaymentToProcess = total / (1 - 0.0238)
  const tax = parseFloat(totalPaymentToProcess - total).toFixed(2);
  const totalPaymentToProcessFixed = parseFloat(totalPaymentToProcess).toFixed(2);
  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    toast.error("Please Login To continue");
    return <Navigate to={"/"} />;
  }

  return (
    <Layout>
      <div className="cartpage">
        <h3 className="website-header">My Shopping Cart</h3>
        {cartItems.length === 0 || cartItems.length === "" ? (
          <div className="no-carts-div">
            <AddShoppingCartIcon className="no-carts-icon" />
            <h3 className="no-carts-h3">Your Cart Is Empty</h3>
            <h6 className="no-carts-h6">
              Looks Like you have not added anything to the cart yet. Go ahead
              and explore our categories
            </h6>
          </div>
        ) : (
          <div className="cart-container">
            <div className="main-cart">
              <div className="product-cart">
                <div className="product-cart-container">
                  {cartItems?.map((product) => (
                    <div className="cart-card" key={product.id}>
                      <div className="cart-left">
                        <img
                          src={
                            product.file ? product.file.secure_url[0] : pmLogo
                          }
                          alt=""
                        />
                      </div>
                      <div className="cart-right">
                        <div className="cart-up">
                          <h4 className="cart-title">{product.name}</h4>
                          <h4 className="cart-total">
                            ₹
                            {product.discountedPrice !== 0
                              ? product.discountedPrice
                              : product.price}
                          </h4>
                        </div>
                        <div className="cart-down">
                          {product.itemType === "plan" ? (
                            <div className="cart-size">{product.size}</div>
                          ) : (
                            product.size !== "NA" && (
                              <div className="cart-size">{product.size}</div>
                            )
                          )}
                          <div className="cart-number">
                            {product.itemType === "plan" ? (
                              <span>Quantity: {product.quantity}</span>
                            ) : (
                              <>
                                <RemoveIcon
                                  className="cart-btn"
                                  onClick={() =>
                                    handleQuantityChange(
                                      product.itemId,
                                      product.quantity - 1
                                    )
                                  }
                                />
                                <span>{product.quantity}</span>
                                <AddIcon
                                  className="cart-btn"
                                  onClick={() =>
                                    handleQuantityChange(
                                      product.itemId,
                                      product.quantity + 1
                                    )
                                  }
                                />
                              </>
                            )}
                          </div>
                          <DeleteIcon
                            className="delete-icon"
                            onClick={() => handleRemove(product.itemId)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cart-forward">
              <div className="cart-boxes">
                <div className="cart-box">
                  <h3 className="cart-key">Discount</h3>
                  <h2 className="cart-value">₹ {discountAmount}</h2>
                </div>
                <div className="cart-box">
                  <h3 className="cart-key">SubTotal</h3>
                  <h2 className="cart-value">₹{subtotal}</h2>
                </div>
                <div className="cart-box">
                  <h3 className="cart-key">Taxes</h3>
                  <h2 className="cart-value">₹{tax}</h2>
                </div>
                <div className="cart-box">
                  <h3 className="cart-key">Total</h3>
                  <h2 className="cart-value">₹{totalPaymentToProcessFixed}</h2>
                </div>
              </div>
              <div className="promo-code">
                <p className="promo-ad">
                  If you have a promotion code, please enter it here
                </p>
                <div className="promo-container">
                  <input
                    type="text"
                    className="promo-input promo-item"
                    placeholder="Please Enter Promo Code"
                    value={discountCode}
                    onChange={(e) => {
                      setDiscountCode(e.target.value);
                    }}
                    disabled={discountAmount !== 0}
                  />
                  {discountAmount === 0 ? (
                    <button
                      className="promo-btn promo-item"
                      onClick={handleDiscount}
                    >
                      Apply Discount
                    </button>
                  ) : (
                    <button className="promo-btn promo-item" onClick={removeDiscount}>
                      Remove
                    </button>
                  )}
                  <button
                    className="promo-btn promo-item"
                    onClick={handleModalOpen}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {showPlanModal && (
          <div className="tnc-modal1">
            <div className="tnc-modal-content1">
              <h4 className="tnc-h4">Submit Data</h4>
              <CloseIcon
                className="close-icon"
                onClick={() => setShowPlanModal(false)}
              />
              <form className="showPlan-form" onSubmit={handleSubmit}>
                <div className="showPlan-form-input">
                  <label htmlFor="gymLocation">Performance Center</label>
                  <select
                    className="showPlan-input-field"
                    required
                    name="gymLocation"
                    value={planData.gymLocation}
                    onChange={setData}
                  >
                    <option value="">Select Performance Center</option>
                    {gymName.map((a) => (
                      <option value={a.name} key={a}>
                        {a.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="showPlan-form-input">
                  <label htmlFor="">Batch Timing</label>
                  <select
                    className="showPlan-input-field"
                    required
                    name="batchTiming"
                    value={planData.batchTiming}
                    onChange={setData}
                  >
                    <option value="">Select Batch Timing</option>
                    <option value="Morning">Morning</option>
                    <option value="Evening">Evening</option>
                  </select>
                </div>
                <div className="showPlan-form-input">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    className="showPlan-input-field"
                    name="startDate"
                    required
                    value={planData.startDate}
                    onChange={setData}
                    min={planData.startDate ? undefined : new Date().toISOString().split("T")[0]}
                  />
                </div>
                <br />
                <button
                  className={`dash-btn showPlan-btn ${
                    loading ? "upload-btn" : ""
                  }`}
                >
                  {loading ? (
                    <AutorenewIcon className="loader-css" />
                  ) : (
                    "Add Data"
                  )}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
