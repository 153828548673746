import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import '../../css/DashUser.css';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import useUserStore from '../../../store';
import { testName } from '../../../data/Gym';
import instance from '../../../utils/axios';

const AssignTest = ({ userId, formId, closeTestModal, name, update, planId }) => {

  const [filterText, setFilterText] = useState('');
  const [selectedName, setSelectedName] = useState([]);
  const [inputClicked, setInputClicked] = useState(false);
  const [ loading, setLoading ] = useState(false);  
  const user = useUserStore((state) => state.user);

  const filteredTestNames = inputClicked
    ? testName
        .filter(test => test.name.toLowerCase().includes(filterText.toLowerCase()))
        .slice(0, 10) 
    : [];

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
    setInputClicked(true); 
  };

  useEffect(() => {
    if (update === true) { 
        const fetchData = async () => {
          try {
              setLoading(true);
              const response = await instance.get(`/api/user/getAssignedTest/${userId}`); 
              if (response.data.success) { 
                const assignedTests = response.data.data[0].testResponse.map(test => test.testName);
                setSelectedName(assignedTests.flat());
                setLoading(false);
              }
          } catch (error) {
            console.error("Error fetching assigned test:", error);
            toast.error("Failed to fetch assigned test data");
            setLoading(false)
          }
        };
        fetchData();
    }
  }, [update, userId]);
  
  const handleSelect = (name) => {
    if(!name) { 
      return toast.error("Please Select a Test Name First!")
    }
    if (selectedName.includes(name)) {
      return toast.error("Test Name already selected!");
    }
    setSelectedName(prevState => [...prevState, name]);
    setFilterText(''); 
    setInputClicked(false); 
  };

  const onClick = (name) => {
    setSelectedName((prevState) => prevState.filter(item => item !== name))
  }

  const handleSubmit = async () => {
    try { 
        setLoading(true);
        if (selectedName.length === 0){ 
            return toast.error("Kindly select a test name first");
        }

        const endpoint = update === true
        ? `/api/user/updateAssignedTest/${userId}`
        : `/api/user/assignTest/${userId}`;
        const response = await instance[update ? "patch" : "post"](endpoint, {
          formId, 
          assignedBy: user.uniqueId,
          planId,
          testName: selectedName
        });
        if(response.data.success) {
            toast.success(response.data.message);
            closeTestModal();
        } else { 
            toast.error("something went wrong");
        }
    } catch ( error ) { 
        console.log("assign test error: ", error);
        toast.error(error.message);
    } finally { 
      setLoading(false);
    }
  }

  return (
    <div className='tnc-modal1'>
      <div className='tnc-modal-content1 show-response-modal'>
        <h4 className='show-response-modal-h4'> { update ? "Update" : "Assign"} Test to {name}</h4>
        <CloseIcon className='close-icon' onClick={closeTestModal} />
        <div className='assign-test-container1' >
            <input
                type='search'
                list='test-names'
                placeholder='Type to filter test names...'
                value={filterText}
                onChange={handleInputChange}
                onFocus={() => setInputClicked(false)} 
                className='assign-test-input'
            />
            <datalist id='test-names'>
                {filteredTestNames.map((test, index) => (
                    <option key={index} value={test.name} onClick={() => handleSelect(test.name)} />
                ))}
            </datalist>
            <button className='dash-btn assign-test-btn' onClick={() => handleSelect(filterText)} >Select</button>
        </div>
        <div className='assign-test-container2'>
            <h5 className='assign-test-h5' >Test Assigned to {name}</h5>
            <div className='assign-test-container2-div'>
                {selectedName && selectedName.map((name, value) => (
                    <div key={value} className='assign-test-container2-div2'  >
                      <span className='assign-test-container2-span' > {value + 1}. {" "}</span>
                      <span className='assign-test-container2-span' >
                        {name}
                      </span>
                      <CloseIcon  className='assign-test-x-icon' onClick={() => onClick(name)}/>
                    </div>
                ))}
                <button className='assign-test-btn1' disabled={loading}  onClick={handleSubmit} > 
                  {loading ? (
                     <AutorenewIcon className="loader-css" />
                    ) : (
                      <>{update === true ? "Update" : "Assign"} Test </>
                  )}
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTest;
