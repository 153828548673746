import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate, useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../../css/DashProducts.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Switch } from "@mui/material";

const DashProductForm = ({ isUpdate }) => {
  const [file, setFile] = useState([]);
  const nav = useNavigate();
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);

  const [productData, setProductData] = useState({
    file: "",
    brand: "",
    name: "",
    quantity: "",
    details: "",
    size: [{ value: "", price: "" }],
    showProduct: false,
  });

  useEffect(() => {
    if (isUpdate && productId) {
      instance
        .get(`/api/user/getProductData/${productId}`)
        .then((response) => {
          if (response.data.success) {
            const productData = response.data.data;
            setProductData({
              ...productData,
              showProduct: productData.showProduct,
            });
            if (productData.file) {
              setFile(productData.file.secure_url);
            }
          } else {
            console.log(response.data.message);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [isUpdate, productId]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "showProduct") {
      setProductData((prevData) => ({
        ...prevData,
        showProduct: checked,
      }));
      const notificationMessage = checked
        ? "The Merchandise is currently accessible via the website."
        : "The website is no longer displaying the Merchandise.";
      toast.success(notificationMessage);
    } else {
      setProductData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSizeChange = (index, field, value) => {
    const updatedSizes = [...productData.size];
    updatedSizes[index][field] = value;
    setProductData({ ...productData, size: updatedSizes });
  };

  const handleAddSize = () => {
    setProductData({
      ...productData,
      size: [...productData.size, { value: "", price: "" }],
    });
  };

  const handleRemoveSize = (index) => {
    const updatedSizes = [...productData.size];
    updatedSizes.splice(index, 1);
    setProductData({ ...productData, size: updatedSizes });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const endpoint = isUpdate
        ? `/api/user/updateProduct/${productId}`
        : "/api/user/productData";
      const response = await instance[isUpdate ? "put" : "post"](endpoint, {
        ...productData,
        file: file,
      });
      if (response.data.success) {
        setLoading(false);
        const successMessage = isUpdate
          ? "Merchandise updated successfully!"
          : "Merchandise added successfully!";
        toast.success(successMessage);
        nav("/dashboard/products");
      } else {
        setLoading(false);
        toast.error(
          `Failed to ${isUpdate ? "update" : "Add"} the Merchandise.`
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...file];
    updatedFiles.splice(index, 1);
    setFile(updatedFiles);
  };

  const handleImageChange = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      toast.error("No files selected.");
      return;
    }

    if (files.length > 6 - file.length) {
      toast.error(`Maximum ${5 - file.length} files allowed.`);
      return;
    }

    const fileArray = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    });

    Promise.all(fileArray)
      .then((results) => {
        setFile([...file, ...results]);
      })
      .catch((error) => {
        toast.error("Failed to add files.");
      });
  };

  return (
    <div>
      <div className="productForm">
        <h4 className="dashboard-title dashMembers-container">
          {isUpdate ? "Update Your Merchandise" : "Add A Merchandise"}
        </h4>
        <div className="product-form-container">
          <form onSubmit={handleSubmit} className="product-form">
            <div className="product-image-div">
              <div className="product-image-container">
                {file === "" || file === null
                  ? ""
                  : file.map((base64String, index) => (
                      <div className="productImgContainer" key={index}>
                        <img
                          src={base64String}
                          alt=""
                          className="product-img"
                        />
                        <DeleteIcon
                          className="delete-btn"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </div>
                    ))}
                {file.length < 6 && (
                  <div>
                    <label className="product-label" htmlFor="file">
                      <DriveFolderUploadIcon className="icons" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      accept=".png, .jpeg, .jpg"
                      multiple
                      style={{ display: "none" }}
                      name="file"
                      onChange={handleImageChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="brand">
                Merchandise Brand{" "}
              </label>
              <input
                type="text"
                required
                name="brand"
                value={productData.brand}
                onChange={handleChange}
                placeholder="Enter Brand Name"
              />
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="name">
                Merchandise Name{" "}
              </label>
              <input
                type="text"
                required
                name="name"
                value={productData.name}
                onChange={handleChange}
                placeholder="Enter Product Name"
              />
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="quantity">
                Merchandise Quantity{" "}
              </label>
              <input
                type="number"
                name="quantity"
                required
                min={0}
                value={productData.quantity}
                onChange={handleChange}
                placeholder="Enter Quantity"
              />
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="details">
                Merchandise Details{" "}
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="10"
                value={productData.details}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="product-input-div">
              <label className="product-label">Merchandise Sizes</label>
              {productData &&
                productData.size.map((size, index) => (
                  <div className="product-sizes" key={index}>
                    <input
                      type="text"
                      className="product-size-map"
                      name="value"
                      value={size.value}
                      onChange={(event) =>
                        handleSizeChange(index, "value", event.target.value)
                      }
                      placeholder="Size"
                    />
                    <input
                      type="number"
                      name="price"
                      min={0}
                      className="product-size-map"
                      value={size.price}
                      onChange={(event) =>
                        handleSizeChange(index, "price", event.target.value)
                      }
                      placeholder="Price"
                    />
                    <CancelIcon
                      className="cancel-icon icons"
                      onClick={() => handleRemoveSize(index)}
                    />
                  </div>
                ))}
              <button
                type="button"
                className="dash-btn add-btn"
                onClick={handleAddSize}
              >
                Add
              </button>
            </div>
            <span style={{ color: "var(--dash-primary)", fontSize: "12px" }}>
              Show on website
            </span>{" "}
            <Switch
              name="showProduct"
              color="primary"
              onChange={handleChange}
              checked={productData.showProduct}
            ></Switch>
            <button
              type="submit"
              className={`dash-btn ${loading ? "upload-btn" : ""}`}
            >
              {loading ? (
                <AutorenewIcon className="loader-css" />
              ) : (
                <>{isUpdate ? "Update Merchandise" : "Add Merchandise"}</>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashProductForm;

export const DashAddProducts = () => <DashProductForm isUpdate={false} />;
export const UpdateProduct = () => <DashProductForm isUpdate={true} />;
