import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import instance from '../../../utils/axios';
import useUserStore from '../../../store';

const Exercise = () => {

  const [ name, setName ] = useState([]);
  const user = useUserStore((state) => state.user )

  const getData = async () => { 
    try { 
      const response = await instance.get(`/api/user/getAllExercises?userId=${user._id}`);
      if ( response.data.success ){
        console.log(response.data.data); 
        setName(response.data.data)
      } else { 
        toast.error(response.data.message)
      }
    } catch ( error ) { 
      console.log("get exercise by week error: ", error.message);
      toast.error(error.message)
    }
  }

  useEffect(() => {  
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div>
        { name?.map((week) => (
          <Link to={`/dashboard/exercises/${week._id}`} key={week._id} > 
            <div>
                <span> Day { week._id }: </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Exercise;