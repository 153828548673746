import {create} from 'zustand';
import { persist } from 'zustand/middleware'

const useUserStore = create(persist((set) => ({
    user: {},
    setUser: (objUser) => set((state) => ({user: objUser})),
    loggedIn: false,
    setLoggedIn: (isLogin) => set((state) => ({loggedIn: isLogin})),
    cartItems: [],
    setCartItems: (items) => {
      set((state) => {
        const existingItems = state.cartItems.filter((existingItem) =>
        items.every((item) => existingItem.id !== item.id)
      );
      return {
        cartItems: [...existingItems, ...items],
      };
      });
    },    
    removeFromCart: (itemId) => set((state) => ({
      cartItems: state.cartItems.filter((product) => product.itemId !== itemId)
    })),
    updateCartItem: (itemId, quantity) => set((state) => ({
      cartItems: state.cartItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, quantity };
        }
        return item;
      }),
    })),
    clearCart: () => set({cartItems: []}),
    notifications: [],
    setNotifications: (notification) => set((state) => ({
      notifications: [...state.notifications, notification]
    })),
  }), {
    name: 'user-store',
    getStorage: () => sessionStorage,
    // getStorage: () => localStorage,
  }));

export default useUserStore;