import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import instance from "../../../utils/axios";
import AddIcon from '@mui/icons-material/Add';
import {motion} from "framer-motion";
import toast from "react-hot-toast";
import "../../css/Dashprescription.css";

const UpdatePrescription = () => {
  const { prescriptionId } = useParams();
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [create, setCreate] = useState([]);

  const handleAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const handleWindowClick = (e) => {
    if (!e.target.matches('.accordion-header')) {
      setActiveAccordion(null);
    }
  };
  window.onclick = handleWindowClick;

  const getData = async () => {
    try {
      const response = await instance.get(
        `/api/user/getIndividualPrescription/${prescriptionId}`
      );
      if (response.data.success) {
        setCreate(response.data.data);
      }
    } catch (error) {
      console.log("get prescription error:", error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (prescriptionId) {
      getData();
    }
    // eslint-disable-next-line
  }, [prescriptionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post(`/api/user/submitPrescription/${prescriptionId}` );
      if (response.data.success) {
        toast.success(response.data.message);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("create day exercise list error: ", error.message);
      toast.error(error.message);
    }
  };


  return (
    <div className="dashMembers">
      <div className="dashMembers-container">
        <h1 className="dashboard-title">
          Update {create.clientName}'s Prescription
        </h1>
      </div>
      <div className="dash-user">
        <div className="prescription-div">
          <div className="prescription-span">Name: {create.clientName}</div>
          <div className="prescription-span">
            Birth Date: {new Date(create.birthDate).toLocaleDateString("en-IN")}
          </div>
          <div className="prescription-span">
            Occupation: {create.occupation}
          </div>
          <div className="prescription-span">
            Health Issues: {create?.healthIssues || "N/A"}
          </div>
        </div>
      <motion.div
        className="accordion"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        {create?.weeks?.map((week, index) => (
          <div key={week._id} className="accordion-item" >
            <button
              className={`accordion-header ${activeAccordion === index ? 'active' : ''}`}
              onClick={() => handleAccordionClick(index)}
            >
              <span className="accordion-span">Week {week.weekNumber} | {week.status}</span> 
              <AddIcon className="icons" />
            </button>
            <div className={`accordion-body ${activeAccordion === index ? 'active' : ''}`}>
              {week.days.map((day) => (
                <div className="prescription-weeks1" key={day._id}>
                  <Link
                    className="Link"
                    to={`/dashboard/prescription/day/${day._id}`}
                  >
                    Day {day.dayNumber}: {day.dayHeading} | {day.status}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ))}
      </motion.div>
      <button className="dash-btn prescription-btn" onClick={handleSubmit}>
        { create.isSubmitted ? " Already Prescribed" : "Prescribe It!"}
      </button>
      </div>
    </div>
  );
};

export default UpdatePrescription;
