import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { motion } from "framer-motion"
import useUserStore from '../../../store';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const Sales = () => {

    const [userData, setUserData] = useState([]);
    const [length, setLength] = useState(0);
    const user = useUserStore((state) => state.user);

    useEffect(() => {
        instance.get('/api/user/getTotalCallData', {
            params: {
                userId: user._id,
                gymLocation: user.gymLocation,
                role: user.role, 
                uniqueId: user.uniqueId 
            }
        }).then((response) => {
            if (response.data.success) {
                let filteredData = response.data.data;

                const rows = filteredData && filteredData.slice().reverse().map((user) => {
                    const startTime = moment(user.batch.batchStartTime, 'HH:mm:ss').format('h:mm A');
                    const endTime = moment(user.batch.batchEndTime, 'HH:mm:ss').format('h:mm A');
                    const shiftInfo = `${user.batch.batchShift}: ${startTime} - ${endTime}`;

                    return {
                        id: user._id,
                        col1: user.file && user.file.secure_url,
                        col2: user.name,
                        col3: user.role,
                        col4: user.gymLocation,
                        col5: shiftInfo || "N/A",
                        col6: user.totalAssigned,
                        col7: user.totalCalls,
                        col8: user.convertedCount,
                        col9: user.declinedCount,
                    }
                });
                setUserData(rows);
                setLength(response.data.data.length);
            }
        }).catch((error) => {
            toast.error("An Error Occurred! ")
        });
    }, [user.gymLocation, user.role, user._id, user.uniqueId]);

    const columns = [
        {
            field: 'col1',
            headerName: 'Image',
            width: 70,
            renderCell: (params) => (
                <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value} alt="user" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
            ),
        },
        { field: 'col2', headerName: 'Employee Name', width: 150 },
        { field: 'col3', headerName: 'Position', width: 150 },
        { field: 'col4', headerName: 'Branch', width: 100 },
        { field: 'col5', headerName: 'Batch Timing', width: 250 },
        { field: 'col6', headerName: 'Assigned', width: 120 },
        { field: 'col7', headerName: 'Total Calls', width: 120 },
        { field: 'col8', headerName: 'Converted', width: 100 },
        { field: 'col9', headerName: 'Declined', width: 100 },
        {
            field: 'col10',
            headerName: 'Conversion',
            width: 100,
            renderCell: (params) => {
                const totalCalls = params.row.col7; // Total Calls
                const callsConverted = params.row.col8; // Calls Converted
                const conversionRate = totalCalls !== 0 ? (callsConverted / totalCalls) * 100 : 0;
                const formattedConversionRate = conversionRate.toFixed(2); // Format conversion rate to two decimal places
                return (
                    <div>{formattedConversionRate}%</div>
                );
            },
        },
        {
            field: 'col14',
            headerName: 'All Comments',
            width: 140,
            renderCell: (params) => (
                <Link to={`/dashboard/sales/viewComments/${params.row.id}`} style={{ textDecoration: "none" }} >
                    <div className="activeButton">View Comments</div>
                </Link>
            ),
        },
    ];

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={`/dashboard/sales/listSales/${params.row.id}`} style={{ textDecoration: "none" }} >
                            <div className="viewButton">Details</div>
                        </Link>
                    </div>
                );
            },
        },
    ];


    return (
        <div className='dataTable'>
            <motion.div className="datatableTitle dashboard-title"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0 }}
            >
                Sales Details ({length})
            </motion.div>
            <motion.div className='dataGrid'
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
            >
                <DataGrid
                    rows={userData}
                    columns={columns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                />
            </motion.div>
        </div>

    )
}

export default Sales;
