import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";
import instance from "../../../utils/axios";
import DeleteModal from "../../components/Modal/DeleteModal";

const FaqQuestions = () => {
  const [showModal, setShowModal] = useState(false);
  const [length, setLength] = useState(0);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [allFaq, setAllFaq] = useState([]);
  const [newFaq, setNewFaq] = useState({
    question: "",
    answer: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setNewFaq((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteFaq/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("FAQ Deleted SuccessFully");
        setAllFaq((prevData) => prevData.filter((tnc1) => tnc1._id !== userIdToDelete));
        setShowModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post("/api/user/faqData", {
        question: newFaq.question,
        answer: newFaq.answer
      });
      if (response.data.success) {
        toast.success("FAQ Added Successfully");
        e.target.reset();
        setNewFaq({ answer: "", question: "" });
        fetchFaq();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFaq = async () => {
    try {
      const response = await instance.get("/api/user/getFaqData")
      if (response.data.success) {
        setAllFaq(response.data.data)
        setLength(response.data.data.length);
      } else {
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchFaq();
  }, []);


  return (
    <div className="tnc">
      <div className="tnc-container">
        <form onSubmit={handleSubmit} className="tnc-form">
        <div className="tnc-form-field">
            <input
              type="text"
              name="question"
              id="question"
              required
              value={newFaq.question}
              autoComplete="off"
              onChange={setData}
              placeholder="Enter the question for the FAQ"
              className="add-tnc"
            />
          </div>
          <div className="tnc-form-field">
            <input
              type="text"
              name="answer"
              id="answer"
              autoComplete="off"
              required
              value={newFaq.answer}
              onChange={setData}
              placeholder="Enter the answer"
              className="add-tnc"
            />
          </div>
          <button type="submit" className="dash-btn tnc-btn">
            Add FAQ
          </button>
        </form>
      </div>
      <div className="tnc-container-1">
        <h4 className="tnc-title">All FAQS ({length}) </h4>
        {allFaq && allFaq.map((value, index) => (
            <div className="faqMap" key={index}>
                <div className="faqMap-container">
                    <h3 className="faqmap-h3">{index + 1}</h3>
                    <div className="faq-container-1">
                        <h4 className="faqmap-question">Q. {value.question}</h4>
                        <h4 className="faqmap-answer">A. {value.answer}</h4>
                    </div>
                </div>
                <DeleteIcon  className="delete-icon" onClick={() => handleDelete(value._id)}/>
            </div>
        ))}
      </div>
      {showModal && <DeleteModal 
        h4="Are you sure you want to delete this FAQ?"
        confirmDelete={confirmDelete}
        closeModal={()=> setShowModal(false)}
      />}
    </div>
  );
};

export default FaqQuestions;
