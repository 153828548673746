import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import instance from "../../utils/axios";
import { motion } from "framer-motion";
import { toast } from 'react-hot-toast';
import Layout from "../MainLayout/Layout"
import "../../css/Products.css"
import Loader from "../../components/MainLoader/Loader";

const Products = () => {
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await instance.get("/api/user/getProductData")
            if (response.data.success) {
                setProductData(response.data.data);
                setLoading(false);
            } else {
                console.error(response.data.error);
                setLoading(false);
                toast.error("Something Went Wrong! Please reload the page");
            }
        } catch (error) {
            toast.error("Failed to fetch product data");
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handleAddToCart = (productId) => {
        const selectedProduct = productData.find((product) => product._id === productId);
        if (!selectedProduct || !selectedProduct.size || !selectedProduct.quantity) {
            toast.error("Select the size and quantity first");
            return;
        }
        const path = `/products/${productId}`;
        window.location.href = path;
    };

    return (
        <>
            <Layout>
                <motion.section
                    className="products"
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <h1 className="website-header">Planet Muscle Merchandise</h1>
                    <p className="product-p">Awesome Collections - New Design </p>
                    <div className="products-container">
                        {loading ? (        
                            <Loader />
                        ) : productData.length === 0 ? (
                            <div className="no-product-div">
                                <h3 className="empty-product-h3">Oops... No Merchandise Yet</h3>
                                <h6 className="empty-product-h6">Go ahead and explore our other categories</h6>
                            </div>
                        ) : (
                            productData.slice().reverse().map((product) => (
                                <div className="product" key={product._id}>
                                    <Link to={`${product._id}`}>
                                        <img src={product.file.secure_url[0]} alt="no name" className="productImage" />
                                    </Link>
                                    <div className="product-description">
                                        <span className="product-brand">{product.brand}</span>
                                        <h6 className="product-name">{product.name}</h6>
                                        <h4 className="product-price">₹{product.size[0].price}</h4>
                                    </div>
                                    <div className="add-to-cart-btn">
                                        <ShoppingCartIcon onClick={() => handleAddToCart(product._id)} className="shopping-icon" />
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </motion.section>
            </Layout >
        </>
    );
};

export default Products;
