import React, { useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import instance from '../../../utils/axios';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {toast} from "react-hot-toast";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { defaultUrl } from "../../../data/Images";



const NewEmployee = () => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newUser, setnewUser] = useState({
    name: '',
    username: "",
    uniqueId: "",
    salary: "",
    email: '',
    address: {
      line1: "",
      line2: "",
      pincode: "",
      city: "",
      state: "",
  },
    country: "",
    mobile: '',
    document: '',
    gymLocation:'',
    batch:{
      batchShift: "",
      batchStartTime: "",
      batchEndTime: "",
    },
    role:'',
    password:'',
    dob: '',
    file: {
      name: "",
      data: "",
    },
    endDate:"",
    addressProof: {
      name: "",
      data: "" 
    },
    offerLetter: {
      name: "",
      data: "" 
    },
    blankCheque: {
      name: "",
      data: "" 
    },
    path: "",
    start: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (e, stateKey) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setnewUser((prevDetails) => ({
        ...prevDetails,
        [stateKey]: {
          name: file.name,
          data: reader.result,
        },
      }));
    };
    reader.readAsDataURL(file);
  };    

  const setData = (e) =>{
    e.preventDefault();
    const {name, value} = e.target;
    setnewUser((preval)=>{
      return {
        ...preval,
        [name]: value,
      }
    });
  }

  const handleUsernameFocusBlur = (event) => {
    const { value } = event.target;
    if (value.includes(' ')) {
      toast.error('Username must not contain spaces');
    }
  };

  const handleSubmit = async (e) => {
    const newRegister = newUser.email.toLowerCase();

    e.preventDefault();
    if (newUser.password.length < 8) {
      toast.error('Password should have at least 8 characters');
      return;
    }
    if (!/[A-Z]/.test(newUser.password)) {
      toast.error('Password should have at least one uppercase letter');
      return;
    }
    if (!/\d/.test(newUser.password)) {
      toast.error('Password should have at least one number');
      return;
    }    
    if (newUser.password.includes(' ')) {
      toast.error('Password should not contain spaces');
      return;
    }
    if (!/^\d*$/.test(newUser.mobile)) {
      toast.error('Mobile number should only contain digits');
      return;
    }
    const updatedUniqueId = newUser.uniqueId.toUpperCase();
    setLoading(true);

    try{
        const response = await instance.post('/api/user/newEmployee', {
          name: newUser.name,
          file: newUser.file,
          email: newRegister,
          salary: newUser.salary, 
          uniqueId: updatedUniqueId,
          username: newUser.username,
          address: {
            line1: newUser.line1,
            line2: newUser.line2,
            city: newUser.city,
            state: newUser.state,
            pincode: newUser.pincode,
          },
          country: newUser.country,
          mobile: newUser.mobile,
          dob: newUser.dob,
          gymLocation: newUser.gymLocation,
          batch: {
            batchShift: newUser.batchShift,
            batchStartTime: newUser.batchStartTime,
            batchEndTime: newUser.batchEndTime,
          },
          role: newUser.role,
          password: newUser.password,
          path: newUser.path,
          start: newUser.start,
          addressProof: newUser.addressProof,
          offerLetter: newUser.offerLetter,
          blankCheque: newUser.blankCheque,
        })
          if(response.data.success){
            setLoading(false);
            toast.success("Counsellor Added Successfully");
            nav('/dashboard/employees');
          }else {
            setLoading(false);
            toast.error(response.data.message);
          }
        }catch (error) {
          setLoading(false);
          toast.error("An Error Occurred");
        }
      }

  return (
    <div className="newContainer">
    <motion.div className="new-top"            
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
    >
      <h1 className="dashboard-title" >Add New Counsellor</h1>
    </motion.div>
    <motion.div className="new-bottom"             
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
    >
      <div className="new-right">
      <form onSubmit={handleSubmit}>
          <div className="forminput">
            <div className="new-left images">
                <img src={newUser.file.data ? newUser.file.data : defaultUrl} alt="" />
              <div>
                <label className='new-image' htmlFor="file"><DriveFolderUploadIcon /></label>
                <input type="file" id='file' onChange={(e) => handleFileChange(e, "file")}  style={{ display: "none" }} />
              </div>
            </div>
          </div>
          <div className="forminput" >
            <label htmlFor="username">Username</label>
            <input type="text" placeholder="john_doe" required maxLength={9} name="username" value={newUser.username} onChange={setData} onFocus={handleUsernameFocusBlur} onBlur={handleUsernameFocusBlur} />
          </div>
          <div className="forminput" >
            <label htmlFor="uniqueId">Unique ID</label>
            <input type="text" placeholder="AEMT1" autoCapitalize="on" required name="uniqueId" value={newUser.uniqueId} onChange={setData} />
          </div>
          <div className="forminput " >
            <label htmlFor="password">Password</label>
            <div className="form-password" >
                <input type={showPassword ? 'text' : 'password'} placeholder="password" required name="password" value={newUser.password} onChange={setData} />
                <div
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                aria-label="toggle password visibility"
                className="icon"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            </div>
          </div>
          <div className="forminput" >
            <label htmlFor="name">Name</label>
            <input type="text" placeholder="john Doe" name="name" onChange={setData} value={newUser.name} required />
          </div>
          <div className="forminput" >
            <label htmlFor="email">Email</label>
            <input type="text" placeholder="johndoe@gmail.com" name="email" value={newUser.email} onChange={setData} required />
          </div>
          <div className="forminput" >
                    <label htmlFor="address">Postal Code</label>
                    <input type="number" maxLength={6} min={0} name='pincode' required value={newUser.pincode} onChange={setData} placeholder="Enter Your Postal Code" />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="address">Address: Line 1</label>
                    <input type="text" name='line1' value={newUser.line1} required onChange={setData} placeholder="Elton St. 216 NewYork"  />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="address">Address: Line 2</label>
                    <input type="text" name='line2' value={newUser.line2} onChange={setData} placeholder="Elton St. 216 NewYork"  />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="address">City</label>
                    <input type="text" name='city' value={newUser.city} required onChange={setData} placeholder="Enter Your City Name"  />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="address">State</label>
                    <input type="text" name='state' value={newUser.state} required onChange={setData} placeholder="Enter Your State"  />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="address">Country</label>
                    <input type="text" name='country' value={newUser.country} required onChange={setData} placeholder="Enter Your Country" />
                  </div>   
                  <div className="forminput" >
                    <label htmlFor="mobile">Mobile</label>
                    <input type="text" name='mobile' value={newUser.mobile} maxLength={10} onChange={setData} placeholder="0000000000" required />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="path">Health Issues</label>
                    <input type="text" name='path' value={newUser.path} onChange={setData} placeholder="Any Medical Issues" required />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="addressProof">Address Proof (Aadhar/Pan/Driving License)</label>
                    <input type="file" id='addressProof' onChange={(e) => handleFileChange(e, "addressProof")} accept=".pdf" placeholder="Add documents" />                
                  </div>
                  <div className="forminput" >
                    <label htmlFor="offerLetter">Offer Letter(PDF)</label>
                    <input type="file" id='offerLetter' onChange={(e) => handleFileChange(e, "offerLetter")} accept=".pdf" placeholder="Add documents" />
                  </div>
                  <div className="forminput" >
                    <label htmlFor="blankCheque">Blank Cheque Details(PDF)</label>
                    <input type="file" id='blankCheque' onChange={(e) => handleFileChange(e, "blankCheque")} accept=".pdf" placeholder="Add documents" />
                  </div>
          <div className="forminput" >
            <label htmlFor="gym-location">Gym Location</label>
            <select name="gymLocation" onChange={setData} value={newUser.gymLocation} required>
              <option value="">Select Gym Location</option>
              <option value="Andheri">Andheri</option>
              <option value="Versova">Versova</option>
              <option value="Hybrid">Hybrid</option>
              <option value="Home">Home</option>
            </select>
          </div>
          <div className="forminput" >
            <label htmlFor="role">Role</label>
            <select name="role" onChange={setData} value={newUser.role} >
                <option value="">Select Role</option>
                <option value="Regional Manager">Regional Manager</option>
                <option value="Branch Manager">Branch Manager</option>
                <option value="Master Trainer">Master Trainer</option>
                <option value="Diamond Trainer">Diamond Trainer</option>
                <option value="Platinum Trainer">Platinum Trainer</option>
                <option value="Gold Trainer">Gold Trainer</option>
                <option value="Silver Trainer">Silver Trainer</option>
                <option value="Sales">Sales</option>
                <option value="Accounts">Accounts</option> 
                <option value="Sales Manager">Sales Manager</option> 
                <option value="Online Manager">Online Manager</option> 
                <option value="Online Regional Manager">Online Regional Manager</option> 
                <option value="Human Resources">Human Resources</option> 
                <option value="Floor Manager">Floor Manager</option> 

            </select>
          </div>
          <div className="forminput" >
            <label htmlFor="batch"> Shift Timing</label>
            <select name="batchShift" onChange={setData} value={newUser.batchShift} required>
              <option value="">Select Shift Timing</option>
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
              <option value="All Day">All Day</option>
            </select>
          </div>
          <div className="forminput" >
            <label htmlFor="batchStartTime">Start Time</label>
            <input type="time" name='batchStartTime' required value={newUser.batchStartTime} onChange={setData} />
          </div>
          <div className="forminput" >
            <label htmlFor="batchEndTime">End Time</label>
            <input type="time" name='batchEndTime' required value={newUser.batchEndTime} onChange={setData} />
          </div>
          <div className="forminput" >
            <label htmlFor="salary">Base Salary</label>
            <input type="number" min={0} name='salary' required value={newUser.salary} onChange={setData} />
          </div>

          <div className="forminput" >
            <label htmlFor="dob">Date of Birth</label>
            <input type="date" name='dob' required value={newUser.dob} onChange={setData} />
          </div>
          <div className="forminput" >
            <label htmlFor="date">Date of Commencement</label>
            <input type="date" name='start' required value={newUser.start} onChange={setData} />
          </div>

          {/* <button className="dash-btn">Add New Employee</button> */}
          <button className={`dash-btn ${loading ? 'upload-btn' : ''}`}>
              {loading ? <AutorenewIcon className="loader-css" /> : "Add New Counsellor"}
          </button>

        </form>
      </div>
    </motion.div>
  </div>

  );
};

export default NewEmployee;
