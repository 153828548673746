import React, { useState } from 'react'
import { motion } from 'framer-motion';
import useUserStore from '../../../store';
import AllOrder from './AllOrders';
import TNC from './Tnc';
import FaqQuestions from './FaqQuestions';
import CouponCode from './CouponCode';
import ExerciseForm from './ExerciseForm';
import { Link } from 'react-router-dom';

const Admin = () => {

    const [isActive, setIsActive] = useState(1);
    const handleActive = (btn) => setIsActive(btn);
    const user = useUserStore((state) => state.user)

  return (
    <div className="dashMembers">
    <motion.h1 className="dashboard-title dashMembers-container"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
    >
         {["Admin", "Regional Manager"].includes(user.role) ? "PM Admin Panel" : "All Orders" }
         {["Admin", "Regional Manager"].includes(user.role)? 
         <Link to="/dashboard/ads" className="table-link">
              Add Ads
          </Link> : ""}
    </motion.h1>
    <div className="tabsContainer">
      <motion.div className="btnContainer"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: .2 }}
      >
        <button
          className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
          onClick={() => handleActive(1)}
        >
          Orders 
        </button>
      {["Admin", "Regional Manager"].includes(user.role) ? <>
        <button
          className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
          onClick={() => handleActive(2)}
        >
          TNC
        </button>
        
        <button
          className={`tabs ${isActive === 3 ? "activeTab" : ""}`}
          onClick={() => handleActive(3)}
        >
          FAQ 
        </button>

        <button
          className={`tabs ${isActive === 4 ? "activeTab" : ""}`}
          onClick={() => handleActive(4)}
        >
          Coupon 
        </button>
        </> : ""}
          <button
            className={`tabs ${isActive === 5 ? "activeTab" : ""}`}
            onClick={() => handleActive(5)}
          >
            Form 
          </button>

      </motion.div>
      
      {isActive === 1 && (
        <div className="tabData">
          <motion.div className="tabContent"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: 0.4 }}
          >
              <AllOrder/>
          </motion.div>
        </div>
      )}
      {["Admin", "Regional Manager"].includes(user.role) ? <>
      {isActive === 2 && (
        <div className="tabData">
        <motion.div className="tabContent"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: 0.4 }}
          >
              <TNC/>
          </motion.div>
        </div>
      )}
      {isActive === 3 && (
        <div className="tabData">
        <motion.div className="tabContent"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: 0.4 }}
          >
              <FaqQuestions/>
          </motion.div>
        </div>
      )}
      {isActive === 4 && (
        <div className="tabData">
        <motion.div className="tabContent"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.8 }}
          >
              <CouponCode/>
          </motion.div>
        </div>
      )} </> : ""}
      {isActive === 5 && (
        <div className="tabData">
        <motion.div className="tabContent"
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: 0.8 }}
          >
              <ExerciseForm />
          </motion.div>
        </div>
      )}



    </div>
  </div>

  )
}

export default Admin;