import React, { useState } from 'react'
import {toast} from "react-hot-toast"

import "../../css/Contact.css"
import instance from "../../utils/axios"

const Contact = () => {
    
    const [contact, setContact] = useState("");

    const setData = (e) => {
        const {name, value} = e.target;
        setContact((prev) =>{
            return {
                ...prev, 
                [name]: value
            }
        })
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (!contact.name || !contact.email || !contact.query) {
            toast.error("Please fill in all fields");
            return;
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(contact.email)) {
            toast.error("Please enter a valid email address");
            return;
        }
    
        try {
            const response = await instance.post('/api/user/contact', {
                name: contact.name,
                email: contact.email,
                query: contact.query
            })
            if (response.data.success) {
                toast.success("Your message is submitted successfully");
                setContact({
                    name: "",
                    email: "",
                    query: ""
                })
            } else {
                toast.error(response.error.message);
            }
        } catch (error) {
            toast.error("An error occurred! Please try again later")
        }
    }

  return (
    <div>
        <h2 className="website-header contact-h1">Contact Us</h2>
        <div className="contact">

                <div className="contact-container">
                    <h3 className="contact-h3">Leave A Message</h3>
                    <h4 className="contact-h4">Your Queries/ Feedbacks Matters To Us</h4>
                    <form className='contact-form' onSubmit={handleSubmit} >
                        <div className='contact-input-container'>
                            <label className='contact-label' htmlFor="name">Name</label>
                            <input type="text" name='name' required value={contact.name} onChange={setData} className='contact-input' placeholder='Enter Your Name' />
                        </div>
                        <div className='contact-input-container'>
                            <label className='contact-label' htmlFor="email">Email</label>
                            <input type="email" name='email' required value={contact.email} onChange={setData} className='contact-input' placeholder='Enter Your Email' />
                        </div>
                        <div className='contact-input-container'>
                            <label className='contact-label' htmlFor="query">Message</label>
                            <textarea  cols="30" name='query' required value={contact.query} onChange={setData} rows="10" 
                            className='contact-input'
                            placeholder="Enter Your Message"></textarea>                    
                        </div>
                        <button className="btn">Send Message</button>
                    </form>
                </div>
                <div className="people">
                <div>
                    <p><span>Versova Branch</span> <br/> Regional Manager: +91 8097261625 <br /> Manager: +91 8454864948 <br /> Sales Manager: +91 7208798279 <br /> Reception: +91 8454858269  </p> 
                </div>
                <div>
                    <p><span>Andheri Branch </span> <br/> Regional Manager: +91 8097261625 <br /> Manager: +91 8454941861  <br /> Sales Manager: +91 9137784435 <br/> Reception: +91 9137829886 </p> 
                </div>
            </div>
        </div>


    </div>
  )
}

export default Contact