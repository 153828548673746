import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {defaultUrl} from "../../data/Images";
import "../../css/CustomerReview.css";
import { content } from "../../data/Review";

const CustomerReview = () => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  const nextText = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentContentIndex((currentContentIndex + 1) % content.length);
      setTransition(false);
    }, 500);
  };

  const prevText = () => {
    setTransition(true);
    setTimeout(() => {
      setCurrentContentIndex(
        currentContentIndex === 0 ? content.length - 1 : currentContentIndex - 1
      );
      setTransition(false);
    }, 500);
  };

  return (
    <div>
      <div className="testimonial">
        <div className="website-header">Customer Review</div>
        <div className="testimonial-header">
          <ChevronLeftIcon className="svg-left" onClick={prevText} />
          <div className={`testimonial-card ${transition ? "transition" : ""}`}>
            <div className="testimonial-image">
                <img src={content[currentContentIndex].img || defaultUrl} alt="testimonial" />
            </div>
            <div className="testimonial-container">
              <h1 className="testimonial-title">
                {content[currentContentIndex].title}
              </h1>
              <p className="testimonial-desc">
                {content[currentContentIndex].desc}
              </p>
            </div>
          </div>
          <ChevronRightIcon className="svg-right" onClick={nextText} />
        </div>
      </div>
    </div>
  );
};

export default CustomerReview;
