import React, { useState, useEffect } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import "../../css/Notifications.css"
import { motion } from 'framer-motion';
import DeleteIcon  from '@mui/icons-material/Delete';
import AutorenewIcon  from '@mui/icons-material/Autorenew';


const DashAds = () => {

    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(false);
    const [ad, setAd] = useState([]);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        if (!file) {
            toast.error("Please Select an Image First");
            return;
        }

    try{
        const response = await instance.post('/api/user/advertisement', {
            file: file
        });
        if (response.data.success) {
            setLoading(false);
            setFile("");
            toast.success("Advertisement added Successfully");
            getData();
        } else {
            toast.error(response.data.error)
        }
    }
    catch (error) {
        console.log(error);
        toast.error("An Error Occurred! Please Try Again!!")
    }
  };

  const getData = async () => {
    try {
        const response = await instance.get("/api/user/getAdvertisement")
        if (response.data.success) {
            setAd(response.data.data);
        } else {
            toast.error(response.data.message)
        }
    } catch (error) {
        console.log(error.message);
        toast.error(error.message)
    }
  }

  useEffect(() => {
    getData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async(id) =>{
    try{  
    const response = await instance.post(`/api/user/deleteAdvertisement/${id}`);
      if (response.data.success) {
        toast.success("Ad deleted successfully");
        setAd((prevData) => prevData.filter((product) => product._id !== id));
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
          toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred');
    }
    }
  }

  const handleRemoveImage = () => {
    setFile(null); // Set the file state to null to remove the image
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      toast.error('No file selected.');
      return;
    }

    if (file) {
      toast.error('Maximum one file allowed.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = () => {
      setFile(reader.result);
    };

    reader.onerror = (error) => {
      toast.error(error);
    };
  };


  return (
    <div className="dashMembers">
        <motion.h1 className="dashboard-title dashMembers-container"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
        >
            Advertisements
        </motion.h1>
        <div className="product-form-container">
            <form onSubmit={handleSubmit} className="product-form">
            <div className="product-image-div">
              <div className="product-image-container">
              {file && (
            <div className="productImgContainer">
              <img src={file} alt="" className="product-img" />
              <div>
                <DeleteIcon className="delete-btn" onClick={handleRemoveImage}/>
              </div>
            </div>
          )}
          {!file && (
            <div>
              <label className="product-label" htmlFor="file">
                <DriveFolderUploadIcon className="icons" />
              </label>
              <input
                type="file"
                id="file"
                required
                accept=".png, .jpeg, .jpg"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>
          )}
              </div>
            </div>
            <button
                type="submit"
                className={`dash-btn ${loading ? "upload-btn" : ""}`}
              >
                {loading ? (
                  <AutorenewIcon className="loader-css" />
                ) : (
                  "Add" 
                )}
              </button>
            </form>
        </div>
        <div className="product-form-container ad-container">
            <div className="collection-h3">
                Current Advertisements
            </div>
            {ad && ad.slice().reverse().map((product) => (
                <div className="ads">
                    <div className="ads-container">
                        <img src={product.file.secure_url} alt="" className="adsImg" />
                        <DeleteIcon onClick={() => handleDelete(product._id)} className="ad-delete-icon" />
                    </div>
                    <div className="ads-time">
                        Date: {new Date(product.createdAt).toLocaleDateString("en-GB")}
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default DashAds;
