import React, { useEffect, useState } from 'react'
import DeleteModal from '../../components/Modal/DeleteModal';
import useUserStore from '../../../store';
import instance from '../../../utils/axios';
import toast from 'react-hot-toast';
import {motion} from "framer-motion";
import { DataGrid } from '@mui/x-data-grid';

const NewsLetters = () => {
    const [userData, setUserData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState("");
  
    const user = useUserStore((state) => state.user)
  
    useEffect(() => {
      instance.get('/api/user/getEmails').then((response) => {
          if (response.data.success) {
            const rows = response.data.data &&  response.data.data.slice().reverse().map((user, index) => {        
              return {
                id: user._id,
                col1: index + 1,
                col2: user.email,
                col3: new Date(user.createdAt).toLocaleDateString("en-GB"),
              };
            });
          
            setUserData(rows);
          }
        })
        .catch((error) => {
        });
    }, []);
    
    const handleDelete = async (id) => {
      setUserIdToDelete(id);
      setShowModal(true);
    };
  
    const confirmDelete = async () => {
      try {
        const response = await instance.post(`/api/user/deleteEmails/${userIdToDelete}`);
        if (response.data.success) {
          toast.success(response.data.message);
          setUserData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
          setShowModal(false);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
          toast.error('An error occurred');
      }
    };
  
    const columns = [
      { field: 'col1', headerName: 'Index', width: 50 },
      { field: 'col2', headerName: 'Email', width: 350 },
      { field: 'col3', headerName: 'Subscribed On', width: 200 },
      {
        field: 'action',
        headerName: 'Action',
        width: 100,
        renderCell: (params) => (
          <div className="cellAction">
            {user.role === "Admin" ? <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div> : ""  }        
          </div>
        ),
      },
    ];
  
    return (
      <div className='data2Table'>
        <motion.div className='dataGrid modal-opener'
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
        >
          <DataGrid
            rows={userData}
            columns={columns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            sx={{ color: "var(--black)", fontWeight: "400" }}
          />
        </motion.div>
        {showModal && (
          <DeleteModal 
            h4="Are you sure you want to delete this EMail ID?"
            confirmDelete={confirmDelete}
            closeModal={() => setShowModal(false)}
        />

        )}
      </div>
    );
  };
  
export default NewsLetters;