import React, { useState, useEffect } from "react";
import "../../css/DashTask.css";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import useUserStore from "../../../store";
import DeleteIcon from '@mui/icons-material/Delete';
import { motion } from "framer-motion";
import AutorenewIcon from '@mui/icons-material/Autorenew';

const DashTask = () => {
  const [uniqueId, setUniqueId] = useState([]);
  const [allTask, setAllTask] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const handleActive = (btn) => setIsActive(btn);
  const [taskData, setTaskData] = useState({
    username1: [],
    task: "",
  });
  const [taskData1, setTaskData1] = useState([]);
  const user = useUserStore((state) => state.user);
  const [createdTasks, setCreatedTasks] = useState([]);
  const [refreshTasks, setRefreshTasks] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setTaskData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  useEffect(() => {
    instance
      .get("/api/user/usernames", {
        params:{
          userRole: user.role,
          userGymLocation: user.gymLocation,
        },
      })
      .then((response) => {
        setUniqueId(response.data.data);
      })
  }, [user.role, user.gymLocation]);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await instance.get("/api/user/getTaskData", {
          params: {
            assigned: user.uniqueId,
          },
        });

        if (response.data.success) {
          setTaskData1(response.data.data);
        } else {
          console.error(response.data.error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTaskData();
  }, [user.uniqueId, refreshTasks]);

  useEffect(() => {
    const fetchCreatedTasks = async () => {
      try {
        const response = await instance.get("/api/user/getCreatedTasks", {
          params: {
            uniqueId: user.uniqueId,
          },
        });

        if (response.data.success) {
          setCreatedTasks(response.data.data);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCreatedTasks();
  }, [user.uniqueId]);

  const handleCheckboxChange = (e, uniqueId) => {
    const { checked } = e.target;
  
    setTaskData((prevData) => {
      if (checked) {
        // If checkbox is checked, add the username to the array
        return {
          ...prevData,
          username1: [...prevData.username1, uniqueId],
        };
      } else {
        // If checkbox is unchecked, remove the username from the array
        return {
          ...prevData,
          username1: prevData.username1.filter((user) => user !== uniqueId),
        };
      }
    });
  };
    
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await instance.post("/api/user/taskData", {
        uniqueId: user.uniqueId,
        assigned: taskData.username1,
        task: taskData.task,
        date: taskData.date,
        completed: false,
      });
  
      if (response.data.success) {
        setLoading(false);
        setCreatedTasks((prevTasks) => [...prevTasks, ...response.data.data]);
        toast.success("Task Assigned Successfully");
  
        const notificationPromises = response.data.data.map((task) =>
          instance.post("/api/user/notification", {
            recipient: task.assigned,
            url: user.file.secure_url || null,
            message: `${task.task}`,
            name: `${user.uniqueId}`,
            location: "Task",
          })
        );
        
        const notificationResponses = await Promise.all(notificationPromises);
  
        notificationResponses.forEach((response1) => {
          if (response1.data.success) {
            toast.success("Notification Sent Successfully");
          } else {
            setLoading(false);
            toast.error(response1.data.message);
          }
        });
  
        setTaskData({
          username1: [],
          task: "",
          date: "",
        });
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
    

  useEffect(() => {
    const fetchAllTasks = async () => {
      try {
        const response = await instance.get("/api/user/allTaskData");
        if (response.data.success) {
          setAllTask(response.data.data);
        } else {
          toast.error("An error occurred!")
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchAllTasks();
  }, [])

  function handleTaskCompletion(taskId) {
    const updatedTaskData = createdTasks.map((task) => {
      if (task._id === taskId) {
        return {
          ...task,
          completed: true,
        };
      }
      return task;
    });
    instance
      .put(`/api/user/taskData/${taskId}`, { completed: true, uniqueId: user.uniqueId })
      .then((response) => {
        if (response.data.success) {
          setCreatedTasks(updatedTaskData);
          toast.success("Task Completion Updated");
          setTaskData1((prevTaskData) => {
            // Update the completed flag in the task data
            const updatedData = prevTaskData.map((task) => {
              if (task._id === taskId) {
                return {
                  ...task,
                  completed: true,
                };
              }
              return task;
            });
            return updatedData;
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => console.error(error.message));
  }
  const assignedTasks = taskData1.filter((task) => !task.completed);
  const completedTasks = taskData1.filter((task) => task.completed);

  async function handleDeleteTask(id) {
    await instance
      .post(`/api/user/deleteTask/${id}`)
      .then((response) => {
        if (response.data.success) {
          const updatedTasks = createdTasks.filter((task) => task._id !== id);
          setCreatedTasks(updatedTasks);
          setRefreshTasks(true);
          toast.success("Task Deleted Successfully");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => console.error(error.message));
  }

  return (
    <>
        <div className="dashMembers">
          <motion.h1 className="dashboard-title dashMembers-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0 }}
          >PM Task Board
          </motion.h1>
          <div className="tabsContainer">
            <motion.div className="btnContainer"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <button
                className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
                onClick={() => handleActive(1)}
              >
                Your Task
              </button>
              <button
                className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
                onClick={() => handleActive(2)}
              >
                Add Task
              </button>
              <button
                className={`tabs ${isActive === 3 ? "activeTab" : ""}`}
                onClick={() => handleActive(3)}
              >
                History
              </button>
            </motion.div>
            {isActive === 1 && (
              <motion.div className="tabData"                                             
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: 0.3 }}
>
                <div className="tabContent">
                    <div className="main-task">
                        <div className="maintask-container">
                        <h3 className="main-title">Assigned Task </h3>
                        {assignedTasks && assignedTasks.map((task) => (
                            <div className="assigned-container" key={task._id}>
                            <div className="ass-left">
                                <h6 className="ass-username">Assigned From: {task.uniqueId}</h6>
                                <h3 className="ass-task">Task: {task.task}</h3>
                            </div>
                            <div className="ass-right">
                                {!task.completed && (
                                <input
                                    type="checkbox"
                                    name="check"
                                    id={task._id}
                                    onChange={() => handleTaskCompletion(task._id)}
                                />
                                )}
                                <div className="ass-due-date">Deadline: {new Date(task.date).toLocaleDateString('en-GB')} {new Date(task.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            </div>
                            </div>
                        ))}
                        </div>
                        <hr />
                        <div className="maintask-container">
                        <h3 className="main-title">Completed Task</h3>
                        {completedTasks && completedTasks.map((task) => (
                            <div className="assigned-container" key={task._id}>
                            <div className="ass-left">
                                <h6 className="ass-username">Assigned From: {task.uniqueId}</h6>
                                <h3 className="ass-task">Task: {task.task}</h3>
                            </div>
                            <div className="ass-right">
                                <input type="checkbox" name="check" id={task._id} disabled checked />
                                <div className="ass-due-date">Task Completed On: {new Date(task.updatedAt).toLocaleDateString('en-GB')} {new Date(task.updatedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
              </motion.div>
            )}
            {isActive === 2 && (
              <motion.div className="tabData"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                <div className="tabContent">
                  <div className="product-form-container">
                    <form onSubmit={handleSubmit} className="product-form">
                      <div className="product-input-div">
                        <label className="product-label" htmlFor="uniqueId">Assigned by </label>
                        <input
                          type="text"
                          required
                          name="uniqueId"
                          value={user.uniqueId}
                          readOnly="readOnly"
                        />
                      </div>
                      <div className="product-input-div">
                        <label className="product-label" htmlFor="uniqueId">Add Unique ID</label>
                        {uniqueId?.map((username, index) => (
                          <div className="unique-id" key={username}>
                            <h5> {index + 1}. {username}</h5>
                            <input
                              type="checkbox"
                              id={username}
                              name="username1"
                              value={username}
                              checked={taskData.username1.includes(username)}
                              onChange={(e) => handleCheckboxChange(e, username)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="product-input-div">
                        <label className="product-label" htmlFor="task">Add Task </label>
                        <input
                          type="text"
                          name="task"
                          id="task"
                          value={taskData.task}
                          onChange={handleChange}
                          required
                          placeholder="Enter task"
                        />
                      </div>
                      <div className="product-input-div">
                        <label className="product-label" htmlFor="task">Deadline</label>
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={taskData.date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <button type="submit" className={`dash-btn ${loading ? 'upload-btn' : ''}`}>
                          {loading ? <AutorenewIcon className="loader-css" /> : "Assign Task"}
                      </button>
                    </form>
                  </div>
                </div>
              </motion.div>
            )}
           {isActive === 3 && (
                <motion.div className="tabData"
                                            initial={{ opacity: 0, y: 50 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.5, delay: 0.8 }}
                >
                  <div className="tabContent">
                      <div className="main-task">
                        <div className="maintask-container">
                        <h3 className="main-title">History Tasks</h3>
                    {createdTasks && createdTasks.length > 0 && createdTasks.map((task) => (
                      <div className="new-assigned-container" key={task._id}>
                        <div className="ass-left">
                          <h6 className="ass-username">Assigned From: {task.uniqueId}</h6>
                          <h4 className="ass-username">Assigned To: {task.assigned.uniqueId}</h4>
                          <h3 className="ass-task">Task: {task.task}</h3>
                        </div>
                        <div className="ass-right">
                          {task.completed ? (
                            <>
                              <input
                                type="checkbox"
                                name="check"
                                id={task._id}
                                disabled
                                checked
                              />
                              <div className="ass-due-date">
                                Task completed on:{" "}
                                {new Date(task.updatedAt).toLocaleDateString('en-GB')}{" "}
                                {new Date(task.updatedAt).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="check"
                                id={task._id}
                                onChange={() => handleTaskCompletion(task._id)}
                              />
                              <div className="ass-due-date">
                                Due Date: {new Date(task.date).toLocaleDateString('en-GB')}
                              </div>
                            </>
                          )}
                          <DeleteIcon
                            className="delete-icon"
                            style={{color: "var(--red)"}}
                            onClick={() => handleDeleteTask(task._id)}
                          />
                        </div>
                      </div>
                    ))}

                        </div>
                      </div>
                      {user.role === "Admin" ? <>
                      <div className="main-task">
                        <div className="maintask-container">
                        <h3 className="main-title">All Tasks</h3>
                      {allTask?.map((task) => (
                      <div className="new-assigned-container" key={task._id}>
                        <div className="ass-left">
                          <h6 className="ass-username">Assigned From: {task.uniqueId}</h6>
                          <h4 className="ass-username">Assigned To: {task.assigned.uniqueId}</h4>
                          <h3 className="ass-task">Task: {task.task}</h3>
                        </div>
                        <div className="ass-right">
                          {task.completed ? (
                            <>
                              <input
                                type="checkbox"
                                name="check"
                                id={task._id}
                                disabled
                                checked
                              />
                              <div className="ass-due-date">
                                Task completed on:{" "}
                                {new Date(task.updatedAt).toLocaleDateString('en-GB')}{" "}
                                {new Date(task.updatedAt).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="check"
                                id={task._id}
                                onChange={() => handleTaskCompletion(task._id)}
                              />
                              <div className="ass-due-date">
                                Deadline: {new Date(task.date).toLocaleDateString('en-GB')}
                              </div>
                            </>
                          )}
                          <DeleteIcon
                            className="delete-icon"
                            style={{color: "var(--red)"}}
                            onClick={() => handleDeleteTask(task._id)}
                          />
                        </div>
                      </div>
                    ))}

                        </div>
                      </div>
                    </>:""}
                  </div>
                </motion.div>
              )}
          </div>
        </div>

    </>
  );
};

export default DashTask;
