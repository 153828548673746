import React, { useState } from 'react';
import PendingUser from './PendingUser';
import Requests from './Requests';
import { motion } from 'framer-motion';
import useUserStore from '../../../store';

const DashRequest = () => {
  const [isActive, setIsActive] = useState(1);
  const user = useUserStore((state) => state.user);

  const handleActive = (btn) => setIsActive(btn);

  return (
    <div className="dashMembers">
      <motion.h3
        className="dashboard-title dashMembers-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        All Queries
      </motion.h3>
      <div className='tabsContainer'>
        <motion.div
          className="btnContainer"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <button
            className={`tabs ${isActive === 1 ? 'activeTab' : ''}`}
            onClick={() => handleActive(1)}
          >
            Contact Query
          </button>
          {["Admin", "Regional Manager"].includes(user.role) ? (
            <button
              className={`tabs ${isActive === 2 ? 'activeTab' : ''}`}
              onClick={() => handleActive(2)}
            >
              Staff Request
            </button>
          ) : null}
          {/* {["Admin", "Regional Manager"].includes(user.role) ? (
            <button
              className={`tabs ${isActive === 3 ? 'activeTab' : ''}`}
              onClick={() => handleActive(3)}
            >
              Feedback Request
            </button>
          ) : null} */}

        </motion.div>
        <div className="tabData">
          <motion.div
            className="tabContent"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.5 }}
          >
            {isActive === 1 && <Requests />}
            {isActive === 2 &&
              ["Admin", "Regional Manager"].includes(user.role) && (
                <PendingUser />
              )}
            {/* {isActive === 3 &&
              ["Admin", "Regional Manager"].includes(user.role) && (
                <FeedbackReq />
              )} */}
          </motion.div>
        </div>
      </div>
    </div>

  );
};

export default DashRequest;
