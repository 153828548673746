import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import instance from '../../utils/axios';
import { motion } from "framer-motion"
import toast from 'react-hot-toast';
import Layout from "../MainLayout/Layout"
import "../../css/Articles.css"
import Loader from '../../components/MainLoader/Loader';

const Articles = () => {

  const [articlesData, setArticlesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await instance.get("/api/user/getArticlesData")
      if (response.data.success) {
        setLoading(false);
        setArticlesData(response.data.data);
      } else {
        setLoading(false);
        console.error(response.data.error);
        toast.error("Something Went Wrong! Please reload the page");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch articles data");
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <Layout>        
        <motion.div
            className="articlesPage"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0 }}
        >
            <div className="article-container">
              <h1 className="website-header">Planet Muscle Articles</h1>
            {loading ? (<Loader />) : 
            articlesData.length === 0 ? (
                <div className="no-product-div">
                  <h3 className="empty-product-h3">Oops... No Articles Yet</h3>
                  <h6 className="empty-product-h6">Go ahead and explore our other categories</h6>
                </div>) : (
                <>
                {articlesData && articlesData.slice().reverse().map((article, value) => (
                    <div className="article-box" key={value}>
                      <div className="article-image">
                          <Link key={article._id} to={`${article._id}`}>
                          <img src={article.file.secure_url} alt="" />
                          </Link>
                      </div>
                      <div className="article-heading">
                          <h4 className="article-h4"> {article.title} </h4>
                          <p className="article-p">{article.article.slice(0, 150) + '...'}</p>
                          <Link className='Link' key={article._id} to={`${article._id}`} >Read More...</Link>
                      </div>
                    </div>
                ))}</>)}
            </div>
        </motion.div>
      </Layout>
    </>
  )
}

export default Articles;