import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import DeleteModal from '../../components/Modal/DeleteModal';

const Requests = () => {

  const [query, setQuery] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");


  useEffect(() => {
    instance.get('/api/user/getContact').then((response) => {
      if (response.data.success) {
        const rows = response.data.data && response.data.data.slice().reverse().map((info) => ({
          id: info._id,
          col1: info.name,
          col2: info.email,
          col3: info.query,
        }));
        setQuery(rows);
      }
    }).catch((error) => {
    })
  }, []);

  const columns = [
    { field: 'col1', headerName: 'Name', width: 120 },
    { field: 'col2', headerName: 'Email', width: 250 },
    { field: 'col3', headerName: 'Message', width: 450 }
  ];

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteContact/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("User deleted successfully");
        setQuery((prevData) => prevData.filter((query) => query.id !== userIdToDelete));
        setShowModal(false);
      } else if (response.data.error) {
        toast.error(response.data.error);
      } else {
        toast.error("An Error Occured")
      }
    } catch (error) {
      toast.error(error.message);
    }
  };


  const actionColumn = [
  {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <div className="deleteButton" onClick={()=>handleDelete(params.id)} >Delete</div>
        </div>
      );
    },
  },
]; 


  return (
    <div className='data2Table'>
      <div className='dataGrid'>
        <DataGrid 
            rows={query}   
            columns={columns.concat(actionColumn)}         
            pageSize={9}
            rowsPerPageOptions={[9]}
        />
      </div>
      {showModal && (
        <DeleteModal 
          h4="Are you sure you want to delete this query?"
          confirmDelete={confirmDelete}
          closeModal={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default Requests;