import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Instagram, LinkedIn, YouTube } from '@mui/icons-material';

import instance from '../../utils/axios';
import '../../css/Footer.css';

const Footer = () => {
    const [email, setEmail] = useState('');

    const handleInputChange = (e) => setEmail(e.target.value);

    const handleNewsLetter = async (e) => {
        e.preventDefault();
        if (!email || !email.includes('@') || !email.includes('.')) {
            toast.error('Please enter a valid email address');
            return;
        }
        try {
            const response = await instance.post('/api/user/newsLetter', { email: email.toLowerCase()});
            if (response.data.success) {
                toast.success(response.data.message);
                setEmail('');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('An Error Occurred');
        }
    };

    const socialLinks = [
        { url: 'https://www.instagram.com/planetmuscle_official/', icon: <Instagram className='footer-icon' />, title: "Instagram" },
        { url: 'https://www.linkedin.com/company/planetmusclefitness/', icon: <LinkedIn className='footer-icon'/>, title: "LinkedIn"  },
        { url: 'https://www.youtube.com/@planetmusclepfc', icon: <YouTube className='footer-icon' />, title: "Youtube" },
    ];

    return (
        <div className='footer'>
            <div className="footer-content">
                <div className="footer-left">
                    <div>Planet Muscle</div>
                    <span>Planet Muscle is the best gym with the best quality one can ever have</span>
                    <div className="newsletter">
                        <h5>Subscribe to our Newsletter</h5>
                        <form onSubmit={handleNewsLetter} className='email-container'>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                autoComplete='off'
                                onChange={handleInputChange}
                                placeholder='name@gmail.com'
                            />
                            <button type='submit' className='btn'>Join</button>
                        </form>
                    </div>
                </div>
                <div className="footer-right">
                    {['Company', 'Merchandise', 'Get In Touch'].map((category, index) => (
                        <div className="about-us" key={index}>
                            <h4>{category}</h4>
                            <ul>
                                {category === 'Company' ?
                                    [<Link className='footer-links' to={'/about-us'}>About Us</Link>,
                                    <Link className='footer-links' to={'/about-us'}>Community</Link>,
                                    <Link className='footer-links' to={'/about-us'}>Jobs</Link>] :
                                    category === 'Merchandise' ?
                                        [<Link className='footer-links' to={'/products'}>Products</Link>,
                                        <Link className='footer-links' to={'/programs'}>Events</Link>,
                                        <Link className='footer-links' to={'/articles'}>Articles</Link>] :
                                        socialLinks.map((link, index) => (
                                            <li className='footer-social-links footer-links' key={index}>
                                                <a href={link.url} className="footer-links-icon" target="_blank" rel="noopener noreferrer">
                                                    <h4 className="footer-links">{link.title}</h4>
                                                </a>
                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className='last-line'>
                &copy; Copyright! ( Shubham Awasthi - 8369722010 | Tanish Jain - 7666311799 ). All Rights Reserved.
            </div>
        </div>
    );
};

export default Footer;
