import React, { useState } from 'react'
import "../../css/DashProfile.css"
import AddIcon from '@mui/icons-material/Add';
import {motion} from "framer-motion";
import UserOrder from "./UserOrder";
import DashProfileData from './DashProfileData'
import CurrentPlans from '../DashboardUserPage/CurrentPlans';
import useUserStore from '../../../store';

const DashProfile = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const user = useUserStore((state) => state.user);

  const handleAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  const handleWindowClick = (e) => {
    if (!e.target.matches('.accordion-header')) {
      setActiveAccordion(null);
    }
  };

  window.onclick = handleWindowClick;


  return (
    <div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.0 }}
        >
            <DashProfileData />
        </motion.div>
        <div className="profile-main">
          <motion.div className="accordion"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <div className="accordion-item">
              <button
                className={`accordion-header ${activeAccordion === 0 ? 'active' : ''}`}
                onClick={() => handleAccordionClick(0)}
              >
                <span className="accordion-span">Your Orders</span> <AddIcon className="icons" />
              </button>
              <div className={`accordion-body ${activeAccordion === 0 ? 'active' : ''}`}>
                <UserOrder />
              </div>
            </div>
            <div className="accordion-item">
              <button
                className={`accordion-header ${activeAccordion === 1 ? 'active' : ''}`}
                onClick={() => handleAccordionClick(1)}
              >
                <span className="accordion-span">Ongoing Plans </span> <AddIcon className="icons" />
              </button>
              <div className={`accordion-body ${activeAccordion === 1 ? 'active' : ''}`}>
                <CurrentPlans userId={user._id} userName={user.username} />
              </div>
            </div>
          </motion.div>
        </div>
    </div>
  )
}

export default DashProfile;