import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { motion } from "framer-motion"

const ListSales = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState([]);
    const [length, setLength] = useState(0);
    const [username, setUsername] = useState("");

    useEffect(() => {
        async function getIndividualProspect() {
            const response = await instance.get(`/api/user/individualProspects/${id}`)
            if (response.data.success) {
                setUsername(response.data.name);
                const rows = response.data && response.data.data.slice().reverse().map((userData) => {
                    const createdAtDate = new Date(userData.createdAt);
                    const updatedAtDate = new Date(userData.updatedAt);

                    const col5 = `${createdAtDate.getDate()} ${createdAtDate.toLocaleString('default', { month: 'short' })} ${createdAtDate.getFullYear()}, ${createdAtDate.toLocaleTimeString()}`;
                    const col6 = `${updatedAtDate.getDate()} ${updatedAtDate.toLocaleString('default', { month: 'short' })} ${updatedAtDate.getFullYear()}, ${updatedAtDate.toLocaleTimeString()}`;
                    const col10 = userData.status.includes("Converted") ? "Converted" : "Declined";

                    return {
                        id: userData._id,
                        col1: userData.uniqueId,
                        col2: userData.name,
                        col3: userData.mobile,
                        col4: userData.email,
                        col5,
                        col6,
                        col7: userData.interest,
                        col8: userData.referral,
                        col9: userData.gymLocation ? userData.gymLocation : "NA",
                        col10
                    };
                });
                setUserData(rows);
                setLength(response.data.data.length);
            }
        }
        getIndividualProspect();
    }, [id]);

    const columns = [
        { field: 'col1', headerName: 'ID', width: 70 },
        { field: 'col2', headerName: 'Client Name', width: 120 },
        { field: 'col3', headerName: 'Mobile', width: 120 },
        { field: 'col4', headerName: 'Email', width: 200 },
        { field: 'col5', headerName: 'Prospect Date', width: 210 },
        { field: 'col6', headerName: 'Called Date', width: 210 },
        { field: 'col7', headerName: 'Plan Interested', width: 180 },
        { field: 'col8', headerName: 'Referral', width: 120 },
        { field: 'col9', headerName: 'Center Name', width: 120 },
        { field: 'col10', headerName: 'Conversion Status', width: 180 },
    ];

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {

                return (
                    <div className="cellAction">
                        <Link to={`/dashboard/users/userPage/${params.row.id}`} className='Link' >
                            <div className="viewButton">View</div>
                        </Link>
                    </div>
                );
            },
        },
    ];


    return (
        <div className='dataTable '>
            <motion.div className="datatableTitle dashboard-title"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
            >
                {username} Sales Details ({length})
            </motion.div>
            <motion.div className='dataGrid'
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
            >
                <DataGrid
                    rows={userData}
                    columns={columns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                />
            </motion.div>
        </div>

    )
}

export default ListSales;
