import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search"
import "./Message.css"
import instance from "../../utils/axios";
import { defaultUrl } from "../../data/Images"
import toast from "react-hot-toast";
import useUserStore from './../../store';
import moment from 'moment';

const ChatSidebar = ({ onChatRoomClick }) => {

  const [chats, setChats] = useState([]);
  const user = useUserStore((state) => state.user)

  const getData = async () => {
    try {
      const response = await instance.get(`/api/user/getUserGroups/${user._id}`,{
        params: {
          Role: user.role
        }
      });
      if (response.data.success) {
        setChats(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Get User Names error:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [user._id]);

  return (
    <div className="content-sidebar">
      <form action="" className="content-sidebar-form">
        <button type="submit" className="content-sidebar-submit">
          <SearchIcon sx={{fontSize: "24px"}} />
        </button>
        <input
          type="text"
          className="content-sidebar-input"
          placeholder="Search"
        />
      </form>
      <div className="content-messages">
        <ul className="content-messages-list">
          {!chats ||  chats.length === 0  ? <span>Currently no Rooms!</span> : (
            <>
            {chats.map((chat, index) => (
              <li key={index}>
                <Link to={`/message/${chat._id}`} onClick={() => onChatRoomClick(chat._id)}>
                  <img
                    className="content-message-image"          
                    src={chat?.profilePicture?.secure_url || defaultUrl}          
                    alt=""
                  />
                  <span className="content-message-info">
                    <span className="content-message-name">{chat.chatName}</span>
                    <span className="content-message-text">{chat.latestMessage.publicId ? "File" : chat.latestMessage}</span>
                  </span>
                  <span className="content-message-more">
                    <span className="content-message-time">{chat.lastMessageTime ? moment(chat.lastMessageTime).format('HH:mm') : moment(chat.createdAt).format('HH:mm')}</span>
                    {chat.unreadMessages > 0 && <span className="content-message-unread">{chat.unreadMessages}</span>}
                  </span>
                </Link>
              </li>
            ))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ChatSidebar;
