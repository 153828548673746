import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import "../../css/DashMembers.css"
import {motion} from "framer-motion"
import { Link } from 'react-router-dom';

const Events = ({ isOnline }) => {
  const [userData, setUserData] = useState([]);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };


  useEffect(() => {
    instance.get('/api/user/getProgramsData').then((response) => {
      if (response.data.success) {
        let filteredData = response.data.data;  
        const rows = filteredData?.slice().reverse().map((user) => {
          return {
            id: user._id,
            col1: user.file && user.file.secure_url,
            col2: user.category,
            col3: user.pincode,
            col4: formatAmount(user.price),
            col5: new Date(user.createdAt).toLocaleDateString("en-GB"),
            col6: user.quantity,
            col7: user.ticketsSold,
            col8: isOnline === "Online" ? user.websiteTickets : user.manualTickets ,
            col9: user.quantityLeft,
            col10: formatAmount(user.totalEarning),
            col11: isOnline === "Online" ? formatAmount(user.websiteEarning) : formatAmount(user.manualEarning),
            col12: formatAmount(user.gstAmount),
            col13: user?.platformFees || "₹0",
            col14: formatAmount(user.profit),
          }
        });        
        setUserData(rows);
      }
    }).catch((error) => {
        console.error("An Error Occurred");
    });
  }, [isOnline]);

    


  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 70,
      renderCell: (params) => (
        <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Event Name', width: 150, renderCell: (params) => (
      <Link className='link-userpage-member' to= {`/dashboard/programs/analysis/${params.row.id}`}>
        <div className="nameLink">
          {params.value}    
        </div>
      </Link>
    )},
    { field: 'col3', headerName: 'Event PinCode', width: 150 },
    { field: 'col4', headerName: 'Price', width: 100 },
    { field: 'col5', headerName: 'Created On', width: 100 },
    { field: 'col6', headerName: 'Quantity', width: 100 },
    { field: 'col7', headerName: 'Quantity Sold', width: 100 },
    { field: 'col8', headerName: `${isOnline} Sold`, width: 100 },
    { field: 'col9', headerName: 'Quantity Left', width: 150 },
    { field: 'col10', headerName: 'Total Sales', width: 150 },
    { field: 'col11', headerName: `${isOnline} Sales`, width: 150 },
    { field: 'col12', headerName: 'GST Amount', width: 150 },
    { field: 'col13', headerName: 'Platform Fees', width: 150 },
    { field: 'col14', headerName: 'Profit', width: 150 },
  ];



  return (
    <div className='data2Table '>
    <motion.div className='dataGrid'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
    >     
      <DataGrid 
          rows={userData}   
          columns={columns}         
          pageSize={9}
          rowsPerPageOptions={[9]}
      />
    </motion.div>
  </div>
)
}

export default Events;