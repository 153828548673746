import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";
import instance from "../../../utils/axios";
import DeleteModal from "../../components/Modal/DeleteModal";

const TNC = () => {
  const [showModal, setShowModal] = useState(false);
  const [length, setLength] = useState(0);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [allTnc, setAllTnc] = useState([]);
  const [newTnc, setNewTnc] = useState({
    tnc: "",
    title: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setNewTnc((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteTnc/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("Term and condition Deleted SuccessFully");
        setAllTnc((prevData) => prevData.filter((tnc1) => tnc1._id !== userIdToDelete));
        setShowModal(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post("/api/user/tncData", {
        tnc: newTnc.tnc,
        title: newTnc.title
      });
      if (response.data.success) {
        toast.success("TnC Added Successfully");
        e.target.reset();
        setNewTnc({ tnc: "", title: "" });
        fetchTnc();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderGroupedTnc = () => {
    const groupedTnc = {};
    allTnc.forEach((product) => {
      const { title, tnc } = product;
      if (!groupedTnc[title]) {
        groupedTnc[title] = [tnc];
      } else {
        groupedTnc[title].push(tnc);
      }
    });

    return Object.entries(groupedTnc).map(([title, tncs], index) => (
      <div key={index}>
        <h3 className="tnc-title">{title}</h3>
        {tncs.map((tnc, innerIndex) => (
          <div className="faqMap" key={index}>
                <div className="faqMap-container faq-tnc">
                    <h3 className="faqmap-h3">{innerIndex + 1}</h3>
                    <h4 className="faqmap-answer">{tnc}</h4>
                </div>
                <DeleteIcon  className="delete-icon" onClick={() => handleDelete(getTncIdByTitleAndIndex(title, innerIndex))}/>
            </div>
        ))}
      </div>
    ));
  };

  const getTncIdByTitleAndIndex = (title, index) => {
    const matchingTnc = allTnc.find((tnc) => tnc.title === title);
    return matchingTnc ? matchingTnc._id : null;
  };


  const fetchTnc = async () => {
    try {
      const response = await instance.get("/api/user/getTncData")
      if (response.data.success) {
        setAllTnc(response.data.data)
        setLength(response.data.data.length);
      } else {
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  useEffect(() => {
    fetchTnc();
  }, []);


  return (
    <div className="tnc">
      <div className="tnc-container">
        <form onSubmit={handleSubmit} className="tnc-form">
          <div className="tnc-form-field">
            <select name="title" className="add-tnc" value={newTnc.title} required onChange={setData} >
              <option value="">Select Title</option>
              <option value="Performance Center">Performance Center</option>
              <option value="Users">Users</option>
              <option value="Members">Members</option>
              <option value="Plans">Plans</option>
              <option value="Merchandise">Merchandise</option>
              <option value="Events">Events</option>
              <option value="Content">Content</option>

            </select>
          </div>
          <div className="tnc-form-field">
            <input
              type="text"
              name="tnc"
              id="tnc"
              required
              value={newTnc.tnc}
              onChange={setData}
              placeholder="Add Terms And Condition"
              className="add-tnc"
            />
          </div>
          <button type="submit" className="dash-btn tnc-btn">
            Add
          </button>
        </form>
      </div>
      <div className="tnc-container-1">
        <h4 className="tnc-title">All Terms And Conditions ({length}) </h4>
        {renderGroupedTnc()}
      </div>
      {showModal && <DeleteModal 
        h4="Are you sure you want to delete this Term & Condition?"
        confirmDelete={confirmDelete}
        closeModal={()=> setShowModal(false)}
      />}
    </div>
  );
};

export default TNC;
