import React, { useState } from 'react'
import Sidebar from '../DashboardSidebar/Sidebar'
import Navbar from '../DashboardNavbar/Navbar'
import Chatbot from '../DashboardChatbot/Chatbot'
import MessageIcon from '@mui/icons-material/Message';
import "./DashLayout.css"
import useUserStore from '../../../store';
import {toast} from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

const DashLayout = ({ children }) => {

  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [isWrippleActive, setWrippleActive] = useState(false);

  const handleChatbotContainerClick = () => {
    setWrippleActive(true);

    setTimeout(() => {
      setWrippleActive(false);
    }, 2000);
  };

  const toggleChatbot = () => {
    setChatbotOpen((prevState) => !prevState);
  };

  const loggedIn = useUserStore((state) => state.loggedIn);

  if(!loggedIn) {
    toast.error("Please Login To continue");
    return <Navigate to={"/login"} />
  }

  return (
    <div>
      <div className="dashLayout">
        <Sidebar />
        <div className="dashLayout-container dashboard">
          <Navbar />
          <main>{children}</main>
            <div className={`chatbot-container ${isWrippleActive ? 'wripple' : ''}`} onClick={handleChatbotContainerClick}>
              <MessageIcon onClick={toggleChatbot} className='chatbot-icon' />
            </div>
            {isChatbotOpen && <Chatbot isChatbotOpen={isChatbotOpen} toggleChatbot={toggleChatbot} />}
        </div>
      </div>
    </div>
  )
}

export default DashLayout