import React, { useRef } from "react";
import JoditEditor from "jodit-react";

const DashTextEditor = ({ setEditorValue,  initialValue }) => {
  const editor = useRef(null);

  return (
    <>
      <JoditEditor
        ref={editor}
        value={initialValue}
        onChange={(content) => setEditorValue(content)}
        style={{ width: "100%" }}
      />
    </>
  );
};

export default DashTextEditor;
