import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import useUserStore from '../../../store';
import { defaultUrl } from '../../../data/Images';
import DeleteModal from '../../components/Modal/DeleteModal';

const ExUsers = () => {

  const [userData, setUserData] = useState([]);
  const user = useUserStore((state) => state.user)
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");

  const getData = async () => {
    try {
      const response = await instance.get('/api/user/getExOfflineUsers', {
        params: {
          userId: user._id,
        }
      });
      if (response.data.success) {
        const offlineMembers = response.data.data; 
        const rows = await Promise.all(offlineMembers.map(async (user) => {
        try {
          return {
            id: user ? user._id : "N/A",
            col1: user.file.secure_url,
            col2: user.uniqueId || "N/A",
            col3: user.name,
            col4: user.email,
            col5: user.mobile,
            col6: user.occupation || "N/A",
            col7: user.lastPlan[0].gymLocation || "N/A",
            col8: user.path || "N/A",
            col9: user.lastPlan[0].planType || "N/A",
            col10: user.lastPlan[0].planTime || "N/A",
            col11: new Date(user.lastPlan[0].startDate).toLocaleDateString("en-GB") || "N/A",
            col12: new Date(user.lastPlan[0].endDate).toLocaleDateString("en-GB") || "N/A",
            col13: user.lastPlan[0].daysLeft ,
            col14: user.lastPlan[0].status || "N/A",
          };
        } catch (error) {
          console.log("Error fetching data for user", user._id);
          return null; 
        }
      }));
        setUserData(rows);
    }
  } catch (error) {
    console.log(error);
    toast.error("An Error Occurred. Please Try Again!");
  }
}
  const handleDelete = async (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteUser/${userIdToDelete}`);
      if (response.data.success) {
        toast.success('Ex Offline Member deleted successfully');
        setUserData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
        setShowModal(false);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
        toast.error('An error occurred');
    }
  };
  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(()=>{
    getData();
    // eslint-disable-next-line 
  },[user._id])

  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 100,
      renderCell: (params) => (
        <img src={ params.value || defaultUrl } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Unique ID', width: 100 },
    {
      field: 'col3', 
      headerName: 'Name',
      width: 170,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Occupation', width: 150 },
    { field: 'col7', headerName: 'Performance Center', width: 150 },
    { field: 'col8', headerName: 'Health Issues', width: 150 },
    { field: 'col9', headerName: 'Plan Type', width: 200 },
    { field: 'col10', headerName: 'Plan Time', width: 150 },
    { field: 'col11', headerName: 'Date of Commencement', width: 150 },
    { field: 'col12', headerName: 'Last Date', width: 150 },
    { field: 'col13', headerName: 'Days Left', width: 150 },
    { field: 'col14', headerName: 'Status', width: 150 },
  ];


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
      
        return (
          <div className="cellAction">
            <Link to={`/dashboard/users/userPage/${params.row.id}`}
            style={{ textDecoration: "none" }} >
              <div className="viewButton">View</div>
            </Link>
            <div className="deleteButton" onClick={()=>handleDelete(params.row.id)} >Delete</div>
          </div>
        );
      },
    },
  ]; 


  return (
    <div className='data2Table'>
      <div className='dataGrid'>
        <DataGrid 
            rows={userData}   
            columns={columns.concat(actionColumn)} 
            pageSize={9}
            rowsPerPageOptions={[9]}
            sx={{ color: "var(--black)", fontWeight: "400" }}
        />
        {showModal && (
          <DeleteModal 
              h4={` Are you sure you want to delete this ex offline Member?`}
              confirmDelete={confirmDelete}
              closeModal={closeModal}
          />
      )}
      </div>
    </div>
  )
}

export default ExUsers;