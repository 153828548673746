import React, { useState, useEffect } from 'react'
import instance from '../../../utils/axios';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import useUserStore from '../../../store';
import { toast } from 'react-hot-toast';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import moment from "moment"
import "../../css/DashUser.css"
import { defaultUrl } from '../../../data/Images';
import DocumentDisplay from '../DashboardUserPage/DocumentDisplay';
import Comment from '../../components/DashboardComments/Comment';
import Loader from '../../../components/MainLoader/Loader';
import  AddIcon  from '@mui/icons-material/Add';
import CurrentEvents from './../DashboardUserPage/CurrentEvents';
import CurrentProducts from './../DashboardUserPage/CurrentProducts';
import ShowPlanModal from '../DashboardUserPage/ShowPlanModal';
import CurrentPlans from '../DashboardUserPage/CurrentPlans';


const EmployeePage = () => {

  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const user = useUserStore((state) => state.user);
  const [addComment, setAddComment] = useState([]);
  const [length, setLength] = useState("");
  const [name, setName] = useState("");
  const currentDate = new Date();
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [lifeTimeValue, setLifeTimeValue] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const showModal = modalAction !== "";
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };


  const toggleShowPlanModal = () => {
    setShowPlanModal(!showPlanModal);
  };

  const formattedDateTime = `${currentDate.toLocaleDateString(
    "en-GB"
  )} ${currentDate.toLocaleTimeString("en-GB")}`;

  const setData = (e) => {
    const { name, value } = e.target;
    setName((preval) => {
      return {
        ...preval, [name]: value
      }
    })
  }

  const handleBlockUser = async () => {
    try {
      const response = await instance.post(`/api/user/block/${userDetails._id}`);
      if (response.data.success) {
        setIsBlocked(true);
        toast.success('Counsellor Blocked successfully');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
    setModalAction("");
  };

  const handleUnblockUser = async () => {
    try {
      const response = await instance.post(`/api/user/unblock/${userDetails._id}`);
      if (response.data.success) {
        setIsBlocked(false);
        toast.success('Counsellor unblocked successfully');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
    setModalAction("");
  };

  const openModal = (action) => {
    setModalAction(action);
  };


  const orderData = async() => {
    try{
      const response = await instance.get(`/api/user/lifeTimeValue/${id}`)
        if (response.data.success) {
          setLifeTimeValue(response.data.data);
        }
      }catch(error){
        console.log("lifeTimeValue error: ", error);
        toast.error("An Error Occurred. Please Try Again!");
      }
    }


  const fetchData = async () => {
    try {
      const response = await instance.get(`/api/user/getdata/${id}`)
      if (response.data.success) {
        setUserDetails(response.data.data);
        setIsBlocked(response.data.data.isBlocked);
      } else {
        toast.error(response.data.message);
      }
    }
    catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post("/api/user/commentData", {
        comment: name.comment,
        username: id,
        addedFrom: user.name,
        location: "Employee",
        callSubject: name.callSelect,
        callResponse: name.callResponse,
        reason: name.reasons,
        priority: name.priority,
        nextCallDate: name.callDate,
        nextCallTime: name.callTime,
        callStatus: name.callStatus,
        callDateAndTime: formattedDateTime,
      });
      if (response.data.success) {
        toast.success("Your Comment is Added Successfully");
        e.target.reset();
        setName({
          comment: "",
          callSelect: "",
          callResponse: "",
          reasons: "",
          priority: "",
          callDate: "",
          callTime: "",
          callStatus: "",
          currentCallDate: formattedDateTime,
        });
        // Fetch the updated comments after adding a new comment
        fetchComments();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  };

  const fetchComments = async () => {
    try {
      const response = await instance.get(`/api/user/getCommentData/${id}`, {
        params: {
          location: "Employee",
        },
      });
      if (response.data.success) {
        setAddComment(response.data.data);
        setLength(response.data.data.length);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An Error Occurred. Please Try Again!");
    }
  };

  useEffect(() => {
    fetchComments();
    fetchData();
    orderData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!userDetails) {
    return (
      <>
        <div className='loader-position' ><Loader /></div>
      </>
    );
  }

  const handleFilter = () => {
    const formattedStartDate = startDate ? new Date(startDate).toLocaleDateString('en-GB') : null;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('en-GB') : null;

    const filteredComments = addComment.filter((comment) => {
      const commentDate = comment.callDateAndTime.split(" ")[0];
      const dateCondition = !formattedStartDate || !formattedEndDate || ((commentDate >= formattedStartDate ||
        commentDate === formattedStartDate) && (commentDate <= formattedEndDate || commentDate === formattedEndDate));
      return dateCondition;
    });

    if (filteredComments === null || filteredComments.length === 0) {
      return toast.error("No Comments Found");
    }
    setFilteredComments(filteredComments);
  };

  const commentsToRender = filteredComments.length > 0 ? filteredComments : addComment && addComment.slice().reverse();

  return (
    <>
        <div className="userPage-container">
          <motion.h2 className="dashboard-title"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.0 }}
          >
            {userDetails.name}'s Counsellor Details
          </motion.h2>
          <motion.div className="user-main"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="user-profile">
              {isBlocked && (
                <div className="block-icon-overlay" >
                  <BlockIcon className="actual-block-icon" />
                  <h3 className="block-icon-h3">Staff Blocked</h3>
                </div>
              )}
              <img src={userDetails?.file?.secure_url || defaultUrl } alt="#" className="userImage" />
              <h3 className="user-title ">Profile Picture</h3>
              <div className='user-edit-div'>
                {["Admin", "Regional Manager", "Branch Manager"].includes(user.role) ?
                  <Link to={`/dashboard/profile/${id}`}>
                    <EditIcon className="edit-mui-icon" />
                  </Link> : ""}
                <Link to={`https://api.whatsapp.com/send?phone=${userDetails.mobile}`} target="_blank">
                  <WhatsAppIcon className="whatsapp-icon" />
                </Link>
                {["Admin", "Regional Manager", "Branch Manager"].includes(user.role) ? <>
                  <BlockIcon className="block-icon" onClick={() => openModal(isBlocked ? "unblock" : "block")} />
                  <AddIcon className="addNew-btn" onClick={toggleShowPlanModal}/></>
                  : ""}

              </div>

            </div>
            <div className="user-wrap">

              <div className="user-index">
                <h3 className="user-title">Name: </h3>
                <h3 className="user-value">{userDetails.name}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Email: </h3>
                <h3 className="user-value">{userDetails.email}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Username: </h3>
                <h3 className="user-value">{userDetails.username}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Date Of Birth: </h3>
                <h3 className="user-value">{userDetails.dob ? new Date(userDetails.dob).toLocaleDateString("en-GB") : "N/A"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Address: </h3>
                <h3 className="user-value">{userDetails.address.line1 && userDetails.address.pincode ? `${userDetails.address.line1}, ${userDetails.address.line2}, ${userDetails.address.city}, ${userDetails.address.state}, ${userDetails.address.pincode}` : "NA"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Country: </h3>
                <h3 className="user-value">{userDetails.country || "N/A"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Mobile number: </h3>
                <h3 className="user-value">{userDetails.mobile}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Gym Location: </h3>
                <h3 className="user-value">{userDetails.gymLocation || "N/A"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Batch Timing: </h3>
                <h3 className="user-value">
                  {userDetails.batch.batchShift ? (
                    <span>
                      {userDetails.batch.batchShift}:
                      {` ${moment(userDetails.batch.batchStartTime, 'HH:mm:ss').format('h:mm A')} - ${moment(userDetails.batch.batchEndTime, 'HH:mm:ss').format('h:mm A')}`}
                    </span>
                  ) : (
                    "TBD"
                  )}
                </h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Date Of Joining: </h3>
                <h3 className="user-value"> { userDetails.start ?  new Date(userDetails.start).toLocaleDateString("en-GB") :  new Date(userDetails.createdAt).toLocaleDateString("en-GB")}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Health issues: </h3>
                <h3 className="user-value">{userDetails.path ? userDetails.path : "None"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Job Designation: </h3>
                <h3 className="user-value">{userDetails.role}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Base Salary: </h3>
                <h3 className="user-value">₹{userDetails.salary}</h3>
              </div>

              <div className="user-index">
                <h3 className="user-title">Emergency Name: </h3>
                <h3 className="user-value">{userDetails.emergencyName || "N/A"}</h3>
              </div>
              <div className="user-index">
                <h3 className="user-title">Emergency Mobile: </h3>
                <h3 className="user-value">{userDetails.emergencyContact || "N/A"}</h3>
              </div>

              <div className="user-index">
                <h3 className="user-title">Life Time Value: </h3>
                <h3 className="user-value">₹{lifeTimeValue}</h3>
              </div>


            </div>
          </motion.div>
          <motion.div className="accordion"          
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                <div className="accordion-item">
                  <button
                    className={`accordion-header ${activeAccordion === 0 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(0)}
                  >
                    <span className="accordion-span">Current Plan</span> <AddIcon className="icons" />
                  </button>
                  <div className={`accordion-body ${activeAccordion === 0 ? 'active' : ''}`}>
                    <CurrentPlans userId={id} userName={userDetails.name} />
                  </div>
                </div>
                <div className="accordion-item">
                  <button
                    className={`accordion-header ${activeAccordion === 1 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(1)}
                  >
                    <span className="accordion-span">Purchased Events</span> <AddIcon className="icons" />
                  </button>
                  <div className={`accordion-body ${activeAccordion === 1 ? 'active' : ''}`}>
                    <CurrentEvents userId={id}/>
                  </div>
                </div>
                <div className="accordion-item">
                  <button
                    className={`accordion-header ${activeAccordion === 2 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(2)}
                  >
                    <span className="accordion-span">Purchased Products</span> <AddIcon className="icons" />
                  </button>
                  <div className={`accordion-body ${activeAccordion === 2 ? 'active' : ''}`}>
                    <CurrentProducts userId={id}/>
                  </div>
                </div>
              </motion.div>
            {showPlanModal && <ShowPlanModal userId={id} closeModal={toggleShowPlanModal} />}

          <motion.div
            className="user-documents"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
                <DocumentDisplay title="Blood Report" document={userDetails.document} />
                <DocumentDisplay title="Address Proof" document={userDetails.addressProof} />
                <DocumentDisplay title="Membership Receipt" document={userDetails.membershipReceipt} />
                <DocumentDisplay title="Progress Report" document={userDetails.progressReport} />
                <DocumentDisplay title="Workout Card" document={userDetails.workoutCard} />
                <DocumentDisplay title="Offer Letter " document={userDetails.offerLetter} />
                <DocumentDisplay title="Blank Cheque " document={userDetails.blankCheque} />
          </motion.div>
          <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
            >
                <Comment
                    length={length}
                    name={name}
                    setData={setData}
                    handleSubmit={handleSubmit}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    handleFilter={handleFilter}
                    commentsToRender={commentsToRender}   
                    formattedDateTime={formattedDateTime}             
                 /> 
            </motion.div>

          {showModal && (
            <div className="modal1">
              <motion.div className="modal-content1"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <h4 className='delete-para'>Are you sure you want to {modalAction === "block" ? "block" : "unblock"} this user?</h4>
                <div className='delete-button'>
                  <button className='btn1' onClick={modalAction === "block" ? handleBlockUser : handleUnblockUser}>Yes</button>
                  <button className='btn2' onClick={() => setModalAction("")}>No</button>
                </div>
              </motion.div>
            </div>
          )}
        </div>

    </>
  )
}

export default EmployeePage;