import React, { useState, useEffect } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import useUserStore from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import instance from "../../../utils/axios";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {defaultUrl} from "../../../data/Images"
import Loader from "../../../components/MainLoader/Loader"
import { gymName } from "../../../data/Gym";

const ProfileData = () => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const nav = useNavigate();
  const { userId } = useParams();

  const [details, setDetails] = useState({
    username: "",
    name: "",
    email: "",
    salary: "",
    address: {
      line1: "",
      line2: "",
      pincode: "",
      city: "",
      state: "",
    },
    country: "",
    mobile: "",
    dob: "",
    role: "",
    batch: {
      batchShift: "",
      batchStartTime: "",
      batchEndTime: "",
    },
    occupation: "",
    gymLocation: "",
    planType: "",
    planTime: "",
    file: {
      name: "",
      data: user.file ? user.file.secure_url : ""
    },
    path: "",
    start: "",
    doc: {
      name: "",
      data: user.doc ? user.doc.secure_url : ""
    },
    endDate: "",
    addressProof: {
      name: "",
      data: user.addressProof ? user.addressProof.secure_url : ""
    },
    progressReport: {
      name: "",
      data: user.progressReport ? user.progressReport.secure_url : ""
    },
    workoutCard: {
      name: "",
      data: user.workoutCard ? user.workoutCard.secure_url : ""
    },
    membershipReceipt: {
      name: "",
      data: user.membershipReceipt ? user.membershipReceipt.secure_url : ""
    },
    offerLetter: {
      name: "",
      data: user.offerLetter ? user.offerLetter.secure_url : ""
    },
    blankCheque: {
      name: "",
      data: user.blankCheque ? user.blankCheque.secure_url : ""
    },

    emergencyContact: "",
    emergencyName: ""
  });

  const setData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setDetails((preval) => {
      return {
        ...preval, [name]: value,
      }
    });
  }

  const handleFileChange = (e, stateKey) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setDetails((prevDetails) => ({
        ...prevDetails,
        [stateKey]: {
          name: file.name,
          data: reader.result,
        },
      }));
    };
    reader.readAsDataURL(file);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading1(true);
    try {
      const response = await instance.put('/api/user/updateUser', {
        userId,
        name: details.name,
        file: details.file,
        email: details.email,
        salary: details.salary,
        username: details.username,
        address: {
          line1: details.line1,
          line2: details.line2,
          city: details.city,
          state: details.state,
          pincode: details.pincode,
        },
        country: details.country,
        mobile: details.mobile,
        dob: details.dob,
        document: details.doc,
        occupation: details.occupation,
        path: details.path,
        gymLocation: details.gymLocation,
        batch: {
          batchShift: details.batchShift,
          batchStartTime: details.batchStartTime,
          batchEndTime: details.batchEndTime,
        },
        planType: details.planType,
        planTime: details.planTime,
        start: details.start,
        endDate: details.endDate,
        addressProof: details.addressProof,
        progressReport: details.progressReport,
        workoutCard: details.workoutCard,
        membershipReceipt: details.membershipReceipt,
        offerLetter: details.offerLetter,
        blankCheque: details.blankCheque,
        emergencyContact: details.emergencyContact,
        emergencyName: details.emergencyName,
        role: details.role,
      });
      if (response.data.success) {
        toast.success("Profile Update Successfully");
        const response1 = await instance.get('/api/user/me')
        if (response1.data.success) {
          setUser(response1.data.user);
          nav(-1);
        } else {
          toast.error(response1.data.message);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading1(false);
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await instance.get(`/api/user/profile/${userId}`)
        const userData = response.data.data;
        setDetails({
          username: userData.username,
          name: userData.name,
          email: userData.email,
          line1: userData.address && userData.address.line1,
          line2: userData.address && userData.address.line2,
          city: userData.address && userData.address.city,
          state: userData.address && userData.address.state,
          pincode: userData.address && userData.address.pincode,
          country: userData.country,
          salary: userData.salary,
          mobile: userData.mobile,
          dob: userData.dob,
          batchShift: userData.batch && userData.batch.batchShift,
          batchStartTime: userData.batch && userData.batch.batchStartTime,
          batchEndTime: userData.batch && userData.batch.batchEndTime,
          occupation: userData.occupation,
          path: userData.path,
          gymLocation: userData.gymLocation,
          planType: userData.planType,
          planTime: userData.planTime,
          file: userData.file || "",
          start: userData.start ? new Date(userData.start).toLocaleDateString("en-CA") : "",
          endDate: userData.endDate ? new Date(userData.endDate).toLocaleDateString("en-CA") : "",
          doc: userData.document || "",
          emergencyName: userData.emergencyName,
          emergencyContact: userData.emergencyContact,
          membershipReceipt: userData.membershipReceipt || "",
          workoutCard: userData.workoutCard || "",
          progressReport: userData.progressReport || "",
          addressProof: userData.addressProof || "",
          offerLetter: userData.offerLetter || "",
          blankCheque: userData.blankCheque || "",
          role: userData.role || "",
        });
        if (user._id === userData._id) {
          setUser(userData);
        };
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    }
    fetchUser();
  }, [userId, setUser, user._id])

  if (loading) return <Loader />

  const getFileName = (url) => {
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];
    return fileName.length > 22 ? fileName.slice(0, 22) + "..." : fileName;
  };

  const isCurrentUser = user.username === details.username;

  return (
    <div className="newContainer">
      <div className="new-top">
        <h1 className="dashboard-title" > {!isCurrentUser ? `${details.username}'s Profile` : "Edit Your Profile"} </h1>
      </div>
      <div className="new-bottom">
        <div className="new-right">
          <form onSubmit={handleSubmit}>
            <div className="forminput">
              <div className="new-left images">
                <img src={!isCurrentUser ? (details.file.secure_url || defaultUrl) : (user.file.secure_url || defaultUrl) } alt="Profile" />
                <div>
                  <label className='new-image' htmlFor="file"><DriveFolderUploadIcon className="icons" /></label>
                  <input type="file" id='file' onChange={(e) => handleFileChange(e, "file")} style={{ display: "none" }} />
                </div>
              </div>
            </div>
            <div className="forminput " >
              <label htmlFor="">Username</label>
              <input type="text" placeholder="john_doe" required name="username" value={details.username} onChange={setData} />
            </div>
            <div className="forminput" >
              <label htmlFor="name">Name</label>
              <input type="text" placeholder="john Doe" name="name" onChange={setData} value={details.name} required/>
            </div>
            <div className="forminput" >
              <label htmlFor="email">Email</label>
              <input type="text" name="email" value={details.email} onChange={setData} readOnly={!isCurrentUser} disabled />
            </div>
            <div className="forminput" >
              <label htmlFor="dob">Date of Birth</label>
              <input type="date" name='dob' onChange={setData} value={details.dob ? new Date(details.dob).toLocaleDateString("en-CA") : ""} />
            </div>
            <div className="forminput" >
              <label htmlFor="address">Postal Code</label>
              <input type="number" maxLength={6} min={0} name='pincode' value={details.pincode} onChange={setData} placeholder="Enter Your Postal Code" />
            </div>
            <div className="forminput" >
              <label htmlFor="address">Address: Line 1</label>
              <input type="text" name='line1' value={details.line1} onChange={setData} placeholder="Elton St. 216 NewYork"/>
            </div>
            <div className="forminput" >
              <label htmlFor="address">Address: Line 2</label>
              <input type="text" name='line2' value={details.line2} onChange={setData} placeholder="Elton St. 216 NewYork" />
            </div>
            <div className="forminput" >
              <label htmlFor="address">City</label>
              <input type="text" name='city' value={details.city} onChange={setData} placeholder="Enter Your City Name" />
            </div>
            <div className="forminput" >
              <label htmlFor="address">State</label>
              <input type="text" name='state' value={details.state} onChange={setData} placeholder="Enter Your State" />
            </div>
            <div className="forminput" >
              <label htmlFor="address">Country</label>
              <input type="text" name='country' value={details.country} onChange={setData} placeholder="Enter Your Country" required  />
            </div>
            <div className="forminput" >
              <label htmlFor="mobile">Mobile</label>
              <input type="text" name='mobile' maxLength={10} value={details.mobile} onChange={setData} disabled />
            </div>
            {user.role === "User" ? (
              <div className="forminput" >
                <label htmlFor="occupation">Occupation</label>
                <input type="text" name='occupation' value={details.occupation} onChange={setData} placeholder="Trainer: Planet Muscle" />
              </div>) : ""}
            <div className="forminput" >
              <label htmlFor="path">Health issues </label>
              <input type="text" name='path' value={details.path} onChange={setData} placeholder="Medical Illness" />
            </div>
            <div className="forminput" >
              <label htmlFor="emergencyName"> Emergency Contact Name </label>
              <input type="text" name='emergencyName' required value={details.emergencyName} onChange={setData} placeholder="Name of the emergency contact"/>
            </div>
            <div className="forminput" >
              <label htmlFor="emergencyContact">Emergency Contact Number </label>
              <input type="text" name='emergencyContact' required maxLength={10} value={details.emergencyContact} onChange={setData} placeholder="Their Contact Number" pattern="[0-9]*" title="Please enter only numbers" />
            </div>

            <div className="form-input-changer">
              <h4 className="dash-title ">Your Documents</h4>
            </div>
            <div className="forminput" >
              <label htmlFor="doc">Blood Test Report (PDF)</label>
              <input type="file" id='doc' onChange={(e) => handleFileChange(e, "doc")} accept=".pdf" />
              {details.doc && details.doc.secure_url ? (
                <a
                  className="ahref"
                  href={details.doc.secure_url}
                  rel="noopener noreferrer"
                  target='_blank'
                >
                  {getFileName(details.doc.secure_url)}
                </a>
              ) : (
                <h3 className="user-value">No document found</h3>
              )}
            </div>
            <div className="forminput" >
              <label htmlFor="addressProof">Address Proof (Aadhar/Pan/Driving License)</label>
              <input type="file" id='addressProof' onChange={(e) => handleFileChange(e, "addressProof")} accept=".pdf" />
              {details.addressProof && details.addressProof.secure_url ? (
                <a
                  className="ahref"
                  href={details.addressProof.secure_url}
                  rel="noopener noreferrer"
                  target='_blank'
                >
                  {getFileName(details.addressProof.secure_url)}
                </a>
              ) : (
                <h3 className="user-value">No document found</h3>
              )}

            </div>
            {user.role === "User" ? <>

              <div className="forminput" >
                <label htmlFor="progressReport">Progress Report (PDF)</label>
                <input type="file" id='progressReport' onChange={(e) => handleFileChange(e, "progressReport")} accept=".pdf" />
                {details.progressReport && details.progressReport.secure_url ? (
                  <a
                    className="ahref"
                    href={details.progressReport.secure_url}
                    rel="noopener noreferrer"
                    target='_blank'
                  >
                    {getFileName(details.progressReport.secure_url)}
                  </a>
                ) : (
                  <h3 className="user-value">No document found</h3>
                )}

              </div>
              <div className="forminput" >
                <label htmlFor="workoutCard">Workout Card (PDF)</label>
                <input type="file" id='workoutCard' onChange={(e) => handleFileChange(e, "workoutCard")} accept=".pdf" />
                {details.workoutCard && details.workoutCard.secure_url ? (
                  <a
                    className="ahref"
                    href={details.workoutCard.secure_url}
                    rel="noopener noreferrer"
                    target='_blank'
                  >
                    {getFileName(details.workoutCard.secure_url)}
                  </a>
                ) : (
                  <h3 className="user-value">No document found</h3>
                )}
              </div>
              <div className="forminput" >
                <label htmlFor="membershipReceipt">Membership Receipt (PDF)</label>
                <input type="file" id='membershipReceipt' onChange={(e) => handleFileChange(e, "membershipReceipt")} accept=".pdf" />
                {details.membershipReceipt && details.membershipReceipt.secure_url ? (
                  <a
                    className="ahref"
                    href={details.membershipReceipt.secure_url}
                    rel="noopener noreferrer"
                    target='_blank'
                  >
                    {getFileName(details.membershipReceipt.secure_url)}
                  </a>
                ) : (
                  <h3 className="user-value">No document found</h3>
                )}
              </div>
            </> : ""}
            {user.role !== "User" ? (
              <div className="forminput">
                <label htmlFor="offerLetter">Offer Letter(PDF)</label>
                <input
                  type="file"
                  id="offerLetter"
                  onChange={(e) => handleFileChange(e, "offerLetter")}
                  accept=".pdf"
                />
                {details.offerLetter && details.offerLetter.secure_url ? (
                  <a
                    className="ahref"
                    href={details.offerLetter.secure_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {getFileName(details.offerLetter.secure_url)}
                  </a>
                ) : (
                  <h3 className="user-value">No document found</h3>
                )}
              </div>
            ) : null}

            {user.role !== "User" ? (
              <div className="forminput">
                <label htmlFor="blankCheque">Blank Cheque Details(PDF)</label>
                <input
                  type="file"
                  id="blankCheque"
                  onChange={(e) => handleFileChange(e, "blankCheque")}
                  accept=".pdf"
                />
                {details.blankCheque && details.blankCheque.secure_url ? (
                  <a
                    className="ahref"
                    href={details.blankCheque.secure_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {getFileName(details.blankCheque.secure_url)}
                  </a>
                ) : (
                  <h3 className="user-value">No document found</h3>
                )}
              </div>
            ) : null}

            <div className="form-input-changer">
              {user.role !== "User" ?
                <h4 className="dash-title ">Current Shift</h4> : ""
              }
            </div>
            {user.role !== "User" ? <>
              <div className="forminput" >
                <label htmlFor="document">User Role</label>
                <select name="role" onChange={setData} value={details.role} disabled={isCurrentUser} >
                  <option value="" >Select Employee Role</option>
                  {user.role === "Admin" && user.username === "Pratik123" ?
                    <option value="Admin">Admin</option> : ""}
                  <option value="Regional Manager">Regional Manager</option>
                  <option value="Branch Manager">Branch Manager</option>
                  <option value="Master Trainer">Master Trainer</option>
                  <option value="Platinum Trainer">Platinum Trainer</option>
                  <option value="Diamond Trainer">Diamond Trainer</option>
                  <option value="Gold Trainer">Gold Trainer</option>
                  <option value="Silver Trainer">Silver Trainer</option>
                  <option value="Sales">Sales</option>
                  <option value="Accounts">Accounts</option>
                  <option value="Sales Manager">Sales Manager</option>
                  <option value="Online Manager">Online Manager</option>
                  <option value="Online Regional Manager">Online Regional Manager</option>
                  <option value="Human Resources">Human Resources</option>
                  <option value="Floor Manager">Floor Manager</option>
                </select>
              </div>
            </> : null}
            <div className="forminput" >
              <label htmlFor="document">Performance Center</label>
              <select name="gymLocation" onChange={setData} value={details.gymLocation} disabled={isCurrentUser}>
                <option value="">Select Performance Center</option>
                {gymName.map((a) => (
                      <option value={a.name} key={a}> {a.name} </option>
                ))}
              </select>
            </div>
            <div className="forminput" >
              <label htmlFor="plan-time">Plan Time</label>
              <select name="planTime" disabled={user.role === "User"} readOnly={user.role === "User"} onChange={setData} value={details.planTime}>
                <option value="">Select Plan Time</option>
                <option >1 Month</option>
                <option >3 Months</option>
                <option >6 Months</option>
                <option >9 Months</option>
                <option >12 Months</option>
              </select>
            </div>
            <div className="forminput" >
              <label htmlFor="batchShift"> Batch Timing</label>
              <select name="batchShift" disabled={user.role === "User"} onChange={setData} value={details.batchShift} >
                <option value="">Select Batch</option>
                <option value="Morning">Morning</option>
                <option value="Evening">Evening</option>
                <option value="All Day">All Day</option>
              </select>
            </div>
            {user.role !== "User" ? <>
              <div className="forminput" >
                <label htmlFor="batchStartTime">Start Time</label>
                <input type="time" name='batchStartTime' disabled={user.role === "User"} onChange={setData} value={details.batchStartTime} />
              </div>
              <div className="forminput" >
                <label htmlFor="batchEndTime">End Time</label>
                <input type="time" name='batchEndTime' disabled={user.role === "User"} onChange={setData} value={details.batchEndTime} />
              </div>
              <div className="forminput" >
                <label htmlFor="salary">Base Salary </label>
                <input type="number" min={0} name='salary' value={details.salary} onChange={setData} disabled={isCurrentUser} />
              </div>
            </> : ""}
            <div className="forminput" >
              <label htmlFor="dob">Date of Joining</label>
              <input type="date" name='start' disabled={user.role === "User"} onChange={setData} value={details.start} />
            </div>


            <button disabled={loading1} className={`dash-btn ${loading1 ? 'upload-btn' : ''}`}>
              {loading1 ? <AutorenewIcon className="loader-css" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>

  );
};

export default ProfileData;