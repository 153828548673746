import React, { useEffect, useState } from "react";
import instance from "../../../utils/axios";
import { toast } from "react-hot-toast";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate, useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import "../../css/DashProducts.css";
import DashTextEditor from "./DashTextEditor"
import CloseIcon from '@mui/icons-material/Close';

const DashArticlesForm = ({ isUpdate }) => {
  const [editorValue, setEditorValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorTitle, setEditorTitle] = useState('');
  const [editorAuthor, setEditorAuthor] = useState('');
  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const nav = useNavigate();
  const { articleId } = useParams();

  const [ setArticleData] = useState({
    file: '',
    file1: '',
    title: '',
    author: '',
    article: '',
  });

  useEffect(() => {
    if (isUpdate && articleId) {
      instance
        .get(`/api/user/getArticle/${articleId}`)
        .then((response) => {
          if (response.data.success) {
            const articleData = response.data.data;
            setEditorValue(articleData.article);
            setEditorAuthor(articleData.author);
            setEditorTitle(articleData.title);
            setFile(articleData.file.secure_url);
            setFile1(articleData.file1.secure_url);
          } else {
            console.error(response.data.error);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [isUpdate, articleId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const cleanedValue = value.replace(/<\/?p>/g, '');
    setArticleData((prevData) => ({
      ...prevData,
      [name]: cleanedValue,
    }));
  };

  function convertBase64(e) {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.onerror = error => {
    }
  }

  function convertBase641(e) {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setFile1(reader.result);
    };
    reader.onerror = error => {
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const cleanedEditorValue = editorValue.replace(/^<p>|<\/p>$/g, '');
      const endpoint = isUpdate
        ? `/api/user/updateArticle/${articleId}`
        : "/api/user/articlesData";
      const response = await instance[isUpdate ? "put" : "post"](endpoint, {
        title: editorTitle,
        article: cleanedEditorValue,
        file: file,
        file1: file1,
        author: editorAuthor,
      });
      if (response.data.success) {
        setLoading(false);
        const successMessage = isUpdate
          ? "Article updated successfully!"
          : "Article added successfully!";
        toast.success(successMessage);
        nav("/dashboard/editor");
      } else {
        setLoading(false);
        toast.error(`Failed to ${isUpdate ? "update" : "Add"} Article.`);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <div className="productForm">
        <h4 className="dashboard-title dashMembers-container">
          {isUpdate ? "Update Your Article" : "Add An Article"}
        </h4>
        <div className="product-form-container">
          <div className="product-form editorForm">
            <div className="product-input-div">
              <label className="product-label" htmlFor="brand">
                Article Title{" "}
              </label>
              <input
                type="text"
                required
                name="editorTitle"
                value={editorTitle}
                onChange={(e) => setEditorTitle(e.target.value)}
                placeholder="Enter A Suitable Title"
              />
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="editorAuthor">
                Article Author{" "}
              </label>
              <input
                type="text"
                required
                name="editorAuthor"
                value={editorAuthor}
                onChange={(e) => setEditorAuthor(e.target.value)}
                placeholder="Enter the author Name"
              />
            </div>
            <div className="product-input-div">
              <label className="product-label" htmlFor="quantity">
                Article Author Photo{" "}
              </label>
              <input type="file" accept='.png, .jpg, .jpeg' name="file1" required onChange={convertBase641} />
            </div>
            <DashTextEditor className="editor-mid" setEditorValue={setEditorValue} initialValue={editorValue} />
            <div className="dash-btn" onClick={() => setIsModalOpen(true)} >
              See Preview
            </div>
            {isModalOpen && (
              <div className="editor-modal">
                <div className="editor-modal-content">
                  <CloseIcon className="editor-close" onClick={() => setIsModalOpen(false)} />
                  <form onSubmit={handleSubmit}>
                    <h2 className="editor-title" name="title" value={editorTitle} onChange={handleChange}>{editorTitle}</h2>
                    <h5 className="editor-title" name="author" value={editorAuthor} onChange={handleChange}>{editorAuthor}</h5>
                    <div className="editor-left">
                      {file === "" || file === null ? "" : <img src={file} alt="" />}
                      <div>
                        <label className='editor-image' htmlFor="file"><DriveFolderUploadIcon /></label>
                        <input type="file" id='file' name="file" required onChange={convertBase64} style={{ display: "none" }} />
                      </div>
                    </div>
                    <div className="editor-article" name="article" value={editorValue} onChange={handleChange} dangerouslySetInnerHTML={{ __html: editorValue }} ></div>
                    <button type="submit" className={`dash-btn ${loading ? 'upload-btn' : ''}`}>
                      {loading ? <AutorenewIcon className="loader-css" /> : (
                          <>{isUpdate ? "Update Product" : "Add Product"}</>
                        )}
                    </button>

                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashArticlesForm;

export const DashAddArticle = () => <DashArticlesForm isUpdate={false} />;
export const UpdateArticle = () => <DashArticlesForm isUpdate={true} />;
