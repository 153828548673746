import React from 'react'
import "../../css/DashUser.css"

const Comment = ({  length, name,setData,handleSubmit,startDate,
setStartDate,endDate,setEndDate,handleFilter,formattedDateTime,commentsToRender}) => {
    return (
        <div>
            <div className="comment-container" >
                <div className="add-comment">
                    <h5 className="comment-h5">Add Comment ({length})</h5>
                    <form onSubmit={handleSubmit} className="comment-form1">
                        <div className="call-form">
                            <div className="call-fields">
                                <label htmlFor="">Call Subject</label>
                                <select
                                    name="callSelect"
                                    value={name.callSelect}
                                    onChange={setData}
                                    className="call-select"
                                    required
                                >
                                    <option value="">Select Subject</option>
                                    <option value="Balance">Balance</option>
                                    <option value="Birthday">Birthday</option>
                                    <option value="Feedback Membership">
                                        Feedback Membership
                                    </option>
                                    <option value="Feedback PT">Feedback PT</option>
                                    <option value="First Orientation Call">
                                        First Orientation Call
                                    </option>
                                    <option value="Irregular Member">Irregular Member</option>
                                    <option value="Locker">Locker</option>
                                    <option value="Nutrition">Nutrition</option>
                                    <option value="Outside PT">Outside PT</option>
                                    <option value="Personal Training">
                                        Personal Training
                                    </option>
                                    <option value="Prospect">Prospect</option>
                                    <option value="Renewal PT">Renewal PT</option>
                                    <option value="Upgradation Membership">
                                        Upgradation Membership
                                    </option>
                                </select>
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Call Response</label>
                                <select
                                    name="callResponse"
                                    value={name.callResponse}
                                    onChange={setData}
                                    className="call-select"
                                    required
                                >
                                    <option value="">Select Response</option>
                                    <option value="Call Again">Call Again</option>
                                    <option value="Successful Call">Successful Call</option>
                                    <option value="Not Interested">Not Interested</option>
                                    <option value="Ringing">Ringing</option>
                                    <option value="Switched Off">Switched Off</option>
                                    <option value="Busy on other call">
                                        Busy on other call
                                    </option>
                                    <option value="Out of Network">Out of Network</option>
                                    <option value="Disconnected">Disconnected</option>
                                    <option value="Converted">Converted</option>
                                    <option value="Trial Booked">Trial Booked</option>
                                    <option value="Trial Converted">Trial Converted</option>
                                    <option value="Balance Cleared">Balance Cleared</option>
                                    <option value="Price Too High">Price Too High</option>
                                    <option value="Service Unavailable">
                                        Service Unavailable
                                    </option>
                                </select>
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Reasons</label>
                                <select
                                    name="reasons"
                                    value={name.reasons}
                                    onChange={setData}
                                    className="call-select"
                                    required
                                >
                                    <option value="">Select Reasons</option>
                                    <option value="Balance Pending">Balance Pending</option>
                                    <option value="Connected">Connected</option>
                                    <option value="Busy">Busy</option>
                                    <option value="Ringing/No Answer">
                                        Ringing/No Answer
                                    </option>
                                    <option value="Engaged">Engaged</option>
                                    <option value="Disconnected">Disconnected</option>
                                    <option value="Not Reachable">Not Reachable</option>
                                    <option value="Incorrect Number">Incorrect Number</option>
                                </select>
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Priority</label>
                                <select
                                    name="priority"
                                    value={name.priority}
                                    onChange={setData}
                                    className="call-select"
                                    required
                                >
                                    <option value="">Select Priority</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    <option value="Expected">Expected</option>
                                </select>
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Next Call Date/ Time</label>
                                <div className="call-input-1">
                                    <input
                                        name="callDate"
                                        value={name.callDate}
                                        onChange={setData}
                                        type="date"
                                        className="call-input"
                                        required
                                    />
                                    <input
                                        name="callTime"
                                        value={name.callTime}
                                        onChange={setData}
                                        type="time"
                                        className="call-input"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Call Date</label>
                                <input
                                    type="text"
                                    name="currentCallDate"
                                    value={formattedDateTime}
                                    onChange={setData}
                                    readOnly="readonly"
                                    className="call-input"
                                />
                            </div>
                            <div className="call-fields">
                                <label htmlFor="">Call Status </label>
                                <select
                                    type="text"
                                    name="callStatus"
                                    value={name.callStatus}
                                    onChange={setData}
                                    className="call-input"
                                    required
                                >
                                    <option value="">Select Call Status</option>
                                    <option value="Open">Open</option>
                                    <option value="Closed">Closed</option>
                                </select>
                            </div>
                        </div>
                        <div className="call-comments">
                            <input
                                type="text"
                                className="call-comment"
                                name="comment"
                                value={name.comment}
                                onChange={setData}
                                placeholder="Add Comment.. "
                                required
                            />
                        </div>
                        <button className="dash-btn addComment-btn">Submit</button>
                    </form>
                </div>
                <div className="filter-container">
                    <input
                        type="date"
                        value={startDate}
                        className='filter-input'
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        value={endDate}
                        className='filter-input'
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <button onClick={handleFilter} className='comment-btn dash-btn'>Filter</button>
                </div>
                <div className="show-comment">
                    {commentsToRender.slice().reverse().map((product, value) => (
                        <div className="show-comment-container-1" key={value}>
                            <div className="show-comment-header">
                                <div className="call-form-header">
                                    <h5 className="call-addedBy">Added by: {product.addedFrom}</h5>
                                </div>
                                <div className="call-form">
                                    <div className="call-fields">
                                        <label htmlFor="">Call Subject</label>
                                        <h5 className="show-comments-value">{product.callSubject}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Call Response</label>
                                        <h5 className="show-comments-value">{product.callResponse}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Reasons</label>
                                        <h5 className="show-comments-value">{product.reason}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Priority</label>
                                        <h5 className="show-comments-value">{product.priority}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Next Call Date/ Time</label>
                                        <h5 className="show-comments-value">{new Date(product.nextCallDate).toLocaleDateString("en-GB")} {product.nextCallTime}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Call Date</label>
                                        <h5 className="show-comments-value">{product.callDateAndTime}</h5>
                                    </div>
                                    <div className="call-fields">
                                        <label htmlFor="">Call Status</label>
                                        <h5 className="show-comments-value">{product.callStatus}</h5>
                                    </div>
                                </div>
                                <div className="call-form-footer">
                                    <h5 className="show-comments-value show-comment-value">Comment: {product.comment}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Comment