import React, { useState } from "react";
// import "../styles/DashNew.css";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import instance from '../../../utils/axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useUserStore from "../../../store";
import { defaultUrl } from "../../../data/Images";
import ShowPlanModal from "../DashboardUserPage/ShowPlanModal";

const NewMember = () => {
    // eslint-disable-next-line 
    const nav = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const user = useUserStore((state) => state.user);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const toggleShowPlanModal = () => {
        setShowPlanModal(!showPlanModal);
    };

    const location = "new User";

    const [showPlanModal, setShowPlanModal] = useState(false);
    const [newUser, setnewUser] = useState({
        start: '',
        name: '',
        username: "",
        email: '',
        address: {
            line1: "",
            line2: "",
            pincode: "",
            city: "",
            state: "",
        },
        mobile: '',
        country: '',
        occupation: '',
        document: '',
        path: "",
        password: '',
        dob: '',
        file: {
            name: "",
            data: "",
        },
        addressProof: {
            name: "",
            data: "",
        },
        mode: "",
    });

    const handleFileChange = (e, stateKey) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setnewUser((prevDetails) => ({
                    ...prevDetails,
                    [stateKey]: {
                        name: file.name,
                        data: reader.result,
                    },
                }));
            };
            reader.readAsDataURL(file);
        }
    };



    const handleUsernameFocusBlur = (event) => {
        const { value } = event.target;
        if (value.includes(' ')) {
            toast.error('Username must not contain spaces');
        }
    };

    const setData = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setnewUser((preval) => {
            return {
                ...preval,
                [name]: value,
            }
        });
    }


    const handleSubmit = async (e) => {
        try {
            const newRegister = newUser.email.toLowerCase();

            e.preventDefault();
            if (newUser.password.length < 8) {
                toast.error('Password should have at least 8 characters');
                return;
            }
            if (!/[A-Z]/.test(newUser.password)) {
                toast.error('Password should have at least one uppercase letter');
                return;
            }
            if (!/\d/.test(newUser.password)) {
                toast.error('Password should have at least one number');
                return;
            }
            if (newUser.password.includes(' ')) {
                toast.error('Password should not contain spaces');
                return;
            }
            if (!/^\d*$/.test(newUser.mobile)) {
                toast.error('Mobile number should only contain digits');
                return;
            }

            setLoading(true);

            const response = await instance.post('/api/user/newUser', {
                name: newUser.name,
                file: newUser.file,
                email: newRegister,
                username: newUser.username,
                address: {
                    line1: newUser.line1,
                    line2: newUser.line2,
                    city: newUser.city,
                    state: newUser.state,
                    pincode: newUser.pincode,
                },
                country: newUser.country,
                mobile: newUser.mobile,
                dob: newUser.dob,
                occupation: newUser.occupation,
                password: newUser.password,
                start: newUser.start,
                path: newUser.path,
                addressProof: newUser.addressProof,
                mode: newUser.mode,
                userId: user.uniqueId,
            })
            if (response.data.success) {
                setLoading(false);
                toast.success(response.data.message);
                setUserId(response.data.data._id);
                setShowPlanModal(true);
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            toast.error("An Error Occurred");
        }
    }

    return (
        <div className="newContainer">
            <motion.div className="dashboard-title new-top"
                style={{ justifyContent: "space-between" }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                Add New Member
                <h4 style={{ textDecoration: "none", fontWeight: "600" }} className='table-link'>
                    Step 1 of 2
                </h4>
            </motion.div>
            <motion.div className="new-bottom"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.3 }}
            >
                <div className="new-right">
                    {showPlanModal && <ShowPlanModal userId={userId} closeModal={toggleShowPlanModal} location={location} />}
                    <form onSubmit={handleSubmit}>
                        <div className="forminput">
                            <div className="new-left images">
                                <img src={newUser.file.data ? newUser.file.data : defaultUrl} alt="" />
                                <div>
                                    <label className='new-image' htmlFor="file"><DriveFolderUploadIcon className="icon" /></label>
                                    <input type="file" id='file' accept=".jpg .jpeg .png" onChange={(e) => handleFileChange(e, "file")} style={{ display: "none" }} />
                                </div>
                            </div>
                        </div>
                        <div className="forminput " >
                            <label htmlFor="username">Username</label>
                            <input type="text" placeholder="john_doe" required maxLength={9} name="username" value={newUser.username} onChange={setData} onFocus={handleUsernameFocusBlur} onBlur={handleUsernameFocusBlur} />
                        </div>
                        <div className="forminput " >
                            <label htmlFor="password">Password</label>
                            <div className="form-password" >
                                <input type={showPassword ? 'text' : 'password'} placeholder="password" required name="password" value={newUser.password} onChange={setData} />
                                <div
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    aria-label="toggle password visibility"
                                    className="icon"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </div>
                            </div>

                        </div>
                        <div className="forminput" >
                            <label htmlFor="name">Name</label>
                            <input type="text" placeholder="john Doe" name="name" onChange={setData} value={newUser.name} required />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="email">Email</label>
                            <input type="text" placeholder="johndoe@gmail.com" name="email" value={newUser.email} onChange={setData} />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="dob">Date of Birth</label>
                            <input type="date" name='dob' required value={newUser.dob} onChange={setData} />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">Postal Code</label>
                            <input type="number" maxLength={6} min={0} name='pincode' value={newUser.pincode} onChange={setData} placeholder="Enter Your Postal Code" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">Address: Line 1</label>
                            <input type="text" name='line1' value={newUser.line1} onChange={setData} placeholder="Elton St. 216 NewYork" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">Address: Line 2</label>
                            <input type="text" name='line2' value={newUser.line2} onChange={setData} placeholder="Elton St. 216 NewYork" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">City</label>
                            <input type="text" name='city' value={newUser.city} onChange={setData} placeholder="Enter Your City Name" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">State</label>
                            <input type="text" name='state' value={newUser.state} onChange={setData} placeholder="Enter Your State" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="address">Country</label>
                            <input type="text" name='country' value={newUser.country} onChange={setData} placeholder="Enter Your Country" required />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="mobile">Mobile</label>
                            <input type="text" maxLength={10} name='mobile' value={newUser.mobile} onChange={setData} placeholder="0000000000" required />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="occupation">Occupation</label>
                            <input type="text" name='occupation' value={newUser.occupation} onChange={setData} placeholder="Trainer: Planet Muscle" required />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="path">Health Issues</label>
                            <input type="text" name='path' value={newUser.path} onChange={setData} placeholder="Any Medical Condition" required />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="addressProof">Address Proof (Aadhar/Pan/Driving License)</label>
                            <input type="file" id='addressProof' onChange={(e) => handleFileChange(e, "addressProof")} accept=".pdf" />
                        </div>
                        <div className="forminput" >
                            <label htmlFor="mode">Training Mode</label>
                            <select name="mode" onChange={setData} value={newUser.mode} required>
                                <option value="">Select Training Mode</option>
                                <option value="Online">Online</option>
                                <option value="Offline">Offline</option>
                            </select>
                        </div>
                        <button className={`dash-btn ${loading ? 'upload-btn' : ''}`}>
                            {loading ? <AutorenewIcon className="loader-css" /> : "Add New User"}
                        </button>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

export default NewMember;
