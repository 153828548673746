import React, { useState, useEffect } from "react";
import instance from "./../../utils/axios";
import { toast } from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import useUserStore from "./../../store";
import { useNavigate } from "react-router-dom";

const NewChatGroup = ({setShowModal, location, groupId }) => {

  const [names, setNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [groupName, setGroupName] = useState("");
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await instance.get("/api/user/getAllUsers");
      if (response.data.success) {
        setNames(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Get User Names error:", error);
      toast.error(error.message);
    }
  };

  const getChatRoomData = async () => {
    try {
      const response = await instance.get(`/api/user/getGroupData/${groupId}`);
      if (response.data.success) {
        console.log(response.data.data);
        setGroupName(response.data.data.chatName)
        const participantNames = response.data.data.participants.map(participant => participant.name);
        setSelectedNames(participantNames);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Get User Names error:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (groupId) {
      getChatRoomData();
    }
    // eslint-disable-next-line
  }, [groupId]);

  useEffect(() => {
    getData();
    setSelectedNames([user.name]);
  }, [user.name]);

  const handleNameClick = (name) => {
    if (selectedNames.includes(name)) {
      setSelectedNames(selectedNames.filter((n) => n !== name));
    } else {
      setSelectedNames([...selectedNames, name]);
    }
  };

  const handleRemoveName = (nameToRemove) => {
    setSelectedNames(selectedNames.filter((name) => name !== nameToRemove));
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    try {
      const endpoint = location === 'update' ? `/api/user/updateChatGroup/${groupId}` : "/api/user/createGroup";
      const response = await instance[location === 'update' ? "patch" : "post"](endpoint, {
        chatName: groupName,
        users: selectedNames,
        userId: user._id,
      });
      if (response.data.success) {
        toast.success(response.data.message);
        setGroupName("");
        setSelectedNames([]);
        setShowModal(false);
        navigate(0);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("Create Group error:", error);
      toast.error(`Failed to ${location === 'update' ? "update" : "create"} group. Please try again.`);
    }
  };

  return (
    <div className={`new-chat-group ${location === "update" ? 'newLeft' : ""}`}>
      <div className="new-chat-group-container">
        <h2 className="new-chat-h2">
          {location === "update" ? 'Update Existing Group' : "Create a New Group"}
        </h2>
        <div className="new-user-group-name">
          <label htmlFor="groupName">Group Name</label>
          <input
            type="text"
            name="groupName"
            placeholder="Enter the group Name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <div className="new-group">
          <div className="new-chat-h3">Add User</div>
          {names?.map((a, b) => (
            <div
              className={`new-chat ${
                selectedNames.includes(a) ? "selected" : ""
              }`}
              key={b}
              onClick={() => handleNameClick(a)}
            >
              <div className="new-chat-name">
                <div className="new-chat-main">
                  <h4 className="new-chat-index">{b + 1}</h4>
                  <h4 className="new-chat-index">{a}</h4>
                </div>
                <AddIcon />
              </div>
            </div>
          ))}
        </div>
        <div className="new-group-members">
          <h3 className="new-group-members-h3">Selected Members:</h3>
          <div className="new-group-member-container">
            {selectedNames.map((name, index) => (
              <div className="selected-member" key={index}>
                {name}
                <CloseIcon onClick={() => handleRemoveName(name)} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <button className="create-group-btn" onClick={handleCreateGroup}>
      {location === "update" ? 'Update' : "Create"}
      </button>
      <CloseIcon className='create-group-close-icon' onClick={() => setShowModal(false)} />
    </div>
  );
};

export default NewChatGroup;
