export const planNameData = [
    { name: "General Fitness Plan" },
    { name: "General Fitness Plan (Couple Membership)" },
    { name: "General Fitness Plan (Senior Citizen Membership)" },
    { name: "General Fitness Plan (Student Membership)" },
    { name: "General Fitness Plan (Group Membership)" },
    { name: "Exercise Science Plan" },
    { name: "Diet & Nutrition" },
    { name: "Physiotherapy Plan" },
    { name: "List a Gym" },
    { name: "List a Garden" },
    { name: "Gym Management Services" },
    { name: "Become a Trainer" },
    { name: "Become a Writer" },
    { name: "Sports Events" },
    { name: "Franchise" },
    { name: "Society Management" },
    { name: "Peak Performance Plan" },
    { name: "Weight Management Services" },
    { name: "Online Personal Training" },
    { name: "Nutritional Plan" },
    { name: "Appointment & Doctor" },
    { name: "Silver Personal Training" },
    { name: "Gold Personal Training" },
    { name: "Diamond Personal Training" },
    { name: "Platinum Personal Training" },
    { name: "Master Personal Training" },
    { name: "One Day Trial" },
    { name: "Outside Personal Training" },
]

export const gymName = [
    { name: "Andheri" },
    { name: "Versova" },
    { name: "Azad Nagar" },
    { name: "Online" },
    { name: "Hybrid" },
]

export const referralType = [
    { name: "Instagram" },
    { name: "facebook" },
    { name: "Walk In" },
    { name: "Friends" },
    { name: "Relatives" },
    { name: "Google" },
    { name: "Linkedin" },
    { name: "SnapChat" },
]

export const testName = [
    {
        name: "Kidney Screening"
    },
    {
        name: "Comprehensive Tumor Panel-500 genes"
    },
    {
        name: "Fabry disease (GLA) gene analysis [expendited]"
    },
    {
        name: "Molecular Genetic BetaThalassemia Mutation analysis-NGS"
    },
    {
        name: "Homologous Recombination Deficiency (HRD ) genes-NGS"
    },
    {
        name: "Recurrent Pregnancy Loss(RPL) gene analysis female-NGS"
    },
    {
        name: "Bohring Optiz syndrome ASXL1 gene Mutation NGS"
    },
    {
        name: "Molecular Panel for CML"
    },
    {
        name: "Molecular Panel MDS"
    },
    {
        name: "NOTCH 3 Gene Mutation"
    },
    {
        name: "Nephrogenetics Panel"
    },
    {
        name: "Carrier Screening-100 genes"
    },
    {
        name: "Arthrogryposis and Congenital Myasthenic Syndrome Gene Panel"
    },
    {
        name: "Glioma Gene Panel (IDH1&IDH2,ATRX,TP53,BRAF)"
    },
    {
        name: "Colorectal cancer panel with MSI by PCR:KRAS, NRAS & BRAF sequencing + MSI by PCR"
    },
    {
        name: "GIST Panel"
    },
    {
        name: "Molecular Genetic Whole Exome Sequencing (WES)-NGS"
    },
    {
        name: "Smith-Lemli-Opitz syndrome (DHCR7) - NGS"
    },
    {
        name: "Marfan syndrome (FBN1) gene analysis"
    },
    {
        name: "Hereditary pancreatitis gene panel"
    },
    {
        name: "TSC1 and TSC2 gene analysis"
    },
    {
        name: "Retinal degeneration gene panel"
    },
    {
        name: "Congenital adrenal hyperplasia CYP21A2(21-OH) gene analysis"
    },
    {
        name: "Pompe disease (GAA) gene analysis"
    },
    {
        name: "Marfan syndrome (FBN1) deletion/duplication analysis"
    },
    {
        name: "Haemophillia gene analysis"
    },
    {
        name: "Molecular Genetic Imantinib Resistance Mutation Analysis (IRMA)-NGS"
    },
    {
        name: "Peroxisomal disorder gene panel (includes adrenoleukodystrophy ) "
    },
    {
        name: "Molecular Genetic Wilson disease (ATP7B) gene analysis-NGS"
    },
    {
        name: "Phenylketonuria (PHA) gene analysis"
    },
    {
        name: "Xeroderma pigmentosum gene panel"
    },
    {
        name: "Menkes disease (ATP7A) gene analysis"
    },
    {
        name: "Familial female mental retardation /epilepsy gene panel"
    },
    {
        name: "Leigh syndrome & mitochondrial encephalopathy gene panel"
    },
    {
        name: "Molecular Genetic Achondroplasia (FGFR3) gene analysis-NGS"
    },
    {
        name: "HLA G (High resolution)"
    },
    {
        name: "Molecular Genetic Osteogenesis impertecta gene panel-NGS"
    },
    {
        name: "Lysosomal Storage disorders gene panel"
    },
    {
        name: "Clinical Exome Sequencing & Hemolytic uremic syndrome (CFH, CFHR1, CFHR2, CFHR3 & CFHR5) deletion/duplication analysis"
    },
    {
        name: "Comprehensive Hereditary Cancer gene panel +MLPA"
    },
    {
        name: "Molecular Genetic Ataxia- telangiectasia (ATM) gene analysis-NGS"
    },
    {
        name: "Limb hypoplasia-reduction defects gene panel"
    },
    {
        name: "Whole Genome sequencing of DNA-NGS"
    },
    {
        name: "Whole exome Sequencing (WES) of DNA for couple-NGS"
    },
    {
        name: "Molecular Genetic Carrier Screening by clinical exome-NGS"
    },
    {
        name: "Molecular Genetic Dystonia Gene Panel-NGS"
    },
    {
        name: "Molecular Genetic Alpha Thalassemia Gene Mutation analysis-Sanger Sequencing"
    },
    {
        name: "Molecular Genetic Comprehensive Alpha & Beta Thalassemia Gene Panel-NGS"
    },
    {
        name: "Molecular Genetic Lynch Syndrome Gene Panel-NGS"
    },
    {
        name: "Molecular Genetic Single Gene Analysis-NGS"
    },
    {
        name: "Molecular Genetic Whole Exome Sequencing (WES)+ Whole Mitochondrial Genome Sequencing (WMS)-NGS"
    },
    {
        name: "Molecular Genetic Somatic/Comprehensive tumor gene panel-NGS"
    },
    {
        name: "Molecular Genetic Clinical exome sequencing-NGS"
    },
    {
        name: "Molecular Genetic Clinical Exome + Whole Mitochondrial Genome Sequencing-NGS"
    },
    {
        name: "Molecular Genetic BRCA1/BRCA2 Gene Analysis - NGS"
    },
    {
        name: "Molecular Genetic Thrombophilia Gene Panel - NGS"
    },
    {
        name: "Molecular Genetic Carrier Screening-2000genes by NGS & MLPA (SMA, CAH and Fragile X)"
    },
    {
        name: "Molecular Genetic Homocystinuria gene analysis-NGS"
    },
    {
        name: "Molecular Genetic Hereditary cancer gene panel-NGS"
    },
    {
        name: "Molecular Genetic Deafness gene panel-NGS"
    },
    {
        name: "Molecular Genetic Neurofibromatosis gene panel - NGS"
    },
    {
        name: "Molecular Genetic Cardiomyopathy gene panel-NGS"
    },
    {
        name: "Molecular Genetic Muscular dystrophy and congenital myopathy gene panel-NGS"
    },
    {
        name: "Molecular Genetic Maturity – onset diabetes of the young(MODY) & neonatal diabetes gene panel"
    },
    {
        name: "Molecular Genetic Skeletal dysplasia gene panel-NGS"
    },
    {
        name: "Molecular Genetic Prader –Willi/Angelman syndrome deletion/duplication analysis-MLPA"
    },
    {
        name: "Molecular Genetic Whole Mitochondrial Genome Sequencing-NGS"
    },
    {
        name: "Molecular Genetic Gaucher disease (GBA) gene analysis-NGS"
    },
    {
        name: "Molecular Genetic Pharmacogenomics Panel-NGS"
    },
    {
        name: "Molecular Genetic HLA -Typing High Resolution(Donor)-NGS"
    },
    {
        name: "Molecular Genetic HLA -Typing High Resolution(Recipiant)-NGS"
    },
    {
        name: "Molecular Genetic Trio Clinical Exome-NGS"
    },
    {
        name: "Molecular Genetic Clinical Exome with MCC-NGS"
    },
    {
        name: "Molecular Genetic TPMT/NUDT15 gene analysis-NGS"
    },
    {
        name: "Molecular Genetic BRCA1&BRCA2 with HRR Genes For Somatic Mutation Testing-NGS"
    },
    {
        name: "Newborn Screening- TMS 52+111 conditions on GCMS+11 conditions"
    },
    {
        name: "Newborn Screening - 111 conditions on GCMS"
    },
    {
        name: "Newborn Screening-Combo panel (TMS 52 conditions+7 Basic conditions + Urine for GCMS 111 conditions)"
    },
    {
        name: "Newborn Screening-TMS 52 conditions"
    },
    {
        name: "Newborn Screening-7 conditions (Basic Panel)"
    },
    {
        name: "Newborn Screening-11 conditions + Haemoglobinopathies"
    },
    {
        name: "Newborn Screening-TMS 52 Conditions + 7 conditions"
    },
    {
        name: "Acute Gastroenteritis Multiplex"
    },
    {
        name: "Bacterial Gastroenteritis Multiplex"
    },
    {
        name: "Viral Gastroenteritis Multiplex"
    },
    {
        name: "Parasitic Gastroenteritis Multiplex "
    },
    {
        name: "Sepsis with AMR Multiplex (23 Pathogen & 17 Antimicrobial Resistance markers)"
    },
    {
        name: "Invasive Fungal Sepsis Multiplex - (29 Pathogen)"
    },
    {
        name: "Neutropenic Pyrexia Multiplex"
    },
    {
        name: "Viral Mycocarditis Multiplex -  ( 11 Pathogen)"
    },
    {
        name: "Extended pneumonia Multiplex - (50 Pathogen)"
    },
    {
        name: "Post transplant Meningo-encephalitis MultipleX - (46 Pathogen)"
    },
    {
        name: "Post Transplant Sepsis Multiplex 1 -  (41 Pathogen)"
    },
    {
        name: "Post transplant Sepsis Multiplex 2 - (44 Pathogen+ 17 AMR)"
    },
    {
        name: "Post Transplant Viral screening Multiplex - (13 Pathogen)"
    },
    {
        name: "Mini transplant Reflex Plus panel -  (4 Pathogen)"
    },
    {
        name: "Mini transplant Reflex panel -  (3 Pathogen)"
    },
    {
        name: "Invasive fungal pneumonia Multiplex - (27 Pathogen)"
    },
    {
        name: "Flu pneumonia multiplex with COVID"
    },
    {
        name: "Upper Respiratory Multiplex"
    },
    {
        name: "Invasive fungal pneumonia Multiplex - Extended"
    },
    {
        name: "Complete pneumonia Multiplex Panel"
    },
    {
        name: "Bacterial pneumonia multiplex -  (21 Pathogen)"
    },
    {
        name: "Respiratory viral Multiplex-  (22 Pathogen)"
    },
    {
        name: "Complete Meningitis Multiplex"
    },
    {
        name: "Pan Viral Meningitis MultipleX - (11 Pathogen)"
    },
    {
        name: "Bacterial Meningitis MultipleX Panel"
    },
    {
        name: "Comprehensive Meningitis Multiplex Panel"
    },
    {
        name: "Meningo-encephalitis MultipleX Panel"
    },
    {
        name: "Fungal meningitis MultipleX Panel"
    },
    {
        name: "Flu pneumonia multiplex with COVID - Extended "
    },
    {
        name: "Newborn Screening-TMS 52 Conditions + 11 conditions"
    },
    {
        name: "Tru Antenatal Advance with Thalassemia Screening"
    },
    {
        name: "Molecular Genetic Noninvasive Prenatal Testing (NIPT)  With Microdeletion-NGS"
    },
    {
        name: "Molecular Genetic Noninvasive Prenatal Testing (NIPT)-NGS"
    },
    {
        name: "DNA Isolation"
    },
    {
        name: "Genetic Counselling"
    },
    {
        name: "Cytogenetic Analysis-13/21, 18/XY aneuploidy-FISH"
    },
    {
        name: "Cytogenetic Analysis - Amniotic Fluid-Karyotype"
    },
    {
        name: "Cytogenetic Analysis- KRAS/CEN 12 Dual Color-FISH"
    },
    {
        name: "Cytogenetic Analysis-ALK/EML4 t(2:2);inc(2)Fusion-FISH"
    },
    {
        name: "Cytogenetic Analysis-ERBB2/CEN17(HER2/neu)-FISH"
    },
    {
        name: "Cytogenetic Analysis-CDKN2A (p16) deletions and aneuploidy of chromosomes 3, 7, and 17-FISH"
    },
    {
        name: "Cytogenetic Analysis- EGFR/CEN 7 Dual Color-FISH"
    },
    {
        name: "Cytogenetic Analysis- COL1A1/PDGFB Dual Color-FISH"
    },
    {
        name: "Cytogenetic Analysis- PDGFRA/FIP1L1 TriCheck-FISH"
    },
    {
        name: "Cytogenetic Analysis- PDGFRB Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis- Leukemia panels- Eosinophilia CBF-Beta, PDGFRAlpha/PDGFRBet-FISH"
    },
    {
        name: "Cytogenetic Analysis-EWSR1 Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis-Retinoblastoma RB1(13q14)-FISH"
    },
    {
        name: "Cytogenetic Analysis- CCND1/CEN 11 Dual Color Probe-FISH"
    },
    {
        name: "Cytogenetic Analysis- ERG/TMPRSS2 TriCheck-FISH"
    },
    {
        name: "Cytogenetic Analysis-Angelman Syndrome UBE3A(15q11)/PML(15q24)-FISH"
    },
    {
        name: "Cytogenetic Analysis-MDS Panel 5 Markers-FISH"
    },
    {
        name: "Cytogenetic Analysis-Deletion 20q (MDS) - FISH"
    },
    {
        name: "Cytogenetic Analysis-Deletion 5q (MDS) - FISH"
    },
    {
        name: "Cytogenetic Analysis-Deletion 7q (MDS) - FISH"
    },
    {
        name: "Cytogenetic Analysis-Peripheral Blood-Karyotype"
    },
    {
        name: "Cytogenetic Analysis-TRISOMY 8 - FISH"
    },
    {
        name: "Cytogenetic Analysis-MDS Panel 4 Markers-FISH"
    },
    {
        name: "Cytogenetic Analysis-Couple Karyotype"
    },
    {
        name: "Cytogenetic Analysis-Aborted Fetus / Products of Conception (POC)/Chorionic villus sampling (CVS)"
    },
    {
        name: "Cytogenetic Analysis-Chromosomes 13/21 & 18/XY FISH and Couple Karyotype"
    },
    {
        name: "Cytogenetic Analysis-Karyotype reflex FISH for Chromosomes 13/21 and 18/XY"
    },
    {
        name: "Karyotyping from cell pellet/slides"
    },
    {
        name: "Cytogenetic Analysis Karyotype + FISH Chromosomes 13/21 and 18/XY"
    },
    {
        name: "Cytogenetic Analysis - Cord Blood-Karyotype"
    },
    {
        name: "Cytogenetic Analysis-CCND1/IGH Dual Color Dual Fusion-FISH"
    },
    {
        name: "Cytogenetic Analysis - Y Chromosome-FISH"
    },
    {
        name: "Cytogenetic Analysis-Fanconi Anemia/Stress Cytogenetics"
    },
    {
        name: "Cytogenetic Analysis-PML- RARa t(15;17) - FISH"
    },
    {
        name: "Cytogenetic Analysis-FGFR1 Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis-Multiple Myeloma Panel (10 markers)-FISH"
    },
    {
        name: "Cytogenetic Analysis-High resolution Karyotype (HRK)"
    },
    {
        name: "Cytogenetic Analysis-DiGeorge Syndrome Tuple (22q11)/22q13 (SHANK3)-FISH"
    },
    {
        name: "Cytogenetic Analysis - SRY deletion- FISH"
    },
    {
        name: "Cytogenetic Analysis-Trisomy 13/21 (Patau and Down syndrome)-FISH"
    },
    {
        name: "Cytogenetic Analysis-Prader Willi Syndrome SNRPN(15q11)/PML(15q2)-FISH"
    },
    {
        name: "Cytogenetic Analysis-Trisomy 18 and sex chromosomes XY (Edward syndrome, Klinefelter and Turner syndrome and cancers)-FISH"
    },
    {
        name: "Cytogenetic Analysis-JAK2 t(9p24) Break-FISH"
    },
    {
        name: "Cytogenetic Analysis-TP53 (17p13)/ ATM (11q22)-FISH"
    },
    {
        name: "Cytogenetic Analysis-MYC(8q24)/IGH (14q32.33)-FISH"
    },
    {
        name: "Cytogenetic Analysis- RUNX1 (21q22.1)/RUNX1T1(8q21.3) Fusion-FISH"
    },
    {
        name: "Cytogenetic Analysis- MAF/IGH Dual Color Dual Fusion-FISH"
    },
    {
        name: "Cytogenetic Analysis- BCL2 Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis- KMT2A Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis- CBFB Dual Color Break Apart-FISH"
    },
    {
        name: "Cytogenetic Analysis- ETV6/RUNX1 Dual Color Dual Fusion-FISH"
    },
    {
        name: " Cytogenetic Analysis-FGFR3/IGH Dual Color Dual Fusion-FISH"
    },
    {
        name: "Cytogenetic Analysis Leukemia panel by-Any Four markers/probes-FISH"
    },
    {
        name: "Cytogenetic Analysis-One Marker/Probe-FISH"
    },
    {
        name: "Cytogenetic Analysis-Multiple Myeloma (MM) (5 markers) panel-FISH"
    },
    {
        name: "Cytogenetic Analysis-Chronic Lymphocytic Leukemia (CLL) panel-FISH"
    },
    {
        name: "Cytogenetic Analysis-Inv 3 (MECOM Break Apart)"
    },
    {
        name: "Cytogenetic Analysis-Acute Myeloid Leukemia (AML) panel-FISH"
    },
    {
        name: "Cytogenetic Analysis-Leukemia panel by FISH-Any Three markers/probes-FISH"
    },
    {
        name: "Cytogenetic Analysis-Leukemia panel by FISH- Any Two markers/probes-FISH"
    },
    {
        name: "Cytogenetic analysis- Bone Marrow (Leukemia/Hematological Cancers)-Karyotype"
    },
    {
        name: "Cytogenetic Analysis-Bcr-Abl (Philadelphia) t(9;22) - FISH"
    },
    {
        name: "Cytogenetic Analysis-Aneuploidy Sperm by 13/21 & 18/XY FISH"
    },
    {
        name: "Cytogenetic Analysis-Peripheral Blood-Karyotype 50 metaphases"
    },
    {
        name: "Cytogenetic Analysis-Williams Syndrome; Microdeletion of chromosome 7q11.23**-FISH"
    },
    {
        name: "Cytogenetic Analysis-Chromosomal Microarray- CNV 315K Optima low resolution genechip-CGH"
    },
    {
        name: "Cytogenetic Analysis-Chromosomal Microarray - CNV 750K genechip-CGH"
    },
    {
        name: "Cytogenetic Analysis-Couple Microarray Optimum (315K) genechip-CGH"
    },
    {
        name: "Karyotypye for cell lines"
    },
    {
        name: "Cytogenetic Analysis-TP53 (17p13) -FISH"
    },
    {
        name: "Cytogenetic Analysis- ATM (11q22)-FISH"
    },
    {
        name: "Cytogenetic Analysis-X,Y Chimerism Screening- FISH"
    },
    {
        name: "Cytogenetic Analysis-MDS Panel (10 markers)-FISH"
    },
    {
        name: "Cytogenetic Analysis-1p /19q Deletions (Oligodendrogliomas)"
    },
    {
        name: "Cytogenetic Analysis-BCL 6 (3q27 Gene rearrangement )"
    },
    {
        name: "Cytogenetic Analysis-CML Panel- Accelerate/ Blast Phase (4 markers) BCR/ABL (Ph) duplication, trisomy 8, trisomy 21, TP53 deletion "
    },
    {
        name: "Cytogenetic Analysis-cMYC; t(8;14) Burkitt Lymphoma Bone marrow"
    },
    {
        name: "Cytogenetic Analysis-cMYC; t(8;14) Burkitt Lymphoma- Tissue "
    },
    {
        name: "Cytogenetic Analysis-Deletion 11q "
    },
    {
        name: "Cytogenetic Analysis-Deletion 17p "
    },
    {
        name: "Cytogenetic Analysis-Deletion 6q "
    },
    {
        name: "Cytogenetic Analysis-Deletion 8p "
    },
    {
        name: "Cytogenetic Analysis-E2A/PBX1 translocation t(1;19) (q23;p13) "
    },
    {
        name: "Cytogenetic Analysis-IGH-BCL2; t(14;18) Follicular Lymphoma (FL) - Tissue "
    },
    {
        name: "Cytogenetic Analysis-IGH-CCND1; t(11;14) Mantle cell Lymphoma (MCL)- Tissue "
    },
    {
        name: "Cytogenetic Analysis-INV (16), CBFB gene "
    },
    {
        name: "Cytogenetic Analysis-Acute Lymphocytic Leukemia (ALL) panel-FISH"
    },
    {
        name: "TRU AUTOIMMUNE Diabetes 1 Profile"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Semen"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain (3 Samples) - Sputum"
    },
    {
        name: "MYCOBACTERIAL CULTURE (L. J. METHOD)"
    },
    {
        name: "Culture & Sensitivity - Blood Set (2 paired Bottles)"
    },
    {
        name: "Fungal Culture and Anti-Fungal Susceptibility Test"
    },
    {
        name: "Fungal Culture And Identification, Urine"
    },
    {
        name: "Fungal Culture And Identification, Stool"
    },
    {
        name: "Fungal Culture And Identification, Sputum*"
    },
    {
        name: "Fungal Culture And Identification, Skin"
    },
    {
        name: "Fungal Culture And Identification, Respiratory Samples"
    },
    {
        name: "Fungal Culture And Identification, Pus"
    },
    {
        name: "Fungal Culture And Identification, Other Sample"
    },
    {
        name: "Fungal Culture And Identification, Nail"
    },
    {
        name: "Fungal Culture And Identification, Hair"
    },
    {
        name: "Fungal Culture And Identification, Eye Sample"
    },
    {
        name: "Fungal Culture And Identification, Ear Sample"
    },
    {
        name: "Fungal Culture And Identification, CSF"
    },
    {
        name: "Fungal Culture And Identification, Body Fluids"
    },
    {
        name: "Fungal Culture And Identification , Bone Marrow*"
    },
    {
        name: "Fungal Culture And Identification"
    },
    {
        name: "Culture And Identification, Anaerobic Bacteria- Pus"
    },
    {
        name: "Culture & Identification - Anaerobic Bacteria, Stool"
    },
    {
        name: "Culture & Identification - Anaerobic Bacteria"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture Urine"
    },
    {
        name: "Genexpert plus (Genexpert Assay & AFB MGIT rapid culture)"
    },
    {
        name: "AFB Rapid Culture, Other"
    },
    {
        name: "AFB Rapid Culture, Endometrium"
    },
    {
        name: "SPINO-BULBAR MUSCULAR ATROPHY -AR Gene CAG Repeats"
    },
    {
        name: "Tumour Markers Panel  (PSA, AFP, CA125, CA 19.9, CEA)"
    },
    {
        name: "Myotonia gene panel"
    },
    {
        name: "Integrated Bone Marrow Report ( Aspirate + Biopsy with up to 2 IHC markers)"
    },
    {
        name: "Second opinion: Extramural / expert opinion"
    },
    {
        name: "Second opinion: Cytopathology (intramural)."
    },
    {
        name: "Histopathology- Very Large (includes disarticulation/ amputation/ radical prostatectomy etc)"
    },
    {
        name: "Acid Fast Bacilli (AFB) Solid Culture by L. J. Method"
    },
    {
        name: "MDR AND XDR SCREENING BY LINE PROBE ASSAY [HAIN 1ST & 2ND LINE]"
    },
    {
        name: "Genexpert MTB/XDR Resistance - XDR Detection"
    },
    {
        name: "OT Microbiological Surveillance Panel - Aerobic & Anaerobic (5 Swabs)"
    },
    {
        name: "OT Microbiological Surveillance Panel - Aerobic & Anaerobic (10 Swabs)"
    },
    {
        name: "OT Microbiological Surveillance Panel - Aerobic & Anaerobic"
    },
    {
        name: "OT Microbiological Surveillance Panel - Aerobic & Anaerobic (1 Swab)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Set, Blood (2 Aerobic Bottles)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Blood Clot"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Blood"
    },
    {
        name: "Blood Culture And Sensitivity: Aerobic Bacteria, Pediatric Blood"
    },
    {
        name: "Blood Culture And Sensitivity: Aerobic Bacteria"
    },
    {
        name: "Tissue biomarker - OCT3/4: IHC analysis"
    },
    {
        name: "Triple-H Profile Quantitative(eCLIA)"
    },
    {
        name: "IGLON5 ANTIBODY"
    },
    {
        name: "Tru Flu PCR Panel (RSV,Influenza A&B, H1N1 & SARS-COV2)"
    },
    {
        name: "AMH Plus Profile"
    },
    {
        name: "Cytology with cell block for IHC"
    },
    {
        name: "Histopathology (biopsy)"
    },
    {
        name: "Final diagnosis panel (Histopathology with IHC inclusive)"
    },
    {
        name: "Tru Gym Package"
    },
    {
        name: "Allergy-Nuts Panel"
    },
    {
        name: "Alpha thalassemia (HBA1 & HBA2) deletion/duplication analysis"
    },
    {
        name: "Premium Automated Aerobic Culture & Sensitivity test"
    },
    {
        name: " Immunostain only- ER, PgR and HER-2/neu"
    },
    {
        name: "Paraffin Bloocks / Slides for outside mumbai"
    },
    {
        name: "Paraffin Bloocks / Slides for mumbai"
    },
    {
        name: "Paraffin Bloocks / Slides walkin CPL"
    },
    {
        name: "Special Histochemical Stain- Fite Faraco stain"
    },
    {
        name: "TB PYROSEQUENCING PANEL"
    },
    {
        name: "Allergy - Adult panel -3"
    },
    {
        name: "Custom IHC panel III"
    },
    {
        name: "Allergy - Environmental Panel"
    },
    {
        name: "Custom IHC panel II"
    },
    {
        name: "Tissue Biomarker GATA-3- IHC analysis"
    },
    {
        name: "Tissue Biomarker: SOX10 - IHC analysis"
    },
    {
        name: "Tissue Biomarker- p63 (IHC analysis)"
    },
    {
        name: "Tissue Biomarker: NKX3.1- IHC analysis"
    },
    {
        name: "TRU CARE PACKAGE"
    },
    {
        name: "Custom IHC panel"
    },
    {
        name: "CSF Cytology"
    },
    {
        name: "Aryl Sulphatase A metachromatic leucodystrophy Blood"
    },
    {
        name: "Tru Covid Suraksha"
    },
    {
        name: "COVID-19 Antigen Test"
    },
    {
        name: "Home Visit"
    },
    {
        name: "Qualitative RNA detection of SARS-CoV-2 ( COVID 19)*"
    },
    {
        name: "ANTIBIOTIC SUSCEPTIBILITY TESTING FOR NOCARDIA"
    },
    {
        name: "FEVER PACKAGE SCREEN 2 (DENGUE RAPID)"
    },
    {
        name: "RESPIRATORY PANEL 10"
    },
    {
        name: "Bordetella pertussis Real time PCR"
    },
    {
        name: "Beta D Glucan Level by Colorimetry, Serum"
    },
    {
        name: "B-CAROTENE,SERUM"
    },
    {
        name: "SCA - Comprehensive profile 6 Genes"
    },
    {
        name: "Neuronal Antibody Profile - 3"
    },
    {
        name: "Huntington Disease (CAG Repeats) Test"
    },
    {
        name: "Gram Negative  Bacterial Marker PCR Detection"
    },
    {
        name: "Tissue Biomarker: Uroplakin- IHC"
    },
    {
        name: "Vaccicheck (IgG Antibody against Spike, S1 Protein)"
    },
    {
        name: "SARS-COV-2 (COVID-19) Qualitative IgG Antibody"
    },
    {
        name: "SARS-CoV-2 IgM Antibody Test"
    },
    {
        name: "Diabetes Profile 1"
    },
    {
        name: "Mucorales Real Time PCR"
    },
    {
        name: "Lymphoma Panel IHC"
    },
    {
        name: "MUCORMYCOSIS Advanced  PACKAGE"
    },
    {
        name: "MUCORMYCOSIS Basic PACKAGE"
    },
    {
        name: "FEVER PACKAGE SCREEN 2"
    },
    {
        name: "BK Virus Quantitative PCR"
    },
    {
        name: "Allergy Profile 2"
    },
    {
        name: "Allergy Profile 1"
    },
    {
        name: "Infective  Endocarditis (Core) Panel"
    },
    {
        name: "Antibiotic Resistance Panel by PCR 1"
    },
    {
        name: "AUTOCLAVE STERILIZAION TESTING - BIOLOGICAL INDICATOR"
    },
    {
        name: "Parvovirus B19 Real Time PCR Quantitative"
    },
    {
        name: "BOH Profile Mini"
    },
    {
        name: "STD Real Time PCR diagnostic Panel"
    },
    {
        name: "CSF Index, IgG"
    },
    {
        name: "Second Opinion - Histopathology"
    },
    {
        name: "PURE CULTURE: MALDI-TOF IDENTIFICATION"
    },
    {
        name: "Bullous Pemphigoid 180"
    },
    {
        name: "Tru Pre Operative Profile"
    },
    {
        name: "Tru Diabetic Profile Advance"
    },
    {
        name: "Tru Diabetes Profile Basic"
    },
    {
        name: "ALLERGY PROFILE FOOD (40 Allergens)"
    },
    {
        name: "ALLERGY PROFILE INHALATION 2 (33 Allergens)"
    },
    {
        name: "Pan Neurotropic Panel by PCR"
    },
    {
        name: "TACROLIMUS-CMIA"
    },
    {
        name: "Triglycerides, Urine"
    },
    {
        name: "GROUP B STREPTOCOCCAL SCREENING TEST"
    },
    {
        name: "ROUTINE MICROSCOPY EXAMINATION"
    },
    {
        name: "Renal biopsy- IF"
    },
    {
        name: "Phospholipase A2 receptor Antibody"
    },
    {
        name: "EXTENDED ANTI-FUNGAL SUSCEPTIBILITY TEST"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - 19 DRUGS (L.J. METHOD)"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - 16 DRUGS (L.J. METHOD)"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - 13 DRUGS (L.J. METHOD)"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - 10 DRUGS (L.J. METHOD)"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - 5 DRUGS (L.J. METHOD)"
    },
    {
        name: "ANTIMYCOBACTERIAL DRUG SUSCEPTIBILITY TESTING - SINGLE DRUG (L.J. METHOD)"
    },
    {
        name: "Organic acid Semi Quantitative, Urine**(GCMS)"
    },
    {
        name: "ALLERGEN, HAZELNUT"
    },
    {
        name: "ALLERGEN, BRINJAL"
    },
    {
        name: "ALLERGEN, COD FISH"
    },
    {
        name: "Metabolic Disorder Panel"
    },
    {
        name: "Tru Raksha Advanced Package"
    },
    {
        name: "Tru Raksha Basic Package"
    },
    {
        name: "Cardiolipin Antibody IgM & IgG"
    },
    {
        name: "Phospholipid Antibody IgG & IgM"
    },
    {
        name: "Meningitis/Encephalitis panel by FilmArray"
    },
    {
        name: "HFE GENOTYPING"
    },
    {
        name: "ALLERGY VEG GIT PANEL-1"
    },
    {
        name: "Tru Health Power"
    },
    {
        name: "Tru Vital Mini"
    },
    {
        name: "Myositis Profile-IgG(16 Antigen)"
    },
    {
        name: "HLA-TYPING DR LOCUS"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Respiratory Samples"
    },
    {
        name: "STD Profile -3"
    },
    {
        name: " Gastrointestinal panel by Filmarray Biofire"
    },
    {
        name: "Glucose, Fasting (F) And Post Prandial (PP), 2 Hours"
    },
    {
        name: "Imatinib Resistance mutation analysis"
    },
    {
        name: "Haemogram"
    },
    {
        name: "Lambda Light Chains-Free, Urine 24HRS"
    },
    {
        name: "Kappa Light Chains-Free, Urine 24HRS"
    },
    {
        name: "P1NP"
    },
    {
        name: "Allergen, Dermatophagoides Pteronyssinus"
    },
    {
        name: "Soluble Transferrin Receptor"
    },
    {
        name: "Primary Immunodeficiency Genes Panel -NGS"
    },
    {
        name: "DPT(Diphtheria, Tetanus, Pertusis Antibody)"
    },
    {
        name: "CALR Muatation Detection - MPC003"
    },
    {
        name: "AML with Normal Cytogenetics (FLT3, NPM1, CEBPA)"
    },
    {
        name: "LEUKEMIA TRANSLOCATION PANEL 2 (Inv(16)(p13;q22), t(15;17)(q22;q22), t(8;21)(q22;q22))"
    },
    {
        name: "LEUKEMIA TRANSLOCATIONPANEL ALL Markers -  t(1; 19) (q23; p13),  t(9; 22) (q34; q11), t(4;11)(q21; q23) and t(12; 21) (p13;q22)"
    },
    {
        name: "APCR (Activated protein C resistance)"
    },
    {
        name: "HLA-B5 (B*51,B*52) Typing"
    },
    {
        name: "KALA AZAR"
    },
    {
        name: "Metanephrine -Total (24HRS URINE)"
    },
    {
        name: "Genexpert HBV Viral load"
    },
    {
        name: "Genexpert HBV Viral load"
    },
    {
        name: "Drugs Of Abuse Panel-5 Drug Panel"
    },
    {
        name: "HEPATITIS DELTA VIRUS IgG ANTIBODIES"
    },
    {
        name: "Vitamin D2 & D3-25 Hydroxy"
    },
    {
        name: "NMO+MOG Antibodies"
    },
    {
        name: "Saifee Profile 4(Female)"
    },
    {
        name: "HISTOPATHOLOGY VERY LARGE"
    },
    {
        name: "Parechovirus RT-PCR Detection**"
    },
    {
        name: "Parainfluenza -4 Virus RT-PCR Detection**"
    },
    {
        name: "Parainfluenza -3 Virus RT-PCR Detection**"
    },
    {
        name: "Parainfluenza -2 Virus RT-PCR Detection**"
    },
    {
        name: "Influenza C Virus RT-PCR Detection**"
    },
    {
        name: "Influenza B Virus RT-PCR Detection**"
    },
    {
        name: "Influenza A Virus RT-PCR Detection**"
    },
    {
        name: "Coronavirus HKU1 RT-PCR Detection**"
    },
    {
        name: "Coronavirus NL63 RT-PCR Detection**"
    },
    {
        name: "Coronavirus 229E RT-PCR Detection**"
    },
    {
        name: "Genexpert Mtb/Rif Resistance Ultra - Extra Pulmonary"
    },
    {
        name: "Genexpert Mtb/Rif Resistance Ultra - Pulmonary"
    },
    {
        name: "Airlines Package 2"
    },
    {
        name: "Airlines Package "
    },
    {
        name: "Allergy panel Nonvegetarian"
    },
    {
        name: "Xpert Carba-R Assay (Carbapenem Resistance)"
    },
    {
        name: "Clostridium Detection by Real Time PCR (Genexpert Assay)"
    },
    {
        name: "GeneXpert FII/FV"
    },
    {
        name: "GeneXpert HCV Viral Load"
    },
    {
        name: "GeneXpert HIV Viral Load"
    },
    {
        name: "Alcohol Impact Check Up"
    },
    {
        name: "EDT - Blood Profile 18"
    },
    {
        name: "Respiratory Panel for Immunosuppressed Individuals"
    },
    {
        name: "COMPREHENSIVE RESPIRATORY PANEL"
    },
    {
        name: "Hospital Acquired CNS Infection"
    },
    {
        name: "ECNS Core Panel"
    },
    {
        name: "Septiscreen Core Panel"
    },
    {
        name: "Senior Citizen Package Basic"
    },
    {
        name: "Smokers Screening package"
    },
    {
        name: "Arthritis Panel Basic"
    },
    {
        name: "Cardiac Risk Profile - 1"
    },
    {
        name: "Healthy Heart Package"
    },
    {
        name: "Manipal Cigna - Set 14"
    },
    {
        name: "Corporate Package 1"
    },
    {
        name: "INTERLEUKIN-6"
    },
    {
        name: "Taenia solium PCR Detection"
    },
    {
        name: "Chromosome Analysis, Karyotyping & FISH- 5 PROBES (13,18,21,X,Y) - CGC033"
    },
    {
        name: "Pneumonia(Atypical) panel-IgM"
    },
    {
        name: "INFLUENZA B IGM"
    },
    {
        name: "INFLUENZA B IGG"
    },
    {
        name: "INFLUENZA A IGG"
    },
    {
        name: "FG - 15"
    },
    {
        name: "FG - Category C"
    },
    {
        name: "AUTOIMMUNUE PANEL (NMDA & VGKC)- CSF"
    },
    {
        name: "FG - Category D"
    },
    {
        name: "STD Panel Premium"
    },
    {
        name: "HCV Quantitative by eCLIA"
    },
    {
        name: "Max Life - Category 4A"
    },
    {
        name: "Cigna - Set 3"
    },
    {
        name: "Osteomyelitis Panel + Antibiotic Resistance Molecular Detection"
    },
    {
        name: "Infective Endocarditis Panel + Antibiotic Resistance Molecular Detection"
    },
    {
        name: "Fever of Unknown Origine Panel + Antibiotic Resistance Molecular Detection"
    },
    {
        name: "Extended Comprehensive CNS Panel + Antibiotic Resistance Molecular Detection"
    },
    {
        name: "Community Acquired Pneumonia Panel + Antibiotic Resistance Molecular Detection - "
    },
    {
        name: "Macrolide Resistance by PCR"
    },
    {
        name: "Liquid based cervico vaginal cytology"
    },
    {
        name: "Conventional cervico-vaginal cytology"
    },
    {
        name: "Y Chromosome Microdeletions"
    },
    {
        name: "Histopathology (high complexity)"
    },
    {
        name: "Histopathology (medium complexity)"
    },
    {
        name: "Histopathology (small )"
    },
    {
        name: "Maternal Screen (Quadruple Marker Test)2nd Trimester"
    },
    {
        name: "AFB Susceptibility-1st Line [SIRE]"
    },
    {
        name: "AFB Susceptibility- 10 Drugs Panel (1st & 2nd Line, Capreomycin)"
    },
    {
        name: "Max Life - Category 5A"
    },
    {
        name: "Hepatitis Marker Profile, Acute"
    },
    {
        name: "Tru Vital Mini Plus"
    },
    {
        name: "FEVER PACKAGE SCREEN 1"
    },
    {
        name: "FEVER PACKAGE ADVANCED"
    },
    {
        name: "Metro Plus 1"
    },
    {
        name: "TAIG - Package 16"
    },
    {
        name: "Yes4me Screening Package "
    },
    {
        name: "BALIC - B1 Package"
    },
    {
        name: "BALIC - A1 Package"
    },
    {
        name: "Max Life - Category 4B"
    },
    {
        name: "Saifee profile 4(Male)"
    },
    {
        name: "Saifee profile 3"
    },
    {
        name: "Saifee profile 2"
    },
    {
        name: "Saifee profile 1"
    },
    {
        name: "FG - Category B"
    },
    {
        name: "BP14"
    },
    {
        name: "Hereditary pancreatitis gene panel"
    },
    {
        name: "RS | Set 2 with ECG"
    },
    {
        name: "Basic Health Package - 1MG"
    },
    {
        name: "Good Health Package with Vitamins/ Summer Health Check - 1MG"
    },
    {
        name: "Good Health Package - 1MG"
    },
    {
        name: "MBHI LEVEL-2 NEW"
    },
    {
        name: "TAIG - Package 12"
    },
    {
        name: "Aharayogam package 2"
    },
    {
        name: "Aharyogyam Package1"
    },
    {
        name: "CD4/CD8 COUNT"
    },
    {
        name: "HLA B27 by Flow Cytometry- Blood"
    },
    {
        name: "Leukemia customised panel by flowcytometry (Any 6 Markers)"
    },
    {
        name: "Leukemia customised panel by flowcytometry (Any 5 Markers)C"
    },
    {
        name: "Wilson disease (ATP7B) gene analysis"
    },
    {
        name: "Tru Wellness Profile(Women) - 2"
    },
    {
        name: "Tru Wellness Profile(Men) - 2"
    },
    {
        name: "CEL/HES (Chronic eosinophilic leukemia/hyper eosinophilic syndrome) prognostic gene panel (PDGFRA and Kit mutation)"
    },
    {
        name: "Basic Health Check Package"
    },
    {
        name: "Leukemia MRD for T ALL"
    },
    {
        name: "Leukemia MRD for B ALL"
    },
    {
        name: "Acute lymphoblastic leukemia Panel"
    },
    {
        name: "Flow Panel CLPD"
    },
    {
        name: "ANDROGEN PROFILE"
    },
    {
        name: "Chlamydia trachomatis PCR Detection+Neisseria gonorrhoea PCR Detection+Culture & Sensitivity - Aerobic Bacteria, Urine**"
    },
    {
        name: "Chlamydia trachomatis PCR Detection + Neisseria gonorrhoea PCR Detection"
    },
    {
        name: "DNA PCR for GC"
    },
    {
        name: "BASIC HEALTH SCREENING - BH130MG"
    },
    {
        name: "Pituitary hormones Profile"
    },
    {
        name: "CD64"
    },
    {
        name: "TAIG - Package 8"
    },
    {
        name: " Paul Bunnel Test"
    },
    {
        name: "Allergy Eczema Maxi Panel"
    },
    {
        name: "Nephrotic syndrome gene panel"
    },
    {
        name: "RS | Set 1 with FBS"
    },
    {
        name: "Cardiac Enzyme Panel"
    },
    {
        name: "Bacterial identification by PCR-sequencing"
    },
    {
        name: "Entamoeba Histolytica antigen(stool)"
    },
    {
        name: "AHC9 - AHC9009"
    },
    {
        name: "AHC8 - AHC8008"
    },
    {
        name: "AHC7 - AHC7007"
    },
    {
        name: "AHC5 - AHC5005"
    },
    {
        name: "AHC3 - AHC3003"
    },
    {
        name: "AHC2 - AHC2002"
    },
    {
        name: "AHC1 - AHC1001"
    },
    {
        name: "MAX BUPA HBV4 PACKAGE - HBV4003"
    },
    {
        name: "MAX BUPA HBV4 PACKAGE - HBV4002"
    },
    {
        name: "MAX BUPA HBV4 PACKAGE - HBV4001"
    },
    {
        name: "Medamrit Package - MED001"
    },
    {
        name: "NTM speciation by PCR-Sequencing"
    },
    {
        name: "Hemolytic Uremic Syndrome (CFH, CFHR1 & CFHR3)"
    },
    {
        name: "Skin Slit smear For Lepra Bacilli "
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Vaginal Sample (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Urethral Discharge (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Tissue/Biopsy (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Throat Swab (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Stool (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Sputum (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Semen (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Respiratory Samples (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Pus (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Perianal Swab (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Body Fluids (Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Others(Automated)"
    },
    {
        name: "Culture & Sensitivity - Aerobic Bacteria, Urine (Automated)"
    },
    {
        name: "Culture & Sensitivity -  Aerobic Bacteria, Ear Samples(Automated)"
    },
    {
        name: "Pathogen Detection By PCR (Any 10) - MPC085"
    },
    {
        name: "Hemophagocytic Lymphohistiocytosis (HLH) Gene Panel - MPC084"
    },
    {
        name: "Aldosterone/Renin ratio"
    },
    {
        name: "BAGIC PPC | 2000-3 YRS HG"
    },
    {
        name: "Entamoeba Histolytica IgG Antibody"
    },
    {
        name: "Endomysial Antibody IgG"
    },
    {
        name: "Myeloperoxidase(MPO)"
    },
    {
        name: "TAIG - Package 2"
    },
    {
        name: "BUN(SPOT URINE) - BHU027"
    },
    {
        name: "Respiratory Syncytial Virus (A&B) RT-PCR Detection"
    },
    {
        name: "Allergy Drug Ampicillin"
    },
    {
        name: "Allergy Drug Amoxicillin"
    },
    {
        name: "CA 19.9(Fluid)"
    },
    {
        name: "Allergen-Beta - Lactoglobulin- Milk"
    },
    {
        name: "Galactomannan Serum"
    },
    {
        name: "Allergen-Mutton (Ovis Spp.)"
    },
    {
        name: "Allergen-Coffee"
    },
    {
        name: "Hepatitis Delta virus-Total antibody"
    },
    {
        name: "Celiac Disease Profile"
    },
    {
        name: "Bajaj PPMC Package - 32948"
    },
    {
        name: "Coeliac Disease Profile-3"
    },
    {
        name: " INFLUENZA A VIRUS IGM, BHI084"
    },
    {
        name: "Extended Senior Male"
    },
    {
        name: "Female Preventive Package"
    },
    {
        name: "Healthy Eating Package"
    },
    {
        name: "HIV Preventive Package"
    },
    {
        name: "Routine Diabetes Care"
    },
    {
        name: "Kit Check*"
    },
    {
        name: "Stone Analysis Gall bladder calculus "
    },
    {
        name: " Lactose tolerance test "
    },
    {
        name: "Serum Urea / Creatinine "
    },
    {
        name: "Thrombophilia Profile Maxi - BH049P"
    },
    {
        name: "AFB Microscopy - Ziehl-Neelsen Stain, Sputum (3 Samples)<br>3rd Day"
    },
    {
        name: "AFB Microscopy - Ziehl-Neelsen Stain, Sputum (3 Samples)<br>2nd Day"
    },
    {
        name: "AFB Microscopy - Ziehl-Neelsen Stain, Sputum (3 Samples)<br>1st Day"
    },
    {
        name: "PUS Examination"
    },
    {
        name: "  Anti thrombin III Antigen"
    },
    {
        name: "DIABETES PROFILE PREMIUM"
    },
    {
        name: "H2C PROFILE"
    },
    {
        name: "HSV PROFILE"
    },
    {
        name: "LIPID PROFILE COMPLETE"
    },
    {
        name: "CD38 by IHC"
    },
    {
        name: "Tissue biomarker: CD 5 by IHC"
    },
    {
        name: "Tissue biomarker: c-myc by IHC"
    },
    {
        name: "Glycogen Storage Disorder Gene Panel"
    },
    {
        name: "Leptospira Antibody, IgM by ELISA"
    },
    {
        name: "Voltage gated potassium Channel antibodies (VGKC) - CSF"
    },
    {
        name: "MALE HORMONE STUDY"
    },
    {
        name: "Complete  Women's Health Checkup."
    },
    {
        name: "Tissue Biomarker-SOX11 - IHC Analysis"
    },
    {
        name: "Adrenaline(EPINEPHRINE)"
    },
    {
        name: "Post Neuro Surgical/Shunt Meningitis Panel - II (Aster)"
    },
    {
        name: "Post Neuro Surgical/Shunt Meningitis Panel  - I (Aster)"
    },
    {
        name: "Hospital Acquired Pneumonia Panel"
    },
    {
        name: "Pneumonia in Immunosuppressed Patients Panel (Aster)"
    },
    {
        name: "Endocarditis Panel ( Aster)"
    },
    {
        name: "Community Acquired Sepsis Panel ( Aster)"
    },
    {
        name: "Viral Encephalitis Panel (Aster)"
    },
    {
        name: "Community Acquired Pneumonia Panel (Aster)"
    },
    {
        name: "Hospital Acquired Sepsis Panel (Aster) "
    },
    {
        name: "Basic Women's Health Checkup"
    },
    {
        name: "MERCURY (Blood)"
    },
    {
        name: "TMKG8"
    },
    {
        name: "TMKG7"
    },
    {
        name: "TMKG6"
    },
    {
        name: "TMKG5"
    },
    {
        name: "TMKG4"
    },
    {
        name: "TAIG - Package 3"
    },
    {
        name: "TAIG - Package 2"
    },
    {
        name: "TAIG - Package 1"
    },
    {
        name: "Vital Health Checkup"
    },
    {
        name: "DCP- Decarboxy Prothrombin PIVKA II"
    },
    {
        name: "MDR TB Detection_MPM058"
    },
    {
        name: "Malaria Detection By QBC"
    },
    {
        name: "ALUMINIUM, PLASMA"
    },
    {
        name: "Sphingomyelin-IgG (Phospholipid) Serum"
    },
    {
        name: "Allergen-Egg Yolk (Anda)_BHA122"
    },
    {
        name: "Allergen-Egg White (Anda)_BHA121"
    },
    {
        name: "Viral Retinitis Panel "
    },
    {
        name: "Special histochemical stain: Perl's Iron Stain"
    },
    {
        name: "U043 CATECHOLAMINES, RANDOM URINE"
    },
    {
        name: "Hcg, Beta, Free, Quantitative"
    },
    {
        name: "Antithrombin Activity, Functional"
    },
    {
        name: "Paraneoplastic Marker- 12 Antigen"
    },
    {
        name: "Pan-Uveitis Panel"
    },
    {
        name: "Pan Uveitis Customized Panel"
    },
    {
        name: "Osteomyelitis Panel"
    },
    {
        name: "Myocarditis Panel"
    },
    {
        name: "Cotinine, Urine"
    },
    {
        name: "Scl-70 IgG"
    },
    {
        name: "MTB & NTM Real Time PCR"
    },
    {
        name: "UREA Fluid"
    },
    {
        name: "HEALTHY JODI MALE"
    },
    {
        name: "Extended Spectrum Beta Lactamase (ESBL) Resistance by PCR"
    },
    {
        name: "Clinical Exome-26MB (Expedited TAT)_IG123P"
    },
    {
        name: "Home delivery - 100"
    },
    {
        name: "Desmoglein III Antibody"
    },
    {
        name: "Desmoglein I Antibody"
    },
    {
        name: "Syphilis Antibodies (Treponema)"
    },
    {
        name: "SSB-AntibodyLaSerum"
    },
    {
        name: "Sm Antibody, IgG"
    },
    {
        name: "Infective Endocarditis Panel"
    },
    {
        name: "SSA-AntibodyRoSerum"
    },
    {
        name: "Fuch's Panel"
    },
    {
        name: "Vancomycin Resistance by PCR"
    },
    {
        name: "Metabolic Screening-Blood Spot"
    },
    {
        name: "TOXOPLASMA -IgG(CSF)"
    },
    {
        name: "Allergy - Dust Panel"
    },
    {
        name: "NSCLC (Non-small cell lung cancer) Panel -II (PDL1 by IHC; EGFR Mutation Analysis by PCR ; ALK and ROS1 by FISH)"
    },
    {
        name: "Endopthalmitis Panel"
    },
    {
        name: "MRSA Screen by PCR*"
    },
    {
        name: "Prolactin, Active & macroprolactin"
    },
    {
        name: "Neutropenia/Post-Transplant Panel (Any 20 Pathogens)"
    },
    {
        name: "Leucocyte Alkaline Phosphatase (LAP) Score**"
    },
    {
        name: "Neutropenia Panel/Post-Transplant Panel (Any 10 Pathogens)"
    },
    {
        name: "Insulin, Random*"
    },
    {
        name: "Influenza Virus A, B & C RT-PCR"
    },
    {
        name: "Influenza Virus A & B RT-PCR"
    },
    {
        name: "BORRELIA BURGDORFERI IGG"
    },
    {
        name: "CMV DNA Quantitative by Real Time PCR"
    },
    {
        name: "Post-Surgical Endopthalmitis Panel"
    },
    {
        name: "Autoimmune encephalitis panel serum"
    },
    {
        name: "HIV - DUO + PLUS"
    },
    {
        name: "Acid Fast Bacilli Culture: Final Positive Report"
    },
    {
        name: "Acid Fast Bacilli Culture : Final Report"
    },
    {
        name: "Acid Fast Bacilli Culture : Interim Report-II"
    },
    {
        name: "Acid Fast Bacilli Culture : Interim Report-I"
    },
    {
        name: "Acid Fast Bacilli Culture : Smear Report Positive"
    },
    {
        name: "Acid Fast Bacilli Culture : Smear Report"
    },
    {
        name: "Community Acquired Meningo Encephalitis Panel - ( Aster) "
    },
    {
        name: "Advance Health care - BHD171"
    },
    {
        name: "Basic Health Care  - BHD170"
    },
    {
        name: "No Growth - Negative (Aerobic)"
    },
    {
        name: "Advance Care Women- BHD168"
    },
    {
        name: "Home Visit"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain (3rd Day)"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain (2nd Day)"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain (1st Day)"
    },
    {
        name: "Glucose Urine Post"
    },
    {
        name: "Extended Comprehensive CNS Panel (Any 30 Pathogens)"
    },
    {
        name: "Extended Comprehensive CNS Panel (Any 20 Pathogens)"
    },
    {
        name: "Extended Comprehensive CNS Panel (Any 10 Pathogens)"
    },
    {
        name: "SYPHILIS PROFILE"
    },
    {
        name: "Cytomegalovirus, Epstein Barr virus & Adenovirus Detection By PCR"
    },
    {
        name: "Post Transplant Viral Panel**"
    },
    {
        name: "Add on Pathogen "
    },
    {
        name: "Triple negative breast cancer for basal like phenotype by IHC - IHT098"
    },
    {
        name: "File Report"
    },
    {
        name: "Chylomicron Qualitative Urine"
    },
    {
        name: "SMA 12+2"
    },
    {
        name: "Advanced Health Checkup (Aarogyam C)"
    },
    {
        name: "TRU Vital Health Package - Advance"
    },
    {
        name: "TRU Vital Health Package - Basic"
    },
    {
        name: "FT3"
    },
    {
        name: "Fever Profile - Advanced (32)"
    },
    {
        name: "Fever Profile - Basic  (29)"
    },
    {
        name: "Hormonal Package (8)"
    },
    {
        name: "Cancer Marker Tests - Female"
    },
    {
        name: "Smoker's Package (Lung Health Package)"
    },
    {
        name: "Fertility Package for Men"
    },
    {
        name: "Fertility Package for Female Plus"
    },
    {
        name: "Diabetes Profile - Advanced (55)"
    },
    {
        name: "Diabetes Profile - Basic (24)"
    },
    {
        name: "STD DETECTION PACKAGE"
    },
    {
        name: "Gram's Stain Smear Examination, Other"
    },
    {
        name: "OT Microbiological Surveillance Panel - Aerobic & Anaerobic (1 Swab) - FORMAT"
    },
    {
        name: "Blood Culture - Fungal"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Other"
    },
    {
        name: "XDR SCREENING TEST BY LINE PROBE ASSAY [HAIN'S 2ND LINE]"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Sputum"
    },
    {
        name: "Bacteriological Examination Of Air"
    },
    {
        name: "MDR SCREENING TEST BY LINE PROBE ASSAY [HAIN'S 1ST LINE]"
    },
    {
        name: "Stool Examination-Hanging Drop Preparation"
    },
    {
        name: "Gram's Stain Smear Examination, Sputum"
    },
    {
        name: "Sputum Routine Examination"
    },
    {
        name: "Antibiogram Fungal MIC Panel"
    },
    {
        name: "Culture & Identification - Coliforms, Water"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Pus, Abscess & Aspirates"
    },
    {
        name: "Fertility Package for Female"
    },
    {
        name: "Hepatitis B Profile"
    },
    {
        name: "Hepatitis, Viral, Comprehensive Panel"
    },
    {
        name: "Thrombophilia Profile-Mini"
    },
    {
        name: "Androgen Index"
    },
    {
        name: "POLARISING MICROSCOPY FOR CRYSTAL, SYNOVIAL FLUID"
    },
    {
        name: "Basic Coagulation Profile"
    },
    {
        name: "Arthritis Panel Advance"
    },
    {
        name: "Thyro Vita Profile + IPTH"
    },
    {
        name: "Hepatitis Acute Virus Screen"
    },
    {
        name: "Toxoplasma Avidity IgG"
    },
    {
        name: "Senior Citizen Health Package For Men"
    },
    {
        name: "Diabetes Profile Mini"
    },
    {
        name: "Catecholamines-24Hrs Urine**"
    },
    {
        name: "Anemia Profile 3 (Anemia Typing)"
    },
    {
        name: "Testosterone Profile  - Extended"
    },
    {
        name: "Breast Cancer Predictive Marker Panel"
    },
    {
        name: "Andrology Panel"
    },
    {
        name: "BOH Profile Maxi"
    },
    {
        name: "Basic Health Check Up"
    },
    {
        name: "H3 VIRAL MARKER PROFILE"
    },
    {
        name: "CHLAMYDIA PROFILE"
    },
    {
        name: "Bacterial - Fungal CNS Panel & Antibiotic Resistance- Molecular Detection"
    },
    {
        name: "PUO PROFILE-Pyrexia of unknown origin"
    },
    {
        name: "STD PANEL SPECIAL"
    },
    {
        name: "Comprehensive CNS Panel & Antibiotic Resistance- Molecular Detection"
    },
    {
        name: "Fever Profile- PCR"
    },
    {
        name: "Septicemia Panel (Molecular & Microbiology)"
    },
    {
        name: "Chromosome Analysis of Chorionic Villus Biopsy by Karyotyping & FISH (13,18,21,X,Y)"
    },
    {
        name: "Complete Care - Basic"
    },
    {
        name: "Complete Care - Total"
    },
    {
        name: "Cancer Screening Male"
    },
    {
        name: "IGenetic 360 Extended"
    },
    {
        name: "Septicemia Panel & Antibiotic Resistance- Molecular Detection"
    },
    {
        name: "Pre Nuptial Package"
    },
    {
        name: "One Plus One Health Check"
    },
    {
        name: "Septiscreen & Antibiotic Resistance-Molecular Detection"
    },
    {
        name: "Thyro Vita Profile"
    },
    {
        name: "Senior Citizen Health Package For Women"
    },
    {
        name: "HCP Plus"
    },
    {
        name: "ELECTROLYTES-24Hrs Urine"
    },
    {
        name: "iGenetic HealthGuard"
    },
    {
        name: "Kidney Function Test 1-Maxi (KFT)"
    },
    {
        name: "WOMEN’ Joy Lab Package (<40Years of age)"
    },
    {
        name: "Triple H (Elisa)"
    },
    {
        name: "Thrombotic Profile"
    },
    {
        name: "Basic Lab Package"
    },
    {
        name: "Cancer Screening Female"
    },
    {
        name: "STD Panel Basic"
    },
    {
        name: "Advanced Health Check up"
    },
    {
        name: "Deal Of the Year Lab package"
    },
    {
        name: "PCOD Profile Test Website"
    },
    {
        name: "APLA SYNDROME PROFILE"
    },
    {
        name: "PCOS Profile"
    },
    {
        name: "Portea Preventive Health Check"
    },
    {
        name: "SWASHTYAM HEART PLUS"
    },
    {
        name: "Chromosome Analysis of Fetal Cord Blood by Karyotyping & FISH (13,18,21,X,Y)"
    },
    {
        name: "Chromosome Analysis of Amniotic Fluid by Karyotyping & FISH (13,18,21,X,Y)"
    },
    {
        name: "Cardiac Risk Profile - 2"
    },
    {
        name: "iGenetic 360 Health Extended Website"
    },
    {
        name: "iGenetic health Plus"
    },
    {
        name: "Annual Health Check - Platinum - BH227MG"
    },
    {
        name: "Pre Operative Panel"
    },
    {
        name: "PCOD Profile"
    },
    {
        name: "Diabetes Package (includes 30 Tests)"
    },
    {
        name: "Healthy Women Package"
    },
    {
        name: "Fluid Examination-Routine, CSF /Nasal Fluid"
    },
    {
        name: "Septicemia Panel"
    },
    {
        name: "Neutropenia/Post Transplant Panel & Antibiotic Resistance Panel"
    },
    {
        name: "Immunofixation Quantitative Electrophoresis-Urine**"
    },
    {
        name: "Annual Health Check - Gold - BH126MG"
    },
    {
        name: "Dhantrayodashi Gold Package - BH123MG"
    },
    {
        name: "Annual Health Check - Silver - BH125MG"
    },
    {
        name: "Fever Profile 2"
    },
    {
        name: "Bone Profile Package - BH124MG"
    },
    {
        name: "Complete Care Advance"
    },
    {
        name: "iGenetic Health Plus Website"
    },
    {
        name: "ROUTINE BODY CHECK UP"
    },
    {
        name: "iGenetic Health CheckUp"
    },
    {
        name: "CORPORATE PACKAGE"
    },
    {
        name: "IGENETIC BASIC BODY PROFILE"
    },
    {
        name: "Lipid and Diabetes Monitoring - BH119MG"
    },
    {
        name: "Fever Package - BH122MG"
    },
    {
        name: "HHH Package"
    },
    {
        name: "Vitamin Plus"
    },
    {
        name: "Tru Wellness Profile(Women) - 1"
    },
    {
        name: "Personalised Full Body Checkup (includes 108 tests)"
    },
    {
        name: "Vital Organs and Diabetes Monitor - BH121MG"
    },
    {
        name: "Kidney Function Monitor - BH120MG"
    },
    {
        name: "Diabetes Panel, Basic - BH113MG"
    },
    {
        name: "Kidney and Diabetes Monitoring - BH118MG"
    },
    {
        name: "Diabetes Package Advanced"
    },
    {
        name: "Thalassemia Profile"
    },
    {
        name: "COMPLETE BODY CHECK - UP -2"
    },
    {
        name: "Anemia Profile - Maxi"
    },
    {
        name: "iGenetic Profile Package"
    },
    {
        name: "DIABETES CARE**"
    },
    {
        name: "Women Wellness Package (includes 31 Tests) - BH108MG"
    },
    {
        name: "Healthy Bones Package (includes 5 Tests) - BH107MG"
    },
    {
        name: "Complete Blood Count With MP"
    },
    {
        name: "COMPREHENSIVE PACKAGE"
    },
    {
        name: "ADD ON PACKAGE VIT B 12 & D 3**"
    },
    {
        name: "Anemia Profile 2( Dimorphic Anemia)"
    },
    {
        name: "ADVANCE BODY CHECK UP"
    },
    {
        name: "Coagulation Screen"
    },
    {
        name: "Diabetes Profile 2"
    },
    {
        name: "COMPLETE BODY CHECK - UP -1(Male)"
    },
    {
        name: "COMPLETE BODY CHECK - UP -1(Female)"
    },
    {
        name: "Pre Wedding Package (includes 44 Tests) - BH105MG"
    },
    {
        name: "Master Health CheckUp (includes 60 Tests) - BH116MG"
    },
    {
        name: "Coagulation Profile 1"
    },
    {
        name: "Obesity Panel I"
    },
    {
        name: "Igenetic 360 Health Check Up Plus"
    },
    {
        name: "HIV Early Detection Premium"
    },
    {
        name: "Igenetic 360 Health Check Up"
    },
    {
        name: "Deal Of The year Max"
    },
    {
        name: "Antenatal I"
    },
    {
        name: "Diabetic Screen"
    },
    {
        name: "Anemia Profile 1(Hemolytic Anemia)"
    },
    {
        name: "Anemia Profile Basic ( Iron Deficiency Anemia )"
    },
    {
        name: "Amenorrhoea Profile"
    },
    {
        name: "Arthritis Panel 1"
    },
    {
        name: "Dengue & Chikungunya (RT- PCR) Combo"
    },
    {
        name: "Fertility Panel-Male"
    },
    {
        name: "T3, Total"
    },
    {
        name: "Helicobacter Pylori IgM"
    },
    {
        name: "Special Histochemical Stain: Congo Red Stain"
    },
    {
        name: "Glucose, 2 Hours Post Glucose"
    },
    {
        name: "Adrenaline (Epinephrine],Urine24hrs"
    },
    {
        name: "Rhinitis asthama screening panel**"
    },
    {
        name: "Flow Cytometry, CD 38 (Plasma Cell/Prognostic CLL Marker)"
    },
    {
        name: "FSH LH PRL"
    },
    {
        name: "Anti HEV-IgG"
    },
    {
        name: "BORRELIA BURGDORFERI IGM."
    },
    {
        name: "Chlamydia Trachomatis-IgA"
    },
    {
        name: "Pathogenic Fungi Detect Panel - I"
    },
    {
        name: "ADA  Serum"
    },
    {
        name: "Dengue IGG & IGM (rapid card)"
    },
    {
        name: "G-6-PD, Newborn Screen"
    },
    {
        name: "5 HIAA (HYDROXY INDOLE ACETIC ACID), 24HRS URINE"
    },
    {
        name: "Nitroprusside Test, Urine"
    },
    {
        name: "D3-Hydroxybutyrate (Ketone Body)"
    },
    {
        name: "TORCH 5-IgG**"
    },
    {
        name: "Glucose Tolerance Test (GTT), Extended, 5 Hours"
    },
    {
        name: "ANA Profile"
    },
    {
        name: "Culture & Sensitivity - OT Settle Plate "
    },
    {
        name: "GFR with creatinine"
    },
    {
        name: "NMDA RECEPTOR ANTIBODY  - CSF"
    },
    {
        name: "Neisseria meningitidis PCR Detection"
    },
    {
        name: "Extended Comprehensive CNS Panel"
    },
    {
        name: "Beta-2-Microglobulin, Urine"
    },
    {
        name: "Manganese, Random Urine"
    },
    {
        name: "Cancer Hotspot Panel"
    },
    {
        name: "Ganglioside Gd1a Antibody-IgM"
    },
    {
        name: "C-Reactive Protein; CRP (Quantitative)"
    },
    {
        name: "Cytomegalovirus (CMV) Antibody IgG"
    },
    {
        name: "Kidney Function Test 2-Mini (KFT)"
    },
    {
        name: "Maternal Serum Screen 2 / Dual Marker"
    },
    {
        name: "Haemophilus influenzae-B PCR Detection"
    },
    {
        name: "Fungal Stain*"
    },
    {
        name: "Cotinine, Serum"
    },
    {
        name: "Flow Cytometry, CD 5 ( T Cell / B Cell Subset)"
    },
    {
        name: "Beta 2 Glycoprotein 1-IgA**"
    },
    {
        name: "HCG, Beta, Total, Quantitative, 24 Hour Urine"
    },
    {
        name: "T3, Free T3 (FT3)"
    },
    {
        name: "Electrolytes, 24 Hour UrineSodiumPotassiumChloride"
    },
    {
        name: "Dengue NS1 Antigen(Rapid Screening)"
    },
    {
        name: "Nor-Adrenaline (Nor-Epinephrine)-UR"
    },
    {
        name: "Mismatch Repair Protein Expression by IHC (MLH1, MSH2, MSH6 and PMS2)"
    },
    {
        name: "TSC1 & TSC2 gene analysis"
    },
    {
        name: "Leukemia-Acute panel by flowcytometry"
    },
    {
        name: "Insulin, PP"
    },
    {
        name: "Intrinsic factor"
    },
    {
        name: "Flow Cytometry, CD 34 (Precursor Cell Marker)"
    },
    {
        name: "Flow Cytometry, CD 41/61 (Megakaryocytic Cell Marker)"
    },
    {
        name: "Kappa And Lambda-Freelite, Urine Spot"
    },
    {
        name: "Free T4 (FT4) "
    },
    {
        name: "Beta 2 Glycoprotein IgG"
    },
    {
        name: "Insulin, Fasting (F) & Post Prandial (PP) Panel"
    },
    {
        name: "AML Panel (AML-ETO, PML-RARA, CBFB-MYH11)"
    },
    {
        name: "Amoebic Serology IgG, Serum"
    },
    {
        name: "Anti Soluble Liver Antigen"
    },
    {
        name: "Nitrosonaphthol, Urine"
    },
    {
        name: "Glucose Tolerance Test (GTT), 2.5 Hours"
    },
    {
        name: "Manganese, 24-Hour Urine"
    },
    {
        name: "Beta 2 Transferrin"
    },
    {
        name: "Neisseria gonorrhoeae PCR Detection"
    },
    {
        name: "Flow Cytometry, CD 4 (Helper T Cell Marker)"
    },
    {
        name: "D - Dimer, Quantitative"
    },
    {
        name: "HAV- IgG Ab to Hepatitis A Virus"
    },
    {
        name: "Ferric Chloride Test for Phenylketonuria"
    },
    {
        name: "Natural Killer cells (NKC)"
    },
    {
        name: "Mantle Cell Lymphoma sample for IHC_IHM002"
    },
    {
        name: "Fungal Stain, Hair*"
    },
    {
        name: "AML Characterisation Panel(ETO/AML1, PML/RARA, CBFB AND MLL) by FISH"
    },
    {
        name: "Flow Cytometry, CD 33 ( Myeloid Cell Marker)"
    },
    {
        name: "Nipah Virus RT- PCR Detection"
    },
    {
        name: "FSH & LH"
    },
    {
        name: "Aspergillus Antibody IgG"
    },
    {
        name: "Protein S, Antigen Free"
    },
    {
        name: "Haptoglobin"
    },
    {
        name: "Antithyroglobulin Antibody; Anti TG"
    },
    {
        name: "Beta 2 Glycoprotein IgM"
    },
    {
        name: "Manganese, Blood"
    },
    {
        name: "Ganglioside Gd1a Antibody-IgG"
    },
    {
        name: "Influenza Panel by RT-PCR with H1N1 & H3N2"
    },
    {
        name: "Ganglioside GQ1b Antibody-IgG"
    },
    {
        name: "Flow Cytometry, CD 7 (Pan T Cell/Early Myeloid Cell Marker)"
    },
    {
        name: "Clostridium Difficile Gdh"
    },
    {
        name: "Haemophilus species PCR Detection"
    },
    {
        name: "Fungal Stain, Nail*"
    },
    {
        name: "Special Histochemical Stain: Grocott Methenamine Silver stain"
    },
    {
        name: "Germ Cell Tumour Panel"
    },
    {
        name: "Fungal Stain, Eye Sample*"
    },
    {
        name: "Sodium, Serum"
    },
    {
        name: "C. Difficile Toxin A & B, Stool*"
    },
    {
        name: "Clonazepam"
    },
    {
        name: "Sodium, Random Urine"
    },
    {
        name: "Hepatitis C Virus (HCV) Rapid Screening Test"
    },
    {
        name: "Voltage gated potassium Channel antibodies (VGKC) - Serum"
    },
    {
        name: "Tissue Biomarker - Synaptophysin"
    },
    {
        name: "Erythropoietin; EPO"
    },
    {
        name: "Factor XIII ( Clot Solubility),Functional, Qualitative"
    },
    {
        name: "ALLERGEN, INDIVIDUAL-FOOD - EGG YOLK"
    },
    {
        name: "Progressive myoclonic epilepsy gene panel"
    },
    {
        name: "Fungal Stain, Ear Sample*"
    },
    {
        name: "CYP2C19 Genotyping (Clopidogren/ clopidogrel)"
    },
    {
        name: "Chlamydia Trachomatis IgM"
    },
    {
        name: "Allergen-Gluten"
    },
    {
        name: "Myelin Basic Protein"
    },
    {
        name: "Tissue Biomarker - PgR"
    },
    {
        name: "Tissue Biomarker - S100"
    },
    {
        name: "Cytomegalovirus (CMV) Antibody IgM"
    },
    {
        name: "Gastrointestinal Stromal Tumor Secondary Panel (CD117, DOG-1, CD34, S100, Desmin, Keratin, Desmin  )"
    },
    {
        name: "Organic Acid Qualitative Urine Spot"
    },
    {
        name: "Dengue Virus RT-PCR Detection"
    },
    {
        name: "Protein Electrophoresis,Serum"
    },
    {
        name: "Acinetobacter species PCR Detection"
    },
    {
        name: "Osmolality, Serum"
    },
    {
        name: "Citrate, Urine 24 Hr. Enzymatic"
    },
    {
        name: "Cardiolipin Antibodies PanelIgGIgAIgM"
    },
    {
        name: "Familial female mental retardation/epilepsy gene panel"
    },
    {
        name: "Opiates Screen, Random Urine"
    },
    {
        name: "FSH, LH Testosterone"
    },
    {
        name: "Myeloproliferative neoplasms (MPN) Panel"
    },
    {
        name: "Tissue Biomarker - Smooth Muscle Actin"
    },
    {
        name: "Hepatitis A Antibody (Anti-HAV) IgM"
    },
    {
        name: "Glucose, Post Prandial (PP), 2 hours"
    },
    {
        name: "Myeloma Panel By Flowcytometry"
    },
    {
        name: "Myelin Associated Glycoprotein-Sulfated Glucuronic Paragloboside Autoantibody**"
    },
    {
        name: "Progesterone, Serum"
    },
    {
        name: "Klebsiella pneumoniae PCR Detection"
    },
    {
        name: "Gliadin Antibody IgA"
    },
    {
        name: "Tissue Biomarker - PSA"
    },
    {
        name: "Hepatitis E Antibody (Anti-HEV) IgM"
    },
    {
        name: "Allergen - Animal Pigeon Feathers & droppings"
    },
    {
        name: "Vitamin B2; Riboflavin"
    },
    {
        name: "Protein Creatinine Ratio, Urine"
    },
    {
        name: "Giardia Antigen Detection"
    },
    {
        name: "Hemosiderin, Urine"
    },
    {
        name: "Hepatitis A Antibody (Anti-HAV) IgG"
    },
    {
        name: "Apolipoproteins A1"
    },
    {
        name: "Cyfra 21-1; Lung Cancer Marker"
    },
    {
        name: "C-Peptide, Serum"
    },
    {
        name: "Flow Cytometry, CD 45/ LCA (Leukocyte Common Antigen )"
    },
    {
        name: "Antibiotic Resistance Molecular Detection Full Panel"
    },
    {
        name: "Galactose -1-Phosphate, Quantitative, Blood"
    },
    {
        name: "Arsenic, 24-Hour Urine"
    },
    {
        name: "CPK; Creatine Kinase"
    },
    {
        name: "AFB Susceptibility-PAS, Pure Culture"
    },
    {
        name: "Estriol (UE3), Un-conjugated"
    },
    {
        name: "Anti HEV-IgM"
    },
    {
        name: "Chlamydia pneumoniae PCR Detection"
    },
    {
        name: "AFB Susceptibility-Pyrazinamide, Pure Culture"
    },
    {
        name: "AFB Susceptibility-1st Line [SIRE]"
    },
    {
        name: "Spinal Muscular Atrophy Gene panel"
    },
    {
        name: "Echinococcus Detection For Scolices In Hydatid Cyst Fluid"
    },
    {
        name: "HE4 with ROMA index"
    },
    {
        name: "Glucose, Fluid"
    },
    {
        name: "Flow Cytometry, CD 8 (Suppressor T Cell Marker)"
    },
    {
        name: "Flow Cytometry, CD 79A (B Lymphoid Cell Marker)"
    },
    {
        name: "Smear For Bacterial Vaginosis"
    },
    {
        name: "Vitamin B6; Pyridoxine"
    },
    {
        name: "Mycoplasma pneumoniae PCR Detection"
    },
    {
        name: "Leukodystrophy gene panel"
    },
    {
        name: "Anti Cyclic Citrullinated Peptide; Anti CCP"
    },
    {
        name: "Anti thrombin III activity"
    },
    {
        name: "AFB Susceptibility-Streptomycin, Pure Culture"
    },
    {
        name: "AFB Susceptibility-2nd Line (KEPO - 4 Drugs)"
    },
    {
        name: "Salmonella species PCR Detection"
    },
    {
        name: "Chromium, Random Urine"
    },
    {
        name: "4H syndrome gene panel"
    },
    {
        name: "Clinical Exome-26MB"
    },
    {
        name: "Smear For Nocardia, Other"
    },
    {
        name: "CA 15.3; Breast Cancer Marker"
    },
    {
        name: "Beta Thalassemia Mutation Testing"
    },
    {
        name: "Antistreptolysin - O (ASO) Titre "
    },
    {
        name: "IGF BP3, Serum"
    },
    {
        name: "Arsenic, Blood"
    },
    {
        name: "AFB Susceptibility-Additional Drugs (MACC - 4 Drugs)"
    },
    {
        name: "CMV  PCR Detection - Cytomegalovirus "
    },
    {
        name: "Chlamydia Pneumoniae IgM"
    },
    {
        name: "Vitamin B12; Cyanocobalamin"
    },
    {
        name: "GAD 65 Antibody**"
    },
    {
        name: "BRCA-1/BRCA-2 Mutation Analysis by NGS"
    },
    {
        name: "Estradiol (E2)"
    },
    {
        name: "Cyclosporine Measured As The Metabolite Cyclosporin A."
    },
    {
        name: "Antisperm Antibody"
    },
    {
        name: "Acinetobacter baumanii PCR Detection"
    },
    {
        name: "Vitamin D Total-25 Hydroxy (VIT D PLUS+)"
    },
    {
        name: "Opportunistic Infectious Panel"
    },
    {
        name: "Tissue Biomarker - CA19-9"
    },
    {
        name: "Tissue Biomarker - EGFR"
    },
    {
        name: "Gliadin Antibody IgG"
    },
    {
        name: "Carcinoma IHC Panel III - Pan cytokeratin (AE1/AE3) + CK 7 + p40 + TTF-1 + NAPSIN-A"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Urine"
    },
    {
        name: "Clot Retraction Test"
    },
    {
        name: "Vitamin C; Ascorbic Acid"
    },
    {
        name: "Kidney stone analysis"
    },
    {
        name: "Cytomegalovirus (CMV), Avidity IgG"
    },
    {
        name: "HSV 2 Antibody Panel, IgG & IgM"
    },
    {
        name: "Beta 2 Microglobulin"
    },
    {
        name: "Krabbe disease (GALC) gene analysis"
    },
    {
        name: "Salmonella typhi PCR Detection"
    },
    {
        name: "ANTI BASAL GANGLION ANTIBODY, SERUM"
    },
    {
        name: "Tissue Biomarker - CD117"
    },
    {
        name: "AFB Susceptibility-Rifampicin, Pure Culture"
    },
    {
        name: "Tissue Biomarker - Desmin"
    },
    {
        name: "Mycoplasma Pneumoniae IgG"
    },
    {
        name: "IgG4 Sub Class"
    },
    {
        name: "Von Willebrand Factor Antigen; VWF AG"
    },
    {
        name: "IGF-1 (Somatomedin C)"
    },
    {
        name: "Hepatitis E Antibody (Anti-HEV) IgG"
    },
    {
        name: "Hepatitis Delta Antibody (Anti-HDV) IgM"
    },
    {
        name: "ESR; Erythrocyte Sedimentation Rate ."
    },
    {
        name: "Hypomyelination syndrome gene panel"
    },
    {
        name: "Clostridium difficile PCR Detection"
    },
    {
        name: "Beta-2 Glycoprotein IgA"
    },
    {
        name: "Chromium, 24-Hour Urine"
    },
    {
        name: "PROTEIN ELECTROPHORESIS WITH REFLEX IF"
    },
    {
        name: "Echinococcus Detection, Hydatid Cyst"
    },
    {
        name: "CSF - Measles (Rubeola) -IgG antibodies, by EIA"
    },
    {
        name: "Echinococcus (Hydatid Cyst) IgG"
    },
    {
        name: "Cyclospora Detection, Stool"
    },
    {
        name: "Chromogranin A"
    },
    {
        name: "Hepatitis C Virus (HCV) RT PCR Detection"
    },
    {
        name: "New Born Screening Metabolism Urine"
    },
    {
        name: "BRAF V600E gene mutation"
    },
    {
        name: "Protein Electrophoresis, 24-Hour Urine Does Not Include IFE"
    },
    {
        name: "Chromium, Blood"
    },
    {
        name: "Echovirus Antibody IgG"
    },
    {
        name: "Immature platelet fraction"
    },
    {
        name: "Allergen-Cow Milk (Doodh)"
    },
    {
        name: "Escherichia coli PCR Detection"
    },
    {
        name: "Allergen - Vegetarians Panel"
    },
    {
        name: "Cryptosporidium Detection, Stool"
    },
    {
        name: "FSH & LH HCG"
    },
    {
        name: "CA 125*"
    },
    {
        name: "Citrobacter freundii PCR Detection"
    },
    {
        name: "Copper, Serum"
    },
    {
        name: "GGTP; GGT; GAMMA GT; Gamma Glutamyl Transpeptidase "
    },
    {
        name: "HE4 (Human Epididymis Protein 4)"
    },
    {
        name: "Glomerular Basement Membrane (GBM) Antibody"
    },
    {
        name: "Tissue Biomarker - Calretinin"
    },
    {
        name: "Apolipoproteins B"
    },
    {
        name: "Chlamydia Pneumoniae IgA"
    },
    {
        name: "NRAS Mutation Analysis"
    },
    {
        name: "Tissue Biomarker -CD31"
    },
    {
        name: "Mycoplasma Pneumoniae IgM"
    },
    {
        name: "ALLERGEN, INDIVIDUAL-FOOD - EGG WHITE"
    },
    {
        name: "Rubella (German Measles) Avidity-IgG"
    },
    {
        name: "Cryptococcus neoformans PCR Detection"
    },
    {
        name: "Chloride, 24-Hour Urine"
    },
    {
        name: "ACTH"
    },
    {
        name: "Melanoma Markers"
    },
    {
        name: "Osmolal GAP, Serum"
    },
    {
        name: "Familial Adenomatous Polyposis (APC) Mutation Analysis"
    },
    {
        name: "Arthrogryposis & congenital myasthenic syndrome gene panel"
    },
    {
        name: "c-KIT mutation"
    },
    {
        name: "Chlamydia Pneumoniae IgG"
    },
    {
        name: "Tissue Biomarker Caldesmon - IHC Analysis"
    },
    {
        name: "Arsenic, Random Urine"
    },
    {
        name: "C4 Complement"
    },
    {
        name: "C3 Complement"
    },
    {
        name: "Absolute Eosinophil Count (AEC) (EDTA whole blood)"
    },
    {
        name: "Allergy screening-Tryptase"
    },
    {
        name: "Tissue Biomarker Cathepsin - IHC Analysis"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Sputum"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Tissue/Biopsy"
    },
    {
        name: "CA 19.9 Pancreatic Cancer Marker"
    },
    {
        name: "Kappa And Lambda-Freelite Serum"
    },
    {
        name: "Gliadin Antibodies Panel IgA & IgG"
    },
    {
        name: "24 Hr. Urine Albumin/Creatinine Ratio"
    },
    {
        name: "Tissue Biomarker - E Cadherin"
    },
    {
        name: "ACTIVE VITAMIN B12"
    },
    {
        name: "24 Hrs. Urine Protein Creatinine Ratio"
    },
    {
        name: "AFB Susceptibility for Mott-Rapid Grower "
    },
    {
        name: "Smear For MP"
    },
    {
        name: "AFB Susceptibility-Amikacin, Pure Culture"
    },
    {
        name: "LEVETIRACETAM,SERUM"
    },
    {
        name: "Tissue Biomarker - EMA"
    },
    {
        name: "Anti DNASE B; Antideoxyribonuclease B"
    },
    {
        name: "WAGR syndrome (PAX6) deletion/duplication analysis"
    },
    {
        name: "Mumps Virus Antibodies Panel, IgG & IgM"
    },
    {
        name: "Water Analysis Chemical"
    },
    {
        name: "Acid Phosphatase, Prostatic Includes Total Acid Phosphatase"
    },
    {
        name: "Leptospira Antibodies Panel, IgG & IgM"
    },
    {
        name: "Tissue Biomarker - DOG-1"
    },
    {
        name: "Rubella virus RT-PCR Detection"
    },
    {
        name: "Smear For Gonorrhea"
    },
    {
        name: "Familial hemiplegic migraine gene panel"
    },
    {
        name: "CPK Isoenzyme Electrophoresis; Includes Total CPK."
    },
    {
        name: "Copper, Random Urine, Atomic Absorption"
    },
    {
        name: "Tissue Biomarker - CD10"
    },
    {
        name: "Serum Iron"
    },
    {
        name: "Allergen - Dermatophagoides Farinae (House Dust Mite)"
    },
    {
        name: "Krabbe disease (GALC) deletion/duplication analysis"
    },
    {
        name: "Adenocarcinoma Versus Mesothelioma Panel"
    },
    {
        name: "Tissue Biomarker - Calponin"
    },
    {
        name: "Serratia species PCR Detection"
    },
    {
        name: "Serotonin ( 5-HT), Serum"
    },
    {
        name: "Sex Hormone Binding Globulin; SHBG"
    },
    {
        name: "Alkaline Phosphatase, Bone-Specific; BSAP"
    },
    {
        name: "Aspergillus species PCR Detection"
    },
    {
        name: "Serum alcohol"
    },
    {
        name: "Antithyroid Antibodies PanelTPO and ATA*"
    },
    {
        name: "Cardiolipin Antibody IgA"
    },
    {
        name: "Nicotine Metabolite, Quantitative, Urine"
    },
    {
        name: "Carcinoma Typing Panel By IHC- Panel II ( TTF1, Napsin A and p63)"
    },
    {
        name: "Immunofixation Electrophoresis (IFE), Qualitative 24-Hour Urine"
    },
    {
        name: "Nickel, Random Urine"
    },
    {
        name: "Chlamydia Trachomatis-IgG"
    },
    {
        name: "Canavan disease (ASPA) deletion/duplication analysis"
    },
    {
        name: "Ataxia-telangiectasia (ATM) gene analysis"
    },
    {
        name: "TSC2 deletion/duplication analysis"
    },
    {
        name: "Alkaptonuria"
    },
    {
        name: "Canavan disease (ASPA) gene analysis"
    },
    {
        name: "Neutropenia/Post-Transplant Panel "
    },
    {
        name: "Chloride, Fluid"
    },
    {
        name: "Alkaline Phosphatase Isoenzymes; ALP Isoenzymes Includes TOT"
    },
    {
        name: "Chlamydia trachomatis PCR Detection "
    },
    {
        name: "Neuronal migration disorder gene panel"
    },
    {
        name: "Ataxia-telangiectasia (ATM) deletion/duplication analysis"
    },
    {
        name: "PDGFRA mutation: Exons 12 and 18 (D842V and D846V) – associated with TKI resistance"
    },
    {
        name: "Potassium, 24-Hour Urine"
    },
    {
        name: "Parvovirus B - 19 Antibodies Panel IgG & IgM"
    },
    {
        name: "OLIGOCLONAL BAND BY IEF(SERUM AND CSF)"
    },
    {
        name: "Candida albicans PCR Detection"
    },
    {
        name: "Chloride, Random Urine"
    },
    {
        name: "Pathogenic Fungi Panel I by PCR"
    },
    {
        name: "Aldosterone, Serum"
    },
    {
        name: "Pathogenic Fungi Detection Panel-II"
    },
    {
        name: "Atypical Pneumonia Panel"
    },
    {
        name: "TSC1 deletion/duplication analysis"
    },
    {
        name: "TSC1 gene analysis"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Urine"
    },
    {
        name: "Aspergillus Antibody IgM"
    },
    {
        name: "TORCH 5-IgM"
    },
    {
        name: "Brucella IgM & IgG Antibody Panel"
    },
    {
        name: "Flow Cytometry, CD 2 (Pan T Cell Marker)"
    },
    {
        name: "Mean Corpuscular Haemoglobin; MCH"
    },
    {
        name: "LAMOTRIGINE LEVEL"
    },
    {
        name: "NT- PROBNP (N-Terminal Pro B Type Natriuretic Peptide)"
    },
    {
        name: "POST GLUCOSE - AFTER 1 HR"
    },
    {
        name: "Mercury, 24-Hours Urine"
    },
    {
        name: "Mycoplasma Pneumoniae Antibodies IgG & IgM"
    },
    {
        name: "Flow Cytometry, CD 13 (Myeloid Cell Marker)"
    },
    {
        name: "Porphyrins Total, Quantitative, 24-Hour Urine"
    },
    {
        name: "TORCH 13"
    },
    {
        name: "Anti B Titre ,IgG**"
    },
    {
        name: "Protein, Total, 24-Hour Urine"
    },
    {
        name: "Toxoplasma Antibodies Panel IgG & IgM"
    },
    {
        name: "Factor X Functional"
    },
    {
        name: "AFB Susceptibility-Isoniazid Pure Culture"
    },
    {
        name: "CMV avidity"
    },
    {
        name: "Alpha-1-Acid Glycoprotein"
    },
    {
        name: "Factor VII Functional"
    },
    {
        name: "Glucose, Random (R)"
    },
    {
        name: "Lactate, CSF"
    },
    {
        name: "Cysticercosis (Taenia Solium) IgG"
    },
    {
        name: "Neuroendocrine Markers (Chromogranin A, Synaptophysin, CD56)"
    },
    {
        name: "Calcitonin"
    },
    {
        name: "Hepatitis C Antibody (Anti HCV)"
    },
    {
        name: "KRAS mutation codon 12, 13, 61"
    },
    {
        name: "Congenital Muscular Dystrophy (LAMA2) deletion/duplication analysis"
    },
    {
        name: "Bacteroides fragilis PCR Detection"
    },
    {
        name: "Porphyrins Total, Qualitative, Random Urine"
    },
    {
        name: "Tissue Biomarker - WT1"
    },
    {
        name: "Digoxin"
    },
    {
        name: "Tissue Biomarker (Micropthalmia Transcription Factor MiTF) by IHC"
    },
    {
        name: "BCR-ABL1  Quantitative (IS)"
    },
    {
        name: "TSC1 & TSC2 gene analysis"
    },
    {
        name: "Maternal Serum Screen 3 / Triple Marker"
    },
    {
        name: "Mean Corpuscular Haemoglobin Concentration; MCHC"
    },
    {
        name: "Bacterial and Fungal CNS Panel"
    },
    {
        name: "Tissue Biomarker - Vimentin"
    },
    {
        name: "Tissue Biomarker - TTF1"
    },
    {
        name: "Lactate, Plasma"
    },
    {
        name: "Breast Monitor Panel CA 15.3 CEA"
    },
    {
        name: "Troponin - T Qualitative"
    },
    {
        name: "Cadmium, Blood"
    },
    {
        name: "Cadmium, Random Urine"
    },
    {
        name: "Comprehensive CNS Panel (Any 6 pathogens)"
    },
    {
        name: "Comprehensive Neurology panel"
    },
    {
        name: "Flow Cytometry, CD 15 (Myeloid Cell Marker)"
    },
    {
        name: "Aldehyde Chopra Test For Kalaazar"
    },
    {
        name: "Barbiturate Screen, Urine"
    },
    {
        name: "Tissue Biomarker - TdT"
    },
    {
        name: "HCG, Beta, Total, Quantitative, Random Urine"
    },
    {
        name: "Mercury, Random Urine"
    },
    {
        name: "BACTERIAL FUNGAL CNS PANEL (ANY 7 PATHOGENS)"
    },
    {
        name: "BACTERIAL FUNGAL CNS PANEL (ANY 14 PATHOGENS)"
    },
    {
        name: "Dihydrotestosterone (DHT)"
    },
    {
        name: "Alkaline Phosphatase; ALP"
    },
    {
        name: "Cadmium, 24-Hour Urine"
    },
    {
        name: "Amylase, Fluid"
    },
    {
        name: "Inhibin A, Reproductive Marker"
    },
    {
        name: "Measles (Rubeola) Antibodies Panel, IgG & IgM"
    },
    {
        name: "Urine, Glucose"
    },
    {
        name: "Special Histochemical Stain: Perls Prussian Blue"
    },
    {
        name: "Measles Virus RT-PCR Detection"
    },
    {
        name: "Tissue Biomarker - CD68"
    },
    {
        name: "CA 72.4; Gastric Cancer Marker"
    },
    {
        name: "West Nile Virus RT- PCR Detection"
    },
    {
        name: "HCG, Beta, Total, Quantitative"
    },
    {
        name: "Special Histochemical Stain: PAS"
    },
    {
        name: "Flow Cytometry, CD 14 (Monocytic Cell Marker)"
    },
    {
        name: "Widal Tube Agglutination Test"
    },
    {
        name: "Zinc, 24-Hour Urine"
    },
    {
        name: "Special Histochemical Stain: PAS diastase"
    },
    {
        name: "Whole Mitochondrial Genome Sequencing"
    },
    {
        name: "HCV Antibody"
    },
    {
        name: "Anti A Titre"
    },
    {
        name: "Anti - SS DNA Antibody"
    },
    {
        name: "Tissue Biomarker - CD57"
    },
    {
        name: "Tissue Biomarkers: CK 7 + CK 20 + p40 + Chromogranin A- IHC Analysis"
    },
    {
        name: "Monospot test for infectious mononucleosis"
    },
    {
        name: "Oxalate, Random Urine"
    },
    {
        name: "Widal Slide Agglutination Test"
    },
    {
        name: "TORCH Panel IgG"
    },
    {
        name: "Packed Cell Volume (PCV)-Hematocrit"
    },
    {
        name: "TORCH 8 Panel"
    },
    {
        name: "Urea, 24-Hour Urine"
    },
    {
        name: "Tissue Biomarker - CD79a"
    },
    {
        name: "Pediatric Small Blue Round Cell Tumors of Childhood (Primary Panel)"
    },
    {
        name: "AFB Susceptibility-Moxifloxacin, Pure Culture"
    },
    {
        name: "Cystatin C"
    },
    {
        name: "Allergy-GIT Panel 1 (Veg)"
    },
    {
        name: "Enterobacter aerogenes PCR Detection"
    },
    {
        name: "Alpha-1-Antitrypsin Quantitation"
    },
    {
        name: "PSA (Prostate - Specific Antigen), Free"
    },
    {
        name: "MPO-ANCA  by  ELISA"
    },
    {
        name: "Tissue Biomarker - HepPar 1"
    },
    {
        name: "Weil Felix TestProteus Antigen OX19, OX 2 & OX K"
    },
    {
        name: "Food Allergy Screening Panel Veg & Non Veg"
    },
    {
        name: "Special Histochemical Stain: Masson trichrome"
    },
    {
        name: "Tissue biomarker: PDL1"
    },
    {
        name: "Tissue Biomarker - CDX2"
    },
    {
        name: "Tissue Biomarker - CD99"
    },
    {
        name: "Anti - DS DNA Antibody,"
    },
    {
        name: "Oxalate, 24-Hours Urine"
    },
    {
        name: "Tissue Biomarker-BerEP4 - IHC Analysis"
    },
    {
        name: "Tissue Biomarkers- CD56, CD4/ CD8 For Nk Cells"
    },
    {
        name: "Cysticercus (Taenia Solium) IgG"
    },
    {
        name: "Cytology Examination (including FNAC)"
    },
    {
        name: "T4, Total"
    },
    {
        name: "Legionella Pneumphila IGG"
    },
    {
        name: "Cystine quantitative(URINE)"
    },
    {
        name: "Cystic megalencephaly (MLC1) gene analysis"
    },
    {
        name: "Epstein-Barr Virus VCA IgM"
    },
    {
        name: "Hepatitis Marker Profile, Acute"
    },
    {
        name: "Hyperekplexia gene panel"
    },
    {
        name: "Chloride, Serum"
    },
    {
        name: "Flow Cytometry, CD 19 (Pan B Cell Marker)"
    },
    {
        name: "Cobalt, Random Urine"
    },
    {
        name: "Hepatitis B Surface Antigen (HBsAg) Confirmation Neutralization"
    },
    {
        name: "Cortisol, Serum (Morning Sample)"
    },
    {
        name: "Striated / Skeletal Muscle Antibody, IFA"
    },
    {
        name: "Dengue Fever Combined Panel (ELISA)"
    },
    {
        name: "Cytomegalovirus (CMV) Antibodies Panel IgG & IgM"
    },
    {
        name: "Muscular dystrophy & congenital myopathy gene panel"
    },
    {
        name: "Gram Positive Bacterial Marker PCR Detection"
    },
    {
        name: "Transferrin"
    },
    {
        name: "Differential Leucocyte Count  (DLC), Automated"
    },
    {
        name: "Streptococcus pyogenes PCR Detection"
    },
    {
        name: "Differential Leucocyte Count  (DLC), Fluid"
    },
    {
        name: "Mumps Virus RT-PCR Detection"
    },
    {
        name: "Hepatitis C Antibody (HCV) IgM"
    },
    {
        name: "Mumps Virus Antibody IgG"
    },
    {
        name: "Mumps Virus Antibody IgM"
    },
    {
        name: "Hepatocellular Carcinoma Panel- HepPar 1, AFP, CD34, CK 7, CK 20"
    },
    {
        name: "Hepatitis E Virus (HEV) RT-PCR Detection"
    },
    {
        name: "Smooth Muscle Antibody (ASMA), IFA"
    },
    {
        name: "Sudan IV Stain"
    },
    {
        name: "Sudan Black B Stain, Blood / Bone Marrow"
    },
    {
        name: "Streptococcus species PCR Detection"
    },
    {
        name: "Gastrointestinal Stromal Tumor Primary Panel (CD117, DOG-1, CD34 )"
    },
    {
        name: "Gastrin"
    },
    {
        name: "Albert Stain"
    },
    {
        name: "Sodium, 24-Hour Urine"
    },
    {
        name: "Smear For Nocardia, Sputum"
    },
    {
        name: "Smear For Nocardia, Tissue"
    },
    {
        name: "Amylase, Serum"
    },
    {
        name: "Protein S, Functional"
    },
    {
        name: "Rota virus Antigen detection"
    },
    {
        name: "Leptospira Antibody, IgG"
    },
    {
        name: "Coombs Test, Direct"
    },
    {
        name: "Cholesterol, LDL, Direct"
    },
    {
        name: "Tay-Sachs disease (HEXA) gene analysis"
    },
    {
        name: "CNS Panel 2"
    },
    {
        name: "Pseudo Cholinesterase"
    },
    {
        name: "Glucose-6 Phosphate Dehydrogenase (G6PD) Gene Mutations"
    },
    {
        name: "Hepatitis C Virus (HCV) Genotyping"
    },
    {
        name: "Semen Analysis; Seminogram"
    },
    {
        name: "Cholesterol, Fluid"
    },
    {
        name: "Thallium, Random Urine"
    },
    {
        name: "Troponin - I Qualitative"
    },
    {
        name: "FOOD INTOLERANCE (IgG)"
    },
    {
        name: "Rubella (German Measles ) avidity"
    },
    {
        name: "India Ink Preparation"
    },
    {
        name: "Pantothenate kinase-associated degeneration (PANK2) deletion/duplication analysis"
    },
    {
        name: "Rubella (German Measles) Antibody IgM"
    },
    {
        name: "Vitamin D, 25 - Hydroxy"
    },
    {
        name: "SGOT"
    },
    {
        name: "Cholesterol, HDL, Direct"
    },
    {
        name: "Rubella (German Measles) Antibody IgG "
    },
    {
        name: "Septiscreen Molecular Detection Panel"
    },
    {
        name: "Septiscreen Molecular Detection (Any 10 Pathogens)"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture Body Fluids"
    },
    {
        name: "Vitamin B12 Binding Capacity, Unsaturated; Transcobalamin"
    },
    {
        name: "Joubert syndrome gene panel"
    },
    {
        name: "Potassium, Random Urine"
    },
    {
        name: "Adenovirus PCR Detection"
    },
    {
        name: "Septiscreen Molecular Detection (Any 20 Pathogens)"
    },
    {
        name: "Adenosine Deaminase; ADA"
    },
    {
        name: "John Cunningham (JC) Virus PCR Detection"
    },
    {
        name: "Semen Fructose Qualitative"
    },
    {
        name: "Alpha Galactosidase, Fabry Disease, Qualitative, Blood"
    },
    {
        name: "Total Leucocyte Count (TLC), Fluid"
    },
    {
        name: "Japanese Encephalitis Virus (JEV) RT-PCR Detection"
    },
    {
        name: "Leptospira species PCR Detection"
    },
    {
        name: "Jo-1 Antibody IgG"
    },
    {
        name: "FIP1L1-PDGFRA GENE REARRANGEMENT"
    },
    {
        name: "CYP2D6 Genotyping"
    },
    {
        name: "Testosterone, Total"
    },
    {
        name: "CH 50 (Complement Total)"
    },
    {
        name: "Thallium, 24-Hour Urine"
    },
    {
        name: "Hepatitis A & B Virus Evaluation (Anti-HAV IgG, Anti-HAV IgM, Antibodies)"
    },
    {
        name: "Flow Cytometry, CD 10 / Calla (Prognostic ALL Marker)"
    },
    {
        name: "Plasma Cell Neoplasms (Myeloma, Plasmacytoma vs. Reactive Plasmacytosis) Panel"
    },
    {
        name: "Comprehensive CNS Panel"
    },
    {
        name: "Vitamin B1 (Thiamine), 3 ml Whole Blood"
    },
    {
        name: "Chandipura Virus RT- PCR Detection"
    },
    {
        name: "HIV, HBV and HCV PCR Detection"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Bone Marrow"
    },
    {
        name: "Cobalt, 24-Hour Urine"
    },
    {
        name: "Viral CNS panel (Any 8 Pathogens)"
    },
    {
        name: "Thallium, Blood"
    },
    {
        name: "Pan Bacterial PCR Detection"
    },
    {
        name: "Vitamin A (Retinol)"
    },
    {
        name: "Flow Cytometry, CD 11C (Hairy Cell/NHL Marker)"
    },
    {
        name: "Charcot-Marie-Tooth and 1A/HNPP (PMP22, COX10, TEKT3) Deletion/Duplication Analysis"
    },
    {
        name: "Viral CNS panel (Any 12 Pathogens)"
    },
    {
        name: "Pan Fungal PCR Detection"
    },
    {
        name: "HIV Combo"
    },
    {
        name: "CFTR gene analysis "
    },
    {
        name: "HIV Prognotool Panel"
    },
    {
        name: "Charcot-Marie-Tooth and Sensory Neuropathies gene panel"
    },
    {
        name: "Ceruloplasmin"
    },
    {
        name: "Cortisol, Free, 24-Hour Urine"
    },
    {
        name: "Cystic megalencephaly (MLC1) deletion/duplication analysis"
    },
    {
        name: "Blood Group ABO & RH Factor"
    },
    {
        name: "Cystic Fibrosis Testing (5 common mutations)"
    },
    {
        name: "Testosterone, Free"
    },
    {
        name: "Cystic Fibrosis, Newborn, Screen"
    },
    {
        name: "Multiple Myeloma Panel by NGS"
    },
    {
        name: "Flow Cytometry, CD 117 (Myeloid Cell Marker)"
    },
    {
        name: "Inhibin B"
    },
    {
        name: "Insulin Antibodies"
    },
    {
        name: "HIV (4th Generation)"
    },
    {
        name: "Dysferlinopathy/LGMD2A (CAPN3) deletion/duplication analysis"
    },
    {
        name: "Inhibin A, Pregnancy"
    },
    {
        name: "HIV 1 & 2  ELISA"
    },
    {
        name: "Bleeding & Clotting Time"
    },
    {
        name: "Tissue Biomarker - CK 20"
    },
    {
        name: "Cortisol, Morning And Evening, Serum"
    },
    {
        name: "Ovarian Serous Carcinoma Versus Peritoneal Mesothelioma (berEP4, PAX 8 and Calretinin)"
    },
    {
        name: "BK (Polyoma) Virus Molecular Detection"
    },
    {
        name: "Hemophilia Profile (APTT, Factor VIII, Factor IX)"
    },
    {
        name: "HBsAb - Antibody (Quantitative)"
    },
    {
        name: "Sickling, Screening Test"
    },
    {
        name: "Total Protein, Serum"
    },
    {
        name: "PSA (Prostate - Specific Antigen), Total"
    },
    {
        name: "Phospholipid Antibody IgG"
    },
    {
        name: "MTHFR Mutation"
    },
    {
        name: "Comprehensive CNS Panel (Any 12 pathogens)"
    },
    {
        name: "Total Leucocyte Count (TLC) (EDTA whole blood)"
    },
    {
        name: "Community Acquired Pneumonia Panel (Any 15 Pathogens)"
    },
    {
        name: "Microalbumin, 24-Hours Urine"
    },
    {
        name: "Phenobarbitone"
    },
    {
        name: "Apolipoproteins Profile (A1 & B)"
    },
    {
        name: "Microalbumin, 1st Morning / Random Urine"
    },
    {
        name: "HSV 1 Antibody Panel, IgG & IgM"
    },
    {
        name: "Random Urine Sugar"
    },
    {
        name: "Hereditary spastic paraplegia gene panel"
    },
    {
        name: "Phospholipid Antibody IgM"
    },
    {
        name: "Antithyroid Peroxidase Antibody; Anti TPO"
    },
    {
        name: "Community Acquired Pneumonia Panel"
    },
    {
        name: "TORCH Panel IgM"
    },
    {
        name: "Dengue Fever Antibody IgG"
    },
    {
        name: "Phenylalanine Quantitative Serum"
    },
    {
        name: "Herpes Simplex Virus (HSV) 1 IgG"
    },
    {
        name: "Human Papilloma Virus (HPV) Genotyping"
    },
    {
        name: "Community Acquired Pneumonia Panel (Any 10 Pathogens)"
    },
    {
        name: "BNP B Type Natriuretic Peptide"
    },
    {
        name: "Plasma Renin Activity"
    },
    {
        name: "Herpes Simplex Virus (HSV) - 1 & 2 PCR Detection"
    },
    {
        name: "Fragile X (FMR 1) Mutation Screen (CGG Repeats)"
    },
    {
        name: "Methylmalonic Acid-Qualitative"
    },
    {
        name: "APCR(Activated Protein C Resistance) Test"
    },
    {
        name: "Methylmalonic Acid-Quantitative"
    },
    {
        name: "Platelet Antibody"
    },
    {
        name: "Bone Marrow Smear Examination"
    },
    {
        name: "APA (Phospholipid Antibody)-IgA**"
    },
    {
        name: "Phadiatop Allergen Screening - Adult"
    },
    {
        name: "Food Intel Platinum"
    },
    {
        name: "Phencyclidine (PCP), Screen, Urine"
    },
    {
        name: "Food Intolerance - Mini"
    },
    {
        name: "Hereditary Cancer Panel"
    },
    {
        name: "HBsAG (Elisa)"
    },
    {
        name: "Blood Picture; Peripheral Blood Smear Examination"
    },
    {
        name: "Tissue Biomarker - Glypican 3"
    },
    {
        name: "Human Papilloma Virus (HPV) PCR Detection"
    },
    {
        name: "Phosphatidylserine Antibody IgM"
    },
    {
        name: "Prealbumin"
    },
    {
        name: "VDRL, CSF"
    },
    {
        name: "Cobalt, Blood"
    },
    {
        name: "Tissue Biomarker - GFAP"
    },
    {
        name: "17 - Hydroxyprogesterone ( 17-OHP)"
    },
    {
        name: "Tissue Biomarker - GCDFP 15"
    },
    {
        name: "Phenytoin"
    },
    {
        name: "Tissue Biomarker - ER"
    },
    {
        name: "Phosphatidylserine Antibody IgG"
    },
    {
        name: "Fasting Urine Sugar"
    },
    {
        name: "PR3-ANCA  by ELISA"
    },
    {
        name: "Protein, Total, CSF"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Blood"
    },
    {
        name: "Sperm DNA Fragmentation By Flow Cytometry"
    },
    {
        name: "Biotinidase, Newborn Screen"
    },
    {
        name: "Amikacin"
    },
    {
        name: "Vitamin D, 1-25-Dihydroxy"
    },
    {
        name: "Protein, Total, Body Fluids"
    },
    {
        name: "Potassium, Serum "
    },
    {
        name: "Prothrombin Time Studies"
    },
    {
        name: "Biotinidase Deficiency, Quantitative,Plasma "
    },
    {
        name: "Coxsackie Antibody IgM"
    },
    {
        name: "Amphetamine Screen, Urine"
    },
    {
        name: "Endomysial Antibody IgA"
    },
    {
        name: "Albumin, Serum"
    },
    {
        name: "Bilirubin, Total"
    },
    {
        name: "Urine, Reducing Substances"
    },
    {
        name: "Cockayne syndrome gene panel"
    },
    {
        name: "Bismuth, 24-Hour Urine"
    },
    {
        name: "Cocaine screen, Urine"
    },
    {
        name: "Leptospira Antibody, IgM"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Urogenital Samples"
    },
    {
        name: "MYCOBACTERIUM SPECIES IDENTIFICATION & SPECIATION"
    },
    {
        name: "Herpes Simplex Virus(HSV) 2 IgM"
    },
    {
        name: "Tissue Biomarker - Cyclin D1"
    },
    {
        name: "Vitamin K1"
    },
    {
        name: "Leptin"
    },
    {
        name: "Bismuth, Random Urine"
    },
    {
        name: "HLA B27 PCR"
    },
    {
        name: "Leptospira Detection By Smear Examination, Blood"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture-Sputum"
    },
    {
        name: "Leishmania (Kala Azar) Antibody, IgG"
    },
    {
        name: "Vitamin E (Tocopherol)"
    },
    {
        name: "ACE (angiotensin converting enzyme)"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture"
    },
    {
        name: "Endometrial Versus Endocervical Adenocarcinoma Panel"
    },
    {
        name: "Amylase,Urine"
    },
    {
        name: "Amino Acid Quantitative Analysis, Plasma"
    },
    {
        name: "Neuronal ceroid lipofuscinosis gene panel"
    },
    {
        name: "Absolute Neutrophil Count"
    },
    {
        name: "Acetyl Choline Receptor Binding Antibody, Serum"
    },
    {
        name: "HLA-B*5701 Genotyping For Abacavir Sensitivity"
    },
    {
        name: "Coxsackie Antibody IgG"
    },
    {
        name: "VMA (Vanilmandelic Acid), 24-Hour Urine"
    },
    {
        name: "Tissue Biomarker - Melanoma Specific Antigen (MSA); HMB 45"
    },
    {
        name: "ANA Blot"
    },
    {
        name: "Enterobacteriaceae family PCR Detection"
    },
    {
        name: "Rh Antibody Titre"
    },
    {
        name: "Rheumatoid Factor (RA), Serum Qualitative"
    },
    {
        name: "Bence Jones Protein, Immunofixation"
    },
    {
        name: "Absolute Lymphocyte Count"
    },
    {
        name: "PSA ProfilePSA, TotalPSA, Free"
    },
    {
        name: "RET Gene Mutations"
    },
    {
        name: "Nor-Metanephrine-Free, Plasma"
    },
    {
        name: "Tissue Biomarker - Chromogranin A"
    },
    {
        name: "Cortisol, Serum (Evening Sample)"
    },
    {
        name: "Hepatitis BE PanelAnti- HBE HBeAg"
    },
    {
        name: "Hepatitis B Core Antibody (Anti-HBC) Total"
    },
    {
        name: "Histone Antibodies"
    },
    {
        name: "Tissue Biomarker - CK7"
    },
    {
        name: "Hepatitis B Core Antibody (Anti-HBC) IgM"
    },
    {
        name: "NOTCH3 (CADASIL) gene analysis"
    },
    {
        name: "NOTCH3 (CADASIL) gene analysis -2 exons (2 & 3)"
    },
    {
        name: "NPM1 Mutation"
    },
    {
        name: "Tissue Biomarker - CEA "
    },
    {
        name: "Proteus mirabilis PCR Detection"
    },
    {
        name: "Nor-Metanephrine, ELISA, urine 24 Hrs"
    },
    {
        name: "Hepatitis A Diagnostic PanelAnti - HAV, IgG Anti - HAV, IgM"
    },
    {
        name: "AFB Susceptibility-Kanamycin Pure Culture"
    },
    {
        name: "AFB Susceptibility-Ofloxacin, Pure Culture"
    },
    {
        name: "Aluminum, Dialysis Fluid"
    },
    {
        name: "Hepatitis B Surface Antigen (HBsAg) Rapid Screening Test"
    },
    {
        name: "Phosphorus, Serum"
    },
    {
        name: "Albumin, CSF"
    },
    {
        name: "Protein, Total, Random Urine"
    },
    {
        name: "Urea, Serum"
    },
    {
        name: "Albumin Fluid"
    },
    {
        name: "Aicardi-Goutieres syndrome gene panel"
    },
    {
        name: "Aluminum, Serum"
    },
    {
        name: "AFP-Alpha Feto Protein"
    },
    {
        name: "Epstein-Barr Virus, Antibody To Nuclear Antigen (Ebna) IgG"
    },
    {
        name: "Acid Fast Bacilli (AFB) FM Stain, Sputum"
    },
    {
        name: "Amiodarone"
    },
    {
        name: "TBG; Thyroxine Binding Globulin"
    },
    {
        name: "Epstein-Barr Virus, Antibody To Early Antigen (D) IgG"
    },
    {
        name: "Tay-Sachs disease (HEXA) deletion/duplication analysis"
    },
    {
        name: "Tissue Biomarker - Melan A"
    },
    {
        name: "Epstein-Barr Virus VCA IgG"
    },
    {
        name: "Uric Acid, Random Urine"
    },
    {
        name: "Duchenne Muscular Dystrophy (DMD) gene analysis - NGS"
    },
    {
        name: "Hemoglobin; Hb (EDTA whole blood)"
    },
    {
        name: "SGPT"
    },
    {
        name: "Copper, 24-Hour Urine, Atomic Absorption"
    },
    {
        name: "Allergy-Git Panel(Veg+Non Veg)"
    },
    {
        name: "Phosphorus, 24-Hour Urine"
    },
    {
        name: "Methamphetamine Screen, Urine "
    },
    {
        name: "Heinz Bodies, Peripheral Blood"
    },
    {
        name: "CEA; Carcino Embryonic Antigen"
    },
    {
        name: "Conventional Cervico-Vaginal Cytology Screening by PAP smear"
    },
    {
        name: "Alpha-2-Macroglobulin"
    },
    {
        name: "Coombs Test, Indirect"
    },
    {
        name: "Leptospira Detection By Smear Examination, CSF"
    },
    {
        name: "Epstein Barr Virus (EBV) PCR Detection"
    },
    {
        name: "Leptospira Detection By Smear Examination, Urine"
    },
    {
        name: "Tissue Biomarker - Leucocyte Common Antigen CD45"
    },
    {
        name: "Clozapine"
    },
    {
        name: "Legionella pneumophilia PCR Detection"
    },
    {
        name: "Factor XII Functional"
    },
    {
        name: "U1 SNRNP ABS IMMUNOBLOT"
    },
    {
        name: "Hepatitis B Virus (HBV) Genotyping"
    },
    {
        name: "ANCA (Antineutrophil Cytoplasmic Antibodies)"
    },
    {
        name: "DHEA; Dehydroepiandrosterone"
    },
    {
        name: "Helicobacter Pylori Antibodies Panel IgA & IgG"
    },
    {
        name: "Clotting Time; CT"
    },
    {
        name: "CEA (FLUID)"
    },
    {
        name: "Eosinophil detection (Sputum)."
    },
    {
        name: "Hepatitis B Surface Antigen; (HBsAg); Australia Antigen"
    },
    {
        name: "Ferritin "
    },
    {
        name: "Factor VIII Functional"
    },
    {
        name: "AFB Susceptibility-Levofloxacin, Pure Culture"
    },
    {
        name: "Helicobacter Pylori Antigen, Stool"
    },
    {
        name: "CPK-MB"
    },
    {
        name: "U1 RNP Antibodies"
    },
    {
        name: "Methaqualone Screen, Urine"
    },
    {
        name: "CEBPA Mutation detection"
    },
    {
        name: "CSF & Serum Protein Electrophoresis / Oligoclonal Bands"
    },
    {
        name: "Legionella Antigen, Urine"
    },
    {
        name: "Methotrexate"
    },
    {
        name: "Hb, TLC, DLC, PS"
    },
    {
        name: "Centromere Antibody"
    },
    {
        name: "Zinc, Serum / Plasma"
    },
    {
        name: "Neuronal Antibody Profile (IgG)"
    },
    {
        name: "Uric Acid, 24-Hour Urine"
    },
    {
        name: "ANA , Anti-Nuclear Antibody (Quantitative)"
    },
    {
        name: "Factor XI Functional"
    },
    {
        name: "Androstenedione"
    },
    {
        name: "Uric Acid, Serum"
    },
    {
        name: "CSF Protein Electrophoresis / Oligoclonal Bands"
    },
    {
        name: "Methionine Quantitative Serum /Plasma**"
    },
    {
        name: "DHEA Sulphate; Dehydroepiandrosterone Sulphate"
    },
    {
        name: "Phosphorus, Random Urine"
    },
    {
        name: "Phospholipid Syndrome Profile"
    },
    {
        name: "Methadone Screen, Urine"
    },
    {
        name: "Hb variant analysis by HPLC (HbA2/F Analysis)"
    },
    {
        name: "Lipid Profile 2, Basic"
    },
    {
        name: "drVVT"
    },
    {
        name: "Neuron Specific Enolase (NSE), Serum"
    },
    {
        name: "Lead, Random Urine"
    },
    {
        name: "Calcium, 24-Hour Urine"
    },
    {
        name: "Calcium Random Urine"
    },
    {
        name: "Alpha Thalassemia Gene Mutation analysis by PCRt"
    },
    {
        name: "Legionella Pneumphila IGM"
    },
    {
        name: "HbA1c (Glycosylated Haemoglobin)"
    },
    {
        name: "Lithium"
    },
    {
        name: "Listeria species PCR Detection"
    },
    {
        name: "HAV-IgM Ab to Hepatitis A Virus"
    },
    {
        name: "Typhi Dot "
    },
    {
        name: "Tissue Biomarker - PAX8"
    },
    {
        name: "Drug of Abuse panel-12 Drug Panel"
    },
    {
        name: "Acid Phosphatase, Total"
    },
    {
        name: "Tumour Necrosis Factor-Alpha"
    },
    {
        name: "Tissue biomarker: p40 - IHC analysis."
    },
    {
        name: "Culture & Identification - MRSA Screen"
    },
    {
        name: "C-Reactive Protein, Cardio; HSCRP"
    },
    {
        name: "Tissue Biomarker - P53"
    },
    {
        name: "Tissue Biomarker: AR (Androgen Receptor)- IHC Analysis"
    },
    {
        name: "NMDA RECEPTOR ANTIBODY"
    },
    {
        name: "Dopamine"
    },
    {
        name: "Immunoglobulin IgM, Serum"
    },
    {
        name: "C. Difficile"
    },
    {
        name: "Allergy-Drugs Panel"
    },
    {
        name: "Bence Jones Protein"
    },
    {
        name: "Burkitt Lymphoma Panel"
    },
    {
        name: "Fibrinogen, Clotting Activity"
    },
    {
        name: "Toxoplasma Antibody IgG"
    },
    {
        name: "Stool Examination, Routine; Stool, R/E"
    },
    {
        name: "Cryptococcus Antigen, CSF"
    },
    {
        name: "Tissue Biomarker - CD3"
    },
    {
        name: "AFB Susceptibility-Capreomycin, Pure Culture"
    },
    {
        name: "Tissue biomarker: CA 125 - IHC analysis"
    },
    {
        name: "Human Immunodeficiency Virus - 1 (HIV - 1) Proviral DNA PCR Detection"
    },
    {
        name: "Tissue Biomarker - CD34"
    },
    {
        name: "GLUCOSE TOLERANCE TEST (F + 1HR + 2HR + 3HR)"
    },
    {
        name: "Anti Nuclear Antibody IFA"
    },
    {
        name: "Special Histochemical Stain: Reticulin"
    },
    {
        name: "Tissue Biomarker - Ki67"
    },
    {
        name: "Drug of Abuse panel-9 Drug Panel"
    },
    {
        name: "Anti Mullerian Hormone"
    },
    {
        name: "Anti Saccharomyces Cerevisiae Antibody IgG"
    },
    {
        name: "Porphobilinogen-Qualitative"
    },
    {
        name: "Tissue Biomarker - High Molecular Weight Cytokeratin CK 5,6"
    },
    {
        name: "Glucose F & PG (Post 75gm)"
    },
    {
        name: "Tissue Biomarker - Inhibin alpha"
    },
    {
        name: "Glucose Fasting (F)"
    },
    {
        name: "Selenium, Random Urine"
    },
    {
        name: "Fungal Stain, Body Fluids*"
    },
    {
        name: "Non-Tuberculous Mycobacteria (NTM) DNA Detection"
    },
    {
        name: "Selenium, Serum"
    },
    {
        name: "SCL-70 (Scleroderma ) Antibody"
    },
    {
        name: "Fungal Stain, Cerebrospinal Fluid*"
    },
    {
        name: "Human Herpes Virus - 6 (HHV - 6) PCR Detection"
    },
    {
        name: "Special Histochemical Stain: Verhoeff"
    },
    {
        name: "Selenium, 24-Hour Urine"
    },
    {
        name: "Urine Examination-Routine"
    },
    {
        name: "Human Immunodeficiency Virus - 1 (HIV - 1) RNA  Detection"
    },
    {
        name: "Episodic ataxia gene panel"
    },
    {
        name: "NMO (Aquaporin) Neuromylitis Optica"
    },
    {
        name: "Follicular Lymphoma versus Reactive Hyperplasia (CD3, CD20, bcl-2., bcl-6, Ki-67)"
    },
    {
        name: "Urine Examination-Routine (Cow/ Cattle)"
    },
    {
        name: "BCR-ABL1 t(9;22) Qualitative by RT PCR"
    },
    {
        name: "Dravet syndrome (SCN1A) gene analysis"
    },
    {
        name: "Scrub Typhus  - Qualitative"
    },
    {
        name: "Immunoglobulin IgG, Serum"
    },
    {
        name: "Tissue Biomarker - HER-2/neu"
    },
    {
        name: "Tissue Biomarker - CD21"
    },
    {
        name: "Anti Saccharomyces Cerevisiae Antibodies Panel, IgG & IgA"
    },
    {
        name: "Tissue Biomarker - CD20"
    },
    {
        name: "Tissue Biomarker - Kappa and Lambda Light Chain Restriction"
    },
    {
        name: "Glucose Challenge Test (GCT)"
    },
    {
        name: "Fluid Examination, Routine, Body Fluids"
    },
    {
        name: "Allergy-Eczema mini panel"
    },
    {
        name: "Urine Ketones (Acetone)"
    },
    {
        name: "Calcium, Ionized"
    },
    {
        name: "Special Histochemical Stain: PTAH"
    },
    {
        name: "Pseudomonas aeruginosa PCR Detection"
    },
    {
        name: "Tissue Biomarker: Er, Pgr, Her-2/Neu By IHC"
    },
    {
        name: "Polarizing Microscopy-Body Fluids"
    },
    {
        name: "Anti Saccharomyces Cerevisiae Antibody IgA"
    },
    {
        name: "DNPH, Urine"
    },
    {
        name: "Drug Of Abuse Panel-6 Drug Panel"
    },
    {
        name: "Echovirus Antibodies Panel IgG & IgM"
    },
    {
        name: "Urine, Dysmorphic RBC"
    },
    {
        name: "Benzodiazepine Screen"
    },
    {
        name: "Tissue Biomarker: p16 by IHC"
    },
    {
        name: "Cryptococcus Antigen, Serum"
    },
    {
        name: "Lupus anticoagulant"
    },
    {
        name: "Pseudomonas species PCR Detection"
    },
    {
        name: "Tissue Biomarker - CD56"
    },
    {
        name: "DPD Gene Mutations (Dihydropyrimidine Dehydrogenase Gene Mutation)"
    },
    {
        name: "Tissue Biomarker - CD30"
    },
    {
        name: "Tissue Biomarker - CD43"
    },
    {
        name: "Urine For Eosinophils"
    },
    {
        name: "PNH  Test "
    },
    {
        name: "CD4 COUNT"
    },
    {
        name: "PAPP-A (Pregnancy Associated Plasma Protein)"
    },
    {
        name: "Triglycerides, Serum "
    },
    {
        name: "Tissue Biomarker - CD23"
    },
    {
        name: "Complete Blood Count; CBC (EDTA whole blood)"
    },
    {
        name: "Varicella ZOSTER VIRUS (VZV) Antibody, IgM ( Chicken Pox / Herpes Zoster/ Shingles)"
    },
    {
        name: "ADA Pus"
    },
    {
        name: "PNEUMOCYSTIS CARINII (JEROVECI) DETECTION BY MICROSCOPY**"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Body Fluids"
    },
    {
        name: "Sirolimus ( Rapamycin)"
    },
    {
        name: "Malaria Detection by PCR"
    },
    {
        name: "HIV-DUO p24 Antigen & Antibody to HIV1 &2 (Serum)"
    },
    {
        name: "Staphylococcus aureus PCR Detection"
    },
    {
        name: "CD 45"
    },
    {
        name: "Lyme Disease (Borrelia Burgdorferi), IgG, Western Blot"
    },
    {
        name: "Mitochondrial Antibody (AMA), IFA"
    },
    {
        name: "HIV (p24 Antigen detection*)"
    },
    {
        name: "HIV RNA, HBV and HCV PCR Detection"
    },
    {
        name: "Myoglobin, Urine"
    },
    {
        name: "Treponema Pallidum Hemagglutination (TPHA)"
    },
    {
        name: "BCR-ABL1 Kinase Domain Mutation Analysis (Imatinib Resistance Mutation Analysis/IRMA)"
    },
    {
        name: "Tissue Biomarker - CD15"
    },
    {
        name: "Calcium, Serum*"
    },
    {
        name: "HIV Western Blot"
    },
    {
        name: "Folate (Folic Acid), Serum"
    },
    {
        name: "NMO PROFILE (CSF AND SERUM)"
    },
    {
        name: "Magnesium, 24-Hour Urine"
    },
    {
        name: "Malaria Vivax And Falciparum Antigen"
    },
    {
        name: "Varicella Zoster Virus (VZV) Antibody, IgG ( Chicken Pox / Herpes Zoster/ Shingles)"
    },
    {
        name: "AFB Susceptibility-Comprehensive Panel,  (13 Drugs) "
    },
    {
        name: "HIV Rapid Screening Test"
    },
    {
        name: "Tissue Biomarker - CD138"
    },
    {
        name: "VDRL (RPR), Serum"
    },
    {
        name: "Flow Cytometry, TDT (Precursor Cell Marker)"
    },
    {
        name: "LE Cell Phenomenon"
    },
    {
        name: "Creatinine, Serum"
    },
    {
        name: "Troponin - I Quantitative"
    },
    {
        name: "Fibrinogen Degradation Products ( FDP)"
    },
    {
        name: "Lymphoblastic Lymphoma Panel"
    },
    {
        name: "AFB Susceptibility-Ciprofloxacin, Pure Culture"
    },
    {
        name: "Troponin- T High Sensitive"
    },
    {
        name: "C3 And C4 Complement Panel"
    },
    {
        name: "Epidermal (Pemphigus) Antibody"
    },
    {
        name: "Cryoglobulins Qualitative Test"
    },
    {
        name: "Triglycerides, Body Fluid"
    },
    {
        name: "FLT3 Mutation detection by PCR"
    },
    {
        name: "Toxoplasma Antibody IgM"
    },
    {
        name: "Lead, Blood"
    },
    {
        name: "Cholesterol, Total "
    },
    {
        name: "Transferrin Saturation"
    },
    {
        name: "Trichomonas Vaginalis-Microscopy"
    },
    {
        name: "Treponema pallidum PCR Detection"
    },
    {
        name: "TPMT Genotyping (TPMT*1/*2/*3A/*3B/*3C)"
    },
    {
        name: "Protein, Total, SerumTotal ProteinAlbuminA : G Ratio"
    },
    {
        name: "Hepatitis B Virus (HBV) Genotyping and Drug Resistance"
    },
    {
        name: "Hepatitis BE Antibody; (Anti-HBE)"
    },
    {
        name: "Malaria Antibody"
    },
    {
        name: "Toxoplasma gondii PCR Detection"
    },
    {
        name: "Streptococcus pneumoniae PCR Detection"
    },
    {
        name: "Viral CNS Panel"
    },
    {
        name: "Human Immunodeficiency Virus - 1 ( HIV 1 ) Quantitative ( Viral Load )"
    },
    {
        name: "Flow Cytometry, ZAP-70"
    },
    {
        name: "Malaria Parasite/Blood Parasite Identification"
    },
    {
        name: "Bicarbonate"
    },
    {
        name: "Prostate PIN4 cocktail"
    },
    {
        name: "NMDA- CSF & Serum"
    },
    {
        name: "Myoepithelial Markers Panel (Calponin, p63))"
    },
    {
        name: "Fine Needle Aspiration Cytology - H & E, PAP, Giemsa"
    },
    {
        name: "Flow Cytometry, CD MPO (Myeloid Cell Marker)"
    },
    {
        name: "Streptococcus Group-B PCR Detection"
    },
    {
        name: "Helicobacter Pylori IgA"
    },
    {
        name: "Prolactin, Serum"
    },
    {
        name: "Pleomorphic Soft Tissue Sarcoma - Primary Panel"
    },
    {
        name: "Cannabinoids (Delta-9-Thc Metabolites),Screen, Urine; Marijuana"
    },
    {
        name: "HOMA Index Insulin Resistance Test"
    },
    {
        name: "Homocysteine, Quantitative, Serum"
    },
    {
        name: "Propoxyphene Screen, Urine"
    },
    {
        name: "Allergy vegatable panel - 2"
    },
    {
        name: "AFB Susceptibility-Ethambutol, Pure Culture"
    },
    {
        name: "EGFR Mutation Analysis"
    },
    {
        name: "Toxoplasma avidity"
    },
    {
        name: "Magnesium, Random Urine"
    },
    {
        name: "Creatinine, Fluid"
    },
    {
        name: "AFB Susceptibility-Ethionamide Pure Culture"
    },
    {
        name: "Spinocerebellar ataxia repeat expansion analysis: SCA1, SCA2, SCA3, SCA6, SCA7, SCA10, SCA12"
    },
    {
        name: "Myoglobin, Serum"
    },
    {
        name: "Porphobilinogen (PBG), Quantitative, Urine Spot"
    },
    {
        name: "LDH; Lactate Dehydrogenase"
    },
    {
        name: "Pyruvate; Pyruvic Acid"
    },
    {
        name: "Enterovirus RT PCR Detection"
    },
    {
        name: "Electrolytes, SerumSodium Potassium Chloride"
    },
    {
        name: "Creatinine Clearance Test"
    },
    {
        name: "Varicella Zoster Virus (VZV) PCR Detection"
    },
    {
        name: "Pompe Disease, Quantitative, Blood"
    },
    {
        name: "Creatinine, Random Urine"
    },
    {
        name: "Lead, 24-Hour Urine"
    },
    {
        name: "Candida species PCR Detection"
    },
    {
        name: "PTH (Parathyroid Hormone), Intact"
    },
    {
        name: "Hepatitis B Virus (HBV) PCR Detection"
    },
    {
        name: "Activated Partial Thromboplastin Time (APTT)"
    },
    {
        name: "Creatinine, 24-Hour Urine"
    },
    {
        name: "Electrolytes, Random UrineSodiumPotassiumChloride"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, CSF*"
    },
    {
        name: "Acid Fast Bacilli (AFB) Rapid Culture, Pus"
    },
    {
        name: "Poorly Differentiated Malignancy (Undifferentiated Malignancy) / Carcinoma of Unknown Primary Panel"
    },
    {
        name: "Streptococcus Group B Antigen Detection"
    },
    {
        name: "Carbamazepine"
    },
    {
        name: "Cannabis Marijuana, THC Urine Spot"
    },
    {
        name: "Microsporidium Detection, Stool"
    },
    {
        name: "TPMT Enzyme Activity (Thiopurine Methyl Transferase)"
    },
    {
        name: "Urea Nitrogen, 24-Hour Urine"
    },
    {
        name: "Hepatitis Be Antigen; (HBeAg)"
    },
    {
        name: "Allergy Vegetable Panel - 1"
    },
    {
        name: "Burkholderia cepacia complex (BCC) PCR Detection"
    },
    {
        name: "Food Allergy Panel 7 (For Infants)"
    },
    {
        name: "Immunoglobulin IgG, CSF"
    },
    {
        name: "LDH, Fluid"
    },
    {
        name: "Enterococcus species PCR Detection"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Tissue, Biopsy"
    },
    {
        name: "Allergy screening test-Infants(<5yrs)"
    },
    {
        name: "Urea Random Urine"
    },
    {
        name: "Helicobacter Pylori IgG"
    },
    {
        name: "Stool, Reducing Substances"
    },
    {
        name: "Flow Cytometry, CD 3( Pan T Cell Marker)"
    },
    {
        name: "Stool Examination, Occult Blood"
    },
    {
        name: "Stenotrophomonas maltophilia PCR Detection"
    },
    {
        name: "Urea Nitrogen, Random Urine"
    },
    {
        name: "Metanephrine-Free Plasma"
    },
    {
        name: "Allergy - Adult (Comprehensive ) Panel"
    },
    {
        name: "Iron Studies"
    },
    {
        name: "Small B Cell Non Hodgkin Lymphoma Panel"
    },
    {
        name: "PML/RARA (Quantitative assay for MRD & relapse)"
    },
    {
        name: "Culture & Identification - Actinomycetes, Pus"
    },
    {
        name: "Acid Fast Bacilli (AFB) ZN Stain, Respiratory Sample"
    },
    {
        name: "Homovanillic Acid"
    },
    {
        name: "Rheumatoid Factor (RA), Serum Quantitative"
    },
    {
        name: "Mantoux Test; Tuberculin Skin Test"
    },
    {
        name: "Staphylococcus species PCR Detection"
    },
    {
        name: "Galactosemia, Newborn Screen"
    },
    {
        name: "ADA (CSF)."
    },
    {
        name: "Culture & Identification, Neisseria Meningitidis"
    },
    {
        name: "RBC Folate"
    },
    {
        name: "CBC with PS"
    },
    {
        name: "Testosterone Panel, Total & Free"
    },
    {
        name: "Dengue Virus Detection and Serotyping by RT-PCR"
    },
    {
        name: "Human Immunodeficiency Virus 1 (HIV-1) Drug Resistance Genotyping (PI, NRTI, NNRTI)"
    },
    {
        name: "Varicella Zoster Virus (VZV) Antibodies Panel, IgG & IgM ( Chicken Pox / Herpes Zoster/ Shingles)"
    },
    {
        name: "Protein C, Antigen"
    },
    {
        name: "Fungal Stain, Tissue*"
    },
    {
        name: "Galactosemia (Epimerase), Quantitative, Blood"
    },
    {
        name: "Galactose, Quantitative, Plasma"
    },
    {
        name: "Catecholamines, Plasma"
    },
    {
        name: "Fungal Stain, Urine"
    },
    {
        name: "Galactosemia Screening, Blood"
    },
    {
        name: "Galactosemia Classical (Transferase), Quantitative, Blood"
    },
    {
        name: "Dengue Fever Antibody IgM"
    },
    {
        name: "DENGUE NS1 ANTIGEN(ELISA)"
    },
    {
        name: "Vanilmandelic Acid, Random Urine"
    },
    {
        name: "Paracetamol (Acetaminophen)"
    },
    {
        name: "Calcium/Creatinine Ratio(Spot Urine)"
    },
    {
        name: "SM (Smith), Antibody IgG"
    },
    {
        name: "Thyroid Profile, Total (T3,T4,TSH)"
    },
    {
        name: "Allergy screening IgE with Phadiatop (Adult and >5y)"
    },
    {
        name: "Thyroglobulin; TG Cancer Marker"
    },
    {
        name: "Follicle Stimulating Hormone (FSH)"
    },
    {
        name: "Measles (Rubeola) Antibody IgG"
    },
    {
        name: "AFB Susceptibility- 10 Drugs  Panel,  (1st & 2nd Line, Capreomycin)"
    },
    {
        name: "Bilirubin, Fluid"
    },
    {
        name: "Lipoprotein (A); LP(A)"
    },
    {
        name: "Cardiovascular Disease (CVD) Molecular Detection (12 Mutations)"
    },
    {
        name: "Theophylline"
    },
    {
        name: "Fungal Stain, Pus*"
    },
    {
        name: "Fungal Stain, Skin Scrapping*"
    },
    {
        name: "Rubella (German Measles) Antibodies Panel, IgG & IgM"
    },
    {
        name: "Fungal Stain, Sputum*"
    },
    {
        name: "Bilirubin Total, Direct And Indirect"
    },
    {
        name: "Thyroid Profile, FreeFree T3Free T4TSH, Ultrasensitive"
    },
    {
        name: "Allergy screening test-Adult"
    },
    {
        name: "Thrombin Time(TT)"
    },
    {
        name: "ALL Panel (E2A-PBX, MLL-AF4, TEL1-AML1, BCR-ABL)"
    },
    {
        name: "Bile Acids-Total"
    },
    {
        name: "Culture & Identification, C. diphtheriae"
    },
    {
        name: "Free Androgen Index (FAI)"
    },
    {
        name: "Bilirubin, Direct"
    },
    {
        name: "JAK2 V617F Mutation Detection"
    },
    {
        name: "Urobilinogen, Qualitative, 24-Hour Urine"
    },
    {
        name: "Allergen-Wheat ( Gehon / Triticum aestivum)"
    },
    {
        name: "Free T4 (FT4), TSH"
    },
    {
        name: "UROBILINOGEN- SPOT URINE"
    },
    {
        name: "Alcohol Screen, Blood"
    },
    {
        name: "Allergy Panel - Other (Animal Panel)"
    },
    {
        name: "Culture & Identification - Actinomycetes, Sputum"
    },
    {
        name: "RNP-SM Antibody, IgG"
    },
    {
        name: "Mean Corpuscular Volume; MCV"
    },
    {
        name: "Flow Cytometry, HLA DR ( Activation Marker)"
    },
    {
        name: "Pregnancy Test, Urine"
    },
    {
        name: "LH; Luteinising Hormone"
    },
    {
        name: "LIPASE (FLUID)"
    },
    {
        name: "Valproic Acid; Valproate"
    },
    {
        name: "Immunoglobulin IgA, Serum"
    },
    {
        name: "Freelyte serum (Free light chain assay, kappa and lambda light chain)"
    },
    {
        name: "TSH (Thyroid Stimulating Hormone), Ultrasensitive "
    },
    {
        name: "Rheumatoid Factor (RA), Synovial Fluids"
    },
    {
        name: "Chikungunya RT-PCR Detection"
    },
    {
        name: "Allergen - Aspergillus Fumigatus"
    },
    {
        name: "Fructosamine"
    },
    {
        name: "Red Blood Cells; RBC Count"
    },
    {
        name: "Rickettsia species PCR Detection"
    },
    {
        name: "Tissue Transglutaminase Antibody IgA"
    },
    {
        name: "Tissue Transglutaminase Antibody IgG"
    },
    {
        name: "Parietal Cells Antibody"
    },
    {
        name: "Chikungunya Virus Antibody IgM"
    },
    {
        name: "JAK2 Exon 12 Mutation Detection"
    },
    {
        name: "Osteocalcin, Serum; Bone GLA Protein (BGP)"
    },
    {
        name: "Tissue Biomarker - ALK"
    },
    {
        name: "Herpes Simplex Virus(HSV) 1 + 2 IgM"
    },
    {
        name: "Factor V Leiden Mutation Detection"
    },
    {
        name: "Hepatitis B Surface Antibody; Anti HBS; HBsAb"
    },
    {
        name: "Chlamydia IgG & IgA"
    },
    {
        name: "Osmotic Fragility Test; RBC Fragility Test"
    },
    {
        name: "Herpes Simplex Virus 2 IgG"
    },
    {
        name: "Osmolality, Urine"
    },
    {
        name: "Herpes Simplex Virus(HSV) 1+2  IgG"
    },
    {
        name: "Flow Cytometry, CD 22 (Pan B Cell Marker)"
    },
    {
        name: "Brucella IgG Antibody"
    },
    {
        name: "Culture & Identification, Neisseria Gonorrhoae"
    },
    {
        name: "Brucella species PCR Detection"
    },
    {
        name: "Liver Kidney Microsomal Antibody"
    },
    {
        name: "TORCH 10 Panel"
    },
    {
        name: "Human Immunodeficiency Virus - 2 (HIV - 2) RNA  Detection"
    },
    {
        name: "Aldosterone, 24-Hour Urine"
    },
    {
        name: "Tissue Biomarker Napsin A - IHC Analysis"
    },
    {
        name: "Factor II (Prothrombin) G20210A Mutation"
    },
    {
        name: "Thyroid Reflex (FT3,FT4 done If TSH is Abnormal)"
    },
    {
        name: "Herpes Simplex Virus(HSV) 1 IgM"
    },
    {
        name: "Microfilaria Detection"
    },
    {
        name: "Tissue Biomarker - Beta Catenin"
    },
    {
        name: "Human Immunodeficiency Virus - 2 (HIV - 2) Proviral DNA PCR Detection"
    },
    {
        name: "Cardiolipin Antibody IgM"
    },
    {
        name: "Tissue Biomarker - bcl 2"
    },
    {
        name: "Hodgkin Versus Non Hodgkin Lymphoma Panel"
    },
    {
        name: "Platelet Distribution Width; PDW"
    },
    {
        name: "Insulin, Fasting"
    },
    {
        name: "Alcohol Screen, Urine"
    },
    {
        name: "Islet Cell Antibody"
    },
    {
        name: "Platelet Count "
    },
    {
        name: "Measles (Rubeola) Antibody IgM"
    },
    {
        name: "Reticulocyte Count"
    },
    {
        name: "Culture And Identification, Anaerobic Bacteria- Blood"
    },
    {
        name: "Cardiolipin Antibody IgG"
    },
    {
        name: "Liver Function Test 2"
    },
    {
        name: "Isospora Detection, Stool"
    },
    {
        name: "Parvovirus B - 19 Antibody IgM"
    },
    {
        name: "Factor V, Functional"
    },
    {
        name: "Immunoglobulin IGE, Serum"
    },
    {
        name: "Flow Cytometry, Glycophorin (Erythroid Cell Marker)"
    },
    {
        name: "Tissue Biomarker - bcl 6"
    },
    {
        name: "Quantiferon -TB Gold; Gamma Interferon"
    },
    {
        name: "PAS (Periodic Acid Schiff) Stain, Blood / Bone Marrow"
    },
    {
        name: "Parvovirus B19 by PCR"
    },
    {
        name: "Brucella IgM Antibody"
    },
    {
        name: "G6PD Qualitative"
    },
    {
        name: "Flow Cytometry, CD 20 ( Pan B Cell Marker)"
    },
    {
        name: "Flow Cytometry, FMC-7 (Prognostic CLL/NHL Marker)"
    },
    {
        name: "Bun; Blood Urea Nitrogen"
    },
    {
        name: "Rabies Virus RT- PCR Detection"
    },
    {
        name: "Magnesium, Serum"
    },
    {
        name: "Procalcitonin"
    },
    {
        name: "Calprotectin"
    },
    {
        name: "Flow Cytometry, CD103 (Hairy Cell Marker)"
    },
    {
        name: "Microalbumin: Creatinine Ratio"
    },
    {
        name: "Tissue Biomarker Myogenin - IHC Analysis"
    },
    {
        name: "Campylobacter Species Antigen Detection"
    },
    {
        name: "Parvovirus B - 19 Antibody IgG"
    },
    {
        name: "Culture & Identification - Actinomycetes, Tissue"
    },
    {
        name: "Herpes Simplex Virus(HSV) 1+2  IgG & IgM"
    },
    {
        name: "Tissue Biomarker Pan Cytokeratin (AE1/AE3)"
    },
    {
        name: "Filaria Antigen"
    },
    {
        name: "Flow Cytometry, CD 25 (Hairy Cell/NHL Marker)"
    },
    {
        name: "Lung Carcinoma Panel by IHC"
    },
    {
        name: "Immunofixation-Quantitative, Serum"
    },
    {
        name: "Lyme Disease (Borrelia Burgdorferi) Antibodies, IgG & IgM"
    },
    {
        name: "Red Cell Distribution Width; RDW"
    },
    {
        name: "Aldolase"
    },
    {
        name: "Mean Platelet Volume; MPV"
    },
    {
        name: "G6PD, Quantitative"
    },
    {
        name: "Protein C, Functional"
    },
    {
        name: "Filaria Antibody detection"
    },
    {
        name: "Growth Hormone; GH"
    },
    {
        name: "Immunofixation- Qualitative, Serum"
    },
    {
        name: "TACROLIMUS by LC MSMS"
    },
    {
        name: "Factor IX, Activity"
    },
    {
        name: "Lung carcinoma IHC panel (CK 7, CK 20, p63, TTF1)"
    },
    {
        name: "TSH Stimulation By TRH"
    },
    {
        name: "Lipase"
    },
    {
        name: "Spinocerebellar ataxia repeat expansion analysis: Any two of (SCA1, SCA2, SCA3, SCA6, SCA7, SCA10, SCA12)"
    },
    {
        name: "Flow Cytometry, CD 23 (CLL Segregation Marker)"
    },
    {
        name: "TSH receptor antibody"
    },
    {
        name: "Arthritis Panel 2"
    },
    {
        name: "STD Panel Plus "
    },
    {
        name: "Autoimmune Hepatitis Profile"
    },
    {
        name: "Hepatitis C Virus ( HCV ) Quantitative ( Viral Load )"
    },
    {
        name: "Pneumocystis carinii PCR Detection"
    },
    {
        name: "Hepatitis B Virus ( HBV ) Quantitative ( Viral Load )"
    }
]