import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import instance from '../../../utils/axios';
import "../../css/Dashprescription.css"

const WeekExercises = () => {

  const { weekId } = useParams();
  const [ name, setName ] = useState([]);

  const getData = async () => { 
    try { 
      const response = await instance.get(`/api/user/getUserExercisesByDays/${weekId}`);
      if ( response.data.success ){ 
        setName(response.data.data)
      } else { 
        toast.error(response.data.message)
      }
    } catch ( error ) { 
      console.log("get exercise by week error: ", error.message);
      toast.error(error.message)
    }
  }

  useEffect(() => {  
    getData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className='dashMembers' >
      <div className='dashMembers-container'>
        <span className="dashboard-title">
           Week { name.weekNumber } 
        </span>  
      </div>
      <div className="dash-user">
        <div className='single-exercise-lists'>
          { name.days?.map((week) => (
            <Link to={`/dashboard/exercises/day/${week._id}`} className='single-exercise-list single-exercise-week' key={week._id} > 
              <div>
                  <span > Day { week.dayNumber }: </span>
                  <span>  { week.dayHeading } </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WeekExercises;