import React, { useState } from "react";
import { motion } from "framer-motion";
import ResponseForm from "./response-form";
import TestingAnalysis from "./testing-analysis";
import AssignedTest from "./assigned-test";
import TestingPrescription from "./testing-prescription";

const Admin = () => {
  const [isActive, setIsActive] = useState(1);
  const handleActive = (btn) => setIsActive(btn);

  return (
    <div className="dashMembers">
      <motion.h1
        className="dashboard-title dashMembers-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        Form Response
      </motion.h1>
      <div className="tabsContainer">
        <motion.div
          className="btnContainer"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <button
            className={`tabs ${isActive === 1 ? "activeTab" : ""}`}
            onClick={() => handleActive(1)}
          >
            Form Response
          </button>
          <button
            className={`tabs ${isActive === 2 ? "activeTab" : ""}`}
            onClick={() => handleActive(2)}
          >
            Assigned Test
          </button>
          <button
            className={`tabs ${isActive === 3 ? "activeTab" : ""}`}
            onClick={() => handleActive(3)}
          >
            Test Results  
          </button>
          <button
            className={`tabs ${isActive === 4 ? "activeTab" : ""}`}
            onClick={() => handleActive(4)}
          >
            Prescription 
          </button>

        </motion.div>

        {isActive === 1 && (
          <div className="tabData">
            <motion.div
              className="tabContent"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <ResponseForm />
            </motion.div>
          </div>
        )}
        {isActive === 2 && (
          <div className="tabData">
            <motion.div
              className="tabContent"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <AssignedTest />
            </motion.div>
          </div>
        )}
        {isActive === 3 && (
          <div className="tabData">
            <motion.div
              className="tabContent"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <TestingAnalysis />
            </motion.div>
          </div>
        )}
        {isActive === 4 && (
          <div className="tabData">
            <motion.div
              className="tabContent"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <TestingPrescription />
            </motion.div>
          </div>
        )}


      </div>
    </div>
  );
};

export default Admin;
