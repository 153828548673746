import React from 'react'
import {motion} from "framer-motion"
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import "../../css/DeleteModal.css"

const DeleteModal = ({confirmDelete, h4, closeModal}) => {
    return (
        <div>
            <motion.div className="modal1"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.2 }}
            >
                <div className="modal-content1">
                    <h4 className='delete-para' >{h4}</h4>
                    <ReportGmailerrorredIcon className="modal-mui" />
                    <div className='delete-button'>
                        <button className='btn1' onClick={confirmDelete}>Yes</button>
                        <button className='btn2' onClick={closeModal}>No</button>
                    </div>
                </div>
            </motion.div>

        </div>
    )
}

export default DeleteModal