import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import '../../css/Calendar.css'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { motion } from "framer-motion"
import { toast } from 'react-hot-toast';
import useUserStore from '../../../store';
import { defaultUrl } from '../../../data/Images';

const ListCalendar = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [length, setLength] = useState(0);
  const [username, setUsername] = useState("");
  const [clientName, setClientName] = useState([]);
  const user = useUserStore((state) => state.user);
  const [employee, setEmployee] = useState([]);
  const [employee1, setEmployee1] = useState("");
  const [name, setName] = useState("");

  const getName = async () => {
    const response = await instance.get("/api/user/getEmployeeName");
    if (response.data.success) {
      setEmployee(response.data.data);
    } else {
      toast.error(response.data.message);
    }
  }

  const handleTransfer = async () => {
    try {
      const response = await instance.put("/api/user/transferClient", {
        clientName: name,
        employee: employee1,
        userId: user._id,
      })
      if (response.data.success) {
        toast.success(response.data.message);
        setName("");
        setEmployee1("");
        getCustomerName();
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error("Failed to transfer PT Client. Please try again.");
    }
  }


  const getCustomerName = async () => {

    const response = await instance.get(`/api/user/getCustomerData/${id}`)
    if (response.data.success) {
      setUsername(response.data.name);
      const rows = response.data && response.data.data.slice().reverse().map((data) => {
        const userData = data.userdata;
        const planData = data.planDetails[0];

        let totalSessions = '';
        if (["1 Month", "1 Months", "1 month", "1", "12 sessions", "12 Sessions"].includes(planData.planTime)) {
          totalSessions = '12 sessions';
        } else if (["3 Months", "3 months", "3", "36 sessions", "36 Sessions"].includes(planData.planTime)) {
          totalSessions = '36 sessions';
        } else if (["6 Months", "6", "6 months", "72 sessions", "72 sessions"].includes(planData.planTime)) {
          totalSessions = '72 sessions';
        } else if (["12 Months", "12", "12 months", "144 sessions", "144 sessions"].includes(planData.planTime)) {
          totalSessions = '144 sessions';
        }
        return {
          id: userData._id,
          col1: userData.file && userData.file.secure_url,
          col2: userData.name,
          col3: userData.username,
          col4: userData.mobile,
          col5: totalSessions,
          col6: new Date(planData.startDate).toLocaleDateString("en-GB"),
          col7: new Date(planData.endDate).toLocaleDateString("en-GB"),
          col8: data.totalAttended,
          col9: data.totalAbsent,
        };
      });
      const clientNames = rows.map((row) => row.col3);
      setClientName(clientNames);
      setUserData(rows);
      setLength(response.data.data.length);
    } else {
    }
  }

  useEffect(() => {
    getName();
    getCustomerName();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 50,
      renderCell: (params) => (
        <img src={params?.value || defaultUrl} alt="user" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
      ),
    },
    { field: 'col2', headerName: 'Client Name', width: 150 },
    { field: 'col3', headerName: 'UserName', width: 150 },
    { field: 'col4', headerName: 'Mobile', width: 150 },
    { field: 'col5', headerName: 'Package', width: 150 },
    { field: 'col6', headerName: 'Start Date', width: 150 },
    { field: 'col7', headerName: 'End Date', width: 150 },
    { field: 'col8', headerName: 'Attended', width: 150 },
    { field: 'col9', headerName: 'Lapsed', width: 150 },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {

        return (
          <div className="cellAction">
            <Link to={`/dashboard/calendar/PTcalendar/listCalendar/detailCalendar/${params.row.id}`} className='Link' >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className='dataTable '>
      <motion.div className="datatableTitle dashboard-title"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {username} user's List ({length})
      </motion.div>
      <motion.div className='dataGrid'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3}}
      >
        <DataGrid
          rows={userData}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </motion.div>
      {["Admin", "Regional Manager", "Branch Manager"].includes(user.role) ? <>
        <motion.div className="filter-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <select
            value={name}
            className="filter-input"
            onChange={(e) => setName(e.target.value)}
            required
          >
            <option value="">Select Client name</option>
            {clientName.map((username, index) => (
              <option key={index} value={username}>
                {username}
              </option>
            ))}
          </select>
          <select
            value={employee1}
            className="filter-input"
            onChange={(e) => setEmployee1(e.target.value)}
            required
          >
            <option value="">Select Employee name</option>
            {employee && employee.map((run, index) => (
              <option key={index} value={run.username}>
                {run.username}
              </option>
            ))}
          </select>
          <button className="comment-btn dash-btn" onClick={handleTransfer} >Assign</button>
        </motion.div>
      </> : ""}
    </div>

  )
}

export default ListCalendar;
