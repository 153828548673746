import React, { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import '../../css/ProgramPage.css'
import { useNavigate, useParams } from 'react-router-dom';
import instance from '../../utils/axios';
import { toast } from 'react-hot-toast';
import useUserStore from '../../store';
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from '@mui/icons-material/Delete';
import { gymName } from '../../data/Gym';

const Prospect = () => {

  const [show, setShow] = useState(true);
  const [file, setFile] = useState(null);
  const [prospect, setProspect] = useState({
    name: '',
    email: '',
    pincode: '',
    dob: '',
    mobile: '',
    path: '',
    train: '',
    gymLocation: '',
    referral: '',
    file: "",
    interest: ""
  });
  const { value } = useParams();
  const [allData, setAllData] = useState("");
  const nav = useNavigate();
  const user = useUserStore((state) => state.user);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);

  const handleClose = () => {
    setShow(false);
    nav('/')
  };

  const handleTrainingModeChange = (e) => {
    const { value } = e.target;
    setProspect({ ...prospect, train: value });
    setShowAdditionalFields(value === 'Offline');
  };
  
  useEffect(() => {
    instance.get("/api/user/allPlanNames")
      .then((response) => {
        if (response.data.success) {
          setAllData(response.data.alldata);
        } else if (response.data.error) {
          toast.error(response.data.message);
        } else {
          toast.error("An Error Occurred");
        }
      })
      .catch((error) => console.error(error));
  }, []);

  if (!show) {
    return null;
  }

  const setData = (e) => {
    const { name, value } = e.target;
    setProspect((preval) => {
      return {
        ...preval, [name]: value
      }
    })
  }

  function convertBase64(e) {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.');
      return;
    }
    if (file) {
      toast.error('Maximum one file allowed.');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.onerror = (error) => {
      toast.error(error);
    };
  }
  
  const handleDeleteFile = (fileName) => {
    setFile(null); 
  };

  const newRegister = prospect.email.toLowerCase();
  
  const handleSubmit = async (e) =>  {
    e.preventDefault();
    try {
      const response = await instance.post('/api/user/prospect', {
        userId: user._id,
        role: user.role,
        name: prospect.name || user.name,
        email: newRegister || user.email,
        pincode: prospect.pincode,
        dob: prospect.dob || user.dob,
        mobile: prospect.mobile || user.mobile,
        path: prospect.path,
        file: file,
        referral: prospect.referral,
        interest: value,
        createdBy: user.uniqueId,
        train: prospect.train,
        gymLocation: prospect.gymLocation,
      });
      if(response.data.success && user.role === "User"){
        toast.success("Prospect Created SuccessFully.");
        if (allData.length > 0) {
          const matchingPlan = allData.find((data) => data.planname === value);
          if (matchingPlan) {
            fetch(matchingPlan.brochure.secure_url)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'brochure.pdf';
              a.click();
              URL.revokeObjectURL(url);
              toast.success("Plans are now available in the Service section")
            });
            nav('/plans');
          }
        }
      } else if (response.data.success){
        toast.success("Prospect Created Successfully");
        nav("/plans");
      }
      else {
        toast.error(response.data.message);
      }
      } catch (error) {
        console.log("Prospect Error: ", error.message)
        toast.error("An Error Occurred. Please Try Again!");
      }
  }

  return (
    <div className="prospect">
      <div className="prospect-container">
        <h1 className="prospect-title">Prospect Form</h1>
        <CloseIcon className="prospect-icon" onClick={handleClose} />
        <form onSubmit={handleSubmit}>
          <div className="prospect-text">
            <label htmlFor="name">Name<span className="asterick">*</span></label>
            {user.role !== "User" ? <input type="text" name='name' value={prospect.name} onChange={setData} placeholder="Enter Name"  />
              :
              <input type="text" name='name' value={user.name} onChange={setData} placeholder="Enter Name" readOnly />
            }
          </div>
          <div className="prospect-text">
            <label htmlFor="dob">Date Of Birth</label>
            {user.role !== "User" ? <input type="date" name='dob' required value={new Date(prospect.dob).toLocaleDateString("en-CA")} onChange={setData} />
              :
              <input type="date" name='dob' value={user.dob ? new Date(user.dob).toLocaleDateString("en-CA") : ""} onChange={setData} readOnly/>
            }
          </div>
          <div className="prospect-text">
            <label htmlFor="pincode">PinCode<span className="asterick">*</span></label>
            <input type="number" min={0} name='pincode' maxLength={6} value={prospect.pincode} onChange={setData} placeholder="Enter Pincode" required="required" />
          </div>
          <div className="prospect-text">
            <label htmlFor="email">Email<span className="asterick">*</span></label>
            {user.role !== "User" ? <input type="email" name='email' placeholder="Enter email-ID" value={prospect.email} onChange={setData} />
            : 
            <input type="email" name='email' readOnly placeholder="Enter email-ID" value={user.email} onChange={setData} />}
          </div>
          <div className="prospect-text">
            <label htmlFor="mobile">Mobile<span className="asterick">*</span></label>
            {user.role !== "User" ? <input type="text" maxLength={12} name='mobile' placeholder="Enter Mobile Number" value={prospect.mobile} onChange={setData} />
              : 
              <input type="text" maxLength={12} name='mobile' placeholder="Enter Mobile Number" value={user.mobile} onChange={setData} readOnly />
            }
            </div>
          <div className="prospect-text">
            <label htmlFor="path">Health issues<span className="asterick">*</span></label>
            <input type="text" name='path' placeholder="Enter Medical conditions" value={prospect.path} onChange={setData} required="required" />
          </div>
          <div className="prospect-text">
            <label htmlFor="documents">Blood Test Report (Within the last 90 days) </label>
            <p className="prospect-p">
              Note: Please provide the reports in PDF format only. <span className="asterisk">*</span>
            </p>
            {file && (
              <div className="prospect-doc">
                <span className='prospect-doc-name' >{file.slice(0,25)}</span>
                <DeleteIcon onClick={() => handleDeleteFile(file)} className="deleted-icon" />
              </div>
            )}
            <div>
              <label className="program-image" htmlFor="file">
                <DriveFolderUploadIcon className="icons prospect-file-icon" />
              </label>
              <input
                type="file"
                id="file" 
                style={{ display: "none" }}
                name="file"
                onChange={convertBase64}
              />
            </div>
          </div>
          <div className="prospect-text">
            <label htmlFor="documents">Training Mode<span className="asterick">*</span></label>
            <select name='train' value={prospect.train} onChange={handleTrainingModeChange} onBlur={setData} required="required">
              <option value="">Select Training Mode</option>
              <option value="Offline">Offline</option>
              <option value="Online">Online</option>
            </select>
          </div>
          {showAdditionalFields && (
            <div className="prospect-text">
              <label htmlFor="gymLocation">Gym Location<span className="asterick">*</span></label>
              <select name="gymLocation" value={prospect.gymLocation} onChange={setData} required="required">
                <option value="">Select Location</option>
                {gymName.map((a) => (
                  <option value={a.name} key={a}> {a.name} </option>
                ))}
              </select>
            </div>
          )}
          <div className="prospect-text">
            <label htmlFor="referral">Referral<span className="asterick">*</span></label>
            <select name='referral' value={prospect.referral} onChange={setData} required="required">
              <option value="">Select Referral Mode</option>
              <option value="instagram">Instagram</option>
              <option value="facebook">Facebook</option>
              <option value="Walk In">Walk In</option>
              <option value="Google">Google</option>
              <option value="Relatives">Relatives</option>
              <option value="Friends">Friends</option>
              <option value="Linkedin">Linkedin</option>
              <option value="SnapChat">SnapChat</option>
            </select>
          </div>
          <div className="prospect-text">
            <label htmlFor="interest">Interest In</label>
            <input type="text" name='interest' value={value} onChange={setData} required="required" />
          </div>
          <button className='send-btn'>Submit </button>
        </form>
      </div>
    </div>
  )
}

export default Prospect