import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import instance from "../../../utils/axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import "../../css/DashAdmin.css"
import DeleteModal from "../../components/Modal/DeleteModal";
import Form from "../../../assets/form.svg"
import useUserStore from "../../../store"

const ExerciseForm = () => {
  const [form, setForm] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const user = useUserStore((state) => state.user);

  const getData = async () => {
    try {
      const response = await instance.get("/api/user/allForms", {
        params: {
          uniqueId: user.uniqueId,
        },
      });
      if (response.data.success) {
        setForm(response.data.data);
      } else {
        toast.error(response.data.data);
      }
    } catch (error) {
      console.log("get exercise form error: ", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const toggleDropdown = (productId) => {
    const dropdownContent = document.getElementById(
      `dashMembersDropdownContent-${productId}`
    );
    if (dropdownContent) {
      dropdownContent.style.display =
        dropdownContent.style.display === "block" ? "none" : "block";
    }
  };

  const handleDelete = async (id) => {
    setUserIdToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteForm/${userIdToDelete}`);
      if (response.data.success) {
        toast.success("Product deleted successfully");
        setForm((prevData) =>
          prevData.filter((product) => product._id !== userIdToDelete)
        );
        setShowModal(false);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      toast.error("An error Occurred");
    }
  };

  return (
    <div className="tnc">
      <div className="tnc-container admin-container">
        {["Admin", "Regional Manager", "Branch Manager"].includes(user.role) && (
          <div className="admin-top">
            <div className="dashboard-title admin-title">Exercise Form</div>
            <div className="admin-top-title">Click on the button below to create a new form for the customer!</div>
            <Link to={"/createForm"} className="admin-exercise">
              <button className="dash-btn" >Create</button>
            </Link>
          </div>
        )}
        <div className="dashboard-title">My Forms</div>
        <div className="admin-my-forms">
          {form?.slice().reverse().map((data, value) => (
            <div className='admin-my-forms-container' key={value} > 
                <div className="admin-my-form-1">
                  <img src={Form} alt="" className='admin-my-form-1-img' />
                </div>
                <div className="admin-my-form-2">
                  <div className="admin-my-form-2-left">
                    {data.formName}
                  </div>
                  <div className="admin-my-form-2-right dashMembers-dropdown">
                      <MoreVertIcon className="dashMembersDropBtn" onClick={() => toggleDropdown(data._id)}/>
                      <div id={`dashMembersDropdownContent-${data._id}`}className="dashMembers-drop-content">
                        {['Admin', "Regional Manager"].includes(user.role) && (
                            <Link to={`/updateForm/${data._id}`} className="new-AContent">
                              Update
                            </Link>
                        )}
                            <Link
                              to={`/dashboard/admin/form/${data._id}`}
                              className="new-AContent"
                            >
                              Response
                            </Link>
                            {['Admin', "Regional Manager"].includes(user.role) && (
                            <Link onClick={() => handleDelete(data._id)} className="new-AContent">
                              Delete
                            </Link>
                        )}

                      </div>
                    </div>
                </div>
            </div>
          ))}
        </div>
        {showModal && (
          <DeleteModal
            h4={"Are you sure you want to delete this form?"}
            confirmDelete={confirmDelete}
            closeModal={() => setShowModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ExerciseForm;
