import React, { useEffect, useState } from 'react'
import '../../css/ProgramPage.css'
import instance from '../../utils/axios'
import { useParams } from 'react-router-dom'
import useUserStore from "../../store";
import {toast} from 'react-hot-toast';
import {motion} from "framer-motion"
import Layout from '../MainLayout/Layout'
import Loader from "../../components/MainLoader/Loader"
import {useNavigate} from "react-router-dom";

const SingleProgram = () => {

  const user = useUserStore((state) => state.user);
  const loggedIn = useUserStore((state) => state.loggedIn)
  const [program, setProgram] = useState(null);
  const { programId } = useParams();
  const nav = useNavigate();
  
  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await instance.get(`/api/user/getParticularprogram/${programId}`);
        if (response.data.success) {
          setProgram(response.data.data);
        } else if(response.data.error) {
          toast.error(response.data.error);
        }else {
          toast.error("An Error Occurred. Please Try Again Later!")
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchProgram();
  }, [programId]);

  if (!program) {
    return <Loader/>;
  }

  const handleAddToCart = async () => {
    if(!loggedIn) {
      toast.error("Please Login To Continue");
      nav("/login")
      return;
    }
    const item = {
      ...program,
      id: program.id,
      name: program.category,
      price: program.price,
      quantity: 1,
      size: "NA",
      itemType: "program",
    };
    try{
      const response = await instance.post("/api/user/addToCart", {item, userId: user._id})
      if(response.data.success) {       
        toast.success("Event added to cart");
      }else if(response.data.error) {
        toast.error(response.data.error);
      }else{
        toast.error("An Error Occurred. Please Try Again Later!");
      }
    }catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <Layout>
        <motion.div className='singleProgram'
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x:0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="singleProgram-container">
                <h3 className="program-header">{program.category}</h3>
                <div className="single-program">
                    <div className="single-program-left">
                        <img src={program.file.secure_url} alt="programImg" loading="lazy"/>
                    </div>
                    <div className="single-program-right">
                        <h4 className="single-program-name r1">Name: {program.category}</h4>
                        <h4 className="single-program-name r1">Place: {program.place}</h4>
                        <h4 className="single-program-date r1">Date & Time: {program.time}</h4>
                        <h4 className="single-program-name r1">Address: {program.town}, {program.district}, {program.city}, {program.state} - {program.pincode}</h4>
                        <h4 className="single-program-desc r1">Description: {program.desc}</h4>
                        <h4 className="single-program-desc r1">Price: ₹{program.price}</h4>
                        <button className="send-btn" onClick={() => handleAddToCart(program)}>Add To Cart</button>
                    </div>
                </div>
            </div>
        </motion.div>
    </Layout>
  )
}

export default SingleProgram