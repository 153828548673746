import React, {useState } from 'react'
import { motion } from 'framer-motion'
import Accounts1 from "./Accounts1"
import Merchandise from "./Merchandise"
import Events from "./Events"
import { Switch } from '@mui/material'
import Membership from './membership'

const Accounts = () => {

    const [isActive, setIsActive] = useState(1)
    const [isOnline, setIsOnline] = useState("Offline");

    const handleActive = (btn) => setIsActive(btn);
    
    const handleSwitchChange = () => {
        setIsOnline((prevIsOnline) => prevIsOnline === "Offline" ? "Online" : "Offline");
    };

  return (
    <div className="dashMembers">
    <motion.h3 className="dashMembers-container dashboard-title"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            style={{marginBottom: "1rem"}}
    >
        PM Accounts
        <div>
            <Switch
                name="showPlan"
                color="primary"
                checked={isOnline === "Online"}
                onChange={handleSwitchChange}
            >
            </Switch>
            <span>
                {isOnline}
            </span>
        </div> 
    </motion.h3>
    <div className='tabsContainer'>
                <motion.div className="btnContainer"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.3}}
                >
                    <button className={`tabs ${isActive === 1 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(1)}> Accounts </button>
                    <button className={`tabs ${isActive === 2 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(2)}> Merchandise </button>
                    <button className={`tabs ${isActive === 3 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(3)}>Events</button>
                    <button className={`tabs ${isActive === 4 ? 'activeTab' : ''}`}
                        onClick={() => handleActive(4)}>Membership</button>
                </motion.div>
                {isActive === 1 && <div className="tabData">
                    <motion.div className="tabContent"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.5 }}
                    >   
                        <Accounts1/>
                    </motion.div>
                </div>}
                {isActive === 2 && <div className="tabData">
                    <motion.div className="tabContent"
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.5 }}                                
                    >
                        <Merchandise isOnline={isOnline} />
                    </motion.div>
                </div>}
                {isActive === 3 && <div className="tabData">
                    <motion.div className="tabContent"
                                 initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.5 }}
                    >
                        <Events isOnline={isOnline}/>
                    </motion.div>
                </div>}
                {isActive === 4 && <div className="tabData">
                    <motion.div className="tabContent"
                                 initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: 0.5 }}
                    >
                        <Membership isOnline={isOnline}/>
                    </motion.div>
                </div>}
    </div>

</div>

  )
}

export default Accounts