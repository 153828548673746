import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/Calendar.css";
import { DataGrid } from "@mui/x-data-grid";
import instance from "../../../utils/axios";
import { motion } from "framer-motion";
import useUserStore from "../../../store";
import moment from "moment";
import { defaultUrl } from "../../../data/Images";

const PTcalendar = () => {
  const [userData, setUserData] = useState([]);
  const user = useUserStore((state) => state.user);
  const [length, setLength] = useState(0);

  useEffect(() => {
    instance
      .get("/api/user/getAllTrainer")
      .then((response) => {
        if (response.data.success) {
          const userRole = user.role;
          const userGymLocation = user.gymLocation;
          const username = user.username;

          let filteredData = response.data.data;

          if (userRole === "Branch Manager" || userRole === "Sales") {
            filteredData = filteredData.filter(
              (user) => user.gymLocation === userGymLocation
            );
          }
          if (userRole.includes("Trainer")) {
            filteredData = filteredData.filter(
              (user) => user.username === username
            );
          }
          // Map the filtered response data to rows state
          const rows = filteredData
            .slice()
            .reverse()
            .map((user) => {
              const startTime = moment(
                user.batch.batchStartTime,
                "HH:mm:ss"
              ).format("h:mm A");
              const endTime = moment(
                user.batch.batchEndTime,
                "HH:mm:ss"
              ).format("h:mm A");
              const shiftInfo = `${user.batch.batchShift}: ${startTime} - ${endTime}`;
              return {
                id: user._id,
                col1: user.file && user.file.secure_url,
                col2: user.name,
                col3: user.role,
                col4: user.gymLocation,
                col5: shiftInfo || "N/A",
              };
            });
          setUserData(rows);
          setLength(filteredData.length);
        }
      })
      .catch((error) => { });
  }, [user.gymLocation, user.role, user.username]);

  const columns = [
    {
      field: "col1",
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img
          src={
            params.value === "" || params.value.length === 0
              ? defaultUrl
              : params.value
          }
          alt="user"
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
      ),
    },
    { field: "col2", headerName: "Counsellor Name", width: 250 },
    { field: "col3", headerName: "Position", width: 150 },
    { field: "col4", headerName: "Branch", width: 150 },
    { field: "col5", headerName: "Batch Timing", width: 250 },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/dashboard/calendar/PTcalendar/listCalendar/${params.row.id}`}
              className="Link"
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dataTable ">
      <motion.div
        className="datatableTitle dashboard-title"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        Personal Trainer's List ({length})
      </motion.div>
      <motion.div
        className="dataGrid"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <DataGrid
          rows={userData}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </motion.div>
    </div>
  );
};

export default PTcalendar;
