import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import {motion} from "framer-motion"
import "../../css/DashMembers.css"
import { Link } from 'react-router-dom';

const Merchandise = ({ isOnline }) => {

  const [userData, setUserData] = useState([]);
         
  useEffect(() => {
    instance.get('/api/user/getProductData').then((response) => {
      if (response.data.success) {
        let filteredData = response.data.data;      
        const rows = filteredData && filteredData.slice().reverse().map((user) => {

          return {
            id: user._id,
            col1: user.file && user.file.secure_url[0],
            col2: user.brand,
            col3: user.name,
            col4: new Date(user.createdAt).toLocaleDateString("en-GB"),
            col5: user.quantity,
            col6: user.quantitySold,
            col7: isOnline === 'Online' ? user.websiteProductSold : user.manualProductSold,
            col8: user.quantityLeft,
            col9: formatAmount(user.totalEarning),
            col10: isOnline === 'Online' ? formatAmount(user.websiteEarning) : formatAmount(user.manualEarning),
            col11: formatAmount(user.gstAmount),
            col12: "₹0",
            col13: formatAmount(user.profit),
          }
        });        
        setUserData(rows);
      }
    }).catch((error) => {
        
    });
  }, [isOnline]);
  
  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };


  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 70,
      renderCell: (params) => (
        <img src={params.value === "" || params.value.length === 0 ? "https://img.freepik.com/free-icon/user_318-159711.jpg?w=2000" : params.value } alt="user" style={{width: '40px', height: '40px', borderRadius: '50%'}} />
      ),
    },
    { field: 'col2', headerName: 'Brand Name', width: 150, renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/products/analysis/${params.row.id}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col3', headerName: 'Product Name', width: 150 },
    { field: 'col4', headerName: 'Created On', width: 100 },
    { field: 'col5', headerName: 'Quantity', width: 100 },
    { field: 'col6', headerName: 'Quantity Sold', width: 100 },
    { field: 'col7', headerName: `${isOnline} Sold`, width: 100 },
    { field: 'col8', headerName: 'Quantity Left', width: 150 },
    { field: 'col9', headerName: 'Total Sales', width: 150 },
    { field: 'col10', headerName: `${isOnline} Sales`, width: 150 },
    { field: 'col11', headerName: 'GST Amount', width: 150 },
    { field: 'col12', headerName: 'Platform Fees', width: 150 },
    { field: 'col13', headerName: 'Profit', width: 150 },
  ];

  return (
    <div className='data2Table '>
    <motion.div className='dataGrid'
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
    >     
      <DataGrid 
          rows={userData}   
          columns={columns}         
          pageSize={9}
          rowsPerPageOptions={[9]}
      />
    </motion.div>
  </div>
)
}

export default Merchandise;
