import React from 'react';
import { motion } from 'framer-motion';
import AutorenewIcon from "@mui/icons-material/Autorenew";

const ExcelModal = ({ showModal, closeModal, handleFileChange, addMember, h4, loading }) => {
  return (
    showModal && (
      <div className="modal1">
        <motion.div
          className="modal-content1"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          <h4 className="delete-para">{h4}</h4>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
          <div className="delete-button">
          <button onClick={addMember} className={`btn1 ${loading ? "upload-btn" : ""}`} disabled={loading} >
            {loading ? <AutorenewIcon  sx={{fontSize: "16px"}} className="loader-css" /> : "Add"}
          </button>
            <button className="btn2" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </motion.div>
      </div>
    )
  );
};

export default ExcelModal;
