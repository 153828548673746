import React from 'react'
import Layout from '../MainLayout/Layout'
import video from '../../assets/PMFamily.webp'
import "./About.css"
import Map from './Map'

import {motion} from "framer-motion"
import { useInView } from 'react-intersection-observer';
import Contact from '../../components/MainContact/Contact'

const AboutUs = () => {

    const variants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.7,
            delay: 0.5,
          },
        },
      };
    
      const ComponentWithAnimation = ({ children }) => {
        const [ref, inView] = useInView({
          triggerOnce: false, 
        });
    
        return (
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={variants}
            transition={{
              duration: 0.7,
              delay: 0.5,
            }}
            exit="hidden"
            whileDrag={{ scale: 0.9 }}
            style={{ border: 'none', outline: 'none' }}
          >
            {children}
          </motion.div>
        );
      };
    
    
  return (
    <div>
        <Layout>
            <div className="about">
                <ComponentWithAnimation>
                    <div className="website-header">
                        About Planet Muscle
                    </div>
                    <div className="aboutUs">
                        <div className="aboutUs-left">
                            <img src={video} alt="" className='aboutUs-img' />
                        </div>
                        <div className="aboutUs-right">
                            Welcome to Planet Muscle, your premier fitness destination in Versova and Andheri West! With over 2000 clients and a team of 20+ dedicated staff members, we are committed to helping you achieve your health and wellness goals. At Planet Muscle, our state-of-the-art facilities provide a wide range of fitness options. From well-equipped gyms featuring the latest exercise machines to group fitness classes like yoga and HIIT, we cater to diverse needs. Our certified trainers and nutrition experts are passionate about guiding you on your fitness journey. We pride ourselves on fostering a supportive and inclusive environment. Our nutrition experts offer personalised guidance and meal plans, ensuring a holistic approach to wellness. We also organise special events and workshops to enhance your fitness experience. Planet Muscle is more than just a fitness company; we are a family. We create a space where individuals from all walks of life can pursue their fitness goals together. Join our community and embark on a transformative journey towards a healthier, stronger, and more vibrant life.
                        </div>
                    </div>
                </ComponentWithAnimation>
                <ComponentWithAnimation>
                    <Map />
                </ComponentWithAnimation>
                <ComponentWithAnimation>
                    <Contact />
                </ComponentWithAnimation>

            </div>
        </Layout>
    </div>
  )
}

export default AboutUs