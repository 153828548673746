import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../css/DashAnalysis.css";
import instance from "../../../utils/axios";
import useUserStore from "../../../store";
import  {toast} from "react-hot-toast"
import { gymName } from "../../../data/Gym";
import { defaultUrl } from "../../../data/Images";
import { Link } from "react-router-dom";

const Collection = ({setGst, setTotal}) => {

  const user = useUserStore((state) => state.user);  
  const [uniqueId, setUniqueId] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
    center: "",
    createdBy: "",
    paymentMode: "",
    planName: "",
  });
  const [collectionData, setCollectionData] = useState([]);
  const [collectionData1, setCollectionData1] = useState([]);
  const [collectionData2, setCollectionData2] = useState([]);
  const [collectionData3, setCollectionData3] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [filtered, setFiltered] = useState(false);

  const columns = [
    {field: "method", headerName: "Method", width: 150,},
    {field: "amount",headerName: "Amount",width: 100,},
  ];

  const formatIndianNumber = (number) => {
    const numberWithNoCommas = number.toString().replace(/,/g, '');
    const formattedNumber = new Intl.NumberFormat("en-IN").format(numberWithNoCommas);
    return formattedNumber;
  };

  const columns1 = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 90,
      renderCell: (params) => (
        <img
          src={params.value || defaultUrl}
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          alt=""
        />
      ),
    },
    { field: 'col2', headerName: 'Unique ID', width: 100 },
    {
      field: 'col3', 
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.userId}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Performance Center', width: 150 },
    { field: 'col7', headerName: 'Plan Type', width: 200 },
    { field: 'col8', headerName: 'Plan Time', width: 150 },
    { field: 'col9', headerName: 'Purchase Date', width: 150 },
    { field: 'col10', headerName: 'Date of Commencement', width: 150 },
    { field: 'col11', headerName: 'Last Date', width: 150 },
    { field: 'col12', headerName: 'Days Left', width: 150 },
    { field: 'col13', headerName: 'Status', width: 150 },
];

  const planNameData = async () => {
    try {
      await instance
        .get("/api/user/getPlanName")
        .then((response) => {
          setPlanData(response.data.data);
        })     
    } catch (error) {
      if (["Admin", "Developer"].includes(user.role)) {
        console.log(error.message);
      }
    }
 }

 const getUserName = async () => {
  try {
    await instance
    .get("/api/user/usernames", {
      params:{
        userRole: user.role,
        userGymLocation: user.gymLocation,
      },
    })
    .then((response) => {
      setUniqueId(response.data.data);
    })   
  } catch  (error) {
    if (["Admin", "Developer"].includes(user.role)) {
      console.log(error.message);
    }
  }
}

  useEffect(() => {
      getUserName();
      planNameData();  
      //eslint-disable-next-line  
    }, [user.role, user.gymLocation]);

  const setData = (e) => {
      const {name, value} = e.target;
      setFilter((preval) => {
        return {
          ...preval,
          [name]: value,
        }
      })
  }
  
  const getCollection = async () => {
    try {
      const response = await instance.get('/api/user/getCollectionData',{
        params: {
          userRole: user.role,
          gymLocation: user.gymLocation,
        }
      });
      if (response.data.success) {
        const formattedData = Object.entries(response.data.data.paymentMode)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData(formattedData);

        const formattedData1 = Object.entries(response.data.data.planType)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData1(formattedData1);

        const formattedData2 = Object.entries(response.data.data.planMode)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData2(formattedData2);

        const formattedData3 = response.data.data.gst;
        setGst(formattedData3)

        const formattedData4 = response.data.data.total;
        setTotal(formattedData4);

        const userPlanDetails = response.data.data.userPlansDetails;
        if (userPlanDetails && Array.isArray(userPlanDetails)) {
          const formattedData3 = userPlanDetails.map((plan, index) => ({
            id: index,
            userId: plan._id,
            col1: plan.file.secure_url || defaultUrl,
            col2: plan.uniqueId,
            col3: plan.name,
            col4: plan.email,
            col5: plan.mobile, 
            col6: plan.gymLocation,
            col7: plan.planType,
            col8: plan.planTime, 
            col9: new Date(plan.purchaseDate).toLocaleDateString("en-GB"),
            col10: new Date(plan.startDate).toLocaleDateString("en-GB"),
            col11: new Date(plan.endDate).toLocaleDateString("en-GB"), 
            col12: plan.daysLeft,
            col13: plan.status,
          }));
          setCollectionData3(formattedData3);
        }
      }    
    } catch (error) {      
    if (["Admin", "Developer"].includes(user.role)) {
      console.log(error.message);
    }
  }
  }

  const getFilteredCollectionData = async() => {
    try{
      setFiltered(true);
      const response = await instance.get("/api/user/getFilteredCollectionData", {
        params: {
          userRole: user.role,
          gymLocation: user.gymLocation,
          startDate: filter.startDate,
          endDate: filter.endDate,
          center: filter.center,
          createdBy: filter.createdBy,
          paymentModes: filter.paymentMode,
          planname: filter.planname,
        }
      })
      if(response.data.success === true) {
        const formattedData = Object.entries(response.data.data.paymentMode)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData(formattedData);

        const formattedData1 = Object.entries(response.data.data.planType)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData1(formattedData1);

        const formattedData2 = Object.entries(response.data.data.planMode)
        .filter(([key]) => key !== 'success')
        .map(([key, value]) => ({
          id: key,
          method: key,
          amount: formatIndianNumber(value),
        }));
        setCollectionData2(formattedData2);

        const formattedData3 = response.data.data.gst;
        setGst(formattedData3)

        const formattedData4 = response.data.data.total;
        setTotal(formattedData4);

        const userPlanDetails = response.data.data.userPlansDetails;
        if (userPlanDetails && Array.isArray(userPlanDetails)) {
          const formattedData3 = userPlanDetails.map((plan, index) => ({
            id: index,
            userId: plan._id,
            col1: plan.file.secure_url || defaultUrl,
            col2: plan.uniqueId,
            col3: plan.name,
            col4: plan.email,
            col5: plan.mobile, 
            col6: plan.gymLocation,
            col7: plan.planType,
            col8: plan.planTime, 
            col9: new Date(plan.purchaseDate).toLocaleDateString("en-GB"),
            col10: new Date(plan.startDate).toLocaleDateString("en-GB"),
            col11: new Date(plan.endDate).toLocaleDateString("en-GB"), 
            col12: plan.daysLeft,
            col13: plan.status,
          }));
          setCollectionData3(formattedData3);
        }
      }else {
        toast.error(response.data.message);
      }
    }catch(error) {
      toast.error("Something Went Wrong. Please Try Again.")
    }
  }

  useEffect(() => {
    if(!filtered) {
      getCollection();
    }
    // eslint-disable-next-line
}, [filtered])

const calculateTotal = (data) => {
  const total = data.reduce((acc, row) => acc + parseFloat(row.amount.replace(/,/g, '')), 0);
  return total;
};

const getTotalRow = (total) => {
  const formattedTotal = formatIndianNumber(total);
  return [{ id: 'total', method: 'Total', amount: `₹${formattedTotal}`}];
};

  return (
    <div className="collection-container">
      <h3 className="collection-h3">Collection Type</h3>
      <div className="filter-container">
        <input
          type="date"
          value={filter.startDate || ""}
          className="filter-input"
          onChange={setData}
          name="startDate"
        />
        <input
          type="date"
          name="endDate"
          value={filter.endDate || ""}
          className="filter-input"
          onChange={setData}
        />
        {["Admin", "Regional Manager", "Developer", "Accounts"].includes(user.role) ? (
          <select
            className="filter-select"
            value={filter.center || ""}
            onChange={setData}
            name="center"
          >
            <option value="">Select Center</option>
            {gymName.map((a) => (
              <option value={a.name} key={a}> {a.name} </option>
            ))}
          </select>
        ) : ""}
        <select name="paymentMode" className="filter-select"  value={filter.paymentMode || ""}
          onChange={setData}
>
          <option value="">Select Payment Method</option>
          <option value="Cash">Cash</option>
          <option value="Card">Card</option>
          <option value="UPI">UPI</option>
          <option value="Cheque">Cheque </option>
          <option value="RTGS">RTGS</option>
          <option value="NEFT">NEFT</option>
          <option value="Net Banking">Net Banking</option>
        </select>
        <select name="createdBy" className="filter-select"  value={filter.createdBy || ""}
          onChange={setData}
>
          <option value="">Created By</option>
          {uniqueId?.map((name, id) => (
            <option key={id} value={name} >{name}</option>
          ))}

        </select>
        <select name="planname" className="filter-select"  value={filter.planname || ""} onChange={setData}>
          <option value="">MemberShip Type</option>
          {planData?.map((name, id) => (
              <option key={id} value={name.planname}>{name.planname}</option>
          ))}       
        </select>
        <button className="comment-btn dash-btn" onClick={getFilteredCollectionData}>
          Filter
        </button>
      </div>
      <div className="collection">
        <div className="collection1">
          <h3 className="collection-h3">Payment Mode wise Collection</h3>
          <DataGrid
            rows={collectionData.concat(getTotalRow(calculateTotal(collectionData)))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
          />
        </div>
        <div className="collection1">
          <h3 className="collection-h3">MemberShip Mode wise Collection</h3>
          <DataGrid
            rows={collectionData1.concat(getTotalRow(calculateTotal(collectionData1)))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
          />
        </div>
        <div className="collection1">
          <h3 className="collection-h3">Type wise Collection</h3>
          <DataGrid
            rows={collectionData2.concat(getTotalRow(calculateTotal(collectionData2)))}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <div className="collection2">
      <h3 className="collection-h3">Search Results</h3>
      <DataGrid
            rows={collectionData3}
            columns={columns1}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
          />
      </div>
    </div>
  );
};

export default Collection;
