import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import { motion } from "framer-motion"
import useUserStore from '../../../store';
import Comment from '../../components/DashboardComments/Comment';

const DetailCalendar = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [username, setUsername] = useState("");
  const [name, setName] = useState('');
  const [addComment, setAddComment] = useState([]);
  const [length, setLength] = useState("");
  const user = useUserStore((state) => state.user);
  const currentDate = new Date();
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);

  const formattedDateTime = `${currentDate.toLocaleDateString(
    "en-GB"
  )} ${currentDate.toLocaleTimeString("en-GB")}`;

  const setData = (e) => {
    const { name, value } = e.target;
    setName((preval) => {
      return {
        ...preval, [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post('/api/user/commentData', {
        comment: name.comment,
        username: id,
        addedFrom: user.name,
        location: "Calendar",
        callSubject: name.callSelect,
        callResponse: name.callResponse,
        reason: name.reasons,
        priority: name.priority,
        nextCallDate: name.callDate,
        nextCallTime: name.callTime,
        callStatus: name.callStatus,
        callDateAndTime: formattedDateTime,
      });
      if (response.data.success) {
        toast.success('Your Comment is Added Successfully');
        e.target.reset();
        setName({ comment: '' });
        // Fetch the updated comments after adding a new comment
        fetchComments();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await instance.get(`/api/user/getCommentData/${id}`, {
        params: {
          location: "Calendar",
        },
      });
      if (response.data.success) {
        setAddComment(response.data.data);
        setLength(response.data.data.length);
      } else {
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchComments();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = () => {
    instance
      .get(`/api/user/getEventsData/${id}`)
      .then((response) => {
        if (response.data.success) {
          setUsername(response.data.name);
          const rows = response.data.data && response.data.data.map((user) => {
            let status = "";
            if (user.attended === 1) {
              status = "Present";
            } else if (user.absent === 1) {
              status = "Absent";
            } else {
              status = "TBD";
            }
            return {
              id: user._id,
              col1: new Date(user.startdate).toLocaleDateString('en-GB'),
              col2: new Date(user.enddate).toLocaleDateString('en-GB'),
              col3: user.starttime,
              col4: user.endtime,
              col5: status,
            };
          });
          setUserData(rows);
        }
      })
      .catch((error) => {
      });
  };

  const updateEvent = (eventId, field, value) => {
    const eventData = {
      field: field,
      value: value,
    };

    instance
      .put(`/api/user/update/${eventId}`, eventData)
      .then((response) => {
        if (response.data.success) {
          toast.success("Session Updated SuccessFully");
          const updatedUserData = userData.map((user) => {
            if (user.id === eventId) {
              if (field === 'attended') {
                return {
                  ...user,
                  col5: 'Present',
                };
              } else if (field === 'absent') {
                return {
                  ...user,
                  col5: 'Absent',
                };
              }
            }
            return user;
          });
          setUserData(updatedUserData);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
      });
  };

  const handleAdd = (eventId) => {
    const eventToUpdate = userData.find((user) => user.id === eventId);
    if (eventToUpdate && eventToUpdate.col1 === getCurrentDate()) {
      updateEvent(eventId, 'attended', 1, 'present');
    } else {
      toast.error("Can only update on current date");
    }
  };

  const handleLapse = (eventId) => {
    const eventToUpdate = userData.find((user) => user.id === eventId);
    if (eventToUpdate && eventToUpdate.col1 === getCurrentDate()) {
      updateEvent(eventId, 'absent', 1, 'absent');
    } else {
      toast.error("Can only update on current date");
    }
  };
  const getCurrentDate = () => {
    const currentDate = new Date().toLocaleDateString('en-GB');
    return currentDate;
  };

  const columns = [
    { field: 'col1', headerName: 'Start Date', width: 150 },
    { field: 'col2', headerName: 'End Date', width: 150 },
    { field: 'col3', headerName: 'Start Time', width: 150 },
    { field: 'col4', headerName: 'End Time', width: 150 },
    { field: 'col5', headerName: 'Status', width: 150 },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const eventId = params.row.id;
        return (
          <div className="cellAction">
            {["Admin", "Regional Manager", "Branch Manager"].includes(user.role) ? (<>
              <div className="viewButton" onClick={() => handleAdd(eventId)}>Add </div>
              <div className="deleteButton" onClick={() => handleLapse(eventId)}>Lapse</div>
            </>) : ""}
          </div>
        );
      },
    },
  ];

  const handleFilter = () => {
    const formattedStartDate = startDate ? new Date(startDate).toLocaleDateString('en-GB') : null;
    const formattedEndDate = endDate ? new Date(endDate).toLocaleDateString('en-GB') : null;

    const filteredComments = addComment.filter((comment) => {
      const commentDate = comment.callDateAndTime.split(" ")[0];
      const dateCondition = !formattedStartDate || !formattedEndDate || ((commentDate >= formattedStartDate ||
        commentDate === formattedStartDate) && (commentDate <= formattedEndDate || commentDate === formattedEndDate));
      return dateCondition;
    });

    if (filteredComments === null || filteredComments.length === 0) {
      return toast.error("No Comments Found");
    }
    setFilteredComments(filteredComments);
  };

  const commentsToRender = filteredComments.length > 0 ? filteredComments : addComment && addComment.slice().reverse();

  return (
    <div className='dataTable '>
      <motion.div className="datatableTitle dashboard-title"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {username}'s PT Review
      </motion.div>
      <motion.div className='dataGrid'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <DataGrid
          rows={userData}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
      >
        <Comment
          length={length}
          name={name}
          setData={setData}
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          commentsToRender={commentsToRender}
          formattedDateTime={formattedDateTime}
        />
      </motion.div>

    </div>
  )
}

export default DetailCalendar;
