import React, { useEffect, useState } from 'react'
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import {toast} from "react-hot-toast";
import { gymName } from '../../../data/Gym';

const PendingUser = () => {

  const [penUser, setPenUser] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedGym, setSelectedGym] = useState('');
  const [batchDetails, setBatchDetails] = useState([]);
  const [uniqueIdData, setUniqueIdData] = useState("");

  const getData = async() =>{
    try{
      const response = await instance.get('/api/user/pendingUser')
      if (response.data.success) {
        const rows =  response.data.data && response.data.data.slice().reverse().map((info) => ({
          id: info._id,
          col1: info.name,
          col2: info.email,
          col3: info.role,
          col4: info.gymLocation,    
          col5: info.batch,    
          col6: info.mobile,
          col7: info.uniqueId,
        }));
        setPenUser(rows);
      }else {
        toast.error(response.data.message);
      }
    }catch(error){
      toast.error("An Error Occurred. Please Try Again!");
    }
  }
  useEffect(() =>{
    getData();
    // eslint-disable-next-line
  },[])

  const columns = [
    { field: 'col1', headerName: 'Name', width: 150 },
    { field: 'col2', headerName: 'Email', width: 200 },
    {
      field: 'col3',
      headerName: 'Role',
      width: 200,
      renderCell: (params) => {
        const { id, col3 } = params.row;
  
        const handleRoleChange = (event) => {
          const selectedRole = event.target.value;
          const updatedRows = penUser.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                col3: selectedRole,
              };
            }
            return row;
          });
          setPenUser(updatedRows);
          setSelectedRole(selectedRole);
        };
  
        return (
          <select value={col3} onChange={handleRoleChange}>
            <option value="" >Select Employee Role</option>
            <option value="Regional Manager">Regional Manager</option>
            <option value="Branch Manager">Branch Manager</option>
            <option value="Master Trainer">Master Trainer</option>
            <option value="Platinum Trainer">Platinum Trainer</option>
            <option value="Diamond Trainer">Diamond Trainer</option>
            <option value="Gold Trainer">Gold Trainer</option>
            <option value="Silver Trainer">Silver Trainer</option>
            <option value="Sales">Sales</option>
            <option value="Accounts">Accounts</option> 
            <option value="Sales Manager">Sales Manager</option> 
            <option value="Online Manager">Online Manager</option> 
            <option value="Online Regional Manager">Online Regional Manager</option> 
            <option value="Human Resources">Human Resources</option> 
            <option value="Floor Manager">Floor Manager</option> 
          </select>
        );
      },
    },
    {
      field: 'col4',
      headerName: 'Performance Center',
      width: 200,
      renderCell: (params) => {
        const { id, col4 } = params.row;
  
        const handleGymChange = (event) => {
          const selectedGym = event.target.value;
          const updatedRow = penUser.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                col4: selectedGym,
              };
            }
            return row;
          });
          setPenUser(updatedRow);
          setSelectedGym(selectedGym);
        };
  
        return (
          <select value={col4} onChange={handleGymChange}>
            <option value="" >Select Performance Center</option>
            {gymName.map((a) => (
              <option value={a.name} key={a}> {a.name} </option>
            ))}

          </select>
        );
      },
    },
    {
      field: 'col5',
      headerName: 'Batch Details',
      width: 450,
      renderCell: (params) => {
        const { id } = params.row;

        const index = batchDetails.findIndex(detail => detail.id === id);
        const localStartTime = index !== -1 ? batchDetails[index].startTime : '';
        const localEndTime = index !== -1 ? batchDetails[index].endTime : '';
        const localBatchShift = index !== -1 ? batchDetails[index].batchShift : '';

        const handleBatchChange = () => {
          const updatedPenUser = penUser.map(user => {
            if (user.id === id) {
              return {
                ...user,
                col5: localBatchShift,
              };
            }
            return user;
          });

          const updatedBatchDetails = batchDetails.map(detail => {
            if (detail.id === id) {
              return {
                ...detail,
                startTime: localStartTime,
                endTime: localEndTime,
                batchShift: localBatchShift,
              };
            }
            return detail;
          });
          setPenUser(updatedPenUser);
          setBatchDetails(updatedBatchDetails);
        };

        return (
          <div>
            <input
              type="text"
              placeholder="Enter Start Time"
              onFocus={(e) => (e.target.type = 'time')}
              value={localStartTime}
              onChange={(e) => {
                const updatedStartTime = e.target.value;
                const updatedBatchDetails = [...batchDetails];
                if (index !== -1) {
                  updatedBatchDetails[index].startTime = updatedStartTime;
                } else {
                  updatedBatchDetails.push({
                    id,
                    startTime: updatedStartTime,
                    endTime: localEndTime,
                    batchShift: localBatchShift,
                  });
                }
                setBatchDetails(updatedBatchDetails);
              }}
            />
            <input
              type="time"
              value={localEndTime}
              onChange={(e) => {
                const updatedEndTime = e.target.value;
                const updatedBatchDetails = [...batchDetails];
                if (index !== -1) {
                  updatedBatchDetails[index].endTime = updatedEndTime;
                } else {
                  updatedBatchDetails.push({
                    id,
                    startTime: localStartTime,
                    endTime: updatedEndTime,
                    batchShift: localBatchShift,
                  });
                }
                setBatchDetails(updatedBatchDetails);
              }}
            />
            <select
              value={localBatchShift}
              onChange={(e) => {
                const updatedBatchShift = e.target.value;
                const updatedBatchDetails = [...batchDetails];
                if (index !== -1) {
                  updatedBatchDetails[index].batchShift = updatedBatchShift;
                } else {
                  updatedBatchDetails.push({
                    id,
                    startTime: localStartTime,
                    endTime: localEndTime,
                    batchShift: updatedBatchShift,
                  });
                }
                setBatchDetails(updatedBatchDetails);
              }}
            >
              <option value="">Select Batch Shift</option>
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
              <option value="All Day">All Day</option>
            </select>
            <button onClick={handleBatchChange}>Save</button>
          </div>
        );
      },
    },    
    { field: 'col6', headerName: 'Mobile', width: 200 },
    {
      field: 'col7',
      headerName: 'Unique ID',
      width: 200,
      editable: true, // Set the field as editable
      renderCell: (params) => {
        const { id, col7 } = params.row;
        // const handleUniqueIdChange = (event) => {
        //   const updatedRow = penUser.map((row) => {
        //     if (row.id === id) {
        //       return {
        //         ...row,
        //         col7: event.target.value,
        //       };
        //     }
        //     return row;
        //   });
        //   setPenUser(updatedRow);
        //   setUniqueIdData((prevData) => ({
        //     ...prevData,
        //     [id]: event.target.value,
        //   }));
        // };

        const handleUniqueIdChange = (event) => {
          const updatedRow = penUser.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                col7: event.target.value,
              };
            }
            return row;
          });
          setPenUser(updatedRow);
          setUniqueIdData((prevData) => ({
            ...prevData,
            [id]: event.target.value,
          }));
        };

        const handleUniqueIdBlur = () => {
          setPenUser((prevUsers) =>
            prevUsers.map((user) => {
              if (user.id === id) {
                return {
                  ...user,
                  col7: uniqueIdData[id] || col7, // Use the updated data or the original value
                };
              }
              return user;
            })
          );
        };
        return (
          <input
            type="text"
            value={col7}
            onChange={handleUniqueIdChange}
            onBlur={handleUniqueIdBlur}
          />
        );
      },
    },
  ];

  const acceptUser = async (id, role, gymLocation, batchDetails, uniqueId )  =>{
    try{
      const response = await instance.post('/api/user/approveStaff',{id, role: selectedRole, gymLocation: selectedGym, batchDetails,uniqueId})
      if(response.data.success){
        toast.success("Counsellor Created Successfully");
        setPenUser(prevUsers => prevUsers.filter(user => user.id !== id)); // Remove the user from the table
        getData();
      }else {
        toast.error(response.data.message);
      }
    }catch (error) {
          toast.error("An Error Occurred. Please Try Again!");
        }
      }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const handleDelete = async (id) => {
          try{  
            const response = await instance.post(`/api/user/deleteStaffRequest/${id}`)
              if (response.data.success) {
                toast.success("Counsellor deleted successfully");
                setPenUser(prevUsers => prevUsers.filter(user => user.id !== id)); 
                getData();
              }else {
                toast.error(response.data.message);
              }
            } catch (error) {
                toast.error("An Error Occurred. Please Try Again!");
            }
          }        
        return (
          <div className="cellAction">
            <div className="viewButton" onClick={() => acceptUser(params.row.id, selectedRole,selectedGym,batchDetails, params.row.col7, params.row.col8)}>Accept</div>
            <div className="viewButton" style={{border: "1px solid red", color: "red"}} onClick={() => handleDelete(params.id)}>Reject</div>
          </div>
        );
      },
    },
  ];

  return (
    <div className='data2Table'>
        <div className='dataGrid'>
        <DataGrid 
            rows={penUser}   
            columns={columns.concat(actionColumn)}         
            pageSize={9}
            rowsPerPageOptions={[9]}
        />
      </div>
    </div>
  )
}

export default PendingUser;


