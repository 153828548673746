import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import useUserStore from '../../../store';
import { defaultUrl } from '../../../data/Images';
import "../../css/DashMembers.css"
import DeleteModal from '../../components/Modal/DeleteModal';

const MemberData  = ({memberType, onFilteredDataLength}) => {
  const [memberData, setMemberData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [select, setSelect] = useState('');

  const user = useUserStore((state) => state.user)

  const closeModal = () => {
    setShowModal(false);
  }
  
  const getData = async () => {
    try {
      const response = await instance.get('/api/user/getdata',{
        params: {
          userRole: user.role,
          staffGymLocation: user.gymLocation,
        },
      });
      if (response.data.success) {
        const members = response.data.data[memberType === 'offline' ? 'offlineMembers' : 'onlineMembers'];
        const rows = await Promise.all(members.map(async (user) => {
          try {
            return {
              id: user.plans.individualPlanId,
              userId: user._id,
              col1: user.file.secure_url ? user.file.secure_url : "",
              col2: user.uniqueId ? user.uniqueId : "N/A",
              col3: user.name ? user.name : "N/A",
              col4: user.email ? user.email : "",
              col5: user.mobile ? user.mobile : "",
              col6: user.occupation ? user.occupation : "N/A",
              col7: user.plans ? user.plans.gymLocation : "N/A" ,
              col8: user.path ? user.path : "N/A",
              col9: user.plans ? user.plans.planType : "N/A",
              col10: user.plans ? user.plans.planTime : "N/A",
              col11: user.plans ? new Date(user.plans.startDate).toLocaleDateString("en-GB") : "N/A",
              col12: user.plans ? new Date(user.plans.endDate).toLocaleDateString("en-GB") : "N/A",
              col13: user.plans ? user.plans.daysLeft : "N/A",
              col14: user.plans ? user.plans.status : "N/A",
            };
          } catch (error) {
            console.log("Error fetching data for user", user._id);
            return null; 
          }
        })); 
        const filteredRows = rows.filter((row) => row !== null);
        setMemberData(filteredRows);
        setFilteredData(filteredRows.reverse());
        onFilteredDataLength(filteredRows.length);
      }
    } catch (error) {
      if (["Admin", "Developer"].includes(user.role)) {
        console.log(error.message);
      }
      toast.error("An Error Occurred. Please Try Again!");
    }
  }
  
  const handleDelete = async (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await instance.post(`/api/user/deleteUser/${userIdToDelete}`);
      if (response.data.success) {
        toast.success(`${memberType === "offline" ? "Offline" : "Online" } Member deleted successfully`);
        setMemberData((prevData) => prevData.filter((user) => user.id !== userIdToDelete));
        setShowModal(false);
        getData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
        toast.error('An error occurred');
    }
  };
  const columns = [
    {
      field: 'col1',
      headerName: 'Image',
      width: 90,
      renderCell: (params) => (
        <img
          src={params.value || defaultUrl}
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          alt=""
        />
      ),
    },
    { field: 'col2', headerName: 'Unique ID', width: 100 },
    {
      field: 'col3', 
      headerName: 'Name',
      width: 200,
      renderCell: (params) => (
        <Link className='link-userpage-member'
          to={`/dashboard/users/userPage/${params.row.userId}`} 
        >
          <div className="nameLink">{params.value}</div>
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Email ID', width: 220 },
    { field: 'col5', headerName: 'Mobile', width: 150 },
    { field: 'col6', headerName: 'Occupation', width: 150 },
    { field: 'col7', headerName: 'Performance Center', width: 150 },
    { field: 'col8', headerName: 'Health Issues', width: 150 },
    { field: 'col9', headerName: 'Plan Type', width: 200 },
    { field: 'col10', headerName: 'Plan Time', width: 150 },
    { field: 'col11', headerName: 'Date of Commencement', width: 150 },
    { field: 'col12', headerName: 'Last Date', width: 150 },
    { field: 'col13', headerName: 'Days Left', width: 150 },
    { field: 'col14', headerName: 'Status', width: 150 },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <div className="cellAction">
          <Link to={`/dashboard/users/userPage/${params.row.userId}`} style={{ textDecoration: "none" }}>
            <div className="viewButton">View</div>
          </Link>
          {user.role === "Admin" ? <div className="deleteButton" onClick={() => handleDelete(params.row.userId)}>Delete</div> : ""}
        </div>
      ),
    },
  ];

  const data = {
    rows: filteredData,
    columns: columns,
  };

  useEffect(()=>{
    getData();
    // eslint-disable-next-line 
  },[])


  const handleFilter = () => {
    if (!startDate || !endDate || !select) {
      toast.error("Please select all the field.");
      return;
    }
    const formattedStartDate = startDate ? new Date(startDate) : null;
    if (formattedStartDate) {
      // Set hours, minutes, seconds, and milliseconds to zero
      formattedStartDate.setHours(0, 0, 0, 0);
    }
    const formattedEndDate = endDate ? new Date(endDate) : null;
    if (formattedEndDate) {
      // Set hours, minutes, seconds, and milliseconds to zero
      formattedEndDate.setHours(0, 0, 0, 0);
    }
    const selectedType = select.toLowerCase();
    const filteredData = memberData.filter((user) => {
      if(selectedType.includes("startdate")) {
        const newDate = parseDate(user.col11);
        const startDateCondition = ((newDate >= formattedStartDate)||(newDate === formattedStartDate)) && 
        ((newDate <= formattedEndDate)||(newDate === formattedEndDate));
        return startDateCondition;
      }else {
        const newDate = parseDate(user.col12);
        const startDateCondition = ((newDate >= formattedStartDate)||(newDate === formattedStartDate)) && 
        ((newDate <= formattedEndDate)||(newDate === formattedEndDate));
        return startDateCondition;
      }
    });
    if (filteredData === null || filteredData.length === 0) {
      toast.error("No Members Found");
    }
    setFilteredData(filteredData);
    if (typeof onFilteredDataLength1 === 'function') {
      onFilteredDataLength(filteredData.length);
    }
  };

  const parseDate = (dateStr) => {
    const parts = dateStr.split("/");
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // January is month 0 in JavaScript Date object
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day, 0,0,0,0);
    }
    return null;
  };
  
  return (
    <div className='data2Table dashboard'>
      <div className="filter-container">
        <input
          type="date"
          value={startDate || ""}
          className='filter-input'
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate || ""}
          className='filter-input'
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select className='filter-select'  value={select} onChange={(e) => setSelect(e.target.value)}>
                <option value="">Select Filter Type</option>
                <option value="StartDate">StartDate</option>
                <option value="EndDate">EndDate</option>
        </select>
        <button className='comment-btn dash-btn' onClick={handleFilter}>Filter</button>
      </div>
      <motion.div className='dataGrid modal-opener'
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <DataGrid
          {...data}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}      
        />
      </motion.div>
      {showModal && (
        <DeleteModal 
            h4={` Are you sure you want to delete this ${memberType === 'offline' ? 'offline Member' : 'online Member'} ?`}
            confirmDelete={confirmDelete}
            closeModal={closeModal}
        />
      )}

    </div>
  );
};

export default MemberData ;
