import React, {useState, useEffect} from 'react'
import instance from '../../../utils/axios';
import { toast } from 'react-hot-toast';
import "../../css/DashUser.css"
import ReceiptIcon from '@mui/icons-material/Receipt';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Document2 from './Document2';


const CurrentEvents = ({userId}) => {
  const [event, setEvent] = useState("");
  const [userData, setUserData] = useState(null);
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await instance.get(`/api/user/getdata/${userId}`);
        if (response.data.success) {
          setUserData(response.data.data);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchUserData();
  }, [userId]);
    
  useEffect(() => {
    handleEvents();
    //  eslint-disable-next-line
  },[]);

  const handleEvents = async () => {
    try {
      const response = await instance.get("/api/user/getIndividualEvents", {
        params: {
          userId,
        }
      })
      if(response.data.success){
        setEvent(response.data.data.events)
      }
    } catch(error) {
      toast.error("An Error Occurred. Please Try Again Later!")
    }
  } 

  return (
    <div className='currentPlans'>
        {(!event || event.length === 0) &&  (
          <div className="currentPlans-container">
              <h4 className="currentPlan-key">
                Currently No Event Purchased! 
              </h4>
          </div>
        )}
        {event && event.length > 0 && event.slice().reverse().map((text, index, array) => (          
          <div className="currentPlans-container" key={index}>
            <h4 className="currentPlans-h4"> ({array.length - index})   {text.eventName} Event </h4>
            <div className="currentPlan-details">
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Performance Center</h4>
                  <h5 className="currentPlan-value">{text.gymLocation}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Event Name</h4>
                  <h5 className="currentPlan-value">{text.eventName}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Number Of Tickets</h4>
                  <h5 className="currentPlan-value">{text.tickets}</h5>
              </div>

              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Event Date</h4>
                  <h5 className="currentPlan-value">{text.eventTime}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Event Location</h4>
                  <h5 className="currentPlan-value">{text.eventPlace}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Purchase Date</h4>
                  <h5 className="currentPlan-value">{(new Date(text.purchaseDate).toLocaleDateString("en-GB"))}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Event Price</h4>
                  <h5 className="currentPlan-value">₹{text.totalEventPrice}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Applied GST</h4>
                  <h5 className="currentPlan-value">{text.gst ?  (text.gst === "1.18" ? "18%" : "5%") : "No"}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">GST Price</h4>
                  <h5 className="currentPlan-value">₹{text.gstAmount}</h5>
              </div>
              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Payment Mode</h4>
                  <h5 className="currentPlan-value">{text.paymentMode}</h5>
              </div>
              {text.paymentMode === "Card" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Card Type</h4>
                    <h5 className="currentPlan-value">{text.cardDetails?.cardType}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Bank Name</h4>
                    <h5 className="currentPlan-value">{text.cardDetails?.bankName}</h5>
                  </div>
                  <div className="currentDetails-container">
                   <h4 className="currentPlan-key">Card Number</h4>
                   {text.cardDetails?.last4Digits.length > 0 && (
                   <h5 className="currentPlan-value">XXXX-XXXX-XXXX-{text.cardDetails?.last4Digits}</h5>
                   )}
                 </div>
                </>
              )}
              {text.paymentMode === "UPI" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">UPI Detail</h4>
                    <h5 className="currentPlan-value">{text.upiDetails?.upiId}</h5>
                  </div>
                </>
              )}
              {text.paymentMode === "Cheque" && (
                <>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Cheque Number</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.chequeNumber}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Cheque Date</h4>
                    <h5 className="currentPlan-value">{new Date(text.chequeDetails?.chequeDate).toLocaleDateString("en-GB")}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Bank Name</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.bankName}</h5>
                  </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Branch Name</h4>
                    <h5 className="currentPlan-value">{text.chequeDetails?.branchName}</h5>
                  </div>
                </>
              )}

              <div className="currentDetails-container">
                  <h4 className="currentPlan-key">Billing Done By</h4>
                  <h5 className="currentPlan-value">{text.createdBy}</h5>
              </div>
              {text?.discountCode && (
                <>
                  <div className="currentDetails-container">
                      <h4 className="currentPlan-key">Discount Applied </h4>
                      <h5 className="currentPlan-value">Yes</h5>
                    </div>
                  <div className="currentDetails-container">
                    <h4 className="currentPlan-key">Discount Code</h4>
                    <h5 className="currentPlan-value">{text.discountCode}</h5>
                  </div>
                </>
              )}

            </div>
            <div className="additional-buttons addComment-btn">
              {/* download plan button */}
              <PDFDownloadLink className="dash-btn Link"  document={<Document2 planDetails={text} userData={userData}/>} fileName={`${text.eventName} Bill`}>
                  <ReceiptIcon/> Document
              </PDFDownloadLink>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CurrentEvents