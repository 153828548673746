import './Loader.css'
import React from 'react'

const Loader = () => {
  return (
    <div className='loader' > 
        <div className="loader-container">
          <div className="loader-spinner"></div>
        </div>
    </div>
  )
}

export default Loader