import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import PropTypes from "prop-types";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { AddQuestionForm } from "./QuestionForm";

const GoogleTab = () => {
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box >{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [values, setValues] = useState(0);
  const handleChange = (event, newValue) => {
    setValues(newValue);
  };
  
  return (
    <div className="google-form">
      <div className="google-form-header">
        <div className="google-form-header-left">
          <InsertDriveFileIcon className="google-form-main-icon" />
          <input
            type="text"
            placeholder="Untitled form"
            className="google-form-name"
            value=""
            readOnly
          />
        </div>
        <Button variant="contained" color="secondary">
          Response
        </Button>
      </div>
      <Box>
        <Box>
          <Tabs value={values} onChange={handleChange} centered>
            <Tab label="Questions" {...a11yProps(0)}></Tab>
            <Tab label="Responses" {...a11yProps(1)}></Tab>
          </Tabs>
        </Box>
        <CustomTabPanel value={values} index={0}>
          <AddQuestionForm />
        </CustomTabPanel>
        <CustomTabPanel value={values} index={1}>
          Item Two
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default GoogleTab;
